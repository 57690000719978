import asurion_loader from '@asurion-hub-web/assets/images/animations/asurion_dots_loader_v2.svg';
import React from 'react';
import styled from 'styled-components';
import Page from '../components/Page';

const StyledPage = styled(Page)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;
const ImageObject = styled.object`
  width: 180px;
`;

const LoadingPage = () => (
  <StyledPage pageName="LoadingPage" testid="loading-page">
    <ImageObject
      type="image/svg+xml"
      data={asurion_loader}
      aria-label="Loader"
    />
  </StyledPage>
);

export default LoadingPage;
