import { authClient } from '@asurion-hub-web/auth';
import {
  BREAK_POINTS_DIMENSIONS_VALUES_IN_PX,
  TabNav,
  TabNavProps,
  useWindowSize,
} from '@asurion-hub-web/ui';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { RouteBuilder } from '@asurion-hub-web/config';
import { WithStorageProps } from '@asurion-hub-web/react-utils-web';
import DetailsTab from './DetailsTab';
import DiscoverTab from './DiscoverTab';
import {
  DeviceDetails,
  PlanDetails,
  TabProps,
  ClaimsInformation,
} from '../../../../types';
import ClaimsTab from './ClaimsTab';
import { parse } from 'query-string';

interface TabsProps extends WithStorageProps {
  deviceDetails: DeviceDetails & { subscriberId?: string };
  planDetails: PlanDetails;
  deviceFriendlyName: string;
  OS?: string;
  benefitsCardsEnabled: boolean;
  claimHistoryLimitInDays?: number;
  claimsInformation: ClaimsInformation;
  missingClaimsLink?: string | null;
}

type TabKey = 'discover' | 'benefits' | 'details' | 'claims';
type Tabs = TabNavProps<TabKey, TabProps>['tabs'];

const tabs: Tabs = [
  {
    key: 'discover',
    label: 'Discover',
    Component: DiscoverTab,
    onlyForPlansWithSupport: true,
  },
  {
    key: 'details',
    label: 'Details',
    Component: DetailsTab,
  },
  {
    key: 'claims',
    label: 'Claims',
    Component: ClaimsTab,
  },
];

const isValidTab = (tabKey: TabKey, tabs: Tabs) =>
  tabs.some(({ key }) => key === tabKey);

const filterVisibleTabs = (tabs: Tabs, planIncludesSupport: boolean) =>
  tabs.filter((tab) => !tab.onlyForPlansWithSupport || planIncludesSupport);

const TabsComponent: React.FC<TabsProps> = ({
  deviceDetails,
  planDetails,
  benefitsCardsEnabled,
  OS,
  claimsInformation,
  deviceFriendlyName,
  getItem,
  setItem,
  missingClaimsLink,
}) => {
  const windowSize = useWindowSize();
  const [isSticky, setIsSticky] = React.useState(false);
  const history = useHistory();

  const tabsToShow = filterVisibleTabs(tabs, planDetails.includesSupport);

  useEffect(() => {
    setIsSticky(
      windowSize.width <= BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.tablet
    );
  }, [windowSize]);

  const logOut = () => authClient.logout();

  const goToInfoNotCorrectPage = () => {
    const infoNotCorrectRoute = RouteBuilder.routeForInfoNotCorrectByPlan(
      deviceDetails.subscriberId!
    );
    history.push(infoNotCorrectRoute, {
      backText: deviceFriendlyName,
    });
  };

  const goToClaimDetailsPage = (claimId: string, assetId?: string) => {
    if (assetId) {
      const route = `${RouteBuilder.routeForClaimDetails(
        claimId
      )}assetId=${assetId}`;
      history.push(route, {
        backText: deviceFriendlyName,
      });
    } else {
      history.push(RouteBuilder.routeForClaimDetails(claimId), {
        backText: deviceFriendlyName,
      });
    }
  };
  const getInitialActiveTab = () => {
    let initialActiveTab = parse(history.location.search).activeTab as TabKey;
    if (!isValidTab(initialActiveTab, tabsToShow)) {
      if (tabsToShow.length > 0) {
        initialActiveTab = tabsToShow[0].key;
      }
    }
    return initialActiveTab;
  };

  const [activeTabKey, setActiveTabKey] = React.useState<TabKey>(
    getInitialActiveTab
  );

  const onSetActiveTab = (newTabKey: TabKey) => {
    // history.replace causes state updates with the <Root> component, so we're using this method instead
    window.history.replaceState(
      null,
      '',
      `${history.location.pathname}?activeTab=${newTabKey}`
    );
  };

  return (
    <TabNav<TabKey, TabProps>
      tabs={tabsToShow}
      isSticky={isSticky}
      tabNavId="DeviceDetails"
      activeTabKey={activeTabKey}
      setActiveTabKey={setActiveTabKey}
      onSetActiveTab={onSetActiveTab}
      tabProps={{
        onLogout: logOut,
        onInfoNotCorrect: goToInfoNotCorrectPage,
        benefitsCardsEnabled,
        deviceFriendlyName,
        OS,
        deviceDetails,
        planDetails,
        claimsInformation,
        goToClaimDetailsPage,
        getItem,
        setItem,
        missingClaimsLink,
      }}
    />
  );
};

export default TabsComponent;
