/* eslint-disable styled-components-a11y/label-has-associated-control */
import * as React from 'react';
import styled from 'styled-components';
import Breakpoints from '../Breakpoints';

const IndicatorContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  z-index: 9999;
  opacity: 0.5;
  height: 30px;
  &:hover {
    opacity: 1;
  }

  .mobile {
    display: block;
  }
  @media ${Breakpoints.mobileBig} {
    .mobile-big {
      display: block;
    }
    .mobile {
      display: none;
    }
  }

  @media ${Breakpoints.tablet} {
    .tablet {
      display: block;
    }
    .mobile-big,
    .mobile {
      display: none;
    }
  }

  @media ${Breakpoints.tabletBig} {
    .tablet-big {
      display: block;
    }
    .tablet,
    .mobile-big,
    .mobile {
      display: none;
    }
  }

  @media ${Breakpoints.desktop} {
    .desktop {
      display: block;
    }
    .mobile,
    .mobile-big,
    .tablet-big,
    .tablet {
      display: none;
    }
  }
  @media ${Breakpoints.desktopLarge} {
    .desktop-large {
      display: block;
    }
    .mobile,
    .mobile-big,
    .tablet-big,
    .tablet,
    .desktop {
      display: none;
    }
  }
  @media ${Breakpoints.desktopWide} {
    .desktop-wide {
      display: block;
    }
    .mobile,
    .mobile-big,
    .tablet-big,
    .tablet,
    .desktop,
    .desktop-large {
      display: none;
    }
  }
`;

const Indicator = styled.label`
  display: none;
  width: auto;
  height: auto;
  padding: 3px;
  color: white;
`;

const DesktopWide = styled(Indicator)`
  background-color: blue;
`;
const DesktopLarge = styled(Indicator)`
  background-color: blue;
`;
const Desktop = styled(Indicator)`
  background-color: blue;
`;

const TabletBig = styled(Indicator)`
  background-color: green;
`;

const Tablet = styled(Indicator)`
  background-color: green;
`;

const Mobile = styled(Indicator)`
  background-color: purple;
`;

const MobileBig = styled(Indicator)`
  background-color: purple;
`;

export default () => (
  <IndicatorContainer>
    <DesktopWide className="desktop-wide">desktop-wide</DesktopWide>
    <DesktopLarge className="desktop-large">desktop-large</DesktopLarge>
    <Desktop className="desktop">desktop</Desktop>
    <TabletBig className="tablet-big">tablet-big</TabletBig>
    <Tablet className="tablet">tablet</Tablet>
    <MobileBig className="mobile-big">mobile big</MobileBig>
    <Mobile className="mobile">mobile</Mobile>
  </IndicatorContainer>
);
