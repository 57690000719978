import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { queryCache } from 'react-query';
import { useAnalytics } from 'react-shisell';
import { authClient } from '@asurion-hub-web/auth';
import { AccountGateway } from '@asurion-hub/enrollment';
import {
  withSessionStorage,
  WithStorageProps,
} from '@asurion-hub-web/react-utils-web';
import {
  COLLECT_MDN_ROUTE,
  MDN_VERIFIED_STORAGE_KEY,
  ROOT_ROUTE,
} from '@asurion-hub-web/config';
import { VerifyMdnPage } from './VerifyMdnPage';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import { GET_PLANS_QUERY_KEY } from '@asurion-hub-web/enrollment';

const VerifyMdnPageContainer: React.FC<WithStorageProps> = ({ setItem }) => {
  const history = useHistory<
    { mdn?: string; errorMessage?: string; usedEmail?: boolean } | undefined
  >();

  const analytics = useAnalytics();
  const [partnerConfig] = usePartnerConfig();
  const { mdn, usedEmail } = history.location.state || {};

  const onResendCode = () =>
    usedEmail
      ? AccountGateway.sendOtpViaEmail(mdn ? mdn : '', partnerConfig.id)
      : AccountGateway.sendOtp(mdn ? mdn : '', partnerConfig.id);
  const onSubmit = async (otp: string) => {
    const { otpVerifiedResult } = await AccountGateway.verifyOtp(
      otp,
      partnerConfig.name.toLowerCase()
    );
    analytics.dispatcher
      .withExtras({
        VerifyOtpResult: otpVerifiedResult,
        ActivityType: 'VerifyOtp',
      })
      .dispatch('Activity');
    if (otpVerifiedResult === 'valid') {
      setItem(MDN_VERIFIED_STORAGE_KEY, 'true');
      await authClient.updateToken();

      await queryCache.invalidateQueries(GET_PLANS_QUERY_KEY);
    }
    return otpVerifiedResult;
  };
  const redirectTo = (path: string) => (errorMessage?: string) => () =>
    history.push(path, errorMessage ? { errorMessage } : {});
  const redirectToMdn = redirectTo(COLLECT_MDN_ROUTE);
  const redirectToRoot = redirectTo(ROOT_ROUTE);

  return mdn ? (
    <VerifyMdnPage
      usedEmail={usedEmail}
      mdn={mdn}
      onMaxAttempts={redirectToMdn(
        'You’ve entered the wrong code too many times. Please reenter your number.'
      )}
      onResendCode={onResendCode}
      onWrongNumber={redirectToMdn()}
      onSubmit={onSubmit}
      onSuccess={redirectToRoot()}
    />
  ) : (
    <Redirect to={COLLECT_MDN_ROUTE} />
  );
};

export default withSessionStorage(VerifyMdnPageContainer);
