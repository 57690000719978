export type Data = Record<string, unknown> & { error?: unknown };

// eslint-disable-next-line @typescript-eslint/ban-types
type Write = (message: string, data?: Data | Error | object) => void;
type WriteError = (message: string, error: Error, data?: Data) => void;

export interface Logger {
  error: WriteError;
  warn: Write;
  debug: Write;
  info: Write;
}

let _logger: Logger = console;

export const setLogger = (logger: Logger) => {
  _logger = logger;
};

export const logger: Logger = {
  error: (...args) => _logger.error(...args),
  warn: (...args) => _logger.warn(...args),
  debug: (...args) => _logger.debug(...args),
  info: (...args) => _logger.info(...args),
};
