import * as React from 'react';
import styled from 'styled-components';
import { Button, pxToEm } from '@asurion-hub-web/ui';
import * as assets from '../assets';

type HaveQuestionsProps = {
  onStartChatClick: React.MouseEventHandler<HTMLButtonElement>;
};

const paddingPx = 16;
const padding = pxToEm(paddingPx);

const Container = styled.div`
  background: #f0f0f5;
  padding: ${padding};
`;

const Paragraph = styled.p`
  font-size: ${pxToEm(16)};
  line-height: 1.5;
  margin: 0;
`;

const Subheading = styled(Paragraph)`
  font-family: Apercu-Bold-Pro;
  display: flex;
  align-items: center;
  margin-bottom: ${pxToEm(22)};
  :before {
    content: '';
    display: block;
    width: ${pxToEm(8)};
    height: ${pxToEm(31)};
    background: #6efac3;
    margin-left: -${padding};
    margin-right: calc(${padding} - ${pxToEm(8)});
  }
`;

const ExpertsContainer = styled.div`
  margin-top: ${pxToEm(31)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExpertsImageContainer = styled.div`
  position: relative;
`;

const ExpertsImage = styled.img``;

const ExpertsContent = styled.div`
  text-align: right;
  line-height: 1.5;
  margin-top: ${pxToEm(12)};
  margin-bottom: ${pxToEm(27)};
`;

const ExpertsHeading = styled.h5`
  font-size: ${pxToEm(14)};
  margin: 0;
`;

const ExpertsArrowDoodle = styled.img`
  display: block;
  position: absolute;
  transform: translateX(calc(-100% + ${pxToEm(13)})) translateY(-${pxToEm(9)});
`;

const BulletList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: ${pxToEm(20)};
`;

const BulletListItem = styled.li`
  line-height: 1.5;
  :before {
    content: '\\2022'; /* &bull; */
    margin-right: ${pxToEm(8)};
  }
`;

const Rating = styled(Paragraph)`
  vertical-align: center;
  font-size: ${pxToEm(12)};
  font-family: Apercu-Bold-Pro;
  color: #4d6af9;
  margin-top: ${pxToEm(5)};
`;

const HaveQuestions: React.FC<HaveQuestionsProps> = (props) => {
  return (
    <Container>
      <ExpertsContainer>
        <ExpertsImageContainer>
          <ExpertsImage src={assets.expertsImage} alt="Experts" />
          <ExpertsArrowDoodle src={assets.arrowDoodleImage} alt="" />
        </ExpertsImageContainer>
        <ExpertsContent>
          <ExpertsHeading>Your Replacement Experts</ExpertsHeading>
          <Rating>
            <img src={assets.starsImage} alt="Rating" /> 4.97
          </Rating>
        </ExpertsContent>
      </ExpertsContainer>
      <Subheading>Questions? Our team can help you with</Subheading>
      <BulletList>
        <BulletListItem>Setting up your new phone</BulletListItem>
        <BulletListItem>Security for your all your data</BulletListItem>
        <BulletListItem>
          Helping if something goes wrong with your replacement
        </BulletListItem>
      </BulletList>
      <Button
        class="full-width primary"
        actionId="ClaimStartChat"
        onClick={props.onStartChatClick}
      >
        Start a chat
      </Button>
    </Container>
  );
};

export default HaveQuestions;
