import { AuthenticatedUser } from '@asurion-hub/auth';
import { AuthClient } from './AuthClient';
import { User, UserManager } from 'oidc-client';

declare const ROUTE_PREFIX: string;

function getState() {
  const params = new URLSearchParams(window.location.search);
  return params.get('state');
}

function clearStateInHistory() {
  window.history.replaceState(
    null,
    '',
    window.location.href.replace(window.location.search, '')
  );
}

const REDIRECT_URI = `${window.location.origin}${ROUTE_PREFIX}/`;
export class OidcAuthClient implements AuthClient {
  userManager?: UserManager;
  user?: User | null;
  redirectError: string | null = null;

  init(clientId: string, authority: string): Promise<void> {
    this.userManager = new UserManager({
      authority,
      client_id: clientId,
      redirect_uri: REDIRECT_URI,
      silent_redirect_uri: `${window.location.origin}/silent-check-sso.html`,
      post_logout_redirect_uri: REDIRECT_URI,
      response_type: 'code',
      scope: 'openid profile offline_access',
    });

    return this.userManager
      .signinSilent({ state: getState() })
      .then((user) => {
        clearStateInHistory();
        this.setUser(user);
      })
      .catch(() => {
        return this.userManager!.signinRedirectCallback().then((user) => {
          clearStateInHistory();
          this.setUser(user);
        });
      })
      .catch(() => {
        this.setUser(null);
      });
  }
  async updateToken(): Promise<void> {
    await this.userManager
      ?.removeUser()
      .then(() => this.userManager?.signinRedirect());
  }

  redirectToLogin() {
    const _state = getState();
    return this.userManager!.signinRedirect({ state: _state });
  }

  logout() {
    void this.userManager?.signoutRedirect();
  }

  resetRedirectError() {
    this.redirectError = null;
  }

  getState() {
    return this.user?.state ? (this.user?.state as string) : undefined;
  }

  clearState() {
    if (this.user) {
      this.user.state = undefined;
    }
  }

  private setUser(user: User | null | undefined) {
    this.user = user;

    AuthenticatedUser.update(user?.access_token || null);
  }
}
