import React from 'react';
import AuthenticatedHomePage from './account/pages/AuthenticatedHomePage';
import HomePage from './onboarding/pages/Home';
import { withAuthState, useStateRedirect } from './hoc';
import { withVerifyMdn } from '@asurion-hub-web/enrollment';
const AuthenticatedComponent: React.FC = () => {
  useStateRedirect();

  return <AuthenticatedHomePage />;
};
export const Root: React.FC = () => {
  const Component = withAuthState(
    withVerifyMdn(AuthenticatedComponent),
    HomePage
  );
  return <Component />;
};

export default Root;
