import * as React from 'react';
import { useParams } from 'react-router-dom';
import { flow } from 'fp-ts/lib/function';
import {
  withLocalStorage,
  WithStorageProps,
} from '@asurion-hub-web/react-utils-web';
import { formatPhone, LoadingPage } from '@asurion-hub-web/ui';
import { INCORRECT_INFO_REPORT } from './constants';
import { InfoNotCorrectPage } from './InfoNotCorrectPage';
import { Asset } from '@soluto-private/account-api-client';
import { PlanProps, usePlan } from '@asurion-hub-web/enrollment';

type InformationDetails = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  friendlyName: string | undefined;
};

const provideInformationDetails = (informationDetails: InformationDetails) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    friendlyName,
  } = informationDetails;
  const name = firstName && lastName ? `${firstName} ${lastName}` : undefined;
  const phoneFormatted = phone && formatPhone(phone);
  return [
    {
      rowTitle: 'Primary account holder',
      primaryText: name,
    },
    {
      rowTitle: 'Email address',
      primaryText: email,
    },
    {
      rowTitle: 'Phone number',
      primaryText: phoneFormatted,
    },
    {
      rowTitle: 'Your phone',
      primaryText: friendlyName,
    },
  ];
};

const InfoNotCorrectPageMultiPlanContainer: React.FC<WithStorageProps> = ({
  getItem,
  setItem,
}) => {
  const { subId } = useParams<{ subId: string }>();
  const planData: PlanProps = usePlan(subId);

  const { plan, planIsFetching } = planData;

  const [reportSubmitted, setReportSubmitted] = React.useState(
    !!getItem(INCORRECT_INFO_REPORT)
  );

  if (planIsFetching) {
    return <LoadingPage />;
  }

  const { firstName, lastName, email, phone, assets } = plan || {};
  const planDevice: Asset | undefined = assets && assets[0];
  const friendlyName: string | undefined = planDevice?.friendlyName;

  const informationDetails = provideInformationDetails({
    firstName,
    lastName,
    email,
    phone,
    friendlyName,
  });

  return (
    <InfoNotCorrectPage
      setItem={setItem}
      setReportSubmitted={setReportSubmitted}
      reportSubmitted={reportSubmitted}
      informationDetails={informationDetails}
    />
  );
};

export const InfoNotCorrectMultiPlan = flow(withLocalStorage)(
  InfoNotCorrectPageMultiPlanContainer
);
