import { contentfulClient } from '@asurion-hub-web/contentful';
import { initialize as initializeCore } from '@soluto-asurion/one-service-core';
import initAnalytics, {
  addFeatureFlagsToAnalytics,
  addPartnerToAnalytics,
} from './analytics';
import initGoogleAnalytics from './googleAnalytics';
import initAuth from './auth';
import initFullStory, { fullstoryWriter } from './fullstory';
import initTheme from './theme';
import initTweek from './tweek';
import initWebsocket from './websocket';
import initLogging from './logging';
import initPartner from './partner';

export default async () => {
  const isProduction = process.env.NODE_ENV === 'production';
  initializeCore({
    appName: 'asurion-hub',
    isProduction,
    version: process.env.VERSION || 'undefined',
    analyticsOptions: {
      additionalWriters: [fullstoryWriter],
    },
  });
  initPartner();
  initLogging(false);
  initTheme();
  initTweek();
  initAnalytics();
  initGoogleAnalytics();
  initFullStory();
  await Promise.all([
    initWebsocket(),
    contentfulClient.init(),
    initAuth(),
    addFeatureFlagsToAnalytics(),
    addPartnerToAnalytics(),
  ]);
};
