import { createTweekClientWithFallback, FetchClientConfig } from 'tweek-client';

const TWEEK_URL = 'https://tweek.mysoluto.com';
const TWEEK_FALLBACK_URL = 'https://tweek-west.mysoluto.com';

const createTweekClient = (config: FetchClientConfig) => {
  return createTweekClientWithFallback({
    ...config,
    urls: [TWEEK_URL, TWEEK_FALLBACK_URL],
  });
};

export default createTweekClient;
