import * as React from 'react';
import styled from 'styled-components';
import { pxToEm, SimpleLayout } from '@asurion-hub-web/ui';
import illustrationFind from './assets/illustration-find.svg';
import {
  IIncorrectInfoFormProps,
  IncorrectInfoForm,
  IncorrectInfoSubmitted,
} from './components';

const Page = styled(SimpleLayout.Page)`
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    flex-direction: row;
  }
`;

const LeftColumn = styled.div`
  display: none;

  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    display: flex;
    justify-content: center;
    width: 40%;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;

  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    width: 50%;
    margin: ${pxToEm(16)};
  }
`;

interface IInfoNotCorrectPageProps extends IIncorrectInfoFormProps {
  reportSubmitted: boolean;
}

export const InfoNotCorrectPage: React.FunctionComponent<IInfoNotCorrectPageProps> = ({
  reportSubmitted,
  ...rest
}) => (
  <>
    <Page pageName="InfoNotCorrect" contained>
      <LeftColumn>
        <img
          style={{ width: '70%', maxWidth: pxToEm(255) }}
          src={illustrationFind}
          alt="Person with large magnifying glass"
        />
      </LeftColumn>
      <RightColumn>
        {reportSubmitted ? (
          <IncorrectInfoSubmitted />
        ) : (
          <IncorrectInfoForm {...rest} />
        )}
      </RightColumn>
    </Page>
  </>
);
