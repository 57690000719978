import React from 'react';
import { CtaProps } from '../types';
import { default as BaseCta } from './_Base';

export const ButtonOnlyCta: React.FC<CtaProps> = ({
  id,
  color,
  headline,
  content,
  buttonActionId,
  buttonText,
  buttonDestination,
  hyperlinkText,
  hyperlinkDestination,
  ctaVariant,
  fullWidth,
}) => (
  <BaseCta
    {...{
      id,
      color,
      headline,
      content,
      buttonText,
      buttonDestination,
      buttonActionId,
      ctaVariant,
      fullWidth,
    }}
  >
    {hyperlinkDestination && hyperlinkText && (
      <a href={hyperlinkDestination}>{hyperlinkText}</a>
    )}
  </BaseCta>
);
