import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { DetailRow, SectionHeader } from '@asurion-hub-web/ui';
import { WhatsNextProps } from '../types';
import PendingData from './PendingData';

import nametagIcon from '@asurion-hub-web/assets/images/icons/nametag.svg';
import truckTimeIcon from '@asurion-hub-web/assets/images/icons/truck-time.svg';
import boxTruckIcon from '@asurion-hub-web/assets/images/icons/box-truck.svg';
import boxReplaceIcon from '@asurion-hub-web/assets/images/icons/box-replace.svg';
import plugIcon from '@asurion-hub-web/assets/images/icons/plug.svg';
import { SHORT_DATE_FORMAT } from '@asurion-hub-web/config';

const TrackingLink = styled.a`
  color: #8223d2;
`;

const WhatsNext: React.FC<WhatsNextProps> = (props) => {
  const expectedDeliveryDate = moment(props.expectedDeliveryDate);
  const hasExpectedDeliveryDate = expectedDeliveryDate.isValid();
  const date = new Date(`${props.filedDate as string}`);
  const createDateString = (date: string): string => {
    const stringArray = date.split(' ');
    return `${stringArray[1]} ${stringArray[2]}, ${stringArray[3]}`;
  };

  return (
    <>
      <SectionHeader>Replacement Details</SectionHeader>
      {!props.isComplete && (
        <>
          {hasExpectedDeliveryDate && (
            <DetailRow
              leftIcon={truckTimeIcon}
              rowTitle="Expected delivery date"
              primaryText={expectedDeliveryDate.format(SHORT_DATE_FORMAT)}
            />
          )}
          <DetailRow
            leftIcon={boxTruckIcon}
            rowTitle="Your tracking number"
            primaryText={
              props.trackingNumber && props.trackingUrl ? (
                <TrackingLink target="_blank" href={props.trackingUrl}>
                  {props.trackingNumber}
                </TrackingLink>
              ) : (
                <PendingData>
                  Your tracking information is currently not available.
                </PendingData>
              )
            }
          />
        </>
      )}
      <DetailRow
        leftIcon={boxReplaceIcon}
        rowTitle="Replacement device"
        primaryText={props.replacementDevice}
      />
      {props.accessories.length > 0 && (
        <DetailRow
          leftIcon={plugIcon}
          rowTitle="Accessories"
          primaryText={props.accessories.map((accessory, i) => (
            <div key={i}>{accessory}</div>
          ))}
        />
      )}
      <DetailRow
        leftIcon={nametagIcon}
        rowTitle={`Claim Details`}
        primaryText={
          <>
            {props.filedDate && (
              <div>Filed on {createDateString(date.toString())}</div>
            )}
            <div>ID# {props.claimId}</div>
          </>
        }
      />
    </>
  );
};

export default WhatsNext;
