import { logger } from '@asurion-hub/logging';
import { PerilType } from '@soluto-private/account-api-client';

export type PerilInfo = { label: string; icon: string };

const perilInfoMap: Record<PerilType | 'Other', PerilInfo> = {
  ADH: {
    label: 'Damage',
    icon: 'brokenBoneIcon',
  },
  Catastrophic: {
    label: 'Complete Loss',
    icon: 'brokenBoneIcon',
  },
  Discovery: {
    label: 'Other',
    icon: 'brokenBoneIcon',
  },
  Cosmetic: {
    label: 'Cosmetic',
    icon: 'deviceDamagedIcon',
  },
  Damaged: {
    label: 'Damage',
    icon: 'brokenBoneIcon',
  },
  DeviceMalfunction: {
    label: 'Malfunction',
    icon: 'cpuBrokenIcon',
  },
  Loss: {
    label: 'Lost',
    icon: 'lostIcon',
  },
  Malfunction: {
    label: 'Malfunction',
    icon: 'cpuBrokenIcon',
  },
  ScreenReplacement: {
    label: 'Damaged Screen',
    icon: 'deviceDamagedIcon',
  },
  Stolen: {
    label: 'Stolen',
    icon: 'policeOfficerIcon',
  },
  Unrecoverable: {
    label: 'Unrecoverable',
    icon: 'hackerIcon',
  },
  Other: {
    label: 'Other',
    icon: 'brokenBoneIcon',
  },
};

export const getPerilInfo = (peril: PerilType | undefined) => {
  const fallback = perilInfoMap.Other;
  if (peril) {
    const perilInfo = perilInfoMap[peril];
    if (perilInfo) {
      return perilInfo;
    }
    logger.warn('Unknown claim peril', { peril });
  }
  return fallback;
};
