import { logger } from '@soluto-asurion/one-service-core';
import { setLogger } from '@asurion-hub/logging';

/* eslint-disable no-console */
export default function initLogging(overrideConsole: boolean) {
  setLogger(logger);

  // we only do this on production, since on development one of the logger appenders
  // is a console appender, which will cause an endless loop
  if (!overrideConsole) {
    return;
  }

  const _error = console.error;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  console.error = (message?: any, ...optionalParams: any[]) => {
    if (message instanceof Error) {
      logger.error('console error', message);
    } else if (typeof message === 'string') {
      logger.error('console error', new Error(message));
    }
    _error.apply(console, [message, optionalParams]);
  };

  const _warn = console.warn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  console.warn = (message?: any, ...optionalParams: any[]) => {
    const str = String(message);
    // avoid infinite loop, as the apiAppender logs a warning if it fails to send the log to the backend
    if (str.includes('LoggingApi')) {
      return;
    }
    logger.warn(String(message));
    _warn.apply(console, [message, optionalParams]);
  };
}
