import { ServicePlan } from '@asurion-hub/enrollment';
import { useAnalytics } from 'react-shisell';
import { useTweekValue } from 'react-tweek';

export const useRedirectForPhotoOnlyPlans = (
  servicePlans?: ServicePlan[]
): void => {
  const photosLink = useTweekValue('asurion_hub/photos/photolink', '');
  const analytics = useAnalytics();
  if (!servicePlans || servicePlans.length === 0 || !photosLink) {
    return;
  }

  if (servicePlans.every((sp) => sp.photoOnlyPlan)) {
    analytics.dispatcher
      .withExtra('ActivityType', 'PhotosOnlyPlanRedirect')
      .dispatch('Activity');
    window.location.href = photosLink;
  }
};
