import { selector, useRecoilValue, selectorFamily } from 'recoil';
import { Device } from '@asurion-hub-web/devices-base';
import { DeviceSearchResult, searchResults } from '../search-results';
import { devicesByIdState } from '@asurion-hub-web/devices';

export const sortResults = (
  results: DeviceSearchResult[]
): DeviceSearchResult[] => {
  return [...results].sort((a, b) => {
    const diff = (b.textSearchScore ?? 0) - (a.textSearchScore ?? 0);
    if (diff === 0) {
      const aMake = a.device.make.toLowerCase();
      const bMake = b.device.make.toLowerCase();
      const aModel = a.device.model.toLowerCase();
      const bModel = b.device.model.toLowerCase();
      if (bMake === aMake) {
        if (bModel === aModel) {
          return (b.device.weight ?? 0) > (a.device.weight ?? 0) ? 1 : -1;
        }
        return bModel > aModel ? -1 : 1;
      } else {
        return bMake > aMake ? -1 : 1;
      }
    }
    return diff;
  });
};

export const searchResultsOrdering = selector<DeviceSearchResult[]>({
  key: 'searchResultsOrdering',
  get: ({ get }) => sortResults(get(searchResults)),
});

export const useOrderedSearchResults = () =>
  useRecoilValue(searchResultsOrdering);

export const searchResultsOrderingDeviceIds = selector<Device['id'][]>({
  key: 'searchResultsOrderingDeviceIds',
  get: ({ get }) =>
    get(searchResultsOrdering).map(
      (deviceSearchResult) => deviceSearchResult.device.id
    ),
});

export const useOrderedSearchResultsDeviceIds = () =>
  useRecoilValue(searchResultsOrderingDeviceIds);

export const searchResultsByIdState = selectorFamily<
  DeviceSearchResult | undefined,
  Device['id']
>({
  key: 'searchResultsByIdState',
  get: (id) => ({ get }) => {
    const deviceSearchResult = get(searchResults).find(
      (deviceSearchResult) => deviceSearchResult.device.id === id
    );
    return (
      deviceSearchResult ||
      ({ device: get(devicesByIdState(id)) } as DeviceSearchResult)
    );
  },
});

export const useDeviceSearchResultsById = (id: Device['id']) =>
  useRecoilValue(searchResultsByIdState(id));
