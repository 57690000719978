import * as React from 'react';

export default function withProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TOuterProps extends Record<string, any> = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TInnerProps extends Record<string, any> = any
>(outerProps: TOuterProps) {
  return (Component: React.ComponentType<TInnerProps & TOuterProps>) => {
    const Wrapped: React.FC<TInnerProps> = (props) => {
      return <Component {...props} {...outerProps} />;
    };
    return Wrapped;
  };
}
