import arrowIcon from '@asurion-hub-web/assets/images/icons/arrowIcon.svg';
import {
  pxToEm,
  Breakpoints,
  Timeline,
  DynamicLink,
} from '@asurion-hub-web/ui';
import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { createDeviceSetupButtonReference } from '../../utils';

const breakpoint = Breakpoints.tablet;

const Bold = styled.span`
  font-weight: bold;
`;

const TrackingLink = styled.a`
  text-decoration: underline;
`;

const ShipmentTable = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoint} {
    flex-direction: row;
  }
`;

const ShipmentTracking = styled.div`
  margin-bottom: ${pxToEm(20)};
`;

const ShipmentDetails = styled.div`
  display: flex;
`;

const ShipmentDetailContainer = styled.div`
  margin-left: ${pxToEm(20)};
  :first-child {
    margin-left: 0;
  }
  @media ${breakpoint} {
    :first-child {
      margin-left: ${pxToEm(20)};
    }
  }
`;

const ShipmentDetailHeading = styled.div`
  :after {
    content: ':';
  }
`;

const ShipmentDetail = styled.div`
  font-weight: bold;
`;

const ShipmentDetailStatus = styled(ShipmentDetail)`
  text-transform: uppercase;
`;

const SetupList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: ${pxToEm(16)};
  > li {
    :before {
      content: '•';
      margin-right: ${pxToEm(5)};
    }
  }
`;

const Button = styled(DynamicLink)`
  display: inline-block;
  line-height: 1.5;
  font-size: ${pxToEm(16)};
  border: 1px solid #000;
  border-radius: 9999px;
  padding: ${pxToEm(3)} ${pxToEm(12)};
  color: #000;
  text-decoration: none;
`;

const IconElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div<{ image?: string }>`
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-repeat: no-repeat;
  background-size: contain;
  width: ${pxToEm(24)};
  height: ${pxToEm(12)};
  margin: ${pxToEm(12)};
`;

type WhatsNextReplaceProps = {
  stepIndex: number;
  confirmationEmail?: string;
  claimSubmittedTime: string; // e.g. "12/13/2020 at 4:45pm"
  trackingNumber?: string;
  trackingUrl?: string;
  deliveryDate: string | null | undefined; // e.g. "12/15/20"
  shipmentStatus: string; // e.g. "IN PROGRESS"
  showDeviceSetupModule: boolean;
  oldDeviceMakeModel?: string;
  newDeviceMakeModel: string;
  deviceSetupLink: string;
};

const numberOfStepsBeforePhoneSetup = 2;

const buildSetupYourPhoneStep = (
  link: string,
  oldDeviceMakeModel: string,
  newDeviceMakeModel: string
) => {
  return {
    heading: 'Set up your phone',
    content: (
      <>
        <SetupList>
          <li>Activate your phone</li>
          <li>Restore apps, photos, and contacts</li>
          <li>Reconnect to your favorite things</li>
        </SetupList>
        <Button
          actionId={'AdvanceExchangeDeviceSetupGuide'}
          to={createDeviceSetupButtonReference(
            link,
            oldDeviceMakeModel,
            newDeviceMakeModel
          )}
          target="_blank"
        >
          <IconElementWrapper>
            View the set up guides <Icon image={arrowIcon} />
          </IconElementWrapper>
        </Button>
      </>
    ),
  };
};

const WhatsNextReplace: React.FC<WhatsNextReplaceProps> = (props) => {
  const {
    stepIndex,
    confirmationEmail,
    claimSubmittedTime,
    deliveryDate,
    shipmentStatus,
    trackingNumber,
    trackingUrl,
  } = props;

  const hasTracking = Boolean(trackingNumber && trackingUrl);

  const entries = [
    {
      heading: 'Claim submitted',
      content: (
        <>
          {moment(claimSubmittedTime).format('MMMM Do YYYY, [at] h:mm a')}
          <br />
          {`We'll send a confirmation email and tracking info to`}{' '}
          {confirmationEmail ? (
            <Bold>{confirmationEmail.toLowerCase()}</Bold>
          ) : (
            'your email address'
          )}
          .
        </>
      ),
    },
    {
      heading: stepIndex > 1 ? 'Device shipped' : 'Preparing for shipment',
      content: (
        <ShipmentTable>
          <ShipmentTracking>
            {hasTracking ? (
              <TrackingLink href={trackingUrl}>{trackingNumber}</TrackingLink>
            ) : (
              <TrackingLink as="span">
                Tracking number not available yet
              </TrackingLink>
            )}
          </ShipmentTracking>
          <ShipmentDetails>
            <ShipmentDetailContainer>
              <ShipmentDetailHeading>Est. Delivery</ShipmentDetailHeading>
              <ShipmentDetail>{deliveryDate}</ShipmentDetail>
            </ShipmentDetailContainer>
            <ShipmentDetailContainer>
              <ShipmentDetailHeading>Status</ShipmentDetailHeading>
              <ShipmentDetailStatus>{shipmentStatus}</ShipmentDetailStatus>
            </ShipmentDetailContainer>
          </ShipmentDetails>
        </ShipmentTable>
      ),
    },
    {
      heading: `We've received your old phone`,
      content: 'Avoid a fee by returning your old phone within 14 days.',
    },
  ];

  if (props.showDeviceSetupModule) {
    entries.splice(
      numberOfStepsBeforePhoneSetup,
      0,
      buildSetupYourPhoneStep(
        props.deviceSetupLink,
        props.oldDeviceMakeModel!,
        props.newDeviceMakeModel
      )
    );
  }

  return <Timeline currentEntryIndex={stepIndex} entries={[...entries]} />;
};

export default WhatsNextReplace;
