import * as React from 'react';
import { formatPhone, DetailRow } from '@asurion-hub-web/ui';

import { DeviceDetails as DeviceDetailsType } from '../../../../../../types';
import chatBubble from '@asurion-hub-web/assets/images/icons/chat-bubble-square-question-mark.svg';
import doorHouse from '@asurion-hub-web/assets/images/icons/door-house.svg';
import email_envelope from '@asurion-hub-web/assets/images/icons/email-envelope.svg';
import phone from '@asurion-hub-web/assets/images/icons/phone.svg';
import user from '@asurion-hub-web/assets/images/icons/user.svg';
import TabContent from '../TabContent';

export type DeviceDetailsProps = {
  deviceDetails: DeviceDetailsType;
  onInfoNotCorrect: () => void;
  onLogout: () => void;
};

const DeviceDetails: React.FC<DeviceDetailsProps> = ({
  deviceDetails,
  onInfoNotCorrect,
  onLogout,
}) => (
  <TabContent>
    {deviceDetails.name && (
      <DetailRow
        leftIcon={user}
        leftIconAltText="User"
        rowTitle="Primary account holder"
        primaryText={deviceDetails.name}
        blockFromAnalytics={true}
      />
    )}
    {deviceDetails.email && (
      <DetailRow
        leftIcon={email_envelope}
        leftIconAltText="Mail envelope"
        rowTitle="Email address"
        primaryText={deviceDetails.email}
        blockFromAnalytics={true}
      />
    )}
    {deviceDetails.phone && (
      <DetailRow
        leftIcon={phone}
        leftIconAltText="Phone"
        rowTitle="Phone number"
        primaryText={formatPhone(deviceDetails.phone)}
        dataTestId="mdn-text"
        blockFromAnalytics={true}
      />
    )}
    <DetailRow
      leftIcon={chatBubble}
      leftIconAltText="Chat bubble with question mark"
      primaryText="My information is not correct"
      onClick={onInfoNotCorrect}
      actionId="InfoNotCorrect"
    />
    <DetailRow
      leftIcon={doorHouse}
      leftIconAltText="Exit door"
      primaryText="Sign out"
      onClick={onLogout}
      actionId="Logout"
    />
  </TabContent>
);

export default DeviceDetails;
