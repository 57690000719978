import {
  HeaderNavigationOptions,
  headerStateDefaults,
  headerBackTextState,
  headerBackPathState,
  headerLogoClickDisabledState,
} from '../HeaderAtoms';
import { useSetRecoilState } from 'recoil';

export const useHeaderNavigation = (options?: HeaderNavigationOptions) => {
  const setBackPath = useSetRecoilState(headerBackPathState);
  const setBackText = useSetRecoilState(headerBackTextState);
  const setHeaderLogoClickDisabled = useSetRecoilState(
    headerLogoClickDisabledState
  );
  setBackPath(options?.headerBackPath || headerStateDefaults.headerBackPath);
  setBackText(options?.headerBackText || headerStateDefaults.headerBackText);
  setHeaderLogoClickDisabled(
    options?.headerLogoClickDisabled ||
      headerStateDefaults.headerLogoClickDisabled!
  );
};

export const useHideHeaderNavigation = (
  shouldHide = true,
  headerTextIfNotHidden: string = headerStateDefaults.headerBackText!
) => {
  const setBackText = useSetRecoilState(headerBackTextState);
  setBackText(shouldHide ? undefined : headerTextIfNotHidden);
};
