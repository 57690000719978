import { useAsyncFn } from 'react-use';
import { logger } from '@soluto-asurion/one-service-core';
import { userDevicesV1Gateway } from '../gateways';
import { UpsertUserDevicesV1GatewayPayload } from '../models';

export const useUpsertUserDevice = () =>
  useAsyncFn(
    async (deviceId: string, make: string, selectedFeatureCodes?: string[]) => {
      const payload: UpsertUserDevicesV1GatewayPayload = {
        userDevices: [
          {
            deviceId,
            userDeviceDetails: {
              deviceName: make,
            },
            userSelectedFeatures: selectedFeatureCodes,
          },
        ],
      };
      try {
        return await userDevicesV1Gateway.upsertUserDevices(payload);
      } catch (err) {
        logger.error('Add Device failed', err);
        throw err;
      }
    }
  );
