import * as React from 'react';
import styled from 'styled-components';
import { pxToEm } from '../../utils';
import repairReplaceIcon from '@asurion-hub-web/assets/images/icons/repairReplaceIcon.svg';
import getSupportIcon from '@asurion-hub-web/assets/images/icons/getSupportIcon.svg';
import photosIcon from '@asurion-hub-web/assets/images/icons/photosIcon.svg';
import discoverTipsIcon from '@asurion-hub-web/assets/images/icons/discoverTipsIcon.svg';
import { useTweekValue } from 'react-tweek';
import DynamicLink from '../DynamicLink';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import Breakpoints from '../../Breakpoints';

const breakpoint = Breakpoints.mobileSmall;

const Banner = styled.div`
  width: 100%;
  display: block;
  color: white;
  text-align: center;
`;

const Text = styled.div`
  margin-bottom: ${pxToEm(12)};
  line-height: 1.3;
  color: black;
  font-size: ${pxToEm(32)};
  font-weight: 900;
  margin: auto;
  display: block;
`;

const ButtonText = styled.span`
  color: black;
  line-height: 1.5;
  display: block;
  margin: auto;
  font-family: 'Apercu-Light-Pro', sans-serif;
`;

const SubText = styled(Text)`
  font-size: ${pxToEm(32)};
  font-weight: 100;
`;

const Circle = styled.span`
  background-color: #f0f0f5;
  transition: background-color 0.3s;
  font-size: ${pxToEm(25)};
  text-align: center;
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin-bottom: 4px;
`;

const Button = styled(DynamicLink)`
  cursor: pointer;
  vertical-align: top;
  display: inline-block;
  text-align: center;
  font-size: ${pxToEm(14)};
  border: none;
  background: white;
  text-decoration: none;
  line-height: normal;
  :hover {
    ${Circle} {
      background-color: #E6E6EB;
    }
  }
  @media ${breakpoint} {
    width: 120px;
  }
`;

const ButtonContainer = styled.div<{ buttonsCount: number }>`
  display: flex;
  align-items: flex-start;
  margin: auto;
  > ${Button} {
    flex-basis: ${({ buttonsCount }) => `${100 / buttonsCount}%`}
  }
  @media ${breakpoint} {
    display: block;
    gap: ${pxToEm(70)};
  }
`;

const Icon = styled.img`
  display: block;
  width: 32px;
  height: 32px;
`;

export const getIntroHeader = (): string => {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) {
    return 'Good Morning!';
  } else if (curHr < 18) {
    return 'Good Afternoon!';
  }
  return 'Good Evening!';
};

type QuickLinksProps = {
  showSupportButton: boolean;
};

export const QuickLinks: React.FunctionComponent<QuickLinksProps> = ({
  showSupportButton,
}) => {
  const photosLink = useTweekValue('asurion_hub/photos/photolink', '');
  const photosEnabled = useTweekValue(
    'asurion_hub/photos/photosenabled',
    false
  );
  const discoveryEnabled = useTweekValue(
    'asurion_hub/discover/discover_enabled',
    false
  );
  const [partnerConfig] = usePartnerConfig();
  const buttonsCount = 1 + +showSupportButton + +photosEnabled + +discoveryEnabled;

  return (
    <Banner>
      <Text>{getIntroHeader()}</Text>
      <SubText>How can we help you today?</SubText>
      <br />
      <ButtonContainer buttonsCount={buttonsCount}>
        <Button
          to={`https://www.phoneclaim.com/${partnerConfig.id.toLowerCase()}/`}
          data-testid="claimButton"
          actionId="startClaim"
        >
          <Circle>
            <Icon src={repairReplaceIcon} alt={'Claims Icon'} />
          </Circle>
          <ButtonText>Repair and replace</ButtonText>
        </Button>
        {showSupportButton && (
          <Button
            onClick={async () => {
              await window.AE_SDK.triggerMessagingOverlay();
            }}
            actionId="getSupport"
          >
            <Circle>
              <Icon src={getSupportIcon} alt={'Support Icon'} />
            </Circle>
            <ButtonText>Get support</ButtonText>
          </Button>
        )}
        {photosEnabled && (
          <Button data-testid="photoButton" to={photosLink} actionId="viewPhotos">
            <Circle>
              <Icon src={photosIcon} alt={'Photos Icon'} />
            </Circle>
            <ButtonText>View photos</ButtonText>
          </Button>
        )}
        {discoveryEnabled && (
          <Button to="#discover" actionId="discoverTips">
            <Circle>
              <Icon src={discoverTipsIcon} alt={'Discover Tips Icon'} />
            </Circle>
            <ButtonText>Discover tips</ButtonText>
          </Button>
        )}
      </ButtonContainer>
    </Banner>
  );
};
