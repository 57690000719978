import { pxToEm } from '@asurion-hub-web/ui';
import styled from 'styled-components';

export default styled.div`
  margin-top: ${pxToEm(10)};
  margin-left: ${pxToEm(15)};
  margin-right: ${pxToEm(15)};
  display: flex;
  flex-direction: column;
`;
