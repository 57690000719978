import React from 'react';
import styled from 'styled-components';
import { pxToEm } from '@asurion-hub-web/ui';
import { CtaProps } from '../types';
import { default as BaseCta } from './_Base';

const Image = styled.img`
  max-width: ${pxToEm(75)};
`;

const StaticImage = styled.img`
  position: absolute;
  right: ${pxToEm(-25)};
  top: ${pxToEm(-40)};
  width: ${pxToEm(120)};
`;

export const IllustrationCta: React.FC<CtaProps> = ({
  id,
  color,
  headline,
  content,
  buttonText,
  buttonDestination,
  buttonActionId,
  image,
  imageAltText,
  ctaVariant,
  fullWidth,
}) => (
  <BaseCta
    {...{
      id,
      color,
      headline,
      content,
      buttonText,
      buttonDestination,
      buttonActionId,
      ctaVariant,
      fullWidth,
    }}
  >
    {fullWidth ? (
      <Image src={image} alt={imageAltText} />
    ) : (
      <StaticImage src={image} alt={imageAltText} />
    )}
  </BaseCta>
);
