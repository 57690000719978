import * as React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { RouteBuilder } from '@asurion-hub-web/config';

// This is a placeholder. We introduced this so Horizon can include the subID in the path,
// and we can later use it to fetch claims for a specific subscription, instead of all subscriptions
export const RedirectToClaimDetails: React.FC = () => {
  const { claimId } = useParams<{
    claimId: string;
  }>();
  return <Redirect to={RouteBuilder.routeForClaimDetails(claimId)} />;
};
