import { selector, useRecoilValue } from 'recoil';
import { selectedFilters } from './selectedFilters';
import { Filter } from './filter';

export const makeFilter = selector<Filter | undefined>({
  key: 'makeFilter',
  get: ({ get }) => get(selectedFilters).find((f) => f.type === 'make'),
});

export const useMakeFilter = () => useRecoilValue(makeFilter);
