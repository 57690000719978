import { shuffleArray } from '../utils';

export type FeatureOption = {
  featureCode: string;
  text: string;
};

export const featureOptions: FeatureOption[] = [
  {
    featureCode: 'PRODUCT_RECALLS',
    text: 'Product recalls',
  },
  {
    featureCode: 'MAN_WARRANTY_EXP',
    text: 'Manufacturer warranty expiration',
  },
  {
    featureCode: 'SECURITY_VULNERABILITY',
    text: 'Security vulnerabilities',
  },
  {
    featureCode: 'NEW_RELEASES',
    text: 'New product releases and updates',
  },
  {
    featureCode: 'EXPERT_TIPS_TRICKS',
    text: 'Expert tips and tricks',
  },
  {
    featureCode: 'YEARLY_PERFORMANCE',
    text: 'Yearly performance check-in',
  },
];

export const randomizedFeatureOptions: FeatureOption[] = shuffleArray(
  featureOptions
);
