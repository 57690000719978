import React from 'react';
import styled from 'styled-components';
import { pxToEm } from '@asurion-hub-web/ui';
import { Device } from '@asurion-hub-web/devices-base';
import checkMark from '@asurion-hub-web/assets/images/icons/check.svg';
import {
  useDeviceSearchResultsById,
  TextFilterResult,
} from '@asurion-hub-web/devices-search';
import reactStringReplace from 'react-string-replace';
import {
  useAnalytics,
} from 'react-shisell';

const DeviceListItemContainer = styled.li<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  line-height: 1.5;
  padding: 1em ${pxToEm(5)};
  font-size: ${pxToEm(16)};
  font-family: Apercu-Regular-Pro;
  border-top: 1px solid #eee;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    `
    background: #eee;
  `}
  :not:is-last {
    border-bottom: 1px solid #eee;
  }
`;

const RightIcon = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: ${pxToEm(21.52)};
  flex-shrink: 0;
  width: ${pxToEm(14.48)};
  height: ${pxToEm(13.12)};
`;

type DevicesListItemProps = {
  deviceId: Device['id'];
  isSelected?: boolean;
  toggleSelectedDevice: (id: Device['id']) => void;
};

const DevicesListItem: React.FC<DevicesListItemProps> = ({
  deviceId,
  isSelected,
  toggleSelectedDevice,
}) => {
  const analytics = useAnalytics();
  const deviceSearchResult = useDeviceSearchResultsById(deviceId);
  if (!deviceSearchResult?.device?.id) {
    throw new Error('Device was not defined');
  }

  const boldMatchedText = (
    filterResults: TextFilterResult[] | undefined,
    displayText: string
  ) => {
    let highlightedElem: string | React.ReactNodeArray = displayText;
    filterResults &&
      filterResults.forEach(({ filter }, i) => {
        highlightedElem = reactStringReplace(
          highlightedElem,
          filter.value,
          (match: string) => <b key={i}>{match}</b>
        );
      });
    return highlightedElem;
  };

  const onListItemClick = (deviceId: string, wasSelected: boolean, device: Device) => {
    analytics.dispatcher
      .withExtra('ActionId', 'DeviceClicked')
      .withExtra('ActivityType', 'DeviceClicked')
      .withExtra('DeviceSearchClickedMake', device.make)
      .withExtra('DeviceSearchClickedModel', device.model)
      .withExtra('DeviceSearchClickedId', deviceId)
      .withExtra('DeviceSearchSelectedToggledTo', wasSelected ? 'deselected' : 'selected')
      .dispatch('Click');
    toggleSelectedDevice(deviceId);
  }

  const deviceDisplayString = `${deviceSearchResult.device.make} ${deviceSearchResult.device.model}`;

  return (
    // eslint-disable-next-line styled-components-a11y/no-noninteractive-element-interactions, styled-components-a11y/click-events-have-key-events
    <DeviceListItemContainer
      isSelected={isSelected}
      onClick={() => onListItemClick(deviceId, isSelected || false, deviceSearchResult.device)}
      data-testid={`device-search-result-${deviceId}`}
    >
      <span>
        {boldMatchedText(
          deviceSearchResult.textFilterResults,
          deviceDisplayString
        )}
      </span>
      {isSelected && (
        <RightIcon
          image={checkMark}
          data-testid={`device-search-result-icon-${deviceId}`}
        />
      )}
    </DeviceListItemContainer>
  );
};

export default DevicesListItem;
