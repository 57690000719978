import { Button } from '@npmsoluto/atomic-ui-library-react';
import React from 'react';
import { withAnalyticOnEvent } from 'react-shisell';

export type ButtonProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement> & {
    class?: string;
    icon?: string;
    disabled?: boolean;
  },
  HTMLElement
> & {
  actionId: string;
  analyticsExtras?: Record<string, unknown>;
};
const EnhancedButton = withAnalyticOnEvent({
  eventName: 'onClick',
  analyticName: 'Click',
})(Button);

const ButtonWithAnalytics: React.FC<ButtonProps> = ({
  actionId,
  analyticsExtras = {},
  ...restProps
}) => (
  <EnhancedButton
    analyticsExtras={{ ActionId: actionId, ...analyticsExtras }}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {...(restProps as any)}
  >
    {restProps.children}
  </EnhancedButton>
);

export default ButtonWithAnalytics;
