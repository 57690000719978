import { authClient } from '@asurion-hub-web/auth';
import { SimpleLayout } from '../components';
import { Paragraph } from '@npmsoluto/atomic-ui-library-react';
import React from 'react';
import styled from 'styled-components';

const ContentContainer = styled(SimpleLayout.ContentContainer)`
  width: 100%;
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    width: ${SimpleLayout.cols(8)};
    margin-left: ${SimpleLayout.cols(2)};
  }
`;

const ButtonContainer = styled.div`
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    width: ${SimpleLayout.cols(8)};
    margin-left: ${SimpleLayout.cols(2)};
  }
`;

const logOut = () => authClient.logout();
const NoDetailsFoundPage = () => {
  return (
    <>
      <SimpleLayout.Page pageName="FailedToFetchAccountDetails" contained>
        <ContentContainer>
          <SimpleLayout.Heading>
            It looks like it’s taking us a little longer to find your coverage
            details.
          </SimpleLayout.Heading>
          <Paragraph>
            We suggest coming back in a few days to try again. Sorry for the
            delay!
          </Paragraph>
        </ContentContainer>
        <ButtonContainer>
          <SimpleLayout.ActionsContainer>
            <SimpleLayout.ActionButton
              actionId="Logout"
              primary={true}
              onClick={logOut}
            >
              Log out
            </SimpleLayout.ActionButton>
          </SimpleLayout.ActionsContainer>
        </ButtonContainer>
      </SimpleLayout.Page>
    </>
  );
};
export default NoDetailsFoundPage;
