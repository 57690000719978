import * as React from 'react';

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState(getWindowSize);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const getScrollPosition = () => {
  const doc = document.documentElement;
  return {
    scrollY: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
    scrollX: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
  };
};

export const useScrollPosition = () => {
  const [pageScrollPosition, setPageScrollPosition] = React.useState(
    getScrollPosition()
  );
  const listener = () => setPageScrollPosition(getScrollPosition());
  React.useEffect(() => {
    listener();
    window.addEventListener('scroll', listener);
    window.addEventListener('wheel', listener);
    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('wheel', listener);
    };
  }, []);
  return pageScrollPosition;
};

export const useComponentClickOutside = (initialIsVisible: boolean) => {
  const [isComponentVisible, setIsComponentVisible] = React.useState(
    initialIsVisible
  );
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  const handleEscClicked = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  React.useEffect(() => {
    if (isComponentVisible) {
      document.addEventListener('mousedown', handleClickOutside, true);
      document.addEventListener('keydown', handleEscClicked, true);
    }
    return () => {
      if (isComponentVisible) {
        document.removeEventListener('mousedown', handleClickOutside, true);
        document.removeEventListener('keydown', handleEscClicked, true);
      }
    };
  }, [isComponentVisible]);

  return { ref, isComponentVisible, setIsComponentVisible };
};
