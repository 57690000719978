import {
  DevicesV1 as _Device,
  DeviceCategory as _GatewayDeviceCategory,
} from '@soluto-private/devices-v1-api-client';
import headphones from '@asurion-hub-web/assets/images/icons/headphones.svg';
import speaker from '@asurion-hub-web/assets/images/icons/speaker.svg';
import smartwatch from '@asurion-hub-web/assets/images/icons/smartwatch.svg';
import gamingConsole from '@asurion-hub-web/assets/images/icons/game-system.svg';
import tv from '@asurion-hub-web/assets/images/icons/tv.svg';
import laptop from '@asurion-hub-web/assets/images/icons/laptop.svg';

export type Device = _Device;
export type GatewayDeviceCategory = _GatewayDeviceCategory;

export type DeviceCategory = 'Headphones' | 'Speaker' | 'Smart watch' | 'TV' | 'Laptop' | 'Gaming console';

export type DeviceCategoryEnriched = { text: DeviceCategory; icon: string };

export const deviceCategoryMap: Record<
  string,
  DeviceCategoryEnriched
> = {
  Headphone: {
    text: 'Headphones',
    icon: headphones,
  },
  Speaker: {
    text: 'Speaker',
    icon: speaker,
  },
  Smartwatch: {
    text: 'Smart watch',
    icon: smartwatch,
  },
  TV: {
    text: 'TV',
    icon: tv,
  },
  "Gaming Consoles": {
    text: 'Gaming console',
    icon: gamingConsole,
  },
  Laptop: {
    text: 'Laptop',
    icon: laptop
  }
};
