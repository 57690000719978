import React from 'react';
import styled from 'styled-components';
import { Breakpoints, pxToEm, Private } from '@asurion-hub-web/ui';
import ReplacementDeviceSection from './ReplacementDeviceSection';

const breakpoint = Breakpoints.tablet;

const Container = styled.div`
  margin-bottom: ${pxToEm(16)};
  padding: ${pxToEm(12.5)};
  padding-bottom: 0;
  @media ${breakpoint} {
    padding: 0;
  }
`;

const Heading = styled.h3`
  font-size: ${pxToEm(32)};
  line-height: 1.2;
  margin: 0;
  font-family: Apercu-Light-Pro;
  font-weight: normal;
  margin-bottom: ${pxToEm(16)};
`;

const ContentContainer = styled.div`
  display: flex;
  @media ${breakpoint} {
    flex-direction: column;
  }
`;

const ContentSection = styled.div`
  flex-basis: 53%;
`;

const Content = styled.p`
  margin: 0;
  line-height: 1.5;
  font-size: ${pxToEm(16)};
`;

type IntroSectionProps = {
  isClaimComplete: boolean;
  claimId: string;
  deviceModel?: string;
  deviceImageUrl?: string;
  email?: string;
  planIncludesSupport: boolean;
};

const IntroSection: React.FC<IntroSectionProps> = (props) => {
  const claimCompleteContent = props.planIncludesSupport
    ? `If you have any questions or run into issues with your replacement phone, just reach out to one of our experts for help!`
    : null;

  return (
    <Container>
      <Heading>
        {props.isClaimComplete
          ? `Your ${props.deviceModel || 'device'} has shipped.`
          : `Your replacement ${
              props.deviceModel || 'device'
            } can't wait to meet you`}
      </Heading>
      <ContentContainer>
        <ContentSection>
          {props.isClaimComplete ? (
            <Content>{claimCompleteContent}</Content>
          ) : (
            <Content>
              We&apos;ll send you tracking info when we&apos;re ready to ship
              it. We&apos;ve sent a confirmation email to{' '}
              <Private element="b">{props.email}</Private>.
            </Content>
          )}
        </ContentSection>
        <ReplacementDeviceSection
          deviceModel={props.deviceModel}
          deviceImageUrl={props.deviceImageUrl}
        />
      </ContentContainer>
    </Container>
  );
};

export default IntroSection;
