import * as React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useTweekValue } from 'react-tweek';
import { useStoredPartner } from '@asurion-hub-web/partner-config-react';
import { SELECT_PARTNER_ROUTE, PARTNER_PICKER_ENABLED } from '@asurion-hub-web/config';

export const PartnerSelector: React.FC = () => {
  const history = useHistory();
  const path = history.location.pathname;
  const isEnabled = useTweekValue(PARTNER_PICKER_ENABLED, false);
  const partner = useStoredPartner();

  if (!isEnabled) {
    return <></>;
  }

  if (path === SELECT_PARTNER_ROUTE) {
    return <></>;
  }

  if (!partner) {
    return <Redirect to={`${SELECT_PARTNER_ROUTE}?redirect_url=${encodeURIComponent(path)}`} />
  }

  return <></>;
};
