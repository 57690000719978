import { SimpleLayout } from '../components';
import { Paragraph } from '@npmsoluto/atomic-ui-library-react';
import React from 'react';
import styled from 'styled-components';
import catchAllErrorIcon from '../asset/catchAllErrorIcon.svg';
import { Page } from '@asurion-hub-web/ui';
import { useTweekValue } from 'react-tweek';

const ContentContainer = styled(SimpleLayout.ContentContainer)`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  height: 100%;
  margin-top: 10%;
  flex-direction: row;
  @media (min-width: 700px) {
    width: block;
  }
`;

const ImageObject = styled.object`
  width: 350px;
  margin-left: auto;
  display: block;
  margin-right: auto;
  float: left;
  flex: 1;
`;

const TextContainer = styled.div`
  margin-left: 40px;
`;

const CatchAllErrorPage = () => {
  const phoneclaimLink = useTweekValue(
    'asurion_hub/claims/phone_claim_link',
    ''
  );
  return (
    <Page pageName={'CatchAllError'}>
      <SimpleLayout.Page pageName="CatchAllErrorPage" contained>
        <ContentContainer>
          <ImageObject
            type="image/svg+xml"
            data={catchAllErrorIcon}
            aria-label="catchAllErrorIcon"
          />
          <TextContainer>
            <SimpleLayout.Heading>
              It looks like something went wrong. Please try again later.
            </SimpleLayout.Heading>
            <a href={phoneclaimLink}>
              <Paragraph>Looking for help with a recent claim?</Paragraph>
            </a>
          </TextContainer>
        </ContentContainer>
      </SimpleLayout.Page>
    </Page>
  );
};
export default CatchAllErrorPage;
