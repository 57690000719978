import React from 'react';
import styled from 'styled-components';
import { Button, pxToEm } from '@asurion-hub-web/ui';
import { Filter } from '@asurion-hub-web/devices-search';

const SearchFilterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  max-width: 100%;
  padding-bottom: 1em;
`;

const SearchFilterButton = styled(Button)<{ isSelected?: boolean }>`
  padding-right: ${pxToEm(10)};
  ${({ isSelected }) => {
    const color = isSelected ? '#fff' : '#000';
    const backgroundColor = isSelected ? '#000' : '#fff';
    return `
      --button-color: ${color};
      --button-background-color: ${backgroundColor};
      --button-color--hover: ${color};
      --button-background-color--hover: ${backgroundColor};
    `
  }}
`;

type SearchFilterButtonsProps = {
  filters: Filter[];
  selectedFilter?: Filter;
  toggleFilter: (filter: Filter, isSelected: boolean) => void;
};

const capitalize = (words: string): string =>
  words
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const SearchFilterButtons: React.FC<SearchFilterButtonsProps> = ({
  filters,
  selectedFilter,
  toggleFilter,
}) => {
  return (
    <SearchFilterButtonContainer data-testid="search-filter-button-container">
      {filters &&
        filters.map((filter) => {
          const isSelected =
            selectedFilter?.type === filter.type &&
            selectedFilter?.value === filter.value;
          return (
            <SearchFilterButton
              key={filter.value}
              isSelected={isSelected}
              actionId={'setMakeFilter'}
              analyticsExtras={{
                'ActivityType': 'setMakeFilter',
                'DeviceSearchFilterValue': filter.value,
                'DeviceSearchFilterToggledTo': isSelected ? 'deselected' : 'selected',
              }}
              onClick={() => toggleFilter(filter, isSelected)}
            >
              {filter.displayText  || capitalize(filter.value)}
            </SearchFilterButton>
          );
        })}
    </SearchFilterButtonContainer>
  );
};

export default SearchFilterButtons;
