import { AuthenticatedUser } from '@asurion-hub/auth';
import { getTweekRepository } from '@asurion-hub/tweek';
import {
  DevicesV1ApiClient,
  DevicesV1,
  GetDevicesV1Response,
  GetDevicesV1Payload
} from '@soluto-private/devices-v1-api-client';

export type GetDevicePayload = {
  deviceId: DevicesV1['id'];
};

export type GetAllDevicesPayload = {apiClient:DevicesV1ApiClient, devices?: DevicesV1[], lastEvaluatedKey?: GetDevicesV1Payload['cursor']};

class DevicesV1Gateway {
  private async getDevicesV1ApiClient(): Promise<DevicesV1ApiClient> {
    const authToken = AuthenticatedUser.accessToken;
    if (!authToken) {
      throw new Error('Missing auth token');
    }

    const url = await getTweekRepository().getValue<string>(
      'asurion_hub/devices/add_device_by_category/v1/devices_api_url'
    );

    return new DevicesV1ApiClient({
      clientApplicationName: 'asurion-hub',
      authToken,
      url,
    });
  }

  public async getDevices(): Promise<DevicesV1[]> {
    const apiClient = await this.getDevicesV1ApiClient();
    return await this._getAllDevices({ apiClient });
  }

  public async getDevice({ deviceId }: GetDevicePayload): Promise<DevicesV1> {
    const apiClient = await this.getDevicesV1ApiClient();
    return await apiClient.getDeviceV1({ id: deviceId });
  }

  private async _getAllDevices({apiClient, devices = [], lastEvaluatedKey = undefined}: GetAllDevicesPayload): Promise<DevicesV1[]>{
    const args = lastEvaluatedKey ? { cursor: lastEvaluatedKey } : undefined;
    const response: GetDevicesV1Response = await apiClient.getDevicesV1(args);
    response.devices && devices.push(...response.devices);
    if(response.LastEvaluatedKey){
      // have to regex the key since it's returned as a '{id={S=1103a326-335b-330a-8da8-b8d9c6bb15ba}}' format from AWS
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const lastEvaluatedKeyParsed: GetAllDevicesPayload['lastEvaluatedKey'] = JSON.parse(response.LastEvaluatedKey.replace(/([\w-]+)(=?)/g, '"$1"$2').replace(/=/g, ':'));
      return await this._getAllDevices({apiClient, devices, lastEvaluatedKey: lastEvaluatedKeyParsed});
    }
    return devices;
  }
}

export default new DevicesV1Gateway();
