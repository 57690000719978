/* eslint-disable*/
import { AuthenticatedUser } from '@asurion-hub/auth';

const ASURION_HUB_ROOT = /\[hubroot\]/gi;

const ASURION_ID_KEY = /\[asurionid\]/gi;
const CALLSITE_ID_KEY = /\[callsiteid\]/gi;
const CLAIM_ID_KEY = /\[claimid\]/gi;

const getCurrentUrlBase = () => {
  const getUrl = window.location;
  const baseUrl = `${getUrl.protocol}//${getUrl.host}`;
  return baseUrl;
};

export const calculateButtonDestination = async (
  urlTemplate: string,
  buttonDestinationInputs: ButtonDestinationInputs
) => {
  const asurionId = AuthenticatedUser.get()?.asurionId;

  const ctaUrl = urlTemplate
    .replace(ASURION_HUB_ROOT, getCurrentUrlBase())
    .replace(ASURION_ID_KEY, asurionId || '')
    .replace(CALLSITE_ID_KEY, buttonDestinationInputs.identifier || '')
    .replace(CLAIM_ID_KEY, buttonDestinationInputs.claimId || '');

  return ctaUrl;
};

export type ButtonDestinationInputs = {
  identifier?: string;
  claimId?: string;
};
