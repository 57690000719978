import * as React from 'react';
import { useQuery } from 'react-query';

export const CACHE_TIME_DEFAULT = 600000; // value in miliseconds (600 seconds = 10 minutes)

export default function withFetch<TFetched = unknown, TProps = unknown>(
  fetch: () => Promise<TFetched>,
  propKey: keyof TProps,
  errorKey: keyof TProps,
  isFetchingKey: keyof TProps,
  queryKey: string,
  cacheTime?: number,
  enabledPropKey?: keyof TProps
) {
  return (Component: React.ComponentType<TProps>) => {
    const Wrapped: React.FC<TProps> = (props) => {
      const enabled = !enabledPropKey || !!props[enabledPropKey];
      // useQuery executes any Promised fetch and caches the results
      const { error, data, isFetching } = useQuery(queryKey, fetch, {
        refetchOnMount: false,
        staleTime: cacheTime || CACHE_TIME_DEFAULT,
        cacheTime: cacheTime || CACHE_TIME_DEFAULT,
        enabled,
      });

      const allProps = {
        ...props,
        [propKey]: data,
        [errorKey]: error,
        [isFetchingKey]: isFetching,
      };

      return <Component {...allProps} />;
    };

    return Wrapped;
  };
}
