import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAuthState } from '@asurion-hub/auth';
import { useAccountProfile } from '@asurion-hub/account-profile';
import { pxToEm } from '@asurion-hub-web/ui';
import { Dropdown } from './Dropdown';
import { SimpleHeader } from './SimpleHeader';
import {
  headerBackPathState,
  headerBackTextState,
  headerLogoClickDisabledState,
} from '../HeaderAtoms';
import { useResetRecoilState, useRecoilValue } from 'recoil';
import { ROOT_ROUTE } from '@asurion-hub-web/config';

const DropdownContainer = styled.div`
  margin: 0 1em;
`;

export const Header: React.FC = () => {
  const {
    data: { firstName, lastName, avatar, email },
  } = useAccountProfile();
  const authState = useAuthState();
  const { location } = useHistory<{ backText?: string }>();

  const history = useHistory<{ backText?: string }>();
  const backTextFromAtom = useRecoilValue(headerBackTextState);
  const resetBackText = useResetRecoilState(headerBackTextState);
  const headerLogoClickDisabled = useRecoilValue(headerLogoClickDisabledState);
  const resetHeaderLogoClickDisabled = useResetRecoilState(
    headerLogoClickDisabledState
  );
  const backPath = useRecoilValue(headerBackPathState);
  const resetBackPath = useResetRecoilState(headerBackPathState);
  const backText = location.state?.backText || backTextFromAtom;
  const reset = () => {
    resetBackPath();
    resetBackText();
    resetHeaderLogoClickDisabled();
  };
  const onBackClickHandler = () => {
    reset();
    if (backPath) {
      history.push(backPath);
    } else if (history.length > 2) {
      // if we have somewhere to go back to
      history.goBack();
    } else {
      history.push(ROOT_ROUTE);
    }
  };

  const [headerHeight, setHeaderHeight] = useState('');
  const headerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  useEffect(() => {
    if (headerRef && headerRef.current && headerRef.current.offsetHeight) {
      setHeaderHeight(pxToEm(headerRef.current.offsetHeight));
    }
  }, [headerRef]);

  return (
    <SimpleHeader
      backText={backText}
      onBackClick={onBackClickHandler}
      disableLogoLink={headerLogoClickDisabled}
      mainRowRef={headerRef}
    >
      {authState === 'authenticated' && (
        <DropdownContainer>
          <Dropdown
            headerHeight={headerHeight}
            backNamePresent={!!backText}
            firstName={firstName}
            lastName={lastName}
            avatar={avatar}
            email={email}
          />
        </DropdownContainer>
      )}
    </SimpleHeader>
  );
};
