import React from 'react';
import { useAllClaims } from '@asurion-hub-web/enrollment';
import { Plan } from '@asurion-hub/enrollment';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import { SectionHeading } from '@asurion-hub-web/ui';
import { mapPlansToPlanDevices } from '../hocs';
import { Devices } from './Devices';

type DevicesSectionProps = {
  plans?: Plan[] | null;
  onInfoClick: () => void;
  onClaimClick: (claimId: string) => void;
};

const DevicesSection: React.FC<DevicesSectionProps> = ({
  plans,
  onInfoClick,
  onClaimClick,
}) => {
  const [partnerConfig] = usePartnerConfig();
  const { claims } = useAllClaims(partnerConfig.id);

  return (
    <>
      <SectionHeading>My phones {plans && `(${plans.length})`}</SectionHeading>
      {plans && (
        <Devices
          onInfoClick={onInfoClick}
          onClaimClick={onClaimClick}
          planDevices={mapPlansToPlanDevices(plans, claims || [])}
          claims={claims ? claims : []}
        />
      )}
    </>
  );
};

export default DevicesSection;
