import { selector } from 'recoil';
import { Device } from '@asurion-hub-web/devices-base';
import { devicesState } from '@asurion-hub-web/devices';

// { 'headphone': { 'apple': Device[] } }
export type SearchableDeviceCategories = Record<Device['make'], Device[]>;
export type SearchableDevices = Record<Device['make'], SearchableDeviceCategories>;
export type DeviceMakesMap = Record<Device['make'], Device['make']>;

export const devicesToSearchableDevices = (
  devices: Device[],
): SearchableDevices => {
  return devices.reduce((prev, curr) => {
    const category = curr.category.toLowerCase();
    const make = curr.make.toLowerCase();
    if (!prev[category]) {
      // eslint-disable-next-line no-param-reassign
      prev[category] = {};
    }
    if (!prev[category][make]) {
      // eslint-disable-next-line no-param-reassign
      prev[category][make] = [];
    }
    prev[category][make].push(curr);
    return prev;
  }, {} as SearchableDevices);
};

export const searchableDevices = selector<SearchableDevices>({
  key: 'searchableDevices',
  get: ({ get }) => devicesToSearchableDevices(get(devicesState)),
});

export const devicesToMappedDeviceMakes = (
  devices: Device[],
) : DeviceMakesMap => {
  return devices.reduce((prev, curr) => {
    const make = curr.make.toLowerCase();
    if (!prev[make]) {
      // eslint-disable-next-line no-param-reassign
      prev[make] = curr.make;
    }
    return prev;
  }, {} as DeviceMakesMap);
};

export const deviceMakesMapState = selector<DeviceMakesMap>({
  key: 'deviceMakesMap',
  get: ({ get }) => devicesToMappedDeviceMakes(get(devicesState)),
});