import passwordManagement from '@asurion-hub-web/assets/images/illustrations/password_management.svg';

export enum CtaColor {
  green = '#6EFAC3',
  white = '#FFFFFF',
  lightGrey = '#E6E6EB',
}

export type CtaData = {
  name: string;
  version: number;
  ctaType: string;
  color?: string;
  headline: string;
  content: string;
  buttonText?: string;
  buttonDestination?: string;
  hyperlinkText?: string;
  hyperlinkDestination?: string;
  // eslint-disable-next-line
  imageOrIcon?: any;
  imageOrientation?: string;
  eligibility?: string[];
};

export type CtaProps = {
  id: string;
  ctaVariant: string;
  color?: string;
  headline: string;
  content: string;
  buttonActionId?: string;
  buttonText?: string;
  buttonDestination?: string;
  hyperlinkText?: string;
  hyperlinkDestination?: string;
  image?: string;
  imageAltText?: string;
  imageOrientation?: string;
  fullWidth?: boolean;
};

export const defaultCta: CtaData = {
  name: 'create-password',
  ctaType: 'illustration-cta',
  version: 1.0,
  color: CtaColor.green.toString(),
  headline: 'Track your claim',
  buttonText: 'Create password',
  buttonDestination: '[registration-link]',
  content:
    'You can check your claim status here any time. Just create a password to finish setting up your account.',
  imageOrIcon: passwordManagement,
};
