import * as React from 'react';
import styled from 'styled-components';

import { pxToEm } from '@asurion-hub-web/ui';

type SignOutIconProps = {
  color: string;
};

const Icon = styled.svg`
  margin: 0 ${pxToEm(9)};
`;

export const SignOutIcon: React.FC<SignOutIconProps> = ({ color }) => {
  return (
    <Icon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 6.1665V3.24984C13.25 2.7896 12.8769 2.4165 12.4167 2.4165H3.25C2.78976 2.4165 2.41666 2.7896 2.41666 3.24984V20.7498C2.41666 21.2101 2.78976 21.5832 3.25 21.5832H12.4167C12.8769 21.5832 13.25 21.2101 13.25 20.7498V17.8332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5833 12H5.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4167 16.1663L21.5833 11.9997L17.4167 7.83301"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};