import { getIntroMessage } from '@asurion-hub/enrollment';
import React from 'react';
import styled from 'styled-components';
import { Breakpoints, formatPhone, Private, pxToEm } from '@asurion-hub-web/ui';
import { FulfillmentOption } from '@soluto-private/account-api-client';
import ReplacementDeviceSection from '../ReplacementDeviceSection';

const breakpoint = Breakpoints.tablet;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${pxToEm(16)};
  padding: ${pxToEm(12.5)};
  padding-bottom: 0;
  @media ${breakpoint} {
    padding: 0;
  }
`;

const Heading = styled.h3`
  font-size: ${pxToEm(20)};
  margin: 0;
  font-family: Apercu-Light-Pro;
  font-weight: normal;
  margin-bottom: ${pxToEm(23)};
  @media ${breakpoint} {
    font-size: ${pxToEm(32)};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.p`
  margin: 0;
  font-family: Apercu-Light-Pro;
  font-weight: light;
  line-height: ${pxToEm(30)};
  font-size: ${pxToEm(20)};
`;

const SubContent = styled.p`
  margin: 0;
  font-family: Apercu-Bold-Pro;
  line-height: ${pxToEm(24)};
  font-size: ${pxToEm(16)};
`;

type IntroSectionProps = {
  claimType: FulfillmentOption | undefined | string;
  mdn: string;
  isClaimComplete: boolean;
  claimId: string;
  fulfillmentDevice?: string;
  deviceImageUrl?: string;
  email?: string;
  planIncludesSupport: boolean;
  customIntroMessage?: string;
};

const IntroSection: React.FC<IntroSectionProps> = (props) => {
  return (
    <Container>
      <ContentContainer>
        <ReplacementDeviceSection
          deviceImageUrl={props.deviceImageUrl}
          alwaysShow
        />
      </ContentContainer>
      <ContentContainer>
        <Heading>
          {getIntroMessage(
            props.claimType,
            props.isClaimComplete,
            props.fulfillmentDevice,
            props.customIntroMessage
          )}
        </Heading>
        <ContentContainer>
          <Content>{props.fulfillmentDevice}</Content>
          <Private>
            <SubContent>{formatPhone(props.mdn)}</SubContent>
          </Private>
        </ContentContainer>
      </ContentContainer>
    </Container>
  );
};

export default IntroSection;
