import * as React from 'react';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';
import { usePartnerTheme } from './hooks';

export const ThemeProvider: React.FC = (props) => {
  const partnerTheme = usePartnerTheme() || {};
  return (
    <BaseThemeProvider theme={partnerTheme}>{props.children}</BaseThemeProvider>
  );
};
