/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  DynamicLink,
  LoadingOverlay,
  NotificationBanner,
  Page,
  pxToEm,
  SimpleLayout,
  TextInput,
  Button,
} from '@asurion-hub-web/ui';

export const breakpoint = SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY;

export const StyledPage = styled(Page)`
  height: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${pxToEm(375)};
  padding: ${pxToEm(16)};
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media ${breakpoint} {
    padding-top: ${pxToEm(90)};
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: auto;
  @media ${breakpoint} {
    margin-top: 2.5rem;
  }
`;

export const Title = styled.div`
  font-size: 2rem;
  line-height: 1.2;
`;

export const Subtitle = styled.div`
  margin-top: 1rem;
`;

export const Strong = styled.strong`
  font-family: Apercu-Bold-Pro;
`;

export const StyledDynamicLink = styled(DynamicLink)`
  text-align: left;
  text-decoration-line: underline;
  margin-top: 1rem;
`;

export const Label = styled.label`
  font-weight: normal;
  display: inline-block;
  font-size: ${pxToEm(16)};
  line-height: 1.5;
  margin-bottom: ${pxToEm(4)};
  margin-top: ${pxToEm(36)};
`;
