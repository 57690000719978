import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'react-shisell';
import { useTweekValue } from 'react-tweek';
import { AccountGateway } from '@asurion-hub/enrollment';
import { unknownErrorMessage, VERIFY_MDN_ROUTE } from '@asurion-hub-web/config';
import CollectMdnPage from './CollectMdnPage';
import CollectAgreementEmailPage from './CollectAgreementEmailPage';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';

const SCRUBBED_MDN_LENGTH = 10;
const EMAIL_CONFIRMATION_ENABLED_TWEEK_KEY =
  'asurion_hub/onboarding/email_confirmation_enabled';

const CollectMdnPageContainer: React.FC = () => {
  const analytics = useAnalytics();
  const history = useHistory<{
    mdn: string;
    usedEmail?: boolean;
    errorMessage?: string;
  }>();
  const scrubMdn = (mdn: string) => (mdn ? mdn.replace(/\D/g, '') : '');
  const [mdn, setMdn] = useState('');
  const [pageLoadingState, setPageLoadingState] = useState<boolean>(false);
  const [pageErrorState, setPageErrorState] = useState<boolean>(false);
  const [pageErrorMessage, setPageErrorMessage] = useState<string>();
  const [emailInputMode, setEmailInputMode] = useState<boolean>(false);
  const [partnerConfig] = usePartnerConfig();

  const isEmailConfirmationEnabled = useTweekValue(
    EMAIL_CONFIRMATION_ENABLED_TWEEK_KEY,
    false
  );

  useEffect(() => {
    if (history.location?.state?.errorMessage) {
      setPageErrorState(true);
      setPageErrorMessage(history.location.state.errorMessage);
    }
  }, [history]);

  const canSubmit = mdn && scrubMdn(mdn).length === SCRUBBED_MDN_LENGTH;
  const onSubmit = (useEmail = false) => async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!canSubmit) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analytics.dispatcher
      .withExtra('ActivityType', 'CollectMdn')
      .dispatch('Activity');
    setPageLoadingState(true);
    try {
      useEmail
        ? await AccountGateway.sendOtpViaEmail(scrubMdn(mdn), partnerConfig.id)
        : await AccountGateway.sendOtp(scrubMdn(mdn), partnerConfig.id);
      history.push(VERIFY_MDN_ROUTE, {
        mdn: scrubMdn(mdn),
        usedEmail: useEmail,
      });
    } catch (err) {
      setPageErrorState(true);
      setPageErrorMessage(unknownErrorMessage);
      setPageLoadingState(false);
    }
  };

  return emailInputMode && isEmailConfirmationEnabled ? (
    <CollectAgreementEmailPage
      pageErrorState={pageErrorState}
      setPageErrorState={setPageErrorState}
      pageErrorMessage={pageErrorMessage}
      pageLoadingState={pageLoadingState}
      onSubmit={onSubmit(true)}
      setEmailInputMode={setEmailInputMode}
      mdn={mdn}
    />
  ) : (
    <CollectMdnPage
      pageErrorState={pageErrorState}
      setPageErrorState={setPageErrorState}
      pageErrorMessage={pageErrorMessage}
      pageLoadingState={pageLoadingState}
      onSubmit={onSubmit()}
      mdn={mdn}
      setMdn={setMdn}
      canSubmit={!!canSubmit}
      setEmailInputMode={setEmailInputMode}
      emailConfirmationEnabled={isEmailConfirmationEnabled}
    />
  );
};

export default CollectMdnPageContainer;
