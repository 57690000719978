declare const ROUTE_PREFIX: string;
export const ADD_A_DEVICE_SELECT_CATEGORY_ROUTE = `${ROUTE_PREFIX}/devices/add-device-by-category/select-category`;
export const ADD_DEVICES_SELECTED_WRAPUP_ROUTE = `${ROUTE_PREFIX}/devices/add-device-by-category/device-selected/wrapup`;
export const ADD_A_DEVICE_SELECT_DEVICE_ROUTE = `${ROUTE_PREFIX}/devices/add-device-by-category/:category/select-device`;

export class RouteBuilder {
  static routeForSelectDevice(category: string) {
    return ADD_A_DEVICE_SELECT_DEVICE_ROUTE.replace(':category', category);
  }
  static getRouteAndPreserveParams(route: string) {
    return `${route}${window.location.search}`;
  }
}
