import { pxToEm } from '@asurion-hub-web/ui';
import { Checkbox } from '@npmsoluto/atomic-ui-library-react';
import * as React from 'react';
import styled from 'styled-components';

const RowContainer = styled.div<{ justification?: string }>`
  display: flex;
  align-items: start;
  padding-top: ${pxToEm(15)};
  padding-bottom: ${pxToEm(15)};
`;

const TextContainer = styled.div`
  width: 100%;
  margin-left: ${pxToEm(16)};
  align-self: center;
  ${({ onClick }) => (onClick ? 'flex-grow: 1;' : undefined)}
`;

const RowTitle = styled.div`
  font-size: ${pxToEm(12)};
  font-weight: bold;
  margin-bottom: ${pxToEm(8)};
`;

const PrimaryText = styled.div`
  font-size: ${pxToEm(16)};
`;
const PrimaryTextWrapper: React.FC<{ blockFromAnalytics?: boolean }> = (
  props
) => (
  <div>
    <PrimaryText className={props.blockFromAnalytics ? 'fs-exclude' : ''}>
      {props.children}
    </PrimaryText>
  </div>
);

interface IDetailRowProps {
  rowTitle: string;
  primaryText: React.ReactNode;
  blockFromAnalytics?: boolean;
  onCheckboxChange: (value: string, checked: boolean) => void;
}

const DetailRow: React.FunctionComponent<IDetailRowProps> = (props) => {
  const { rowTitle, primaryText, blockFromAnalytics, onCheckboxChange } = props;

  const [checked, setChecked] = React.useState(false);
  const itemName = rowTitle.replace(/\s/g, '').toLowerCase();
  const handleCheckboxChange = (
    value: string,
    isChecked: boolean,
    setState: (checked: boolean) => void
  ) => () => {
    const newCheckedStatus = !isChecked;
    setState(newCheckedStatus);
    onCheckboxChange(value, newCheckedStatus);
  };

  return (
    <>
      <RowContainer>
        <Checkbox
          id={`${itemName}Checkbox`}
          name="items"
          value={itemName}
          onChange={handleCheckboxChange(itemName, checked, setChecked)}
        />
        <TextContainer>
          {rowTitle && <RowTitle>{rowTitle}</RowTitle>}
          <PrimaryTextWrapper blockFromAnalytics={blockFromAnalytics}>
            {primaryText}
          </PrimaryTextWrapper>
        </TextContainer>
      </RowContainer>
    </>
  );
};

export default DetailRow;
