import { useReducer } from 'react';
import { NotificationState } from './context';
import { ALERT_BANNER, AlertBanner, TOAST, Toast } from './Notification';

type Notification = AlertBanner | Toast;

type NotificationMap = Record<string, Notification>;

type CreateNotificationAction = {
  type: 'CreateNotification';
  notification: Notification;
};

type DeleteNotificationAction = {
  type: 'DeleteNotification';
  id: string;
};

type NotificationAction = CreateNotificationAction | DeleteNotificationAction;

const reducer = (
  state: NotificationMap,
  action: NotificationAction
): NotificationMap => {
  switch (action.type) {
    case 'CreateNotification':
      return { ...state, [action.notification.id]: action.notification };
    case 'DeleteNotification':
      const newState = { ...state };
      delete newState[action.id];
      return newState;
    default:
      return state;
  }
};

export const useNotificationReducer = (): NotificationState => {
  const [state, dispatch] = useReducer(reducer, {} as NotificationMap);
  const dismissNotification = (id: string) =>
    dispatch({ type: 'DeleteNotification', id });
  const createNotification = ({
    id,
    onClose = () => undefined,
    ...rest
  }: Notification) =>
    dispatch({
      type: 'CreateNotification',
      notification: {
        ...rest,
        id,
        onClose: () => {
          dismissNotification(id);
          onClose();
        },
      },
    });
  const createAlertBanner = (alertBanner: Omit<AlertBanner, 'type'>) =>
    createNotification({ ...alertBanner, type: ALERT_BANNER });
  const createToast = (toast: Omit<Toast, 'type'>) =>
    createNotification({ autoDismiss: true, ...toast, type: TOAST });
  return {
    alertBanners: Object.values(state).filter(
      (v) => v.type === ALERT_BANNER
    ) as AlertBanner[],
    toasts: Object.values(state).filter((v) => v.type === TOAST) as Toast[],
    createAlertBanner,
    createToast,
  };
};
