import { useEffect, useState } from 'react';
import { useAccountServicePlans } from './useAccountServicePlans';

export const useAccountIncludesSupport = () => {
  const [accountIncludesSupport, setAccountIncludesSupport] = useState<
    boolean | null
  >();
  const servicePlans = useAccountServicePlans();
  useEffect(() => {
    setAccountIncludesSupport(
      servicePlans?.servicePlans?.some((sp) => sp.includesSupport)
    );
  }, [servicePlans]);
  return {
    isFetching: servicePlans.servicePlansAreFetching,
    accountIncludesSupport,
    error: servicePlans.servicePlansError,
  };
};
