import * as React from 'react';
import styled from 'styled-components';
import { pxToEm } from '@asurion-hub-web/ui';

const Cta = styled.div`
  display: flex;
  background-color: #f3e9fb;
  padding-right: ${pxToEm(50)};
  padding-bottom: ${pxToEm(26)};
  margin-bottom: ${pxToEm(20)};
`;

const CtaIcon = styled.div`
  width: 72px;
  flex-shrink: 0;
  padding-top: ${pxToEm(16)};
  text-align: center;
`;

const CtaContent = styled.div`
  margin-top: ${pxToEm(18)};
  line-height: 1.5;
`;

const CtaHeading = styled.div`
  font-weight: bold;
`;

const CtaBody = styled.div``;

type WhatsNextCtaProps = {
  heading: string;
  icon: React.ReactNode;
  className?: string;
};

const WhatsNextCta: React.FC<WhatsNextCtaProps> = (props) => {
  const { heading, icon, children, className } = props;
  return (
    <Cta className={className}>
      <CtaIcon>{icon}</CtaIcon>
      <CtaContent>
        <CtaHeading>{heading}</CtaHeading>
        <CtaBody>{children}</CtaBody>
      </CtaContent>
    </Cta>
  );
};

export default WhatsNextCta;
