import { useQuery, queryCache } from 'react-query';
import { useAuthState } from '@asurion-hub/auth';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import { AccountGateway, Plan } from '@asurion-hub/enrollment';

export interface PlansProps {
  plans?: Plan[] | null;
  plansError?: Error | null;
  plansAreFetching: boolean;
}

export const GET_PLANS_QUERY_KEY = 'getPlans';

export const usePlans = (): PlansProps => {
  const [partnerConfig] = usePartnerConfig();
  const authState = useAuthState();
  const { error, data, isFetching } = useQuery(
    GET_PLANS_QUERY_KEY,
    () => AccountGateway.getPlans(partnerConfig.id),
    {
      refetchOnMount: false,
      retry: false, // disable retries for any error'ed requests
      enabled: authState === 'authenticated',
      onError: async () => {
        // invalidate the query from the cache on error - refetchActive is needed to prevent infinite loop of bad queries
        await queryCache.invalidateQueries(GET_PLANS_QUERY_KEY, {
          refetchActive: false,
        });
      },
    }
  );
  return {
    plansError: error,
    plansAreFetching: isFetching,
    plans: data,
  };
};
