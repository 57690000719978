import JwtDecode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { AuthState, IAuthenticatedUser, TokenInfo, UserUpdate } from './types';

const updated$ = new BehaviorSubject<UserUpdate>({
  state: AuthState.inProgress,
});

export const AuthenticatedUser = {
  updated$: updated$.asObservable(),

  get: () => updated$.value.user,

  get State(): AuthState {
    return updated$.value.state;
  },

  get accessToken(): string {
    return updated$.value.accessToken || '';
  },

  update: (accessToken: string | null) => {
    if (!accessToken) {
      updated$.next({
        accessToken: null,
        user: null,
        state: AuthState.unauthenticated,
      });
      return;
    }

    const {
      principal_identifier,
      asurion_id,
      sub,
      ext,
      ...tokenInfo
    } = JwtDecode<TokenInfo>(accessToken);

    const user: IAuthenticatedUser = {
      principalIdentifier: ext?.principal_identifier || principal_identifier,
      // - token v1 will have asurion_id
      // - token v2 will have ext?.asurion_id
      asurionId: ext?.asurion_id || asurion_id,
      subject: sub,
      personas: [],
      ...tokenInfo,
      ...ext,
    };

    if (!user.asurionId) {
      updated$.next({
        accessToken: null,
        user: null,
        state: AuthState.unauthenticated,
      });
      return;
    }

    updated$.next({
      accessToken,
      user,
      state: AuthState.authenticated,
    });
  },
};
