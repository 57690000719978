import { logger } from '@asurion-hub/logging';
import {
  Claim,
  FulfillmentOption,
  RepairClaimFulfillmentStatus,
  ReplacementClaimFulfillmentStatus,
} from '@soluto-private/account-api-client';
import { ClaimStatusTag, StatusInfo, StatusTagInfo } from '../../types';

export const statusTagInfoMap: Record<ClaimStatusTag, StatusTagInfo> = {
  complete: {
    label: 'Complete',
    color: '#000',
    textColor: '#fff',
  },
  inProgress: {
    label: 'In progress',
    color: '#37E7A7',
    textColor: '#000',
  },
  attentionNeeded: {
    label: 'Attention needed',
    color: '#F9D51C',
    textColor: '#000',
  },
  cancelled: {
    label: 'Cancelled',
    color: '#DB1B1B',
    textColor: '#fff',
  },
  notAvailable: {
    label: 'Not available',
    color: '#E6E6EB',
    textColor: '#000',
  },
};

const replacementStatusInfoMap: Record<
  ReplacementClaimFulfillmentStatus,
  StatusInfo
> = {
  pendingShipment: {
    label: 'Pending Shipment',
    icon: 'boxAlertIcon',
    tag: statusTagInfoMap.inProgress,
  },
  shipped: {
    label: 'Shipped',
    icon: 'boxCheckmarkIcon',
    tag: statusTagInfoMap.complete,
  },
  cancelled: {
    label: 'Cancelled',
    icon: 'xIcon',
    tag: statusTagInfoMap.cancelled,
  },
  notAvailable: {
    label: 'Not available',
    icon: 'questionMarkIcon',
    tag: statusTagInfoMap.notAvailable,
  },
};

const repairStatusInfoMap: Record<RepairClaimFulfillmentStatus, StatusInfo> = {
  readyForRepair: {
    label: 'Ready for Repair',
    icon: 'checklistIcon',
    tag: statusTagInfoMap.inProgress,
  },
  repairCompleted: {
    label: 'Completed',
    icon: 'checklistIcon',
    tag: statusTagInfoMap.complete,
  },
  cancelled: {
    label: 'Cancelled',
    icon: 'xIcon',
    tag: statusTagInfoMap.cancelled,
  },
  notAvailable: {
    label: 'Not available',
    icon: 'questionMarkIcon',
    tag: statusTagInfoMap.notAvailable,
  },
};

export const getStatusInfo = (
  claimType?: FulfillmentOption,
  status?: ReplacementClaimFulfillmentStatus | RepairClaimFulfillmentStatus
) => {
  const fallback = {
    label: 'Not available',
    icon: 'questionMarkIcon',
    tag: statusTagInfoMap.notAvailable,
  };
  if (claimType && status) {
    if (
      claimType === FulfillmentOption.AdvancedExchange &&
      Object.values(ReplacementClaimFulfillmentStatus).includes(
        status as ReplacementClaimFulfillmentStatus
      )
    ) {
      return replacementStatusInfoMap[
        <ReplacementClaimFulfillmentStatus>status
      ];
    }
    if (
      claimType === FulfillmentOption.Repair &&
      Object.values(RepairClaimFulfillmentStatus).includes(
        status as RepairClaimFulfillmentStatus
      )
    ) {
      return repairStatusInfoMap[<RepairClaimFulfillmentStatus>status];
    }
    logger.warn('Unknown claim status', { status });
  }
  return fallback;
};

export const isClaimComplete = (claim: Claim): boolean =>
  getStatusInfo(claim.claimType, claim.claimStatus).tag.label ===
  statusTagInfoMap.complete.label;
