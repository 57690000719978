import { Breakpoints, pxToEm } from '@asurion-hub-web/ui';
import React from 'react';
import styled from 'styled-components';
import arrowIcon from '../assets/images/icons/Arrow1.svg';

const CtaLeft = styled.div`
  margin-left: ${pxToEm(21.21)};
  padding-right: 20%;

  h3 {
    margin-top: ${pxToEm(24)};
    margin-bottom: ${pxToEm(7)};
    font-size: 1.25em;
  }

  div {
    font-size: 0.875em;
    margin-bottom: ${pxToEm(16)};

    @media ${Breakpoints.mobileBig} {
      margin-bottom: initial;
    }
  }
`;

const PhoneIcon = styled.div<{ icon: string }>`
  @media ${Breakpoints.mobileBig} {
    top: -5px;
    right: -5px;
  }

  background-image: ${({ icon }) => `url(${icon})`};
  width: 84px;
  height: 84px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -5px;
  right: -5px;
`;

const ArrowIcon = styled.div`
  @media ${Breakpoints.mobileBig} {
    bottom: -30px;
    right: -15px;
  }

  background-image: url(${arrowIcon});
  width: 64px;
  height: 64px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -23px;
  right: -10px;
`;

const DeviceConnectionsCta = styled.div`
  @media ${Breakpoints.mobileBig} {
    max-width: 649.77px;
    padding-bottom: 1em;
  }

  position: relative;
  background-color: #6b8bff19;
  margin-bottom: 1.5em;
  margin-top: 10px;
  display: flex;
  border-radius: 5px;
  font-family: Apercu-Regular-Pro;
`;

export type SelectedDeviceConnectionsCtaProps = {
  name: string;
  version: string;
  headline: string;
  content: string;
  icon?: string;
};

const SelectDeviceConnectionsCta: React.FC<SelectedDeviceConnectionsCtaProps> = ({
  headline,
  content,
  icon,
}) => (
  <DeviceConnectionsCta>
    <CtaLeft data-testid="device-connections-cta-content">
      <h3>{headline}</h3>
      <div>{content}</div>
    </CtaLeft>
    {icon && (
      <PhoneIcon icon={icon} data-testid="device-connections-cta-icon" />
    )}
    <ArrowIcon />
  </DeviceConnectionsCta>
);

export default SelectDeviceConnectionsCta;
