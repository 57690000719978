import { Device } from '@soluto-private/account-api-client';
import { Claim } from '../../types';

export const buildDeviceName = (asset?: Device): string =>
  [asset?.make, asset?.model].filter(Boolean).join(' ');

export const getReplacementFriendlyName = (claim: Claim | undefined) => {
  const originalDevice = claim?.originalDevice;
  const replacementDevice = claim?.fulfillmentInfo?.replacementDevice;

  return replacementDevice &&
    replacementDevice.model &&
    replacementDevice.make &&
    !originalDevice?.color === !replacementDevice.color
    ? buildDeviceName(replacementDevice)
    : buildDeviceName(originalDevice);
};
