import { Partner, PartnerConfig, SupportedPersona } from './types';

export const verizon: PartnerConfig = {
  id: Partner.Verizon,
  name: 'Verizon',
  clientChannelId: '1E5CA959308202F08CB4005056A72010',
  supportedPersonas: [
    SupportedPersona.DIGITAL,
    SupportedPersona.LEGACY,
    SupportedPersona.HORIZON,
  ],
};

export const cricket: PartnerConfig = {
  id: Partner.Cricket,
  name: 'Cricket',
  clientChannelId: '416E2FA388E2164AE0539B39030ACB29',
  supportedPersonas: [SupportedPersona.HORIZON],
};

export const supportedPartners: Record<Partner, PartnerConfig> = {
  verizon,
  cricket,
};
