import * as React from 'react';
import {
  Page,
  LoadingOverlay,
  NotificationBanner,
  pxToEm,
} from '@asurion-hub-web/ui';
import styled from 'styled-components';
import { UpsellCTA } from '..';
import { SurveyCTA, IntroSection } from '.';
import { ClaimDetailsBasePageProps } from '../../types';

const PageContainer = styled.div`
  max-width: 850px;
  margin: 0 auto;
  display: content;
  align-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Accordion = styled.div`
  margin-bottom: ${pxToEm(30)};
`;

export const ClaimDetailsPageBase: React.FC<ClaimDetailsBasePageProps> = (
  props
) => {
  const onNotificationClose = () =>
    props.setNotification(
      props.notification
        ? { ...props.notification, isVisible: false }
        : undefined
    );

  const { cta, detailAccordion, whatsNext } = props;

  return (
    <PageContainer>
      <NotificationBanner
        notificationBannerId="ClaimsDetailsErrorBanner"
        notificationReason={props.notification?.reason ?? 'unknown'}
        isOpen={!!props.notification?.isVisible}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        status={props.notification?.status}
        onClose={onNotificationClose}
      >
        {props.notification?.message}
      </NotificationBanner>
      <Page pageName="ClaimDetailsPage">
        <LoadingOverlay isVisible={props.isLoading} />
        <Container>
          <IntroSection
            mdn={props.mdn}
            claimType={props.claimType}
            isClaimComplete={props.isClaimComplete}
            email={props.shippingInfo?.email}
            claimId={props.claimId}
            deviceImageUrl={props.replacementDeviceImageUrl}
            fulfillmentDevice={props.fulfillmentDevice!}
            planIncludesSupport={props.planIncludesSupport}
            customIntroMessage={
              props.customIntroMessage ? props.customIntroMessage : ''
            }
          />
          {whatsNext}
          <Accordion>{detailAccordion}</Accordion>
          {cta}
          <UpsellCTA />
          {props.showSurveyCta && <SurveyCTA {...props.surveyCTA} />}
        </Container>
      </Page>
    </PageContainer>
  );
};
