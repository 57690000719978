import {
  Partner,
  supportedPartners,
  verizon,
} from '@asurion-hub/partner-config';
import { BehaviorSubject } from 'rxjs';

export const partnerConfig$ = new BehaviorSubject(verizon); // initial value is verizon

export const setPartner = (partner: Partner): void => {
  partnerConfig$.next(supportedPartners[partner]);
};
