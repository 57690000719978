import React from 'react';
import styled from 'styled-components';
import { Device } from '@asurion-hub-web/devices-base';
import { default as DevicesListItem } from './DevicesListItem';
import noResultsIcon from '../assets/images/mini-devices-girl.svg';
import { pxToEm } from '@asurion-hub-web/ui';

const NoResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoResultsIcon = styled.div`
  background-image: url(${noResultsIcon});
  width: ${pxToEm(150)};
  height: ${pxToEm(150)};
  background-repeat: no-repeat;
  margin-top: ${pxToEm(40)};
  margin-bottom: ${pxToEm(16)};
`;

const NoResultsText = styled.div`
  font-size: ${pxToEm(20)};
  font-family: 'Apercu-Regular-Pro';
  margin-left: ${pxToEm(6)};
  margin-right: ${pxToEm(16)};
  margin-bottom: ${pxToEm(40)};
  line-height: 30px;
  text-align: center;
  color: #6E767D;
`;

const DevicesList = styled.ul<{ deviceSelected: boolean }>`
  margin: 0;
  padding: 0;
  list-style: none;
`;

type DevicesSelectListProps = {
  deviceIds: Device['id'][];
  selectedDeviceId: Device['id'];
  setSelectedDeviceId: (id: Device['id']) => void;
};

const DevicesSearchResultsList: React.FC<DevicesSelectListProps> = ({
  deviceIds,
  selectedDeviceId,
  setSelectedDeviceId,
}) => {
  return (
    <>
      {deviceIds && deviceIds.length > 0 ? (
        <DevicesList
          data-testid="devices-search-results-list"
          deviceSelected={selectedDeviceId !== ''}
        >
          {deviceIds.map((deviceId: Device['id']) => {
            const isSelected = selectedDeviceId === deviceId;

            return (
              <DevicesListItem
                key={deviceId}
                deviceId={deviceId}
                isSelected={isSelected}
                toggleSelectedDevice={(selectedId) =>
                  setSelectedDeviceId(
                    selectedId === selectedDeviceId ? '' : selectedId
                  )
                }
              />
            );
          })}
        </DevicesList>
      ) : (
        <NoResultsContainer data-testid="devices-search-no-results">
          <NoResultsIcon />
          <NoResultsText>
            That one isn’t in our records. New things are being added all the time, so check back later.
          </NoResultsText>
        </NoResultsContainer>
      )}
    </>
  );
};

export default DevicesSearchResultsList;
