import { ContentfulClientApi, createClient } from 'contentful';
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useMemo,
} from 'react';
import { useContentfulConfig } from './contentfulClientConfig';

export const ContentfulContext = createContext<ContentfulClientApi | undefined>(
  undefined
);

export type ContentfulProviderProps = {
  Loader?: FunctionComponent;
};

export const ContentfulProvider: FunctionComponent<ContentfulProviderProps> = ({
  children,
  Loader,
}) => {
  const config = useContentfulConfig();

  const client = useMemo(() => config && createClient(config), [config]);

  if (!client) {
    return Loader ? <Loader /> : null;
  }

  return (
    <ContentfulContext.Provider value={client}>
      {children}
    </ContentfulContext.Provider>
  );
};

export const useContentfulClient = () => {
  const client = useContext(ContentfulContext);

  if (!client) {
    throw new Error('Contentful client not initialized');
  }

  return client;
};
