import { Partner } from '@asurion-hub/partner-config';
import { PartnerTheme } from '../../types';

import headerLogoVerizon from './logos/header_verizon.svg';
import headerLogoCricket from './logos/header_cricket.svg';

const images: Record<Partner, PartnerTheme['images']> = {
  cricket: {
    logos: {
      header: headerLogoCricket,
    },
  },
  verizon: {
    logos: {
      header: headerLogoVerizon,
    },
  },
};

export default images;
