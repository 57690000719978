import { Device } from '@asurion-hub-web/devices-base';
import { Filter } from '../filters';

export type MatchResult = 'partial' | 'startsWith' | 'exact';

const matchScore: Record<MatchResult, number> = {
  partial: 1,
  startsWith: 10,
  exact: 100,
};

const getMatches = (
  searchString: string,
  searchKey: string
): MatchResult | undefined => {
  const searchKeyLowerCase = searchKey.toLowerCase();
  const searchStringLowerCase = searchString.toLowerCase();
  if (searchKeyLowerCase === searchStringLowerCase) {
    return 'exact';
  }

  if (searchKeyLowerCase.startsWith(searchStringLowerCase)) {
    return 'startsWith';
  }

  if (searchKeyLowerCase.includes(searchStringLowerCase)) {
    return 'partial';
  }

  return undefined;
};

export type TextFilterResult = {
  filter: Filter;
  matchResult?: MatchResult;
};

export type DeviceSearchResult = {
  device: Device;
  textFilterResults?: TextFilterResult[];
  textSearchScore?: number;
};

const deviceTextSearch = (
  device: Device,
  filters: Filter[]
): DeviceSearchResult => {
  const displayName = `${device.make} ${device.productName ?? device.model}`;
  const textFilterResults = filters.map((filter) => ({
    filter,
    matchResult: getMatches(filter.value, displayName),
  }));
  const textSearchScore = textFilterResults.find((r) => !r.matchResult)
    ? 0
    : textFilterResults
        .map((r) => matchScore[r.matchResult!])
        .reduce((prev, curr) => prev + curr, 0);
  return {
    device,
    textFilterResults,
    textSearchScore,
  };
};

export const textSearch = (
  devices: Device[],
  filters: Filter[]
): DeviceSearchResult[] => {
  if (filters.length === 0) {
    return devices.map((device) => ({ device }));
  }

  return devices
    .map((device) => deviceTextSearch(device, filters))
    .filter((d) => d.textSearchScore !== 0);
};
