import { selector, useRecoilValue } from 'recoil';
import { Device } from '@asurion-hub-web/devices-base';
import { categoryFilter, makeFilter, textFilters } from '../filters';
import {
  searchableDevices,
  SearchableDeviceCategories,
} from './searchableDevices';
import { textSearch, DeviceSearchResult } from './textSearch';

export const searchResultsByCategory = selector<SearchableDeviceCategories>({
  key: 'searchResultsByCategory',
  get: ({ get }) => {
    const filter = get(categoryFilter);
    if (!filter) {
      return {};
    }
    return get(searchableDevices)[filter.value.toLowerCase()];
  },
});

export const useSearchResultsByCategory = () =>
  useRecoilValue(searchResultsByCategory);

export const searchResultsByCategoryAndMake = selector<Device[]>({
  key: 'searchResultsByCategoryAndMake',
  get: ({ get }) => {
    const filter = get(makeFilter);
    const categoryDevices = get(searchResultsByCategory);
    if (!filter || !filter.value) {
      return Object.values(categoryDevices).reduce(
        (prev, curr) => [...prev, ...curr],
        [] as Device[]
      );
    }
    return categoryDevices[filter.value.toLowerCase()] ?? [];
  },
});

export const useSearchResultsByCategoryAndMake = () =>
  useRecoilValue(searchResultsByCategoryAndMake);

export const searchResults = selector<DeviceSearchResult[]>({
  key: 'searchResults',
  get: ({ get }) =>
    textSearch(get(searchResultsByCategoryAndMake), get(textFilters)),
});

export const useSearchResults = () => useRecoilValue(searchResults);
