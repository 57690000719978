import DynamicLink from './DynamicLink';
import Private from './Private';
import { pxToEm } from '../utils';
import * as React from 'react';
import styled from 'styled-components';
import chevronRight from '@asurion-hub-web/assets/images/icons/chevron-right.svg';
import Popover from './Popover';

const pxInEm8 = pxToEm(8);
const pxInEm12 = pxToEm(12);
const pxInEm15 = pxToEm(15);
const pxInEm16 = pxToEm(16);
const pxInEm25 = pxToEm(25);

const RowContainer = styled.div<{ justification?: string }>`
  display: flex;
  align-items: start;
  padding-top: ${pxInEm15};
  padding-bottom: ${pxInEm15};

  justify-content: ${({ justification }) => justification || 'inherit'};
`;

const RowLink = styled(DynamicLink)`
  display: flex;
  align-items: start;
  text-align: left;
  padding-top: ${pxInEm15};
  padding-bottom: ${pxInEm15};
`;

const LeftIcon = styled.div<{ image?: string }>`
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  margin-left: ${pxToEm(15)};
  width: ${pxToEm(24)};
  height: ${pxToEm(24)};
`;

const TextContainer = styled.div`
  width: 100%;
  margin-left: ${pxInEm25};
  align-self: center;
  ${({ onClick }) => (onClick ? 'flex-grow: 1;' : undefined)}
`;

const RowTitle = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => pxToEm(fontSize)};
  font-family: Apercu-Bold-Pro;
  margin-bottom: ${pxInEm8};
`;

const PrimaryText = styled.div`
  font-size: ${pxInEm16};
  line-height: 1.5;
`;

const PrimaryTextWrapper: React.FC<{
  blockFromAnalytics?: boolean;
  dataTestId?: string;
}> = (props) => {
  const rendered = (
    <PrimaryText data-test-id={props.dataTestId}>{props.children}</PrimaryText>
  );

  return props.blockFromAnalytics ? <Private>{rendered}</Private> : rendered;
};

const SecondaryText = styled.div`
  font-size: ${pxInEm12};
  color: #60676c;
  margin-top: ${pxInEm8};
`;

const PopoverWrapper = styled.div`
  padding: ${pxInEm8};
  align-self: center;
`;

const RightCarat = styled.img``;

interface IDetailRowProps {
  leftIcon: string;
  leftIconAltText?: string;
  rowTitle?: string;
  primaryText: React.ReactNode;
  secondaryText?: string;
  justification?: string;
  blockFromAnalytics?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  actionId?: string;
  dataTestId?: string;
  className?: string;
  titleFontSizeInPxOverride?: number;
  popoverId?: string;
  popoverContent?: string;
}

export const DetailRow: React.FunctionComponent<IDetailRowProps> = (props) => {
  const {
    leftIcon,
    rowTitle,
    primaryText,
    secondaryText,
    onClick,
    blockFromAnalytics,
    dataTestId,
    titleFontSizeInPxOverride,
    popoverId,
    popoverContent,
  } = props;

  return (
    <>
      <LeftIcon image={leftIcon} />
      <TextContainer>
        {rowTitle && (
          <RowTitle fontSize={titleFontSizeInPxOverride || 12}>
            {rowTitle}
          </RowTitle>
        )}
        <PrimaryTextWrapper
          blockFromAnalytics={blockFromAnalytics}
          dataTestId={dataTestId}
        >
          {primaryText}
        </PrimaryTextWrapper>
        {props.children}
        {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
      </TextContainer>
      {popoverContent && popoverId && (
        <PopoverWrapper>
          <Popover popoverId={popoverId}>{popoverContent}</Popover>
        </PopoverWrapper>
      )}
      {onClick && <RightCarat alt="next icon" src={chevronRight} />}
    </>
  );
};

const WrappedDetailRow: React.FunctionComponent<IDetailRowProps> = (props) => {
  const { onClick, justification, actionId } = props;
  const tabIndex = onClick ? 0 : undefined;

  return onClick ? (
    <RowLink
      onClick={onClick}
      tabIndex={tabIndex}
      actionId={actionId}
      className={props.className}
    >
      <DetailRow {...props} />
    </RowLink>
  ) : (
    <RowContainer justification={justification} className={props.className}>
      <DetailRow {...props} />
    </RowContainer>
  );
};

export default WrappedDetailRow;
