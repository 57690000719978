import { useEffect, useState } from 'react';
import {
  Claim,
  Plan,
  getStatusInfo,
  statusTagInfoMap,
} from '@asurion-hub/enrollment';
import { usePlans } from '@asurion-hub-web/enrollment';
import { formatLongDate } from '@asurion-hub-web/ui';

export type PlanDevice = Omit<Plan, 'assets'> &
  Plan['assets'][0] & { name?: string; hasClaimInProgress: boolean };

export type PlanDeviceProps = {
  planDevice?: PlanDevice | null;
  planDeviceError?: Error | null;
  planDeviceIsFetching: boolean;
};

export const mapPlansToPlanDevices = (
  plans: Plan[],
  claims?: Claim[]
): PlanDevice[] => {
  return plans.reduce((prev, { assets, ...others }) => {
    assets.forEach((asset) => {
      const claimsBySubId =
        claims && claims.filter((c) => c.subId === others.subscriberId);
      const hasClaimInProgress = !!(
        claimsBySubId &&
        claimsBySubId.find(
          (c) =>
            getStatusInfo(c.claimType, c.claimStatus).tag.label ===
            statusTagInfoMap.inProgress.label
        )
      );
      prev.push({
        ...others,
        ...asset,
        name: `${others.firstName ?? ''} ${others.lastName ?? ''}`,
        enrollmentDate: others.enrollmentDate
          ? formatLongDate(others.enrollmentDate)
          : undefined,
        hasClaimInProgress,
      });
    });
    return prev;
  }, [] as PlanDevice[]);
};

export const usePlanDevice = (assetId: string): PlanDeviceProps => {
  const { plans, plansAreFetching, plansError } = usePlans();
  const [planDevice, setPlanDevice] = useState<PlanDevice>();
  const [planDeviceError, setPlanDeviceError] = useState<Error>();

  useEffect(() => {
    if (plans) {
      const planDevice = mapPlansToPlanDevices(plans).find(
        (p) => p.assetId === assetId
      );
      if (!planDevice) {
        setPlanDeviceError(
          new Error(`No Plans matched for assetId: ${assetId}`)
        );
      } else {
        setPlanDevice(planDevice);
      }
    }
  }, [assetId, plans]);
  return {
    planDevice,
    planDeviceError: planDeviceError || plansError,
    planDeviceIsFetching: plansAreFetching,
  };
};
