import React from "react";
import styled from "styled-components";
import { Breakpoints, Button } from '@asurion-hub-web/ui';

const CancelModalBackGround = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: #0008;
    height: 100%;
    width: 100%;
    z-index: 1000;
    display: flex;
`;

const CancelModalContainer = styled.div`
    background-color: white;
    margin: auto 16px;
    padding: 16px;
    max-width: calc(500px - 32px);

    @media ${Breakpoints.mobileBig} {
        margin: auto;
    }
`;

const CancelModalHeading = styled.div`
    font-weight: 400;
    font-size: 32px;
    line-height: 38.4px;
    margin-bottom: 16px;
`;

const CancelModalSubHeading = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
`;

const CancelModalButtonContainer = styled.div`
    display: flex;
    position: relative;
    float: right;
`;

const StayButton = styled(Button)`
    margin-right: 5px;
`;

const LeaveButton = styled(Button)``;

const CancelAddModal: React.FC<{onYesCancelClick: () => void, onStayClick: () => void}> = (props) => {

    const onBackgroundClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            props.onStayClick();
        }
    };

    return (
        // eslint-disable-next-line styled-components-a11y/no-static-element-interactions, styled-components-a11y/click-events-have-key-events
        <CancelModalBackGround
            onClick={onBackgroundClick}
            data-testid={"cancel-modal-background"}
        >
            <CancelModalContainer data-testid={"cancel-modal-container"} >
                <CancelModalHeading>
                    Cancel adding this device?
                </CancelModalHeading>
                <CancelModalSubHeading>
                    We monitor tech updates to make sure you’re up  to speed and everything is working as it should.
                </CancelModalSubHeading>
                <CancelModalButtonContainer>
                    <StayButton
                        actionId={"CancelModalNo"}
                        onClick={() => props.onStayClick()}
                        data-testid={"cancel-modal-button-stay"}
                    >
                        Stay here
                    </StayButton>
                    <LeaveButton
                        className={"primary"}
                        actionId={"CancelModalYes"}
                        onClick={() => props.onYesCancelClick()}
                        data-testid={"cancel-modal-button-cancel"}
                    >
                        Yes, cancel
                    </LeaveButton>
                </CancelModalButtonContainer>
            </CancelModalContainer>
        </CancelModalBackGround>
    )
};

export default CancelAddModal;