import { SimpleLayout } from '@asurion-hub-web/ui';
import { ROOT_ROUTE } from '@asurion-hub-web/config';
import * as React from 'react';
import styled from 'styled-components';

import { ButtonContainer, ContentContainer, Instructions } from './Layout';

const SubmittedContentContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    margin-right: 0;
    width: ${SimpleLayout.cols(12)};
  }
`;

const IncorrectInfoSubmitted: React.FC = () => (
  <>
    <SubmittedContentContainer>
      <SimpleLayout.Heading>
        Thanks. We&rsquo;ll look into it!
      </SimpleLayout.Heading>
      <Instructions>
        <p>
          Much of your information comes from your wireless carrier, so our
          technical teams will work on fixing the issue.
        </p>
        <p>
          We&rsquo;ll take it from here, so nothing else is required on your
          part. Sorry for any inconvenience!
        </p>
      </Instructions>
    </SubmittedContentContainer>
    <ButtonContainer>
      <SimpleLayout.ActionsContainer>
        <SimpleLayout.ActionButton
          actionId="GoBack"
          primary={true}
          to={ROOT_ROUTE}
        >
          Back to my account
        </SimpleLayout.ActionButton>
      </SimpleLayout.ActionsContainer>
    </ButtonContainer>
  </>
);

export default IncorrectInfoSubmitted;
