import React from 'react';
import styled from 'styled-components';
import DynamicLink from './DynamicLink';
import Breakpoints from '../Breakpoints';
// eslint-disable-next-line
const logoWhite = require('@npmsoluto/asurion-design-assets-asurion-black')
  .logoWhite.default;

const FooterContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 1em 0;
  background: black;
  flex-direction: column;
  align-items: flex-start;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  height: auto;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  @media ${Breakpoints.tablet} {
    padding-left: 38px;

    flex-direction: row;
    text-align: left;
  }
`;

const AsurionLogo = styled.img`
  height: 48px;
  width: auto;
`;

const StyledDynamicLink = styled(DynamicLink)`
  text-decoration: none;
  color: white;
  text-align: center;
  margin-top: 1rem;
  margin-right: 0;
  &:visited {
    color: white;
  }
  @media ${Breakpoints.tablet} {
    text-align: left;
    margin-right: 30px;
    margin-top: 0;
  }
`;

const PaddedRowContainer = styled(RowContainer)`
  padding: 0 56px;
`;

const Footer: React.FC = () => (
  <FooterContainer>
    <RowContainer>
      <AsurionLogo
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        src={logoWhite}
        alt="asurion logo"
        data-test-id="footer-logo"
      />
      <StyledDynamicLink
        actionId="FooterTermsOfUse"
        to="https://www.asurion.com/terms-conditions"
      >
        Terms of Use
      </StyledDynamicLink>
      <StyledDynamicLink
        actionId="FooterPrivacy Policy"
        to="https://www.asurion.com/privacy-policy"
      >
        Privacy Policy
      </StyledDynamicLink>
    </RowContainer>
    <PaddedRowContainer>
      Asurion® is the property of Asurion, LLC. All rights reserved. Apple® and
      iPhone® are registered trademarks of Apple Inc. All other trademarks,
      service marks, and product brands that appear on this website are not
      owned by Asurion and are the property of their respective owners. Asurion
      is not affiliated with, sponsored by, or endorsed by Apple or any of the
      respective owners of the other trademarks, service marks and/or product
      brands cited herein.
    </PaddedRowContainer>
    <PaddedRowContainer>
      © Asurion 1992–2021. All Rights Reserved.
    </PaddedRowContainer>
  </FooterContainer>
);

export default Footer;
