import * as React from 'react';
import styled, { keyframes } from 'styled-components';

export type LoadingSpinnerProps = {
  size?: number; // In pixels.
  className?: string;
};

const defaultSize = 58;

const Spinner = styled.div<LoadingSpinnerProps>`
  animation ${keyframes`100% { transform: rotate(360deg) }`} 1.5s linear infinite;
  width: ${(props) => (props.size ? props.size : defaultSize)}px;
  height: ${(props) => (props.size ? props.size : defaultSize)}px;
`;

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const { size = defaultSize, className } = props;
  const scale = size / defaultSize;

  return (
    <Spinner {...props} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: 'block' }}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
      >
        <g transform={`scale(${scale})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="black"
            d="M 52.4147 29.3339C52.4147 18.7536 45.4127 9.80407 35.7858 6.8751C34.8488 6.59002 34.3203 5.59932 34.6054 4.66232C34.8905 3.72531 35.8812 3.19683 36.8182 3.48191C47.8968 6.85256 55.9614 17.1491 55.9614 29.3339C55.9614 41.5187 47.8968 51.8152 36.8182 55.1859C35.8812 55.471 34.8905 54.9425 34.6054 54.0055C34.3203 53.0685 34.8488 52.0778 35.7858 51.7927C45.4127 48.8637 52.4147 39.9142 52.4147 29.3339Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#000"
            d="M5.47205 29.3339C5.47205 39.9142 12.474 48.8637 22.1009 51.7927C23.0379 52.0778 23.5664 53.0685 23.2813 54.0055C22.9962 54.9425 22.0055 55.4709 21.0685 55.1859C9.98989 51.8152 1.92529 41.5187 1.92529 29.3339C1.92529 17.1491 9.98989 6.85255 21.0685 3.48189C22.0056 3.19681 22.9962 3.7253 23.2813 4.6623C23.5664 5.5993 23.0379 6.59 22.1009 6.87508C12.474 9.80405 5.47205 18.7536 5.47205 29.3339Z"
          />
        </g>
      </svg>
    </Spinner>
  );
};

export default LoadingSpinner;
