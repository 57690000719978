import * as React from 'react';
import { BenefitHighlight } from '@asurion-hub/enrollment';
import BenefitsCards from './BenefitsCards';
import BenefitsRows from './BenefitsRows';

type BenefitsProps = {
  cardsEnabled: boolean;
  deviceFriendlyName: string;
  benefits: Array<BenefitHighlight>;
};

const Benefits: React.FC<BenefitsProps> = ({
  cardsEnabled,
  benefits,
  deviceFriendlyName,
}) => {
  return cardsEnabled ? (
    <BenefitsCards
      benefits={benefits}
      deviceFriendlyName={deviceFriendlyName}
    />
  ) : (
    <BenefitsRows benefits={benefits} />
  );
};

export default Benefits;
