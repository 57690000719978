import { getContentfulConfig } from '@asurion-hub/contentful';
import { ContentfulClientApi, createClient, Entry } from 'contentful';
import { ServicePlan, ServicePlanEntry } from './types';

const getServicePlanFromEntry = (
  servicePlanEntry: Entry<ServicePlanEntry>
): ServicePlan => {
  const {
    benefitHighlights = [],
    protectionHighlights = [],
    ...restProduct
  } = servicePlanEntry.fields;

  return {
    ...restProduct,
    benefitHighlights: benefitHighlights.map(
      ({ fields: { text, popoverId, popoverText, image, articleId } }) => ({
        text,
        popoverId,
        popoverText,
        image,
        articleId,
      })
    ),
    protectionHighlights: protectionHighlights.map(
      ({ fields: { icon, text } }) => ({
        iconSrc: `https:${icon.fields.file.url}`,
        text,
      })
    ),
  };
};

export class ServicePlanCache {
  private readonly cache = new Map<string, ServicePlan>();

  constructor(public contentfulClient?: ContentfulClientApi) {}

  private async getClient() {
    if (!this.contentfulClient) {
      const options = await getContentfulConfig();
      this.contentfulClient = createClient(options);
    }

    return this.contentfulClient;
  }

  private async loadServicePlan(id: string): Promise<ServicePlan> {
    const client = await this.getClient();
    const response = await client.getEntries<ServicePlanEntry>({
      content_type: 'service',
      'fields.id': id,
      include: 10,
    });

    if (!(response.items && response.items.length > 0)) {
      throw new Error(`Service Plan not found for ${id}`);
    }

    const servicePlan = getServicePlanFromEntry(response.items[0]);
    this.cache.set(id, servicePlan);
    return servicePlan;
  }

  public async get(id: string): Promise<ServicePlan> {
    return this.cache.get(id) || (await this.loadServicePlan(id));
  }
}
