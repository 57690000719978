import { useAuthState } from '@asurion-hub/auth';
import {
  useHeaderNavigation,
  useHideHeaderNavigation,
} from '@asurion-hub-web/header';
import { useTweekValue } from 'react-tweek';
import { session } from '@soluto-asurion/one-service-core';

// An explanation about what should happen here:
// For logged out users there should be no back button. If they have '?campaign=VZ_Engagement' the logo
// should not be clickable.
// for logged in users there should be a back button.
export const useHeaderConfigurationBasedOnContent = () => {
  // eslint-disable-next-line
  const campaign = session.UserBrowser.get()
    .sessionQueryParameters?.get('campaign')
    ?.toString()
    .toLowerCase();

  const emailCampaignName = useTweekValue(
    'asurion_hub/emails/content_origin_campaign',
    ''
  ).toLowerCase();

  const authState = useAuthState();

  useHeaderNavigation({
    headerLogoClickDisabled:
      campaign === emailCampaignName && authState === 'unauthenticated',
  });

  // for logged out users there shouldn't be any back button
  useHideHeaderNavigation(authState === 'unauthenticated');
};
