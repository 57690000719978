import { Partner } from '@asurion-hub/partner-config';
import { PartnerTheme } from './types';
import images from './assets/images';

const defaultStatusColors: PartnerTheme['colors']['status'] = {
  light: '#e6e6eb',
  outline: '#fff',
  dark: '#000',
  success: '#37e7a7',
  warn: '#F9D51C',
  error: '#D91B1B',
};

export const verizon: PartnerTheme = {
  colors: {
    primary: '#000',
    status: { ...defaultStatusColors },
  },
  images: images.verizon,
};

export const cricket: PartnerTheme = {
  colors: {
    primary: '#60a630', // light green
    status: { ...defaultStatusColors },
  },
  images: images.cricket,
};

export const themes: Record<Partner, PartnerTheme> = {
  verizon,
  cricket,
};
