import React from 'react';
import { Accordion, formatPhone, pxToEm } from '@asurion-hub-web/ui';
import styled from 'styled-components';
import replacementDeviceIcon from '@asurion-hub-web/assets/images/icons/replacementDeviceIcon.svg';
import claimDetailsIcon from '@asurion-hub-web/assets/images/icons/claimDetailsIcon.svg';
import shippingIcon from '@asurion-hub-web/assets/images/icons/shippingIcon.svg';
import accessoryIcon from '@asurion-hub-web/assets/images/icons/accessoryIcon.svg';
import emailIcon from '@asurion-hub-web/assets/images/icons/emailIcon.svg';
import contactNumberIcon from '@asurion-hub-web/assets/images/icons/contactNumberIcon.svg';
import deliveryTruckIcon from '@asurion-hub-web/assets/images/icons/deliveryTruckIcon.svg';
import shippingAddressIcon from '@asurion-hub-web/assets/images/icons/shippingAddressIcon.svg';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 30em) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }
`;

const Boxes = styled.div`
  width: ${pxToEm(415)};
`;

const ReplacementIcon = styled.img`
  margin-right: ${pxToEm(10)};
  width: 20px;
  margin-left: 20px;
  float: left;
`;

const HeaderText = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 16px;
  width: 200px;
`;

const ClaimDetailsText = styled(HeaderText)`
  margin-right: 70px;
`;

const ShippingAddressText = styled(HeaderText)`
  margin-right: 40px;
`;

const ContactNumberText = styled(HeaderText)`
  margin-right: 45px;
`;

const ReplacementDeviceText = styled.div`
  width: 200px;
`;

const TextContainer = styled.div`
  float: right;
  max-width: 160px;
  margin-right: 205px;
  margin-bottom: 20px;
  flex: 50%;
`;

const HeaderInfo = styled.div`
  margin-top: 20px;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pxToEm(20)} ${pxToEm(20)} ${pxToEm(10)} ${pxToEm(20)};
`;

const TotalContainer = styled.div`
  background-color: #f0f0f5;
  display: content;
  font-weight: bold;
`;

const TrackingLink = styled.a`
  color: #8223d2;
`;

const PendingData = styled.div`
  opacity: 0.5;
`;

type ReplacementDetailsSectionProps = {
  expectedDeliveryDate?: string;
  trackingNumber?: string;
  trackingUrl?: string;
  replacementDevice?: string;
  accessories: Array<string>;
  filedDate?: string;
};

const constructReplacementDetailsSection: React.FC<ReplacementDetailsSectionProps> = (
  props: ReplacementDetailsSectionProps
) => {
  return (
    <Container>
      <Boxes>
        <HeaderInfo>
          <ReplacementIcon alt="Delivery Truck Icon" src={deliveryTruckIcon} />
        </HeaderInfo>
        <TextContainer>
          <HeaderText>Expected delivery date</HeaderText>
          <div>{props.expectedDeliveryDate}</div>
        </TextContainer>
      </Boxes>
      <Boxes>
        <HeaderInfo>
          <ReplacementIcon
            alt="Delivery Truck Shipping Icon"
            src={shippingIcon}
          />
        </HeaderInfo>
        <TextContainer>
          <HeaderText>Your tracking number</HeaderText>
          {!!props.trackingNumber || !!props.trackingUrl ? (
            <TrackingLink target="_blank" href={props.trackingUrl}>
              {' '}
              {props.trackingNumber}{' '}
            </TrackingLink>
          ) : (
            <PendingData>
              {' '}
              Your tracking information is currently not available.{' '}
            </PendingData>
          )}
        </TextContainer>
      </Boxes>{' '}
      <Boxes>
        <HeaderInfo>
          <ReplacementIcon
            alt="Replacement Device Icon"
            src={replacementDeviceIcon}
          />
        </HeaderInfo>
        <TextContainer>
          <HeaderText>Replacement device</HeaderText>
          <ReplacementDeviceText>
            {props.replacementDevice}
          </ReplacementDeviceText>
        </TextContainer>
      </Boxes>{' '}
      <Boxes>
        <HeaderInfo>
          <ReplacementIcon alt="Accessories Icon" src={accessoryIcon} />
        </HeaderInfo>
        <TextContainer>
          <HeaderText>Accessories</HeaderText>
          {props.accessories.length ? (
            props.accessories.map((accessory, i) => (
              <div key={i}>{accessory}</div>
            ))
          ) : (
            <ReplacementDeviceText>
              No accessories with this device.
            </ReplacementDeviceText>
          )}
        </TextContainer>
      </Boxes>
      <Boxes>
        <HeaderInfo>
          <ReplacementIcon alt="Claim Form Icon" src={claimDetailsIcon} />
        </HeaderInfo>
        <TextContainer>
          <ClaimDetailsText>Claim details</ClaimDetailsText>
          <div>Filed on {props.filedDate}</div>
        </TextContainer>
      </Boxes>{' '}
    </Container>
  );
};

type ShippingDetailsSectionProps = {
  formattedShippingAddress: string;
  claimContactPhoneNumber: string;
  claimContactEmail: string;
};

const constructShippingDetailsSection: React.FC<ShippingDetailsSectionProps> = (
  props: ShippingDetailsSectionProps
) => {
  return (
    <Container>
      <Boxes>
        <HeaderInfo>
          <ReplacementIcon
            alt="Delivery Truck Icon"
            src={shippingAddressIcon}
          />
        </HeaderInfo>
        <TextContainer>
          <ShippingAddressText>Shipped to</ShippingAddressText>
          <div>{props.formattedShippingAddress}</div>
        </TextContainer>
      </Boxes>
      <Boxes>
        <HeaderInfo>
          <ReplacementIcon alt="Claim Form Icon" src={emailIcon} />
        </HeaderInfo>
        <TextContainer>
          <HeaderText>Contact email</HeaderText>
          <div>{props.claimContactEmail}</div>
        </TextContainer>
      </Boxes>{' '}
      <Boxes>
        <HeaderInfo>
          <ReplacementIcon alt="Contact Number Icon" src={contactNumberIcon} />
        </HeaderInfo>
        <TextContainer>
          <ContactNumberText>Contact number</ContactNumberText>
          <div>{formatPhone(props.claimContactPhoneNumber)}</div>
        </TextContainer>
      </Boxes>{' '}
    </Container>
  );
};

type PaymentSectionProps = {
  insuranceDeductibleFormatted: string;
  shippingFeeFormatted: string;
  taxesFormatted: string;
  totalFormatted: string;
  cardType: string;
  cardEndingIn: string;
};

const constructPaymentSection: React.FC<PaymentSectionProps> = (
  props: PaymentSectionProps
) => {
  return (
    <div>
      <LineItem>
        <div>Insurance deductible</div>
        <div>{props.insuranceDeductibleFormatted}</div>
      </LineItem>
      <LineItem>
        <div>Shipping fee</div>
        <div>{props.shippingFeeFormatted}</div>
      </LineItem>
      <LineItem>
        <div>Taxes</div>
        <div>{props.taxesFormatted}</div>
      </LineItem>
      <TotalContainer>
        <LineItem>
          <div>Total</div>
          <div>{props.totalFormatted}</div>
        </LineItem>
        <LineItem></LineItem>
      </TotalContainer>
      <LineItem></LineItem>
    </div>
  );
};

export type ReplacementAccordionProps = {
  replacementDetails: ReplacementDetailsSectionProps;
  shippingDetails: ShippingDetailsSectionProps;
  paymentDetails?: PaymentSectionProps;
};

const ReplacementAccordion: React.FC<ReplacementAccordionProps> = (
  props: ReplacementAccordionProps
) => {
  const sections = [
    {
      heading: 'Replacement details',
      content: constructReplacementDetailsSection(props.replacementDetails),
      startOpen: true,
    },
    {
      heading: 'Shipping and contact info',
      content: constructShippingDetailsSection(props.shippingDetails),
      startOpen: false,
    },
  ];
  if (props.paymentDetails) {
    sections.push({
      heading: 'Payment details',
      content: constructPaymentSection(props.paymentDetails),
      startOpen: false,
    });
  }

  return <Accordion sections={sections} />;
};

export default ReplacementAccordion;
