/* eslint-disable */
import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'react-shisell';
import { useTweekValue } from 'react-tweek';
import { flow } from 'fp-ts/lib/function';
import { ArticleList } from '@soluto-private/content-discovery';
import { useScrollPosition } from '@asurion-hub-web/ui';
import {
  withSessionStorage,
  WithStorageProps,
} from '@asurion-hub-web/react-utils-web';
import { RouteBuilder } from '@asurion-hub-web/config';

const POSITION_STORAGE_KEY = 'article_position';

interface ContentPaneProps extends WithStorageProps {
  partner: string;
  articleContext: string;
  deviceFriendlyName: string;
  limit: number;
  className?: string;
}

export const ContentPanes: React.FC<ContentPaneProps> = ({
  partner,
  articleContext,
  deviceFriendlyName,
  limit,
  className,
  getItem,
  setItem,
}) => {
  const history = useHistory();
  const analytics = useAnalytics();
  const pageScrollPosition = useScrollPosition();

  useLayoutEffect(() => {
    window.requestAnimationFrame(() => {
      const scrollYPostion = getItem(POSITION_STORAGE_KEY);

      if (Number(scrollYPostion)) {
        setTimeout(() => {
          window.scrollTo({ left: 0, top: Number(scrollYPostion) });
          setItem(POSITION_STORAGE_KEY, `0`);
        }, 700);
      }
    });
  }, [history]);

  const showVerizonEnrollment = useTweekValue(
    'asurion_hub/contentful/show_verizon_enrollment',
    false
  );

  return (
    <div className={className}>
      <ArticleList
        clickHandler={(currentArticle: any) => {
          if (currentArticle?.sys?.id) {
            setItem(POSITION_STORAGE_KEY, `${pageScrollPosition.scrollY}`);
            analytics.dispatcher
              .withExtra('ContentArticleId', currentArticle.sys.id)
              .withExtra('ActionId', 'ContentArticleCard')
              .withExtra('ContentArticleTitle', currentArticle.fields?.title)
              .dispatch('Click');
            history.push(RouteBuilder.routeForArticle(currentArticle.sys.id), {
              backText: articleContext,
            });
          }
        }}
        limit={limit}
        skip={0}
        partner={partner}
        context={{
          friendlyName: deviceFriendlyName,
          verizonEnrollment: showVerizonEnrollment,
        }}
      />
    </div>
  );
};

export default flow(withSessionStorage)(ContentPanes);
