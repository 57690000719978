import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { withVerifyMdn } from '@asurion-hub-web/enrollment';
import { withAuthState } from './withAuthState';

type PrivateRouteProps = RouteProps & {
  /**
   * TODO: This should be React.ComponentType<never>, since page components
   * shouldn't accept props, but the types aren't quite right on many of them.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ComponentType<any>;
  mdnVerify?: boolean;
  partner?: string;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  Component,
  mdnVerify = true,
  ...rest
}) => {
  let EnhancedComponent: React.ComponentType = withAuthState(Component);
  if (mdnVerify) {
    EnhancedComponent = withVerifyMdn(Component);
  }

  return (
    <Route
      {...rest}
      render={() => {
        return <EnhancedComponent {...rest} />;
      }}
    />
  );
};
