import techExperts from '@asurion-hub-web/assets/images/people/asurion_tech_experts.png';
import { authClient } from '@asurion-hub-web/auth';
import {
  LoadingPage,
  NotificationBanner,
  SimpleLayout,
} from '@asurion-hub-web/ui';
import { Paragraph } from '@npmsoluto/atomic-ui-library-react';
import React, { useEffect } from 'react';
import { withTweekValues } from 'react-tweek';
import styled from 'styled-components';

const Image = styled.img`
  margin: auto;
  max-height: 100%;
  max-width: 100%;
`;

const SecondButton = styled(SimpleLayout.ActionButton)`
  margin-top: 1em;
`;

const ContentContainer = styled(SimpleLayout.ContentContainer)``;

interface Props {
  generalErrorText: string;
}
const HomePage: React.FC<Props> = ({ generalErrorText }) => {
  const [isWarningState, setWarningState] = React.useState(false);
  const [authError, setAuthError] = React.useState('');
  const login = () => authClient.redirectToLogin(() => setWarningState(true));
  useEffect(() => {
    if (authClient.redirectError) {
      setAuthError(`auth_error_${authClient.redirectError}`);
      setWarningState(true);
      authClient.resetRedirectError();
    } else {
      authClient.redirectToLogin(() => setWarningState(true));
    }
  }, []);
  if (!authClient.redirectError) {
    return <LoadingPage />;
  }
  return (
    <>
      <NotificationBanner
        isOpen={isWarningState}
        notificationBannerId="HomePageNotificationBanner"
        onClose={() => setWarningState(false)}
        status="warn"
        notificationReason={authError}
      >
        {generalErrorText}
      </NotificationBanner>
      <SimpleLayout.Page pageName="Home" contained>
        <ContentContainer>
          <SimpleLayout.Heading>
            We’re here to help with all of your tech.
          </SimpleLayout.Heading>
          <Paragraph>
            Sign in to your Asurion account to view your benefits and manage
            your devices.
          </Paragraph>
        </ContentContainer>
        <SimpleLayout.ImageContainer>
          <Image
            src={techExperts}
            alt="Our tech experts, ready to help you with your device"
          />
        </SimpleLayout.ImageContainer>
        <SimpleLayout.ActionsContainer>
          <SimpleLayout.ActionButton
            actionId="CreateAccount"
            primary={true}
            onClick={() => login()}
          >
            Create new account
          </SimpleLayout.ActionButton>
          <SecondButton
            actionId="SignIn"
            onClick={() => login()}
            data-testid="sign-in-btn"
          >
            Sign in to existing account
          </SecondButton>
        </SimpleLayout.ActionsContainer>
      </SimpleLayout.Page>
    </>
  );
};

const Enhanced = withTweekValues({
  generalErrorText: 'asurion_hub/texts/errors/general',
})(HomePage);
export default Enhanced;
