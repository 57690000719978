import React from 'react';
import { flow } from 'fp-ts/lib/function';
import { useHeaderNavigation } from '@asurion-hub-web/header';
import addIcon from '@asurion-hub-web/assets/images/icons/add-2.svg';
import {
  deviceCategoryMap,
  withSuspenseAndErrorBoundary,
  GatewayDeviceCategory,
} from '@asurion-hub-web/devices-base';
import { useDeviceCategoriesState } from '@asurion-hub-web/devices';
import styled from 'styled-components';
import { Breakpoints, LoadingPage } from '@asurion-hub-web/ui';
import { useHistory } from 'react-router-dom';
import {
  enrichAnalytics,
  useAnalytics,
  withAnalyticOnView,
} from 'react-shisell';
import { RouteBuilder } from '../config';
import { shuffleArray } from '../utils';
import { ROOT_ROUTE } from '@asurion-hub-web/config';

const DeviceCategoryContainer = styled.div`
  @media ${Breakpoints.mobileBig} {
    flex-basis: 33%;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 114px;
  height: 105px;
  flex-shrink: 0;
  text-align: center;
  border: 1px solid #d5d6da;
  border-radius: 4px;
  padding-top: 15px;
  position: relative;

  :hover {
    background-color: #0003;
  }
`;

const CategoryAddIcon = styled.div<{ iconUrl: string }>`
  background-image: url(${(props) => props.iconUrl});
  position: absolute;
  width: 24px;
  height: 24px;
  top: 3px;
  left: 3px;
`;

const DeviceCategoriesContainer = styled.div`
  @media ${Breakpoints.mobileBig} {
    width: 100%;
    justify-content: center;
  }

  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-x: auto;
  width: 350px;
  flex-wrap: wrap;
  padding-top: 24px;
`;

const CategoryIcon = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  margin-bottom: 10px;
`;

const CategoryHeading = styled.div`
  font-size: 24px;
  line-height: 32.4px;
`;

const CategorySubHeading = styled.div`
  margin-top: 10px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 21px;
`;

const CategoryContainer = styled.div`
  @media ${Breakpoints.mobileBig} {
    margin: 8px auto;
    text-align: left;
    width: 70%;
  }

  margin: 8px 16px;
`;

export const SelectCategoryPage: React.FC = () => {
  const history = useHistory();
  const analytics = useAnalytics();
  useHeaderNavigation({
    headerBackPath: ROOT_ROUTE,
    headerBackText: 'Home',
  });

  const deviceCategories: string[] = shuffleArray(useDeviceCategoriesState());
  const navigateToAddADevicePage = (category: string) => {
    analytics.dispatcher
      .withExtra('DeviceCategory', category)
      .withExtra('ClickId', 'AddDevice')
      .dispatch('Click');
    history.push(
      RouteBuilder.getRouteAndPreserveParams(
        RouteBuilder.routeForSelectDevice(category)
      )
    );
  };

  return (
    <CategoryContainer>
      <CategoryHeading>Select a device category</CategoryHeading>
      <CategorySubHeading>
        You have access to tech help for any device that connects to your phone.
        Add a device to activate this benefit.
      </CategorySubHeading>
      <DeviceCategoriesContainer>
        {deviceCategories.map((deviceCategory) => (
          /* eslint-disable-next-line */
          <DeviceCategoryContainer
            key={deviceCategory}
            onClick={() => navigateToAddADevicePage(deviceCategory)}
          >
            <CategoryAddIcon iconUrl={addIcon} />
            <CategoryIcon
              image={
                deviceCategoryMap[deviceCategory as GatewayDeviceCategory].icon
              }
            />
            {deviceCategoryMap[deviceCategory as GatewayDeviceCategory].text}
          </DeviceCategoryContainer>
        ))}
      </DeviceCategoriesContainer>
    </CategoryContainer>
  );
};

const Enhanced = flow(
  enrichAnalytics<unknown>((dispatcher) =>
    dispatcher
      .withExtra('Page', 'SelectCategory')
      .withExtra('DevicesVersion', 'v1')
  ),
  withAnalyticOnView({
    analyticName: 'PageView',
    mapPropsToExtras: () => ({
      Page: 'SelectCategory',
    }),
  })
)(withSuspenseAndErrorBoundary(SelectCategoryPage, LoadingPage));

export default Enhanced;
