import React, { useState } from 'react';
import { Callout } from '@asurion-hub-web/ui';
import { SurveyCTAProps } from '../types';
import { enrichAnalytics, withAnalyticOnView } from 'react-shisell';

const SurveyCTA: React.FC<SurveyCTAProps> = ({ onSurveyLinkClicked }) => {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => setIsOpen(false);

  return (
    <Callout
      color="#E6E9F4"
      isOpen={isOpen}
      headline="Tell us what you think!"
      hasCloseButton={true}
      buttons={[
        {
          content: 'Tell us',
          onClick: onSurveyLinkClicked,
          actionId: 'claims-survey-link',
        },
      ]}
      onClose={onClose}
    >
      Did our website exceed your expectations? Please tell us about it. We hope
      you will recommend us to your friends and family.
    </Callout>
  );
};

const EnhancedSurveyCta = withAnalyticOnView<SurveyCTAProps>({
  analyticName: 'SectionView',
})(SurveyCTA);

export default enrichAnalytics<SurveyCTAProps>((dispatcher) =>
  dispatcher
    .withExtra('Section', 'CTA')
)(EnhancedSurveyCta);
