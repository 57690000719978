import { useQuery, queryCache } from 'react-query';
import { useAuthState } from '@asurion-hub/auth';
import { AccountGateway, Claim } from '@asurion-hub/enrollment';
import { CACHE_TIME_DEFAULT } from '@asurion-hub-web/react-utils-web';

export interface ClaimsProps {
  claims?: Claim[] | null;
  claimsError?: Error | null;
  claimsAreFetching: boolean;
}

export const GET_CLAIMS_BY_SUB_ID_QUERY_KEY = 'getClaimsBySubId';

export const useClaims = (subId?: string): ClaimsProps => {
  const authState = useAuthState();
  const getClaims = subId
    ? () => AccountGateway.getClaimsBySubId(subId)
    : () => Promise.resolve([]);
  const { error, data, isFetching } = useQuery(
    `${GET_CLAIMS_BY_SUB_ID_QUERY_KEY}_${subId || ''}`,
    getClaims,
    {
      refetchOnMount: false,
      retry: false, // disable retries for any error'ed requests
      staleTime: CACHE_TIME_DEFAULT,
      cacheTime: CACHE_TIME_DEFAULT,
      enabled: authState === 'authenticated' && !!subId,
      onError: async () => {
        // invalidate the query from the cache on error - refetchActive is needed to prevent infinite loop of bad queries
        await queryCache.invalidateQueries(GET_CLAIMS_BY_SUB_ID_QUERY_KEY, {
          refetchActive: false,
        });
      },
    }
  );
  return {
    claimsError: error,
    claimsAreFetching: isFetching,
    claims: data,
  };
};
