import { ThemeStatus } from '@asurion-hub/partner-theme';

export const ALERT_BANNER = 'ALERT_BANNER';
export const TOAST = 'TOAST';

export type NotificationType = typeof ALERT_BANNER | typeof TOAST;

export interface Notification {
  id: string;
  type: NotificationType;
  status?: ThemeStatus;
  reason: string;
  message: React.ReactNode;
  autoDismiss?: boolean;
  onClose?: () => void;
}

export interface AlertBanner extends Notification {
  type: typeof ALERT_BANNER;
}

export interface Toast extends Notification {
  type: typeof TOAST;
}
