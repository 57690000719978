import React, { useEffect, useState } from 'react';
import { useTweekValue } from 'react-tweek';
import { useQuery, queryCache } from 'react-query';
import { CACHE_TIME_DEFAULT } from '@asurion-hub-web/react-utils-web';
import { useContentfulClient } from '@asurion-hub/contentful';
import { CtaData, CtaProps, defaultCta } from '../types';
import {
  calculateButtonDestination,
  ctaTypeVariants,
  ctaEligibility,
} from '../utils';
import { TextOnlyCta } from '../templates';
import { ROOT_ROUTE } from '@asurion-hub-web/config';

const TWEEK_QUERY = 'asurion_hub/cta/dynamic_cta_v2';
const DEFAULT_TWEEK_VALUE = { entryId: '6gcmgYTJEqs0yGM1tuZbA7' };
const CONTENTFUL_QUERY = (entryId: string) => `getCta-entryId: ${entryId}`;
const QUERY_SETTINGS = (queryString: string) => ({
  refetchOnMount: false,
  retry: false,
  staleTime: CACHE_TIME_DEFAULT,
  cacheTime: CACHE_TIME_DEFAULT,
  onError: async () => {
    await queryCache.invalidateQueries(queryString, { refetchActive: false });
    return defaultCta;
  },
});

export const useCtaProvider = (
  useFullWidth: boolean,
  claimId?: string
): JSX.Element => {
  const [ctaData, setCtaData] = useState<CtaData>();
  const [identifierWithVersion, setIdentifierWithVersion] = useState<string>();
  const [isEligible, setIsEligible] = useState<boolean>();
  const [buttonDestination, setButtonDestination] = useState<string>();
  const contentfulClient = useContentfulClient();

  const contentfulEntryId = useTweekValue(TWEEK_QUERY, DEFAULT_TWEEK_VALUE);

  const { data } = useQuery(
    CONTENTFUL_QUERY(contentfulEntryId.entryId),
    () => contentfulClient.getEntry(contentfulEntryId.entryId),
    QUERY_SETTINGS(CONTENTFUL_QUERY(contentfulEntryId.entryId))
  );

  useEffect(() => {
    if (data?.fields) {
      const ctaData: CtaData = data?.fields as CtaData;
      setCtaData(ctaData);
      setIdentifierWithVersion(`cta-${ctaData.name}-v${ctaData.version}`);

      // ELIGIBILITY RETRIEVAL
      const isEligible = ctaData.eligibility
        ? !ctaData.eligibility
            .map((rule) => {
              const ruleMapping = ctaEligibility.find(
                (mapping) => mapping.rule === rule
              );
              return ruleMapping?.check();
            })
            .filter((x) => !x).length
        : true;
      setIsEligible(isEligible);
    }
  }, [data, setIsEligible, setCtaData]);

  useEffect(() => {
    const getButtonDestination = async () => {
      const calculatedDestination = await calculateButtonDestination(
        ctaData?.buttonDestination || ROOT_ROUTE,
        {
          identifier: identifierWithVersion,
          claimId,
        }
      );
      setButtonDestination(calculatedDestination);
    };

    if (ctaData?.buttonDestination && isEligible && identifierWithVersion) {
      void getButtonDestination();
    }
  }, [ctaData, isEligible, identifierWithVersion, claimId]);

  if (ctaData && identifierWithVersion) {
    // MAP DATA TO PROPS
    const buttonData = ctaData.buttonDestination && {
      buttonDestination: buttonDestination,
      buttonActionId: identifierWithVersion,
    };

    const ctaProps = {
      id: identifierWithVersion,
      // eslint-disable-next-line
      image: ctaData.imageOrIcon
        ? // eslint-disable-next-line
          ctaData?.imageOrIcon.fields.file.url
        : undefined,
      ctaVariant: identifierWithVersion,
      ...ctaData,
      ...buttonData,
      fullWidth: useFullWidth,
    };

    // TEMPLATE RETRIEVAL
    const componentMapping = ctaTypeVariants.find(
      (x) => x.identifier === ctaData.ctaType
    );
    const CtaComponent: React.FC<CtaProps> = componentMapping
      ? componentMapping.Component
      : TextOnlyCta;

    return isEligible ? <CtaComponent {...ctaProps} /> : <></>;
  }
  return <></>;
};
