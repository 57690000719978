import * as React from 'react';
import { PlanDetails as PlanDetailsType } from '../../../../../../types';

import calendar_check from '@asurion-hub-web/assets/images/icons/calendar-check-2.svg';
import protect from '@asurion-hub-web/assets/images/icons/romance-love-protect.svg';
import { DetailRow } from '@asurion-hub-web/ui';
import TabContent from '../TabContent';

type PlanDetailsProps = {
  planDetails: PlanDetailsType;
};

const PlanDetails: React.FC<PlanDetailsProps> = ({ planDetails }) => (
  <TabContent>
    {planDetails.name && (
      <DetailRow
        leftIcon={protect}
        leftIconAltText="Caring hands"
        rowTitle="Provided by"
        primaryText={planDetails.name}
      />
    )}
    {planDetails.enrollmentDate && (
      <DetailRow
        leftIcon={calendar_check}
        leftIconAltText="Calendar with check mark"
        rowTitle="Enrolled since"
        primaryText={planDetails.enrollmentDate}
      />
    )}
  </TabContent>
);

export default PlanDetails;
