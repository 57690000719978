import React from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from 'react-shisell';
import styled from 'styled-components';

const StyledLink = styled(Link)<{
  underline?: string;
}>`
  text-decoration: ${(props) => props.underline};
  &&&:hover {
    color: #6e767d;
  }
  &:visited {
    color: black;
  }
`;

interface Props {
  to: string;
  actionId: string;
  onClick?: () => void;
  underline?: boolean;
}

const EnhancedLink: React.FC<Props> = (props) => {
  const analytics = useAnalytics();
  const {
    actionId,
    children,
    underline,
    onClick = () => undefined,
    ...linkProps
  } = props;
  return (
    <StyledLink
      {...linkProps}
      underline={underline ? 'underline' : 'none'}
      onClick={() => {
        analytics.dispatcher.withExtra('ActionId', actionId).dispatch('Click');
        onClick();
      }}
    >
      {children}
    </StyledLink>
  );
};

export default EnhancedLink;