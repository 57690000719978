import React from 'react';
import { ReplacementClaimFulfillmentStatus } from '@soluto-private/account-api-client';
import { ClaimDetailsPageBase } from '../components/MultipleClaimTypeComponents/_ClaimDetailsPageBase';
import { SameDayClaimDetailProps } from '../types';
import { SameDayAccordion } from '../components/MultipleClaimTypeComponents';
import WhatsNextSameDay from '../components/MultipleClaimTypeComponents/WhatsNextSameDay';
import { getDeviceMakeModelString } from '../utils';

const whatsNextReplaceStepIndexMap = (
  replacementStatus: ReplacementClaimFulfillmentStatus | undefined
) => {
  switch (replacementStatus) {
    case 'pendingShipment':
      return 1;
    case 'shipped':
      return 2;
    default:
      // default to highlighting initial step if status unknown
      return 0;
  }
};

export const ClaimDetailsSameDayPage: React.FC<SameDayClaimDetailProps> = (
  props
) => {
  return (
    <ClaimDetailsPageBase
      claimType={props.claim.claimType}
      isLoading={props.isLoading}
      planIncludesSupport={props.planIncludesSupport}
      showSurveyCta={props.showSurveyCta}
      cta={props.cta}
      isClaimComplete={props.isClaimComplete as boolean}
      billingSummary={props.billingSummary!}
      accessories={props.claim.accessories}
      claimId={props.claim.claimId}
      customIntroMessage={
        props.isClaimComplete
          ? 'Your device was delivered!'
          : 'We’re bringing your replacement in a few hours!'
      }
      isComplete={props.isLoading}
      surveyCTA={props.surveyCTA}
      mdn={props.claim.subscriberContactPhone!}
      status={props.claim.claimStatus!}
      notification={props.notification}
      setNotification={props.setNotification}
      replacementDevice={props.fulfillmentDevice}
      replacementDeviceImageUrl={props.replacementDeviceImageUrl}
      detailAccordion={
        <SameDayAccordion
          replacementDetails={{ ...props.accordionProps.replacementDetails }}
          paymentDetails={{ ...props.accordionProps.paymentDetails }}
        />
      }
      whatsNext={
        <WhatsNextSameDay
          stepIndex={whatsNextReplaceStepIndexMap(
            props.claim.claimStatus as ReplacementClaimFulfillmentStatus
          )}
          waitTime={props.whatsNextProps.waitTime}
          address={props.whatsNextProps.address}
          visitTime={props.whatsNextProps.visitTime}
          showDeviceSetupModule={props.showDeviceSetupModule}
          oldDeviceMakeModel={getDeviceMakeModelString(
            props.claim.originalDevice?.make,
            props.claim.originalDevice?.model
          )}
          newDeviceMakeModel={getDeviceMakeModelString(
            props.claim.fulfillmentInfo?.replacementDevice?.make,
            props.claim.fulfillmentInfo?.replacementDevice?.model
          )}
          deviceSetupLink={props.deviceSetupLink}
        />
      }
      claimDetails={{
        repairDate: props.claim.claimFiledDate,
        shipmentStatus: props.claim.claimStatus,
        repairLocation: props.claim.fulfillmentInfo?.address,
      }}
    />
  );
};
