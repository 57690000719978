import { setAuthClient, OidcAuthClient } from '@asurion-hub-web/auth';
import { getTweekRepository } from '@asurion-hub/tweek';

export default async function initAuthentication() {
  const oidcAuthClient = new OidcAuthClient();
  await oidcAuthClient.init(
    await getTweekRepository().getValue('asurion_hub/persona/client_id'),
    await getTweekRepository().getValue('asurion_hub/persona/authority_url')
  );

  setAuthClient(oidcAuthClient);
}
