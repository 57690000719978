import { Device } from '@asurion-hub-web/devices-base';
import { useRecoilValue, selector } from 'recoil';
import { devicesState } from './devicesState';

export const devicesToDeviceCategories = (
  devices: Device[]
): string[] => {
  const deviceCategories = devices && devices.reduce((prev, curr) => {
    const category = curr.category;
    // istanbul ignore next
    if (!prev[category]) {
      // eslint-disable-next-line no-param-reassign
      prev[category] = true;
    }
    return prev;
  }, {} as {[key: string]: boolean});
  return Object.keys(deviceCategories);
};

// deviceCategory state
export const deviceCategoriesState = selector<string[]>({
  key: 'getDeviceCategories',
  get: ({ get }) => {
    return devicesToDeviceCategories(get(devicesState));
  },
});


export const useDeviceCategoriesState = () =>
  useRecoilValue(deviceCategoriesState);
