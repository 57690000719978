export const unknownErrorMessage =
  'Sorry, something’s not working. It’s probably not your fault. Try again later?';

export const mdnInputMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const MDN_VERIFIED_STORAGE_KEY = 'MDN_VERIFIED_STORAGE_KEY';

// matches persona
export const EMAIL_REGEX_RFC5322 = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const SHORT_DATE_FORMAT = 'MM/DD/YYYY';
