import React from 'react';
import styled from 'styled-components';
import { getResponsiveImageUrlFromAssetAttributes } from '@asurion-hub/enrollment';
import { pxToEm, formatPhone, DynamicLink } from '@asurion-hub-web/ui';
import { default as MobileDeviceImage } from './MobileDeviceImage';
import rightArrowIcon from '@asurion-hub-web/assets/images/icons/right-arrow.svg';
import { PlanDevice } from '../hocs';

const RightArrowIcon = styled.div`
  background-image: url(${rightArrowIcon});
  width: 20px;
  height: 10px;
  margin: 2px 0 0 12px;
`;

const Device = styled.div`
  padding: ${pxToEm(24)};
`;

interface ButtonProps {
  onClick: () => void;
  ariaLabel: string;
  actionId: string;
}

const SeeInfoButton = styled(DynamicLink)<ButtonProps>`
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  justify-content: center;
  color: black;
  width: ${pxToEm(140)};
  height: ${pxToEm(50)};
  border-radius: ${pxToEm(40)};
  z-index: 1;
  align-items: center;
  margin-right: ${pxToEm(8)};
  margin-top: ${pxToEm(16)};
`;

const DevicesContainer = styled.div`
  margin-left: 1.25em;
  margin-right: 1.25em;
`;

const DeviceDisplayModuleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const DeviceInfo = styled.div`
  margin: auto 0;
  padding-left: ${pxToEm(24)};
`;

const DeviceInfoRow = styled.div`
  justify-content: center;
  margin-bottom: 8px;
`;

const DeviceFriendlyName = styled.div`
  font-size: ${pxToEm(36)};
  margin-bottom: 16px;
`;

const DeviceImageContainer = styled(MobileDeviceImage)`
  width: 100%;
  max-width: 140px;
`;

const StatusBanner = styled.div<{ contentVisible: boolean }>`
  ${(props) => (props.contentVisible ? 'background-color: #37E7A7;' : '')}
  width: fit-content;
  padding: ${pxToEm(2)} ${pxToEm(3)};
  font-family: 'Apercu-Regular-Pro';
  border-radius: 2px;
  font-size: ${pxToEm(14)};
  line-height: 14px;
  min-height: 14px;
`;

export const DeviceDisplayModule: React.FC<{
  planDevice: PlanDevice;
  onInfoClick: (assetId: string) => void;
}> = (props) => {
  const claimIsInProgress = props.planDevice.hasClaimInProgress;
  return (
    <DeviceDisplayModuleContainer>
      <Device>
        <DeviceImageContainer
          model={props.planDevice.model}
          responsiveImageUrl={getResponsiveImageUrlFromAssetAttributes(
            props.planDevice.assetCatalogAttributes
          )}
        />
      </Device>
      <DeviceInfo>
        <DeviceInfoRow>
          <StatusBanner contentVisible={claimIsInProgress}>
            {claimIsInProgress && 'Claim in progress'}
          </StatusBanner>
        </DeviceInfoRow>
        {props.planDevice.model && (
          <DeviceInfoRow>
            <DeviceFriendlyName data-testid="device-friendly-name">
              {props.planDevice.friendlyName ?? props.planDevice.model}
            </DeviceFriendlyName>
          </DeviceInfoRow>
        )}
        {props.planDevice.phone && (
          <DeviceInfoRow>
            <b>{formatPhone(props.planDevice.phone)}</b>
          </DeviceInfoRow>
        )}
        <SeeInfoButton
          onClick={() => props.onInfoClick(props.planDevice.assetId!)}
          actionId="SeeDeviceDetails"
          ariaLabel="See more details about this device."
        >
          See details
          <RightArrowIcon />
        </SeeInfoButton>
      </DeviceInfo>
    </DeviceDisplayModuleContainer>
  );
};

export const DevicesDisplayModule: React.FC<{
  planDevices: PlanDevice[];
  onInfoClick: (assetId: string) => void;
}> = (props) => {
  return (
    <>
      <DevicesContainer>
        {props.planDevices.map((planDevice) => (
          <DeviceDisplayModule
            key={planDevice.assetId}
            planDevice={planDevice}
            onInfoClick={props.onInfoClick}
          />
        ))}
      </DevicesContainer>
    </>
  );
};
