import { useTweekValue } from 'react-tweek';
import React from 'react';
import {
  ADD_A_DEVICE_SELECT_CATEGORY_ROUTE,
  ADD_A_DEVICE_SELECT_DEVICE_ROUTE,
  ADD_DEVICES_FLOW_V1_FEATURE_FLAG,
  ADD_DEVICES_SELECTED_WRAPUP_ROUTE,
} from '../config';
import {
  DeviceSelectedWrapupPage,
  SelectCategoryPage,
  SelectDevicePage,
} from '../pages';

export const getDeviceRoutes = (): { path: string; Component: React.FC }[] => [
  {
    path: ADD_A_DEVICE_SELECT_CATEGORY_ROUTE,
    Component: SelectCategoryPage,
  },
  {
    path: ADD_A_DEVICE_SELECT_DEVICE_ROUTE,
    Component: SelectDevicePage,
  },
  {
    path: ADD_DEVICES_SELECTED_WRAPUP_ROUTE,
    Component: DeviceSelectedWrapupPage,
  },
];

export const useDeviceRoutes = (): {
  path: string;
  Component: React.ComponentType<unknown>;
}[] => {
  const showDevicesRoutes = useTweekValue(
    ADD_DEVICES_FLOW_V1_FEATURE_FLAG,
    false
  );

  return !showDevicesRoutes ? [] : getDeviceRoutes();
};
