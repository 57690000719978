import * as React from 'react';
import { TabPanelContainer } from './tab-content';
import {
  DetailRow,
  pxToEm,
  LoadingSpinner,
  DynamicLink,
} from '@asurion-hub-web/ui';
import { ClaimsTabProps } from '../../../../types';
import styled from 'styled-components';
import { getPerilInfo, getStatusInfo } from '@asurion-hub/enrollment';
import moment from 'moment';
import { SHORT_DATE_FORMAT } from '@asurion-hub-web/config';
import noClaimsImage from '@asurion-hub-web/assets/images/illustrations/man_sitting_with_device_in_hand.svg';
import { claimIconsMap } from '@asurion-hub-web/assets/claimIcons';

const NoClaimsContainer = styled.div`
  display: inline-block;
  text-align: center;
  width: 100%;
  padding-top: 60px;
`;
const NoClaimsImage = styled.img``;
const NoClaimsText = styled.div`
  font-size: ${pxToEm(16)};
  font-weight: bold;
  line-height: 1.5;
`;
const ClaimsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ClaimStatusLabel = styled.div<{ backgroundColor: string; color: string }>`
  font-size: ${pxToEm(14)};
  line-height: 1;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  display: inline-block;
  padding: ${pxToEm(4)};
`;
const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 50px auto 0 auto;
`;

const Disclaimer = styled.div`
  font-size: ${pxToEm(14)};
  line-height: 150%;
  color: #6e767d;
  text-align: center;
  margin-top: ${pxToEm(50)};
  margin-bottom: ${pxToEm(50)};
`;

const StyledDynamicLink = styled(DynamicLink)`
  color: #6e767d;
`;

export const ClaimsTab: React.FC<ClaimsTabProps> = ({
  claimsInformation,
  goToClaimDetailsPage,
  deviceDetails,
  missingClaimsLink,
}) => {
  return (
    <TabPanelContainer data-test-id="claims-tab">
      {claimsInformation.claimsIsFetching ? (
        <StyledLoadingSpinner />
      ) : claimsInformation.claims && claimsInformation.claims.length > 0 ? (
        <ClaimsListContainer>
          {claimsInformation.claims.map((claim) => {
            const claimStatusInfo = getStatusInfo(
              claim.claimType,
              claim.claimStatus
            );
            const perilInfo = getPerilInfo(claim.perilType);
            return (
              <DetailRow
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                key={`claim_${claim.claimId}`}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                leftIcon={claimIconsMap[perilInfo.icon]}
                rowTitle={`Filed on ${moment(claim.claimFiledDate).format(
                  SHORT_DATE_FORMAT
                )}`}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                primaryText={perilInfo.label}
                onClick={() =>
                  goToClaimDetailsPage(claim.claimId, deviceDetails.assetId!)
                }
                actionId="ClaimItem"
                titleFontSizeInPxOverride={16}
              >
                <ClaimStatusLabel
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  backgroundColor={claimStatusInfo.tag.color}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  color={claimStatusInfo.tag.textColor}
                >
                  {claimStatusInfo.tag.label}
                </ClaimStatusLabel>
              </DetailRow>
            );
          })}
        </ClaimsListContainer>
      ) : (
        <NoClaimsContainer>
          <NoClaimsImage
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,global-require
            src={noClaimsImage}
            alt=""
          />
          <NoClaimsText>
            We haven’t found any claims on this device.
          </NoClaimsText>
        </NoClaimsContainer>
      )}
      {missingClaimsLink && (
        <Disclaimer>
          Not seeing what you’re looking for?
          <br />
          <StyledDynamicLink
            to={missingClaimsLink}
            actionId="NotSeeingWhatYourLookingForClaims"
          >
            See more claim details{' '}
          </StyledDynamicLink>
        </Disclaimer>
      )}
    </TabPanelContainer>
  );
};

export default ClaimsTab;
