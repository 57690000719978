import { AuthenticatedUser, AuthState } from '@asurion-hub/auth';
import { logger } from '@asurion-hub/logging';
import { BehaviorSubject, defer, EMPTY, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AccountProfileClient } from './AccountProfileClient';
import { AccountProfile, UpdateAccountProfile } from './types';

export type AccountProfileState = {
  loading: boolean;
  accountProfile?: AccountProfile;
  error?: unknown;
};

const client = new AccountProfileClient();
const state$ = new BehaviorSubject<AccountProfileState>({ loading: false });

const updateProfile = async (profile: UpdateAccountProfile) => {
  const accountProfile = await client.updateProfile(profile);
  state$.next({ loading: false, accountProfile });
  return accountProfile;
};

const loadProfile$ = () =>
  AuthenticatedUser.updated$
    .pipe(
      switchMap(({ state }) => {
        switch (state) {
          case AuthState.authenticated:
            return defer(() => client.getProfile()).pipe(
              map((accountProfile) => ({ loading: false, accountProfile })),
              catchError((error: Error) => {
                logger.warn('failed loading profile', error);
                return of({
                  ...state$.value,
                  loading: false,
                  error,
                });
              }),
              startWith({
                ...state$.value,
                loading: true,
              })
            );

          case AuthState.unauthenticated:
            return of({ loading: false });

          default:
            return EMPTY;
        }
      })
    )
    .subscribe((state) => state$.next(state));

export const AccountProfileService = {
  updated$: state$.asObservable(),
  get: () => state$.value,
  updateProfile,
  init: (preloadAvatar?: boolean) => {
    client.preloadAvatar = preloadAvatar;
    return loadProfile$();
  },
};
