import { useTweekValue } from 'react-tweek';
import { useEffect, useState } from 'react';
import { AuthenticatedUser } from '@asurion-hub/auth';
import { authClient } from '@asurion-hub-web/auth';

export const useSessionTimeout = (): void => {
  const minuteInMilliseconds = 10000;
  const enableSessionTimeout = useTweekValue<boolean>(
    'asurion_hub/account/session_timeout_enabled',
    false
  );
  const [intervalId, setIntervalId] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (enableSessionTimeout && !intervalId) {
      const id = window.setInterval(() => {
        const expiration = AuthenticatedUser.get()?.exp;
        if (expiration && Date.now() - expiration * 1000 >= 0) {
          authClient.logout(`${window.location.origin}/error/session-timeout`);
          clearInterval(intervalId);
        }
      }, minuteInMilliseconds);
      setIntervalId(id);
    }
    return function cleanup() {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [enableSessionTimeout, intervalId]);
};
