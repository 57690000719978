import React from 'react';
import MaskedInput from 'react-text-mask';
import { useTweekValue } from 'react-tweek';
import styled from 'styled-components';

import {
  DynamicLink,
  NotificationBanner,
  TextInput,
  Button,
  Private,
  formatPhone,
  LoadingOverlay,
} from '@asurion-hub-web/ui';
import { logger } from '@soluto-asurion/one-service-core';
import { VerifyOtpStatus } from '@asurion-hub/enrollment';
import { unknownErrorMessage } from '@asurion-hub-web/config';
import {
  ContentContainer,
  Title,
  ButtonContainer,
  StyledPage,
  Strong,
  Label,
} from '../CollectMdn/components';

type VerifyMdnPageProps = {
  onSuccess: () => void;
  mdn: string;
  usedEmail?: boolean;
  onResendCode: () => Promise<void>;
  onWrongNumber: () => void;
  onSubmit: (otp: string) => Promise<VerifyOtpStatus>;
  onMaxAttempts: () => void;
};

const Form = styled.form``;
const StyledDynamicLink = styled(DynamicLink)`
  text-align: left;
  text-decoration-line: underline;
  margin-top: 1rem;
`;

const VerifyMdnPage: React.FC<VerifyMdnPageProps> = (props) => {
  const maxAttempts = useTweekValue('asurion_hub/persona/otp_max_attempts', 6);
  const otpLength = useTweekValue('asurion_hub/persona/otp_length', 6);
  const [resent, setResent] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [pageErrorMessage, setPageErrorMessage] = React.useState<
    string | undefined
  >();
  const [attempts, setAttempts] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    onWrongNumber,
    onResendCode,
    mdn,
    usedEmail,
    onSubmit,
    onSuccess,
    onMaxAttempts,
  } = props;
  const [otp, setOtp] = React.useState('');
  const inputRef = React.useRef<MaskedInput>(null);
  const canSubmit = !isLoading && otp.length === otpLength;
  const handleResult = (status: VerifyOtpStatus) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case 'valid':
        onSuccess();
        return;
      case 'invalid':
        const updatedAttempts = attempts + 1;
        const remaining = maxAttempts - updatedAttempts;
        setAttempts(updatedAttempts);
        if (remaining > 0) {
          setErrorMessage(
            `Sorry, that’s not the right code. ${remaining} attempt${
              remaining === 1 ? '' : 's'
            } left.`
          );
        } else {
          onMaxAttempts();
        }
        return;
      case 'expired':
        setErrorMessage(
          'That code has expired, you’ll need to request a new one.'
        );
        return;
      case 'max-attempts':
        onMaxAttempts();
        return;
    }
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setOtp(event.target.value);
  };

  const wrappedOnSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!canSubmit) {
      return;
    }

    setIsLoading(true);
    try {
      if (attempts >= maxAttempts) {
        onMaxAttempts();
      } else {
        const result = await onSubmit(otp);
        handleResult(result);
      }
    } catch (err) {
      setErrorMessage(unknownErrorMessage);
      logger.error('Failed to submit OTP', err);
    } finally {
      setIsLoading(false);
    }
  };

  const wrappedOnResendCode = async () => {
    setIsLoading(true);
    try {
      await onResendCode();
      setResent(true);
    } catch (err) {
      setPageErrorMessage(unknownErrorMessage);
      logger.error('Failed to resend OTP', err);
    } finally {
      setIsLoading(false);
    }
  };

  const clearPageError = () => setPageErrorMessage(undefined);

  React.useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.inputElement) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      inputRef.current.inputElement.focus();
    }
  }, [inputRef]);

  return (
    <>
      <NotificationBanner
        isOpen={Boolean(pageErrorMessage)}
        onClose={clearPageError}
        notificationBannerId="VerifyMdnOtp"
        notificationReason={pageErrorMessage || ''}
        status="warn"
      >
        {pageErrorMessage}
      </NotificationBanner>
      <StyledPage pageName="VerifyMdn">
        <LoadingOverlay isVisible={isLoading} />
        <ContentContainer>
          {!usedEmail ? (
            <Title>
              Last step, enter the passcode{' '}
              {resent ? 'we resent' : 'we’ve sent'} to{' '}
              <Private>
                <Strong>{formatPhone(mdn)}</Strong>.
              </Private>
            </Title>
          ) : (
            <Title>
              {resent ? 'We resent' : 'We’ve sent'} a code to
              <Private>
                <Strong>the email associated with {formatPhone(mdn)}</Strong>.
                Please enter it below.
              </Private>
            </Title>
          )}
          <StyledDynamicLink actionId="OTPWrongNumber" onClick={onWrongNumber}>
            Not your number?
          </StyledDynamicLink>
          <Form onSubmit={wrappedOnSubmit}>
            <Label htmlFor="otp">SMS passcode</Label>
            <TextInput
              shadowed={true}
              maskConfig={{
                mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
                showMask: false,
                guide: false,
              }}
              ref={inputRef}
              onChange={onChange}
              value={otp}
              type="tel"
              id="otp"
              infoText={errorMessage}
              status={errorMessage ? 'warn' : undefined}
            />
          </Form>
          <StyledDynamicLink
            actionId="OTPResendCode"
            onClick={wrappedOnResendCode}
          >
            Send a new code
          </StyledDynamicLink>
          <ButtonContainer>
            <Button
              className="full-width primary"
              disabled={!canSubmit}
              actionId="OTPVerify"
              onClick={wrappedOnSubmit}
            >
              Continue
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </StyledPage>
    </>
  );
};

export { VerifyMdnPage };
