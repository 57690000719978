import * as React from 'react';
import splashAnimation from '../assets/images/animations/splash_CSS.svg';
import styled from 'styled-components';
import {
  pxToEm,
  SimpleLayout,
  DynamicLink,
  Breakpoints,
  LoadingPage,
} from '@asurion-hub-web/ui';
import { useHistory } from 'react-router-dom';
import {
  useDeviceSelectedState,
  useResetDeviceIdSelectedState,
} from '@asurion-hub-web/devices';
import {
  RouteBuilder,
  POST_CLAIMS_ADD_DEVICE_CTA,
  ADD_A_DEVICE_SELECT_CATEGORY_ROUTE,
} from '../config';
import {
  withSuspenseAndErrorBoundary,
  Device,
  deviceCategoryMap,
} from '@asurion-hub-web/devices-base';
import {
  enrichAnalytics,
  useAnalytics,
  withAnalyticOnView,
} from 'react-shisell';
import { flow } from 'fp-ts/lib/function';
import { useQueryString } from '../hooks';
import { useHideHeaderNavigation } from '@asurion-hub-web/header';
import { ROOT_ROUTE, RouteBuilder as AppRouteBuilder } from '@asurion-hub-web/config';

const ImageContainer = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  margin: ${pxToEm(50)} auto;
  width: 10.5em;
  height: 10.5em;
`;

const AnimationContainer = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 18.5em;
  height: 12.5em;
  margin: auto;
`;

const BodyContent = styled.div`
  margin: 0 16px;
  font-size: 32px;
  line-height: 38px;
  margin-top: 3.125em;

  @media ${Breakpoints.tabletBig} {
    margin-top: initial;
  }
`;

const SubBodyContent = styled.div`
  margin: 0 16px;
  font-size: 14px;
  line-height: ${pxToEm(21)};
  margin-bottom: ${pxToEm(40)};
  margin-top: 8px;

  @media ${Breakpoints.tabletBig} {
    margin-top: 7em;
  }
`;

const ButtonContainer = styled.div`
  margin: 0 16px;

  @media ${Breakpoints.mobileBig} {
    display: flex;
    flex-direction: column;
  }
`;

const AddAnotherLink = styled(SimpleLayout.ActionButton)`
  margin: auto;

  @media ${Breakpoints.tabletBig} {
    margin-bottom: 10px;
  }
`;

const NotNowLink = styled(DynamicLink)`
  text-decoration: underline;
  width: 100%;
  height: ${pxToEm(46)};
  margin-top: 8px;
`;

const PageContentWrapper = styled.div`
  min-width: ${pxToEm(375)};
  width: min-content;
  margin: auto;
  font-weight: 400;
  font-family: Apercu-Regular-Pro;

  @media ${Breakpoints.tabletBig} {
    margin: 0 auto;
    text-align: center;
    width: initial;
  }
`;

const FlipContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${Breakpoints.tabletBig} {
    margin-top: 4em;
    flex-direction: column-reverse;
  }
`;

type WrapupProps = {
  deviceSelectedState: Device | undefined;
  source?: string;
  claimId?: string;
};

export const DeviceSelectedWrapupPageContent: React.FC<WrapupProps> = ({
  deviceSelectedState,
  source,
  claimId,
}) => {
  const history = useHistory();
  const analytics = useAnalytics();
  const resetSelectedDeviceId = useResetDeviceIdSelectedState();
  const clearDeviceAndNavigate = React.useCallback(
    (route, clickId) => {
      analytics.dispatcher.withExtra('ClickId', clickId).dispatch('Click');

      resetSelectedDeviceId();
      history.push(route);
    },
    [history, resetSelectedDeviceId, analytics]
  );

  let deviceDisplayText = '';
  if (deviceSelectedState && deviceSelectedState.make) {
    deviceDisplayText += deviceSelectedState.make;
    const model = deviceSelectedState.model;
    deviceDisplayText += ` ${model} added!`;
  } else {
    // eslint-disable-next-line no-console
    console.warn('No selected device-state or device-make detected');
    history.push(
      RouteBuilder.getRouteAndPreserveParams(ADD_A_DEVICE_SELECT_CATEGORY_ROUTE)
    );
    return <div />; // added to remove eslint complaints about the deviceSelectedState being possibly undefined
  }

  return (
    <PageContentWrapper>
      <FlipContainer>
        <AnimationContainer image={splashAnimation}>
          <ImageContainer
            image={deviceCategoryMap[deviceSelectedState.category].icon}
          />
        </AnimationContainer>
        <BodyContent>{deviceDisplayText}</BodyContent>
      </FlipContainer>
      <SubBodyContent>
        Do you have any other devices that connect to your phone?
      </SubBodyContent>
      <ButtonContainer>
        <AddAnotherLink
          actionId="AddAnother"
          primary={true}
          onClick={() =>
            clearDeviceAndNavigate(
              RouteBuilder.getRouteAndPreserveParams(
                ADD_A_DEVICE_SELECT_CATEGORY_ROUTE
              ),
              'AddAnotherDevice'
            )
          }
          disabled={false}
          tabIndex={0}
        >
          Add another device
        </AddAnotherLink>
        <NotNowLink
          actionId="Submit"
          onClick={() => clearDeviceAndNavigate(ROOT_ROUTE, 'NotRightNow')}
        >
          {`${source ? 'Take me to my Asurion Hub homepage' : 'Not right now'}`}
        </NotNowLink>
        {source &&
          source.toLowerCase() === POST_CLAIMS_ADD_DEVICE_CTA.toLowerCase() &&
          claimId && (
            <NotNowLink
              actionId="Submit"
              onClick={() =>
                clearDeviceAndNavigate(
                  AppRouteBuilder.routeForClaimDetails(claimId),
                  'ShowClaimDetails'
                )
              }
            >
              Show claim details
            </NotNowLink>
          )}
      </ButtonContainer>
    </PageContentWrapper>
  );
};

const EnhancedWrapupPage = flow(
  enrichAnalytics((dispatcher, props: WrapupProps) =>
    dispatcher
      .withExtra('Page', 'DeviceAddedSuccess')
      .withExtra('DevicesVersion', 'v1')
      .withExtra('DeviceId', props.deviceSelectedState?.id)
      .withExtra('DeviceMake', props.deviceSelectedState?.make)
      .withExtra('DeviceModel', props.deviceSelectedState?.model)
      .withExtra('DeviceCategory', props.deviceSelectedState?.category)
  ),
  withAnalyticOnView({
    analyticName: 'PageView',
    mapPropsToExtras: (props: WrapupProps) => ({
      Page: 'DeviceAddedSuccess',
      DeviceId: props.deviceSelectedState?.id,
      DeviceMake: props.deviceSelectedState?.make,
      DeviceModel: props.deviceSelectedState?.model,
      DeviceCategory: props.deviceSelectedState?.category,
    }),
  })
)(withSuspenseAndErrorBoundary(DeviceSelectedWrapupPageContent, LoadingPage));

const WrapupPageContainer: React.FC = () => {
  const deviceSelectedState = useDeviceSelectedState();
  const source = useQueryString('source');
  const claimId = useQueryString('claimId');
  useHideHeaderNavigation();
  return (
    <EnhancedWrapupPage
      deviceSelectedState={deviceSelectedState}
      source={source}
      claimId={claimId}
    />
  );
};

export const DeviceSelectedWrapupPage = WrapupPageContainer;
