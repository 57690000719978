import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAnalytics, enrichAnalytics } from 'react-shisell';
import { formatPhone, LoadingPage } from '@asurion-hub-web/ui';
import { trigger } from '@asurion-hub-web/anywhere-expert-sdk';
import { useTweekValue } from 'react-tweek';
import { flow } from 'fp-ts/lib/function';
import { parse } from 'query-string';
import { usePlans, useServicePlan } from '@asurion-hub-web/enrollment';
import {
  AccountGateway,
  Claim,
  Plan,
  canBuildSurveyLink,
  buildSurveyLink,
  getTrackingLink,
  isClaimComplete,
  buildDeviceName,
} from '@asurion-hub/enrollment';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { withFetch, withProps } from '@asurion-hub-web/react-utils-web';
import { getTokenState } from '@asurion-hub/auth';
import { logger } from '@soluto-asurion/one-service-core';
import { PageNotification } from '@asurion-hub-web/notifications';
import { useHeaderNavigation } from '@asurion-hub-web/header';
import ClaimDetailsPage from './ClaimDetailsPage';
import { useCtaProvider } from '../../../ctas';
import { ClaimDetailTypeSelectionContainer } from './MultipleClaimTypePages/ClaimDetailTypeSelectionContainer';
import { ROOT_ROUTE, RouteBuilder } from '@asurion-hub-web/config';
import { partnerConfig$ } from '@asurion-hub-web/partner-config-react';

export type ClaimDetailsPageContainerProps = {
  claims: Claim[];
  claimsError?: Error;
  claimsIsFetching: boolean;
  openChat: () => Promise<void>;
  location: Record<string, string>;
  cta: JSX.Element | null;
  shouldSupportClaimArchitectureV2: boolean;
};

const ClaimDetailsPageBaseContainer: React.FC<ClaimDetailsPageContainerProps> = (
  props
) => {
  const [currentPlan, setCurrentPlan] = useState<Plan | null>();
  const { plans } = usePlans();
  const { servicePlan } = useServicePlan(currentPlan?.productId);
  const { claimId } = useParams<{ claimId?: string }>();
  const analytics = useAnalytics();
  const history = useHistory();
  const [notification, setNotification] = React.useState<PageNotification>();
  const surveyLink = useTweekValue('asurion_hub/claims/claim_survey_link', '');
  const [claim, setClaim] = useState<Claim | undefined>();

  useEffect(() => {
    if (!claimId) {
      logger.warn('ClaimId is undefined.');
      void analytics.dispatcher
        .withExtra('ActivityType', 'ClaimViewError')
        .withExtra('Error', 'ClaimId is undefined.')
        .withExtra('ClaimType', claim?.claimType)
        .withExtra('FulfillmentType', claim?.fulfillmentInfo?.method)
        .dispatch('Activity');
      history.push(ROOT_ROUTE);
    }
  }, [
    claimId,
    history,
    analytics.dispatcher,
    claim?.claimType,
    claim?.fulfillmentInfo?.method,
  ]);

  useEffect(() => {
    if (!claim || !plans) {
      return;
    }
    const plan = plans.find((plan) => plan.subscriberId === claim.subId);
    setCurrentPlan(plan);
  }, [claim, plans]);

  useHeaderNavigation({
    headerBackPath: currentPlan?.assets[0].assetId
      ? `${RouteBuilder.routeForDeviceDetails(
          currentPlan.assets[0].assetId
        )}?activeTab=claims`
      : undefined,
    headerBackText: currentPlan?.assets[0].friendlyName,
  });

  useEffect(() => {
    if (props.claimsError) {
      logger.error('Failed to fetch claimsList.', props.claimsError, {
        tokenState: getTokenState(),
      });
      void analytics.dispatcher
        .withExtra('ActivityType', 'ClaimViewError')
        .withExtra('Error', props.claimsError.message)
        .withExtra('ClaimType', claim?.claimType)
        .withExtra('FulfillmentType', claim?.fulfillmentInfo?.method)
        .dispatch();
      history.push(ROOT_ROUTE);
      return;
    }
    // not fetched yet
    if (!props.claims) {
      return;
    }
    const selectedClaim = props.claims?.find((c) => c.claimId === claimId);
    if (!selectedClaim) {
      logger.error(
        'Claim Id is not in claim list',
        new Error('Claim Id is not in claim list')
      );
      void analytics.dispatcher
        .withExtra('ActivityType', 'ClaimViewError')
        .withExtra('ClaimId', claimId)
        .withExtra('ClaimType', claim?.claimType)
        .withExtra('FulfillmentType', claim?.fulfillmentInfo?.method)
        .withExtra('NumberOfClaimsInList', props.claims.length)
        .withExtra('Error', 'Claim Id is not in claim list')
        .dispatch('Activity');
      history.push(ROOT_ROUTE);
      return;
    }
    void analytics.dispatcher
      .withExtra('ActivityType', 'ClaimViewSuccess')
      .withExtra('ClaimId', claimId)
      .withExtra('ClaimType', selectedClaim?.claimType)
      .withExtra('FulfillmentType', selectedClaim.fulfillmentInfo?.method)
      .withExtra(
        'ClaimOriginalDevice',
        buildDeviceName(selectedClaim.originalDevice)
      )
      .withExtra(
        'ClaimReplacementDevice',
        buildDeviceName(selectedClaim.fulfillmentInfo?.replacementDevice)
      )
      .dispatch('Activity');
    setClaim(selectedClaim);
  }, [
    claimId,
    props.claims,
    props.claimsError,
    history,
    analytics.dispatcher,
    claim,
  ]);

  const { recordingUrl } = parse(props.location?.search);

  if (!claim) {
    return <LoadingPage />;
  }

  const showSurveyCta = canBuildSurveyLink(claim);
  const onSurveyLinkClicked = () => {
    if (claimId && !props.claimsError && !props.claimsIsFetching) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.open(
        buildSurveyLink(surveyLink, claim, recordingUrl as string),
        '_blank'
      );
    }
  };

  const isLoading = props.claimsIsFetching;
  const planIncludesSupport = !!servicePlan?.includesSupport;

  return props.shouldSupportClaimArchitectureV2 ? (
    <ClaimDetailTypeSelectionContainer
      {...{
        claim,
        currentPlan: currentPlan!,
        isLoading,
        planIncludesSupport,
        showSurveyCta,
        surveyCTA: { onSurveyLinkClicked },
        notification,
        setNotification,
        cta: props.cta,
      }}
    />
  ) : (
    <ClaimDetailsPage
      isLoading={isLoading}
      planIncludesSupport={!!servicePlan?.includesSupport}
      showSurveyCta={showSurveyCta}
      isClaimComplete={isClaimComplete(claim)}
      onStartChatClick={props.openChat}
      billingSummary={{ fees: claim.fees }}
      claimDetails={{
        mdn: formatPhone(currentPlan?.phone || ''),
        assetId: currentPlan?.assets[0].assetId,
        type: claim.claimType,
        peril: claim.perilType,
        claimId: claim.claimId,
        // eslint-disable-next-line
        status: claim.claimStatus!,
        // eslint-disable-next-line
        originalDevice: claim.originalDevice?.name,
        trackingNumber: claim.trackingNumber,
        // eslint-disable-next-line
        trackingUrl: getTrackingLink(claim),
        filedDate: claim.claimFiledDate,
        expectedDeliveryDate: claim.expectedDeliveryDate,
        fulfillmentDevice: claim.fulfillmentInfo?.replacementDevice?.name,
        replacementDeviceImageUrl: undefined,
        accessories: claim.accessories,
        isComplete: isClaimComplete(claim),
      }}
      shippingInfo={{
        name: claim.shipToName,
        addressLines: claim.addressLines,
        email: claim.subscriberEmail,
        phoneNumber: claim.subscriberContactPhone,
      }}
      surveyCTA={{ onSurveyLinkClicked }}
      notification={notification}
      setNotification={setNotification}
      cta={props.cta}
    />
  );
};

export const ClaimDetailsPageContainer: React.FC<ClaimDetailsPageContainerProps> = (
  props
) => {
  const shouldSupportClaimArchitectureV2: boolean = useTweekValue(
    'asurion_hub/claims/support_claim_architecture_v2',
    false
  );

  const { claimId } = useParams<{ claimId?: string }>();
  const cta = useCtaProvider(shouldSupportClaimArchitectureV2, claimId);

  if (claimId) {
    return (
      <ClaimDetailsPageBaseContainer
        cta={cta}
        {...props}
        shouldSupportClaimArchitectureV2={shouldSupportClaimArchitectureV2}
      />
    );
  }

  return <Redirect to={ROOT_ROUTE} />;
};

export const EnhancedClaimDetailsPageContainer = flow(
  withProps({ openChat: trigger }),
  enrichAnalytics<ClaimDetailsPageContainerProps>((dispatcher, props) =>
    dispatcher.withExtra('ClaimId', props.claims)
  )
)(ClaimDetailsPageContainer);

export default flow(
  withFetch<unknown, ClaimDetailsPageContainerProps>(
    async () => {
      return await AccountGateway.getAllClaims(partnerConfig$.value.id);
    },
    'claims',
    'claimsError',
    'claimsIsFetching',
    'getAllClaims'
  )
)(EnhancedClaimDetailsPageContainer);
