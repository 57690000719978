import DynamicLink from './DynamicLink';
import * as React from 'react';

type CloseButtonProps = {
  onClick: () => void;
  actionId?: string;
  ariaLabel?: string;
};

const CloseButton: React.FC<CloseButtonProps> = (props) => {
  const { onClick, actionId, ariaLabel } = props;

  return (
    <DynamicLink onClick={onClick} ariaLabel={ariaLabel} actionId={actionId}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5859 12.0001L4.29297 18.293L5.70718 19.7072L12.0001 13.4143L18.293 19.7072L19.7072 18.293L13.4143 12.0001L19.7072 5.70718L18.293 4.29297L12.0001 10.5859L5.70718 4.29297L4.29297 5.70718L10.5859 12.0001Z"
          fill="currentColor"
        />
      </svg>
    </DynamicLink>
  );
};

export default CloseButton;
