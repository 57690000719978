import React from 'react';
import { Accordion, pxToEm } from '@asurion-hub-web/ui';
import styled from 'styled-components';
import locationPinIcon from '@asurion-hub-web/assets/images/icons/locationPin.svg';
import calendarIcon from '@asurion-hub-web/assets/images/icons/calendarCheck.svg';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: ${pxToEm(60)};
  margin-left: ${pxToEm(25)};
  @media (min-width: 30em) {
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
`;

const Boxes = styled.div`
  width: ${pxToEm(415)};
`;

const RepairIcon = styled.img`
  margin-left: ${pxToEm(18)};
  padding-bottom: ${pxToEm(8)};
  width: ${pxToEm(20)};
  float: left;
  transform: scale(1.3);
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pxToEm(20)} ${pxToEm(20)} ${pxToEm(10)} ${pxToEm(20)};
`;

const HeaderText = styled.div`
  font-weight: bold;
  margin-bottom: ${pxToEm(10)};
  font-size: ${pxToEm(16)};
  margin-right: 60px;
`;

const ClaimText = styled(HeaderText)`
  margin-right: 40px;
`;

const TextContainer = styled.div`
  float: right;
  max-width: ${pxToEm(200)};
  margin-right: ${pxToEm(190)};
  flex: 50%;
`;

const HeaderInfo = styled.div`
  margin-top: ${pxToEm(20)};
`;

const TotalContainer = styled.div`
  background-color: #f0f0f5;
`;

const Text = styled.div`
  margin-top: ${pxToEm(20)};
  margin-bottom: ${pxToEm(30)};
  margin-left: ${pxToEm(24)};
  margin-right: ${pxToEm(24)};
`;

const EligibilityText = styled(Text)`
  margin-bottom: ${pxToEm(30)};
  padding-bottom: ${pxToEm(30)};
`;

type RepairDetailsSectionProps = {
  scheduledDate: string;
  repairAddress: string[];
  helpNumber: string;
  phoneclaimLink: string;
};

export type PaymentSectionProps = {
  insuranceDeductibleFormatted: number;
  taxesFormatted: number;
  total: number;
};

type HelpSectionProps = {
  helpNumber: string;
};

export type RepairAccordionProps = {
  repairDetails: RepairDetailsSectionProps;
  paymentDetails: PaymentSectionProps;
  helpDetails: HelpSectionProps;
};

const constructRepairDetailsSection = (
  helpNumber: string,
  scheduledDate: string,
  repairAddress: string[],
  phoneclaimLink: string
) => {
  return (
    <>
      <Container>
        <Boxes>
          <HeaderInfo>
            <RepairIcon alt="Location Icon" src={locationPinIcon} />
          </HeaderInfo>
          <TextContainer>
            <ClaimText>Repair location</ClaimText>
            {repairAddress.map((addressline, i) => (
              <div key={i}>{addressline}</div>
            ))}
          </TextContainer>
        </Boxes>
        <Boxes>
          <HeaderInfo>
            <RepairIcon alt="Calendar Icon" src={calendarIcon} />
          </HeaderInfo>
          <TextContainer>
            <HeaderText>Date and time</HeaderText>
            <div>{scheduledDate}</div>
          </TextContainer>
        </Boxes>
      </Container>
      <Text>
        An Asurion certified technician will assist you with your repair. The
        repair center will evaluate your device and perform the repair. The
        repair process is normally completed in 2 hours. If you have any
        questions or need to reschedule, please visit us online at{' '}
        <a href={phoneclaimLink}>{phoneclaimLink.replace('https://', '')}</a> or
        call {helpNumber} anytime
        <br></br>
        <br></br>
        Note: You must have your device repaired on or before your scheduled
        date or you’ll need to reschedule to ensure parts are available.
        <br></br>
        <br></br>
      </Text>
    </>
  );
};

const constructPaymentSection = (
  tax: number,
  total: number,
  insuranceDeductible: number
) => {
  return (
    <div>
      <LineItem>
        <div>Insurance deductible</div>
        <div>{insuranceDeductible ? insuranceDeductible : '---'}</div>
      </LineItem>
      <LineItem>
        <div>Shipping fee</div>
        <div>FREE</div>
      </LineItem>
      <LineItem>
        <div>Taxes</div>
        <div>{tax ? tax : '---'}</div>
      </LineItem>
      <TotalContainer>
        <LineItem>
          <div>Total</div>
          <div>{total ? total : '---'}</div>
        </LineItem>
        <LineItem></LineItem>
      </TotalContainer>
      <LineItem></LineItem>
    </div>
  );
};

const constructRepairEligbilityAndWarrantySection = (helpNumber: string) => {
  return (
    <div>
      <EligibilityText>
        Repair eligibility will be determined upon inspection. If the phone
        cannot be repaired, you will still have the option to replace it at the
        standard deductible.
        <br></br>
        <br></br>
        Repairs are performed by an Asurion-certified technician and comes with
        a 12-month warranty. For warranty issues, contact Asurion at{' '}
        {helpNumber}.
      </EligibilityText>
    </div>
  );
};

const RepairAccordion: React.FC<RepairAccordionProps> = ({
  repairDetails,
  helpDetails,
  paymentDetails,
}) => {
  return (
    <Accordion
      sections={[
        {
          heading: 'Repair details',
          content: constructRepairDetailsSection(
            helpDetails.helpNumber,
            repairDetails.scheduledDate,
            repairDetails.repairAddress,
            repairDetails.phoneclaimLink
          ),
          startOpen: true,
        },
        {
          heading: 'Payment details',
          content: constructPaymentSection(
            paymentDetails.taxesFormatted,
            paymentDetails.total,
            paymentDetails.insuranceDeductibleFormatted
          ),
          startOpen: false,
        },
        {
          heading: 'Repair eligibility and warranty',
          content: constructRepairEligbilityAndWarrantySection(
            helpDetails.helpNumber
          ),
          startOpen: false,
        },
      ]}
    />
  );
};

export default RepairAccordion;
