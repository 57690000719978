import { pxToEm, SimpleLayout } from '@asurion-hub-web/ui';
import styled from 'styled-components';

export const ContentContainer = styled(SimpleLayout.ContentContainer)`
  display: flex;
  flex-direction: column;
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    width: ${SimpleLayout.cols(11)};
    max-width: ${pxToEm(453)};
  }
`;

export const ContentHeading = styled.div`
  font-size: ${pxToEm(20)};
  padding-top: ${pxToEm(16)};
  padding-bottom: ${pxToEm(16)};
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    padding-top: ${pxToEm(16)};
    padding-bottom: ${pxToEm(16)};
  }
`;

export const Instructions = styled.div`
  margin-top: ${pxToEm(19)};
  margin-bottom: ${pxToEm(19)};
  line-height: 150%;
`;

export const BottomPadding = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: ${pxToEm(32)};

  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    width: ${SimpleLayout.cols(8)};
  }
`;
