import { partnerConfig$ } from '@asurion-hub-web/partner-config-react';
import { doesUserHaveSupportedPersonas } from '@asurion-hub/auth';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unused import
import React, { ComponentType } from 'react';
import {
  COLLECT_MDN_ROUTE,
  DETAILS_NOT_FOUND_ROUTE,
  MDN_VERIFIED_STORAGE_KEY,
} from '@asurion-hub-web/config';
import { Redirect } from 'react-router-dom';
import { useAnalytics } from 'react-shisell';

export const withVerifyMdn = <T,>(Comp: ComponentType<T>) => (props: T) => {
  const analytics = useAnalytics();
  const mdnIsVerified =
    sessionStorage.getItem(MDN_VERIFIED_STORAGE_KEY) === 'true';

  if (!doesUserHaveSupportedPersonas(partnerConfig$.value.id)) {
    if (mdnIsVerified) {
      analytics.dispatcher
        .withExtra('ActivityType', 'RedirectedToNoDetailsFound')
        .dispatch('Activity');
      return <Redirect to={DETAILS_NOT_FOUND_ROUTE} />;
    } else {
      return <Redirect to={COLLECT_MDN_ROUTE} />;
    }
  }

  return <Comp {...props} />;
};
