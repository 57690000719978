import { authClient } from '@asurion-hub-web/auth';
import { ROOT_ROUTE } from '@asurion-hub-web/config';
import { LoadingPage } from '@asurion-hub-web/ui';
import { AuthenticatedUser, AuthState } from '@asurion-hub/auth';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

export default () => {
  useEffect(() => {
    if (AuthenticatedUser.State === AuthState.authenticated) {
      authClient.logout();
    }
  }, []);

  if (AuthenticatedUser.State === AuthState.unauthenticated) {
    return <Redirect to={ROOT_ROUTE} />;
  }
  return <LoadingPage />;
};
