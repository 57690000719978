import { getContentfulConfig } from '@asurion-hub/contentful';
import {
  ContentfulClientApi,
  createClient,
  CreateClientParams,
} from 'contentful';

class ContentfulClient {
  private client: ContentfulClientApi | undefined;

  public init = async (): Promise<void> => {
    const options: CreateClientParams = await getContentfulConfig();

    if (!options.accessToken || !options.space) {
      throw new Error('Contentful access token and/or space are missing');
    }

    this.client = createClient(options);
  };

  public get instance(): ContentfulClientApi {
    if (!this.client) {
      throw new Error('Contentful client not initialized');
    }

    return this.client;
  }
}

export default new ContentfulClient();
