import * as React from 'react';

type PrivateProps = {
  element?: keyof React.ReactHTML;
};

const Private: React.FC<PrivateProps> = (props) => {
  const { element: Element = 'div' } = props;
  return <Element className="fs-exclude">{props.children}</Element>;
};

export default Private;
