import { selector, useRecoilValue } from 'recoil';
import { Filter } from '../filters';
import { searchResultsFilters } from './searchResultsFilters';

export const DEVICE_MAKE_FILTERS_LIMIT = 15;

export const searchResultsFiltersOrdering = selector<Filter[]>({
  key: 'searchResultsFiltersOrdering',
  get: ({ get }) =>
    get(searchResultsFilters).slice(0, DEVICE_MAKE_FILTERS_LIMIT),
});

export const useOrderedSearchResultsFilters = () =>
  useRecoilValue(searchResultsFiltersOrdering);
