import { selector, useRecoilValue } from 'recoil';
import { Filter } from '../filters';
import { searchResultsByCategory, deviceMakesMapState } from '../search-results';

export const searchResultsFilters = selector<Filter[]>({
  key: 'searchResultsFilters',
  get: ({ get }) => {
    const resultsByCategory = Object.entries(get(searchResultsByCategory));
    if (!(resultsByCategory.length > 0)) {
      return [];
    }
    const deviceMakesMap = get(deviceMakesMapState);

    return resultsByCategory
      .map((entry) => {
        const [make, devices] = entry;
        const totalWeight = devices.reduce((prev, curr) => {
          return prev + (curr.weight ?? 0);
        }, 0);
        return { make, totalWeight };
      })
      .sort((a, b) => b.totalWeight - a.totalWeight)
      .map(({ make }) => ({
        type: 'make',
        value: make,
        displayText: deviceMakesMap[make]
      }));
  },
});

export const useSearchResultsFilters = () =>
  useRecoilValue(searchResultsFilters);
