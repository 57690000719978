import {
  atom,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil';
import { Device } from '@asurion-hub-web/devices-base';

export const deviceIdSelectedState = atom<Device['id']>({
  key: 'deviceIdSelectedState',
  default: '',
});
export const useDeviceIdSelectedState = () =>
  useRecoilValue(deviceIdSelectedState);
export const useSetDeviceIdSelectedState = () =>
  useSetRecoilState(deviceIdSelectedState);
export const useResetDeviceIdSelectedState = () =>
  useResetRecoilState(deviceIdSelectedState);
