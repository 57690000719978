import {
  RepairClaimFulfillmentStatus,
  ReplacementClaimFulfillmentStatus,
} from '@soluto-private/account-api-client';
import { Claim } from '../../types';

export const whatsNextReplaceStepIndexMap = (claim: Claim) => {
  switch (claim.claimStatus) {
    case ReplacementClaimFulfillmentStatus.InProgress:
      return 1;
    case ReplacementClaimFulfillmentStatus.Completed:
      return 2;
    default:
      // default to highlighting initial step if status unknown
      return 0;
  }
};

export const whatsNextRepairStepIndexMap = (claim: Claim) => {
  switch (claim.claimStatus) {
    case RepairClaimFulfillmentStatus.InProgress:
      return 1;
    case RepairClaimFulfillmentStatus.Completed:
      return 2;
    default:
      // default to highlighting initial step if status unknown
      return 0;
  }
};
