import { HorizontalRule, SimpleLayout } from '@asurion-hub-web/ui';
import * as React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-cycle
import { DetailRow } from '.';
import { INCORRECT_INFO_REPORT } from '../constants';
import {
  BottomPadding,
  ButtonContainer,
  ContentContainer,
  ContentHeading,
  Instructions,
} from './Layout';

const HorizontalRuleContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: unset;
`;

export type IIncorrectInfoFormProps = {
  informationDetails: Array<{
    rowTitle: string;
    primaryText: string | undefined;
  }>;
  setReportSubmitted: (submitted: boolean) => void;
  setItem: (key: string, item: string) => void;
};

const IncorrectInfoForm: React.FC<IIncorrectInfoFormProps> = ({
  informationDetails,
  setReportSubmitted,
  setItem,
}) => {
  const [selectedInfoItems, setSelectedInfoItems] = React.useState<
    Array<string>
  >([]);

  const onReportSubmit = () => {
    setItem(INCORRECT_INFO_REPORT, selectedInfoItems.join(','));
    setReportSubmitted(true);
  };

  const onCheckboxChange = (value: string, checked: boolean) => {
    let newSelectedInfoItems = [...selectedInfoItems];
    if (checked) {
      newSelectedInfoItems.push(value);
    } else {
      newSelectedInfoItems = newSelectedInfoItems.filter(
        (item) => item !== value
      );
    }
    setSelectedInfoItems(newSelectedInfoItems);
  };

  const disabled = selectedInfoItems.length === 0;
  return (
    <>
      <ContentContainer>
        <SimpleLayout.Heading>
          We&rsquo;re sorry your information isn&rsquo;t correct.
        </SimpleLayout.Heading>
        <Instructions>
          Select which details are wrong and we&rsquo;ll do what we can to fix
          the problem. This will not affect your ability to file a claim or get
          help.
        </Instructions>
      </ContentContainer>
      <ContentContainer>
        <ContentHeading>Information</ContentHeading>
      </ContentContainer>
      <HorizontalRuleContainer>
        <HorizontalRule />
      </HorizontalRuleContainer>
      <ContentContainer>
        {informationDetails
          .filter((detail) => detail.primaryText !== undefined)
          .map((detail, index) => (
            <DetailRow
              {...detail}
              blockFromAnalytics={true}
              onCheckboxChange={onCheckboxChange}
              key={index}
            />
          ))}
      </ContentContainer>
      <ButtonContainer>
        <SimpleLayout.ActionsContainer>
          <SimpleLayout.ActionButton
            actionId="Submit"
            primary={true}
            onClick={onReportSubmit}
            disabled={disabled}
            analyticsExtras={{ incorrectInfoItems: selectedInfoItems }}
            tabIndex={0}
          >
            Submit
          </SimpleLayout.ActionButton>
        </SimpleLayout.ActionsContainer>
      </ButtonContainer>
      <BottomPadding />
    </>
  );
};

export default IncorrectInfoForm;
