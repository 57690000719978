import React from 'react';
import { useTweekValue } from 'react-tweek';
import { useDeviceConnectionsCtaState } from '@asurion-hub-web/user-devices';
import { default as SelectDeviceConnectionsCta } from '../components/SelectDeviceConnectionsCta';
import { withAnalyticOnView } from 'react-shisell';

const TWEEK_QUERY =
  'asurion_hub/devices/add_device_by_category/v1/device_connections_cta_home';
const DEFAULT_TWEEK_VALUE = { entryId: '1PuGqKckL8sIiGF98Qjclz' };

export type DeviceConnectionsCtaData = {
  name: string;
  version: string;
  headline: string;
  content: string;
  // eslint-disable-next-line
  icon?: any;
};

export const useDeviceConnectionsCtaProvider = (): JSX.Element => {
  const contentfulEntryId = useTweekValue(TWEEK_QUERY, DEFAULT_TWEEK_VALUE);
  const data = useDeviceConnectionsCtaState(contentfulEntryId.entryId);
  const ctaData = data?.fields as DeviceConnectionsCtaData;

  if (ctaData && ctaData.name) {
    const ctaProps = {
      ...ctaData,
      // eslint-disable-next-line
      icon: ctaData.icon
        ? // eslint-disable-next-line
          ctaData.icon.fields.file.url
        : undefined,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const EnhancedCTA = withAnalyticOnView<any>({
      analyticName: 'SectionView',
      mapPropsToExtras: () => ({
        Page: 'DevicesHomepageCTA',
        DevicesVersion: 'v1',
        CtaName: ctaData.name,
        CtaVersion: ctaData.version,
      }),
    })(SelectDeviceConnectionsCta);
    return <EnhancedCTA {...ctaProps} />;
  }
  return <></>;
};
