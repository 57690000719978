import React from 'react';
import { Transition } from 'react-transition-group';
import { TransitionChildren } from 'react-transition-group/Transition';

type FadeProps = {
  /**
   * Show the component; triggers the enter or exit states.
   * @default false
   */
  in?: boolean;

  /**
   * The duration of the animation.
   */
  timeout: number;
  /**
   * The HTML class to pass through. This enables use with styled-components.
   @ default empty string
   */
  className?: string;

  /**
   * The HTML element to wrap the children in.
   * @default 'div'
   */
  element?: keyof React.ReactHTML;

  /**
   * Can be a function if you need to receive the transition status.
   * See https://reactcommunity.org/react-transition-group/transition
   */
  children?: TransitionChildren;
};

/**
 * Handles fading in and out. Only sets display: none when the fade animation completes.
 */
const Fade: React.FC<FadeProps> = (props) => {
  const WrapperElement: typeof props.element = props.element || 'div';
  return (
    <Transition in={props.in} timeout={{ enter: 0, exit: props.timeout }}>
      {(state) => (
        <WrapperElement
          className={props.className}
          style={{
            transition: `opacity ${props.timeout}ms`,
            opacity: state === 'entered' ? 1 : 0,
            ...(state === 'exited' ? { display: 'none' } : {}),
          }}
        >
          {typeof props.children === 'function'
            ? // eslint-disable-next-line @typescript-eslint/ban-types
              (props.children as Function)(state)
            : props.children}
        </WrapperElement>
      )}
    </Transition>
  );
};

export default Fade;
