import React from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import { LoadingSpinner } from '@asurion-hub-web/ui';

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-props-no-spreading */
// We can localize ErrorBoundary and Suspense Loading states at any level so different parts of the UI can handles loading/errors independently
export const withSuspenseAndErrorBoundary = <P extends {}>(
  Wrapped: React.ComponentType<P>,
  LoadingComponent: React.ComponentType = LoadingSpinner
) => (props: P) => (
  <ErrorBoundary>
    <React.Suspense fallback={<LoadingComponent />}>
      <Wrapped {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
