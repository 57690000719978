import {
    useRecoilValue,
    selectorFamily
} from 'recoil';
import { useContentfulClient } from '@asurion-hub/contentful';
import { Entry } from 'contentful';

export const deviceConnectionsCtaState = selectorFamily<Entry<unknown> | undefined, string>({
    key: 'deviceConnectionsCtaState',
    get: (entryId: string) => async () => {
        const contentfulClient = useContentfulClient();
        return await contentfulClient.getEntry(entryId);
    },
});

export const useDeviceConnectionsCtaState = (entryId: string) => useRecoilValue(deviceConnectionsCtaState(entryId));
