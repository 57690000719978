import { selector, useRecoilValue } from 'recoil';
import { selectedFilters } from './selectedFilters';
import { Filter } from './filter';

export const categoryFilter = selector<Filter | undefined>({
  key: 'categoryFilter',
  get: ({ get }) => get(selectedFilters).find((f) => f.type === 'category'),
});

export const useCategoryFilter = () => useRecoilValue(categoryFilter);
