// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unused import
import React, { ComponentType } from 'react';

export type WithStorageProps = {
  getItem: (item: string) => string | undefined;
  setItem: (key: string, item: string) => void;
};

const withStorage = (storage: Storage) => <T extends WithStorageProps>(
  Comp: ComponentType<T>
) => {
  const getItem = (item: string) => storage.getItem(item);
  const setItem = (key: string, item: string) => storage.setItem(key, item);

  return (props: Omit<T, keyof WithStorageProps>) => (
    <Comp {...(props as T)} getItem={getItem} setItem={setItem} />
  );
};

const withLocalStorage = withStorage(localStorage);
const withSessionStorage = withStorage(sessionStorage);

export { withLocalStorage, withSessionStorage };
