/* eslint-disable */
import { AuthenticatedUser } from '@asurion-hub/auth';
import { session } from '@soluto-asurion/one-service-core';
import { AnalyticsEventModel, EventModelWriter } from 'shisell';

declare global {
  interface Window {
    FS: any;
  }
}

export default function initFullStory() {
  if (!window.FS) {
    return;
  }
  const userBrowserId = session.UserBrowser.get().UserBrowserId;

  // subscribe to the AuthenticatedUser since it informs which FS method we use
  AuthenticatedUser.updated$.subscribe(({ user }) => {
    if (user?.asurionId) {
      const asurionId = user.asurionId;

      /*
       * according to FullStory docs, identify() should only be called for authenticated users
       * using asurionId to track users across their various sessions as one user in FS
       * https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
       */
      window.FS.identify(asurionId, {
        asurionId,
        userBrowserId,
      });
    } else {
      // for guests, we call setUserVars with the userBrowserId
      window.FS.setUserVars({
        userBrowserId,
      });
    }
  });
}

export const fullstoryWriter: EventModelWriter<void> = (
  e: AnalyticsEventModel
) => {
  if (!window.FS || e.MetaData?.IsTest) {
    return;
  }

  window.FS.event(`${e.Scope}_${e.Name}`, {
    ...e.Identities,
    ...e.ExtraData,
  });
};
