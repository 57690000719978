// this file was copied from https://github.com/cyberwombat/shipmethod
// because that package is not maintained and doesn't have types

import { format } from 'util';

export enum Carrier {
  ups = 'ups',
  fedex = 'fedex',
  usps = 'usps',
}

const urls: Record<Carrier, string> = {
  [Carrier.ups]:
    'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=%s',
  [Carrier.fedex]:
    'https://www.fedex.com/Tracking?action=track&tracknumbers=%s',
  [Carrier.usps]:
    'https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=%s',
};

const carriers = [
  {
    name: Carrier.ups,
    regex: /^1Z[0-9A-Z]{16}$/i,
  },
  {
    name: Carrier.ups,
    regex: /^[\dT]\d{10}$/i,
  },
  {
    name: Carrier.usps,
    regex: /^82\d{8}$/i,
  },
  {
    name: Carrier.usps,
    regex: /^(EA|EC|CP|RA)\d{9}(\D{2})?$/i,
  },
  {
    name: Carrier.usps,
    regex: /^(7\d|03|23|91|94)(\d{18}|\d{20})$/i,
  },
  {
    name: Carrier.fedex,
    regex: /^\d{12}$/i,
  },
  {
    name: Carrier.fedex,
    regex: /^\d{15}$/i,
  },
  {
    name: Carrier.usps,
    regex: /^[A-Za-z]{2}[0-9]+US$/i,
  },
];

export const getCarrier = (track: string) => {
  let carrier;
  if (track) {
    track = track.replace(/\s+/g, '');
    carrier = carriers.find((c) => c.regex.test(track));
  }
  return carrier?.name;
};

export const getCarrierLink = (carrier: string, code: string) => {
  carrier = carrier.toLowerCase();
  const url = urls[carrier as keyof typeof urls];
  return url ? format(url, code) : undefined;
};
