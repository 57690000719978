import * as React from 'react';
import styled from 'styled-components';
import chevronRight from '@asurion-hub-web/assets/images/icons/chevron-right.svg';
import { Collapse, CollapseProps } from './Collapse';
import { default as BaseSectionHeader } from './SectionHeader';
import { pxToEm } from '../utils';

export type AccordionSection = {
  heading: React.ReactNode;
  content: React.ReactNode;
  /* Whether the accordion sections start open or closed. */
  startOpen: boolean;
};

export type AccordionProps = {
  sections: Array<AccordionSection>;
  /* In milliseconds */
  collapseDuration?: number;
};

const SectionHeader = styled(BaseSectionHeader)<Required<CollapseProps>>`
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: ${pxToEm(15)};
  position: relative;
  :after {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: ${pxToEm(15)};
    top: calc(50% - 12px);
    background-image: url(${chevronRight});
    background-repeat: no-repeat;
    transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : `rotate(0deg)`)};
    transition: ${({ duration }) => `transform ${duration}ms`};
  }
`;

export const Accordion: React.FC<AccordionProps> = (props) => {
  const { sections, collapseDuration = 300 } = props;
  const [openFlags, setOpenFlags] = React.useState(
    sections.map((section) => section.startOpen)
  );
  const toggleSection = (i: number) => () => {
    const newOpenFlags = [...openFlags];
    newOpenFlags[i] = !openFlags[i];
    setOpenFlags(newOpenFlags);
  };
  return (
    <>
      {sections.map((section, i) => (
        <React.Fragment key={i}>
          <SectionHeader
            isOpen={openFlags[i]}
            duration={collapseDuration}
            onClick={toggleSection(i)}
          >
            {section.heading}
          </SectionHeader>
          <Collapse isOpen={openFlags[i]} duration={collapseDuration}>
            {section.content}
          </Collapse>
        </React.Fragment>
      ))}
    </>
  );
};
