import { RichText } from '@asurion-hub-web/contentful';
import { Popover, pxToEm, DetailRow } from '@asurion-hub-web/ui';
import * as React from 'react';
import styled from 'styled-components';
import { BenefitHighlight } from '@asurion-hub/enrollment';
import checkMark from '@asurion-hub-web/assets/images/icons/complete.svg';
import TabContent from '../TabContent';

const Benefit = styled.div`
  display: flex;
  align-items: center;
`;

const BenefitPopoverWrapper = styled.span`
  display: block;
  margin-left: ${pxToEm(10)};
`;

const BenefitTextWrapper = styled.span`
  display: block;
  margin-right: auto;
`;

const PopoverText = styled.span`
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    :hover {
      text-decoration: underline;
    }
  }
`;

const getBenefits = (benefitHighlights: BenefitHighlight[]) =>
  benefitHighlights.map(({ text, popoverId, popoverText }, idx) => (
    <Benefit key={idx}>
      <BenefitTextWrapper>{text}</BenefitTextWrapper>
      {popoverText && (
        <BenefitPopoverWrapper>
          <Popover popoverId={popoverId}>
            <PopoverText>
              <RichText document={popoverText} />
            </PopoverText>
          </Popover>
        </BenefitPopoverWrapper>
      )}
    </Benefit>
  ));

const BenefitsRows: React.FC<{ benefits: BenefitHighlight[] }> = (props) => {
  return (
    <>
      <TabContent>
        {getBenefits(props.benefits).map((benefit, i) => (
          <DetailRow
            leftIcon={checkMark}
            leftIconAltText="Green circle with check mark"
            primaryText={benefit}
            key={`benefit_${i}`}
          />
        ))}
      </TabContent>
    </>
  );
};

export default BenefitsRows;
