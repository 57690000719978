import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

type PublicRouteProps = RouteProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ElementType<any>;
  path: string;
};
export const PublicRoute: React.FC<PublicRouteProps> = ({
  Component,
  path,
  ...rest
}) => {
  return (
    <Route path={path} {...rest} render={(props) => <Component {...props} />} />
  );
};
