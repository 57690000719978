import { atom } from 'recoil';

export type HeaderNavigationOptions = {
  headerBackPath?: string;
  headerBackText?: string;
  headerLogoClickDisabled?: boolean;
};

export const headerStateDefaults: HeaderNavigationOptions = {
  headerBackText: 'Back',
  headerBackPath: undefined,
  headerLogoClickDisabled: false,
};

export const headerBackTextState = atom<string | undefined>({
  key: 'headerBackText',
  default: headerStateDefaults.headerBackText,
});

export const headerBackPathState = atom<string | undefined>({
  key: 'headerBackPath',
  default: headerStateDefaults.headerBackPath,
});

export const headerLogoClickDisabledState = atom<boolean>({
  key: 'headerLogoClickDisabled',
  default: headerStateDefaults.headerLogoClickDisabled!,
});
