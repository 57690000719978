import { AuthenticatedUser } from '@asurion-hub/auth';
import { session } from '@soluto-asurion/one-service-core';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

const ADD_ASURION_ID_EVENT_NAME = 'addAsurionId';
const ADD_USER_BROWSER_ID_EVENT_NAME = 'addUserBrowserId';

const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID!,
  auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
  preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW_ENV,
  dataLayer: {
    userBrowserId: '',
    asurionId: '',
  },
  events: {
    addAsurionId: ADD_ASURION_ID_EVENT_NAME,
    addUserBrowserId: ADD_USER_BROWSER_ID_EVENT_NAME,
  },
};

export default () => {
  TagManager.initialize(tagManagerArgs);

  const userBrowserId = session.UserBrowser.get().UserBrowserId;
  TagManager.dataLayer({
    dataLayer: { event: ADD_USER_BROWSER_ID_EVENT_NAME, userBrowserId },
  });

  AuthenticatedUser.updated$.subscribe(({ user }) => {
    if (user?.asurionId) {
      const asurionId = user.asurionId;
      TagManager.dataLayer({
        dataLayer: { event: ADD_ASURION_ID_EVENT_NAME, asurionId },
      });
    }
  });
};
