export enum AuthState {
  authenticated = 'authenticated',
  unauthenticated = 'unauthenticated',
  inProgress = 'inProgress',
}

export interface Persona {
  id: string;
  sourcePlatform: string;
  entityType: string;
  externalId: string;
}

export interface IAuthenticatedUser {
  principalIdentifier: string;
  subject?: string;
  asurionId: string;
  personas?: Persona[];
  provisional?: boolean;
  exp: number;
}

export type UserUpdate =
  | {
      state: AuthState.authenticated;
      user: IAuthenticatedUser;
      accessToken: string;
    }
  | {
      state: AuthState.unauthenticated;
      user: null;
      accessToken: null;
    }
  | {
      state: AuthState.inProgress;
      user?: undefined;
      accessToken?: undefined;
    };

export type TokenInfo = {
  principal_identifier: string;
  asurion_id: string;
  personas?: Persona[];
  sub?: string;
  provisional?: boolean;
  exp: number;
  ext?: {
    principal_identifier: string;
    asurion_id: string;
    personas?: Persona[];
    provisional?: boolean;
  };
};

export type TokenState = 'valid' | 'expired' | 'empty';
