import * as React from 'react';
import { useHideHeaderNavigation } from '@asurion-hub-web/header';
import { SimpleLayout } from '@asurion-hub-web/ui';
import {
  useAccountServicePlans,
  useRedirectForPhotoOnlyPlans,
} from '@asurion-hub-web/enrollment';
import AuthenticatedHomePage from './AuthenticatedHomePage';

const AuthenticatedHomePageContainer: React.FC = () => {
  useHideHeaderNavigation();
  const servicePlansState = useAccountServicePlans();
  useRedirectForPhotoOnlyPlans(servicePlansState.servicePlans);

  return (
    <SimpleLayout.Page pageName="AuthenticatedHomePage">
      <AuthenticatedHomePage servicePlansState={servicePlansState} />
    </SimpleLayout.Page>
  );
};

export default AuthenticatedHomePageContainer;
