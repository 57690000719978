import { noop } from '@asurion-hub-web/utils';
import * as React from 'react';
import { useAnalytics } from 'react-shisell';
import styled from 'styled-components';
import { useWindowSize } from '../hooks';
import { ThemeStatus } from '@asurion-hub/partner-theme';
import { getStatusColor, getStatusTextColor, pxToEm } from '../utils';
import DynamicLink from './DynamicLink';

export type NotificationBannerProps = {
  isOpen?: boolean;
  onClose?: () => void;
  status?: ThemeStatus;
  notificationBannerId: string;
  notificationReason: string;
};

const NotificationBannerContainer = styled.div<{
  status?: ThemeStatus;
  isOpen?: boolean;
  contentHeight: number;
}>`
  background-color: ${getStatusColor};
  color: ${getStatusTextColor};
  transition: color 0.3s, background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: ${({ isOpen }) => (isOpen ? pxToEm(12) : 0)} ${pxToEm(16)};
  line-height: 1.5;
  height: ${({ isOpen, contentHeight }) => (isOpen ? contentHeight : 0)}px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: height 0.3s, padding 0.3s, opacity 0.3s;
`;

const NotificationBannerContent = styled.div`
  display: flex;
  margin-right: auto;
`;

const NotificationBannerCloseButton = styled(DynamicLink)`
  margin-left: ${pxToEm(21)};
`;

const NotificationBannerCloseButtonPath = styled.path<{ status?: ThemeStatus }>`
  fill: ${getStatusTextColor};
  transition: fill 0.3s;
`;

const NotificationBanner: React.FC<NotificationBannerProps> = (props) => {
  const windowSize = useWindowSize();
  const [contentHeight, setContentHeight] = React.useState(0);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const analytics = useAnalytics();

  const {
    isOpen,
    notificationBannerId,
    children,
    status,
    onClose = noop,
    notificationReason,
  } = props;

  React.useEffect(() => {
    if (isOpen && contentRef && contentRef.current) {
      const el = contentRef.current;
      setContentHeight(el.offsetHeight);
    }
  }, [windowSize, isOpen, children]);

  React.useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      analytics.dispatcher
        .withExtra('NotificationReason', notificationReason)
        .withExtra('NotificationStatus', status)
        .createScoped('NotificationBanner')
        .dispatch('View');
    }
  }, [isOpen, notificationReason, analytics, status]);

  return (
    <NotificationBannerContainer
      status={status}
      isOpen={isOpen}
      contentHeight={contentHeight}
      data-testid="notification-banner"
    >
      <NotificationBannerContent ref={contentRef}>
        {children}
      </NotificationBannerContent>
      <NotificationBannerCloseButton
        actionId={`${notificationBannerId}Close`}
        onClick={onClose}
        ariaLabel="close notification banner"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <NotificationBannerCloseButtonPath
            status={status}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5859 12.0001L4.29297 18.293L5.70718 19.7072L12.0001 13.4143L18.293 19.7072L19.7072 18.293L13.4143 12.0001L19.7072 5.70718L18.293 4.29297L12.0001 10.5859L5.70718 4.29297L4.29297 5.70718L10.5859 12.0001Z"
          />
        </svg>
      </NotificationBannerCloseButton>
    </NotificationBannerContainer>
  );
};

export default NotificationBanner;
