import * as React from 'react';
import styled from 'styled-components';
import { pxToEm, SectionHeader } from '@asurion-hub-web/ui';
import { BillingSummaryProps } from '../types';

const Details = styled.div`
  display: flex;
  padding-left: ${pxToEm(25)};
  padding-right: ${pxToEm(25)};
  flex-direction: column;
`;

const Total = styled.div`
  display: flex;
  margin-left: ${pxToEm(15)};
  margin-right: ${pxToEm(15)};
  padding-left: ${pxToEm(10)};
  padding-right: ${pxToEm(10)};
  flex-direction: column;
  background: #f0f0f5;
`;

const LineItem = styled.div`
  padding-left: ${pxToEm(6)};
  padding-right: ${pxToEm(6)};
  display: flex;
  justify-content: space-between;
  font-size: ${pxToEm(16)};
  line-height: 1.5;
  padding-bottom: ${pxToEm(16)};
`;

const FeeName = styled.div``;

const FeeValue = styled.div``;

const TotalLineItem = styled(LineItem)`
  padding-top: ${pxToEm(16)};
  font-family: Apercu-Bold-Pro;
`;

const BillingSummary: React.FC<BillingSummaryProps> = (props) => {
  const { deductible, shipping, tax, userPaid } = props.fees || {};
  return (
    <>
      <SectionHeader>Billing Summary</SectionHeader>
      <Details>
        <LineItem>
          <FeeName>Insurance deductible</FeeName>
          <FeeValue>${deductible}</FeeValue>
        </LineItem>
        <LineItem>
          <FeeName>Shipping fee</FeeName>
          <FeeValue>{shipping ? `$${shipping}` : 'FREE'}</FeeValue>
        </LineItem>
        <LineItem>
          <FeeName>Taxes</FeeName>
          <FeeValue>${tax}</FeeValue>
        </LineItem>
      </Details>
      <Total>
        <TotalLineItem>
          <FeeName>Total</FeeName>
          <FeeValue>${userPaid}</FeeValue>
        </TotalLineItem>
      </Total>
    </>
  );
};

export default BillingSummary;
