import { clamp } from '@asurion-hub-web/utils';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import Breakpoints, {
  BREAK_POINTS_DIMENSIONS_VALUES_IN_PX,
} from '../../Breakpoints';
import { useWindowSize } from '../../hooks';
import { pxToEm } from '../../utils';
import DynamicLink from '../DynamicLink';
import { Fade } from '../transitions';

type PopoverPanelProps = {
  /**
   * Used for analytics. The dispatched click event on close will
   * be "[popoverId]Close".
   */
  popoverId: string;
  open?: boolean;
  onClose?: () => void;
  className?: string;
  targetRef?: React.RefObject<HTMLElement>;
};

const desktopWidthPx = 400;
const marginPx = 16;
const transitionDuration = 200;
const breakpointKey: keyof Breakpoints = 'mobileBig';
const breakpointPx = BREAK_POINTS_DIMENSIONS_VALUES_IN_PX[breakpointKey];
const breakpoint = Breakpoints[breakpointKey];

const PopoverPanelPortal = styled.div`
  width: 0;
  height: 0;
`;

const PopoverOverlay = styled(DynamicLink)<{ open?: boolean }>`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: 9;
`;

const PopoverPanelContainer = styled.span`
  font-size: ${pxToEm(14)};
  line-height: 1.5;
  display: block;
  background: #fff;
  box-sizing: border-box;
  padding: ${pxToEm(16)};
  border: 1px solid #60676c;
  margin-top: ${pxToEm(16)};
  margin-left: ${pxToEm(marginPx)};
  margin-right: ${pxToEm(marginPx)};
  left: 0;
  right: 0;
  position: absolute;
  box-shadow: 3px 3px 0px 0px #c4c4c4;
  z-index: 10;
  @media ${breakpoint} {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const PopoverPanel: React.FC<PopoverPanelProps> = (props) => {
  const [{ x, y }, setPos] = React.useState({ x: 0, y: 0 });
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width >= breakpointPx;
  const xClamp = clamp(marginPx, windowSize.width - desktopWidthPx - marginPx);

  const getPos = () => {
    if (props.targetRef && props.targetRef.current) {
      const targetRect = props.targetRef.current.getBoundingClientRect();
      return {
        x: targetRect.left + window.pageXOffset + targetRect.width / 2,
        y: targetRect.top + window.pageYOffset + targetRect.height,
      };
    }
    return { x: 0, y: 0 };
  };

  const update = () => setPos(getPos());

  React.useEffect(() => {
    if (props.open) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.targetRef, windowSize]);

  return ReactDOM.createPortal(
    <PopoverPanelPortal>
      <PopoverOverlay
        open={props.open}
        onClick={props.onClose}
        actionId={`${props.popoverId}Close`}
        ariaLabel="close more info"
      />
      <Fade element="span" timeout={transitionDuration} in={props.open}>
        {(state) => (
          <PopoverPanelContainer
            style={{
              transition: `transform ${transitionDuration}ms`,
              transform: `translateY(${state === 'entered' ? 0 : '5px'})`,
              top: `${y}px`,
              ...(isDesktop
                ? {
                    left: `${xClamp(x - desktopWidthPx / 2)}px`,
                    right: `${xClamp(
                      windowSize.width - desktopWidthPx / 2 - x
                    )}px`,
                  }
                : {}),
            }}
          >
            {props.children}
          </PopoverPanelContainer>
        )}
      </Fade>
    </PopoverPanelPortal>,
    document.body
  );
};

export default PopoverPanel;
