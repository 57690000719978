import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { SHORT_DATE_FORMAT } from '@asurion-hub-web/config';
import { Address } from '@soluto-private/account-api-client';
import { trigger } from '@asurion-hub-web/anywhere-expert-sdk';
import {
  pxToEm,
  Breakpoints,
  SectionHeader,
  Timeline,
} from '@asurion-hub-web/ui';
import WhatsNextCta from './WhatsNextCta';
import questionBubbleIcon from '@asurion-hub-web/assets/images/icons/question_bubble_icon.svg';

const breakpoint = Breakpoints.tablet;

const Bold = styled.span`
  font-family: Apercu-Bold-Pro;
`;

const Button = styled.button`
  font-family: Apercu-Regular-Pro;
  display: inline-block;
  line-height: 1.5;
  font-size: ${pxToEm(16)};
  border: 1px solid #000;
  border-radius: 9999px;
  padding: ${pxToEm(3)} ${pxToEm(24)};
  color: #000;
  text-decoration: none;
  background: transparent;
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoint} {
    flex-direction: row;
  }
`;

const CtaBody = styled.div``;

const CtaButtonContainer = styled.div`
  flex-shrink: 0;
  margin-top: ${pxToEm(10)};
  @media ${breakpoint} {
    margin-top: 0;
    margin-left: ${pxToEm(50)};
  }
`;

type WhatsNextRepairProps = {
  stepIndex: number;
  confirmationEmail?: string;
  deviceName?: string; // e.g. "iPhone XS Max 256GB"
  claimSubmittedTime: string; // e.g. "12/13/2020 at 4:45pm"
  repairLocation?: Address; // e.g. "1234 Main Street"
  repairDate: string; // e.g. "Tuesday, Novemeber 17"
};

const WhatsNextRepair: React.FC<WhatsNextRepairProps> = (props) => {
  const {
    // stepIndex,
    confirmationEmail,
    deviceName,
    claimSubmittedTime,
    repairLocation,
    repairDate,
  } = props;
  const stepIndex = 2;
  return (
    <>
      <SectionHeader>What&apos;s next?</SectionHeader>
      <Timeline
        currentEntryIndex={stepIndex}
        entries={[
          {
            heading: 'Claim submitted',
            content: (
              <>
                {claimSubmittedTime
                  ? moment(claimSubmittedTime).format(SHORT_DATE_FORMAT)
                  : ''}
                <br />
                We&apos;ll send a confirmation email to{' '}
                {confirmationEmail ? (
                  <Bold>{confirmationEmail}</Bold>
                ) : (
                  'your email address'
                )}
                .
              </>
            ),
          },
          {
            heading: 'You come to us!',
            content: (
              <>
                Bring your {deviceName || 'device'} to{' '}
                <Bold>{repairLocation?.address1 || 'us'}</Bold> on{' '}
                <Bold>
                  {repairDate.length > 1
                    ? moment(repairDate).format(SHORT_DATE_FORMAT)
                    : ''}
                </Bold>
                ! An Asurion certified technician will assist you with your
                repair.
              </>
            ),
          },
        ]}
      />
      {stepIndex > 1 && (
        <WhatsNextCta
          heading="Questions? Our team can help!"
          icon={<img src={questionBubbleIcon} alt="have questions" />}
        >
          <CtaContent>
            <CtaBody>
              Whether you need help setting up your new phone, securing all of
              your data or general helping if something goes wrong. We&apos;re
              ready for you!
            </CtaBody>
            <CtaButtonContainer>
              <Button onClick={trigger}>Start a chat</Button>
            </CtaButtonContainer>
          </CtaContent>
        </WhatsNextCta>
      )}
    </>
  );
};

export default WhatsNextRepair;
