import React from 'react';
import { Breakpoints, Toast, ToastProps, pxToEm } from '@asurion-hub-web/ui';
import styled from 'styled-components';
import { useNotifications } from './context';
import { Notification } from './Notification';
import { useAutoDismiss } from './useAutoDismiss';

const ToastNotificationsContainer = styled.div`
  z-index: 1200;
  position: fixed;
  left: ${pxToEm(15)};
  right: ${pxToEm(15)};
  bottom: 100px;

  @media ${Breakpoints.tabletBig} {
    padding: 0 1.5em 7em 0;
    bottom: 0;
    left: auto;
    right: 0;
    width: 400px;
  }
`;

const ToastContainer = styled.div`
  padding: 0;
  float: right;
  width: 100%;
  display: flex;

  :last-child {
    padding: 0;
  }

  @media ${Breakpoints.desktop} {
    padding: 0 0 0.75em 0;
    width: auto;
  }
`;

type AutoDismissableToastProps = ToastProps & Pick<Notification, 'autoDismiss'>;
const AutoDismissableToast: React.FC<AutoDismissableToastProps> = ({
  autoDismiss,
  onClose = () => undefined,
  ...rest
}) => {
  useAutoDismiss({ autoDismiss, onClose });
  return (
    <ToastContainer>
      <Toast onClose={onClose} {...rest} />
    </ToastContainer>
  );
};

export const ToastNotifications: React.FC = () => {
  const { toasts } = useNotifications();
  return (
    <ToastNotificationsContainer>
      {toasts.map((toast) => (
        <AutoDismissableToast
          key={`Toast-${toast.id}`}
          isOpen={true}
          status={toast.status}
          autoDismiss={toast.autoDismiss}
          onClose={toast.onClose}
        >
          {toast.message}
        </AutoDismissableToast>
      ))}
    </ToastNotificationsContainer>
  );
};
