import CloseButton from './CloseButton';
import * as React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { BREAK_POINTS_DIMENSIONS_VALUES_IN_PX } from '../Breakpoints';
import { useWindowSize } from '../hooks';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-contents: space-between;
  margin-bottom: 1em;
`;

const CloseButtonContainer = styled.div`
  font-size: xx-large;
  margin-left: auto;
  align-self: center;
`;

const HeadingContainer = styled.div`
  height: 3rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 1em;
`;

export type ModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  heading?: string;
  customStyle?: ReactModal.Styles;
};

const desktopStyle: ReactModal.Styles = {
  overlay: {
    background: 'rgba(0,0,0,0.8)',
  },
  content: {
    top: '10%',
    bottom: 'auto',
    left: '50%',
    padding: '32px',
    maxWidth: '535px',
    maxHeight: '600px',
    transform: 'translateX(-50%)',
  },
};
const mobileStyle: ReactModal.Styles = {
  content: {
    top: '0%',
    bottom: '0%',
    left: '0%',
    right: '0%',
  },
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  heading,
  customStyle,
  children,
}) => {
  const { width: windowWidth } = useWindowSize();
  ReactModal.setAppElement('body');
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={
        customStyle ||
        (windowWidth < BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.mobileBig
          ? mobileStyle
          : desktopStyle)
      }
      closeTimeoutMS={200}
    >
      <HeaderContainer>
        {heading && <HeadingContainer>{heading}</HeadingContainer>}
        <CloseButtonContainer>
          <CloseButton
            onClick={onRequestClose}
            actionId="ModalClose"
            ariaLabel="close modal"
          />
        </CloseButtonContainer>
      </HeaderContainer>
      {children}
    </ReactModal>
  );
};

export default Modal;
