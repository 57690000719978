import * as React from 'react';
import styled from 'styled-components';
import DynamicLink from '../DynamicLink';

type PopoverButtonProps = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  actionId: string;
};

const iconSize = 20;
const touchSize = 40;

const PopoverButtonWrapper = styled.span`
  width: ${iconSize}px;
  height: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;

const PopoverButtonStyled = styled(DynamicLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: ${touchSize}px;
  height: ${touchSize}px;
  flex-shrink: 0;
  outline: none;
  color: #a5aaaf;
  :focus {
    color: #000000;
  }
  -webkit-tap-highlight-color: transparent;

  svg {
    fill: currentColor;
    :active {
      color: #000000;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PopoverButtonIcon = React.forwardRef<any, React.SVGProps<SVGSVGElement>>(
  (_, ref) => (
    <svg
      ref={ref}
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 ${iconSize} ${iconSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10C2 5.59144 5.5907 2 10 2C14.4093 2 18 5.59144 18 10C18 14.4103 14.4096 18 10 18C5.59044 18 2 14.4103 2 10ZM10 0C4.486 0 0 4.487 0 10C0 15.515 4.486 20 10 20C15.514 20 20 15.515 20 10C20 4.487 15.514 0 10 0ZM10 4.75098C10.69 4.75098 11.25 5.31098 11.25 6.00098C11.25 6.69198 10.69 7.25098 10 7.25098C9.31 7.25098 8.75 6.69098 8.75 5.99998C8.75 5.30998 9.31 4.75098 10 4.75098ZM13 13V15H7V13H9V9.99998H8V7.99998H10C10.553 7.99998 11 8.44798 11 8.99998V13H13Z"
      />
    </svg>
  )
);

const PopoverButton = React.forwardRef<HTMLButtonElement, PopoverButtonProps>(
  (props, ref) => {
    return (
      <PopoverButtonWrapper>
        <PopoverButtonStyled
          onClick={props.onClick}
          className={props.className}
          actionId={props.actionId}
          ariaLabel="view more info"
        >
          <PopoverButtonIcon ref={ref} />
        </PopoverButtonStyled>
      </PopoverButtonWrapper>
    );
  }
);

export default PopoverButton;
