import {
  Claim as _Claim,
  Plan as _Plan,
  VerifyOtpResult,
  AssetAttribute,
  PlanStatus as _PlanStatus,
  OtpVerifiedResult as _OtpVerifiedResult,
} from '@soluto-private/account-api-client';

export * from './ServicePlan';
export * from './Claim';
export * from './StatusTag';

export type VerifyOtpStatus = VerifyOtpResult['otpVerifiedResult'];
export type MobilityCoverage = {
  productId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  enrollmentDate?: string;
  make?: string;
  model?: string;
  assetId?: string;
  assetCatalogId?: string;
  assetCatalogAttributes?: AssetAttribute[];
  friendlyName?: string;
  subscriptionNumber?: string;
};
export type Claim = _Claim;
export type Plan = _Plan;
export type PlanStatus = _PlanStatus;
export type OtpVerifiedResult = _OtpVerifiedResult;
