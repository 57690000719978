import { SHORT_DATE_FORMAT } from '@asurion-hub-web/config';
import {
  getReplacementFriendlyName,
  getReplacementImageDevice,
  getTrackingLink,
  isClaimComplete,
} from '@asurion-hub/enrollment';
import { Claim } from '@soluto-private/account-api-client';
import moment from 'moment';
import React from 'react';
import { useTweekValue } from 'react-tweek';
import { ClaimDetailsPageSharedProps } from '../types';
import { shouldShowDeviceSetupModule } from '../utils';
import { default as ClaimDetailsReplacePage } from './ClaimDetailsReplacePage';

const formatPrice = (price: number) => {
  return price >= 0 ? `$${price.toString()}` : '---';
};

const getAccordionProps = (claimDetails: Claim, fulfillmentDevice: string) => {
  const replacementDetails = {
    expectedDeliveryDate: moment(claimDetails.expectedDeliveryDate).format(
      SHORT_DATE_FORMAT
    ),
    trackingNumber: claimDetails.trackingNumber,
    trackingUrl: getTrackingLink(claimDetails),
    replacementDevice: fulfillmentDevice,
    accessories: claimDetails.accessories,
    filedDate: moment(claimDetails.claimFiledDate).format(SHORT_DATE_FORMAT),
  };
  const shippingDetails = {
    formattedShippingAddress: claimDetails.addressLines.join('\n'),
    claimContactPhoneNumber: claimDetails.subscriberContactPhone!,
    claimContactEmail: claimDetails.subscriberEmail!,
  };
  const paymentDetails = claimDetails.fees
    ? {
        insuranceDeductibleFormatted: formatPrice(
          claimDetails.fees?.deductible ? claimDetails.fees.deductible : 0
        ),
        shippingFeeFormatted: formatPrice(
          claimDetails.fees?.shipping ? claimDetails.fees.shipping : 0
        ),
        taxesFormatted: formatPrice(
          claimDetails.fees?.tax ? claimDetails.fees.tax : 0
        ),
        totalFormatted: formatPrice(
          claimDetails.fees?.userPaid ? claimDetails.fees.userPaid : 0
        ),
        cardType: 'tbd',
        cardEndingIn: '****',
      }
    : undefined;
  return { replacementDetails, shippingDetails, paymentDetails };
};

export const ClaimDetailsReplaceContainer: React.FC<ClaimDetailsPageSharedProps> = (
  props
) => {
  const shouldShowDeviceSetupStepFlag = useTweekValue<boolean>(
    'asurion_hub/claims/device_setup_step_enabled',
    false
  );

  const showDeviceSetupModule: boolean = shouldShowDeviceSetupModule(
    props.claim,
    shouldShowDeviceSetupStepFlag
  );

  const deviceSetupLink = useTweekValue<string>(
    'asurion_hub/claims/device_setup_guide_link',
    ''
  );

  const fulfillmentDeviceName = getReplacementFriendlyName(props.claim);

  return (
    <ClaimDetailsReplacePage
      {...props}
      claim={props.claim}
      isLoading={props.isLoading}
      isClaimComplete={isClaimComplete(props.claim)}
      planIncludesSupport={props.planIncludesSupport}
      showSurveyCta={props.showSurveyCta}
      surveyCTA={props.surveyCTA}
      billingSummary={{ fees: props.claim.fees }}
      notification={props.notification}
      setNotification={props.setNotification}
      fulfillmentDevice={fulfillmentDeviceName}
      replacementDeviceImageUrl={getReplacementImageDevice(
        props.currentPlan,
        props.claim
      )}
      shippingInfo={{
        name: props.claim.shipToName,
        addressLines: props.claim.addressLines,
        email: props.claim.subscriberEmail,
        phoneNumber: props.claim.subscriberContactPhone,
      }}
      cta={props.cta}
      accordionProps={getAccordionProps(props.claim, fulfillmentDeviceName)}
      showDeviceSetupModule={showDeviceSetupModule}
      deviceSetupLink={deviceSetupLink}
    />
  );
};
