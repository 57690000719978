import { useDebounce } from 'react-use';
import { selector } from 'recoil';
import {
  selectedFilters,
  useSelectedFiltersRemoveAndAddByType,
} from './selectedFilters';
import { Filter } from './filter';
import { useState } from 'react';

export const textFilters = selector<Filter[]>({
  key: 'textFilters',
  get: ({ get }) => get(selectedFilters).filter((f) => f.type === 'text'),
});

export const useTextFilters = () => {
  const state = useState('');
  const [ searchString ] = state;
  const removeAndAddFiltersByType = useSelectedFiltersRemoveAndAddByType();
  useDebounce(
    () => {
      const sanitized = searchString.trim().toLowerCase();
      if (sanitized !== '') {
        removeAndAddFiltersByType(
          [{ type: 'text' }],
          [...new Set(sanitized.split(' '))].map((word) => ({
            type: 'text',
            value: word,
          }))
        );
      } else {
        removeAndAddFiltersByType([{ type: 'text' }], []);
      }
    },
    500,
    [searchString]
  );

  return state;
};
