import React from 'react';
import { useTweekValue } from 'react-tweek';
import {
  FulfillmentOption,
  FulfillmentInfoMethod,
} from '@soluto-private/account-api-client';
import { ClaimDetailsPageSharedProps as PageProps } from '../types';
import { ClaimDetailsRepairContainer } from './ClaimDetailsRepairContainer';
import { ClaimDetailsReplaceContainer } from './ClaimDetailsReplaceContainer';
import { ClaimDetailsSameDayContainer } from './ClaimDetailsSameDayContainer';

export const ClaimDetailTypeSelectionContainer: React.FC<PageProps> = (
  props: PageProps
) => {
  const shouldSupportMultipleClaimTypes: boolean = useTweekValue(
    'asurion_hub/claims/support_multiple_claim_types',
    false
  );

  const shouldShowSameDayDeliveryClaim: boolean = useTweekValue(
    'asurion_hub/claims/same_day_enabled',
    false
  );

  if (shouldSupportMultipleClaimTypes) {
    if (
      shouldShowSameDayDeliveryClaim &&
      props.claim.claimType === FulfillmentOption.AdvancedExchange &&
      props.claim.fulfillmentInfo?.method ===
        // eslint-disable-next-line
        FulfillmentInfoMethod.SameDay
    ) {
      return <ClaimDetailsSameDayContainer {...props} />;
    }
    switch (props.claim.claimType) {
      case FulfillmentOption.Repair:
        return <ClaimDetailsRepairContainer {...props} />;
      case FulfillmentOption.AdvancedExchange:
        // Change this back once you are done with testing
        return <ClaimDetailsReplaceContainer {...props} />;
      default:
        // defaulting to the replace page in the case of no claimType
        return <ClaimDetailsReplaceContainer {...props} />;
    }
  }

  // defaulting to the replace page in the case of no multi-type support
  return <ClaimDetailsReplaceContainer {...props} />;
};
