import {
  DynamicLinkProps,
  pxToEm,
  Button,
  Breakpoints,
} from '@asurion-hub-web/ui';
import * as React from 'react';
import styled from 'styled-components';
import paperAndPen from '@asurion-hub-web/assets/images/icons/paper-and-pen.svg';
import userChat from '@asurion-hub-web/assets/images/icons/user-chat.svg';
// eslint-disable-next-line import/no-cycle
import { PageNotification } from '@asurion-hub-web/notifications';
import { useAsyncFn } from 'react-use';
import { PlanTypeDependant } from '../../../types';

const Container = styled.div`
  display: flex;
  text-align: center;
  margin: ${pxToEm(45)} ${pxToEm(10)} ${pxToEm(15)};
  flex-wrap: wrap;
`;

const buttonContainerMarginPx = 5;
const buttonContainerMargin = pxToEm(buttonContainerMarginPx);

const ButtonContainer = styled.div`
  flex: 1;
  flex-basis: 100%;
  margin-top: ${buttonContainerMargin};
  margin-bottom: ${buttonContainerMargin};
  margin-left: 0;
  margin-right: 0;
  @media ${Breakpoints.mobileBig} {
    margin-left: ${buttonContainerMargin};
    margin-right: ${buttonContainerMargin};
    flex-basis: auto;
    width: calc(50% - ${buttonContainerMarginPx * 2}px);
  }
`;

const ButtonIcon = styled.img`
  margin-left: ${pxToEm(15)};
`;

type ButtonConfig = DynamicLinkProps &
  PlanTypeDependant & {
    imageSrc: string;
    buttonText: string;
    imageAltText: string;
    actionId: string;
  };

interface IMoreInfoButtonsProps {
  claimsUrl?: string;
  setNotification: (notification?: PageNotification) => void;
  openChat: () => Promise<void>;
  setIsLoading: (flag: boolean) => void;
  generalErrorText: string;
  planIncludesSupport: boolean;
}

const MoreInfoButtons: React.FunctionComponent<IMoreInfoButtonsProps> = ({
  claimsUrl,
  setNotification,
  openChat,
  setIsLoading,
  generalErrorText,
  planIncludesSupport,
}) => {
  const [state, fetch] = useAsyncFn(openChat);

  React.useEffect(() => {
    if (state.loading) {
      setNotification(undefined);
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    if (state.error) {
      window.scrollTo(0, 0);
      setNotification({
        status: 'warn',
        message: generalErrorText,
        reason: state.error.name,
      });
    }
  }, [
    state.loading,
    state.error,
    setNotification,
    setIsLoading,
    generalErrorText,
  ]);

  const moreInfoButtons: Array<ButtonConfig> = [
    ...(claimsUrl
      ? [
          {
            imageSrc: paperAndPen,
            imageAltText: 'Notepad and pen',
            buttonText: 'File a claim',
            actionId: 'FileClaim',
            onClick: () => {
              window.location.href = claimsUrl;
            },
          },
        ]
      : []),
    ...[
      {
        imageSrc: userChat,
        imageAltText: 'Person with chat bubble',
        buttonText: 'Get help now',
        onClick: fetch,
        actionId: 'TriggerMessaging',
        onlyForPlansWithSupport: true,
      },
    ],
  ];

  return (
    <>
      <Container>
        {moreInfoButtons
          .filter(
            (infoButton) =>
              !infoButton.onlyForPlansWithSupport || planIncludesSupport
          )
          .map((buttonConfig, index) => {
            return (
              <ButtonContainer key={index}>
                <Button
                  class="full-width primary"
                  tabIndex={0}
                  {...buttonConfig}
                >
                  {buttonConfig.buttonText}
                  <ButtonIcon
                    src={buttonConfig.imageSrc}
                    alt={buttonConfig.imageAltText}
                  />
                </Button>
              </ButtonContainer>
            );
          })}
      </Container>
    </>
  );
};

export default MoreInfoButtons;
