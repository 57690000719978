/* eslint-disable */
import { initTweekRepo } from '@asurion-hub/tweek';
import { logger, session } from '@soluto-asurion/one-service-core';
import { MemoryStore, TweekRepository } from 'tweek-local-cache';
import { partnerConfig$ } from '@asurion-hub-web/partner-config-react';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserBrowser } from '@soluto-asurion/one-service-core/dist/session';

const defaultKeys = require('../tweek-defaults.json');

declare global {
  interface Window {
    tweekRepo: TweekRepository;
  }
}

const buildContext = (userBrowser: UserBrowser, partner: string) => ({
  user_browser: {
    id: userBrowser.UserBrowserId,
    IsTest: process.env.NODE_ENV === 'development',
    BrowserType: userBrowser.BrowserType,
    BrowserVersion: userBrowser.BrowserVersion,
    FormFactor: userBrowser.FormFactor,
    OsType: userBrowser.Os,
    NodeEnv: process.env.NODE_ENV,
    partner,
    IsE2E:
      userBrowser.sessionQueryParameters &&
      userBrowser.sessionQueryParameters.get('IsE2E') === 'true',
  },
});
export default function initTweek() {
  const context = buildContext(
    session.UserBrowser.get(),
    partnerConfig$.value.name
  );
  const repo = initTweekRepo({
    context,
    store: new MemoryStore(defaultKeys),
    onError: (err: Error) => logger.error('Failed to fetch tweek keys', err),
  });
  window.tweekRepo = repo;

  partnerConfig$.pipe(distinctUntilChanged()).subscribe((newConfig) => {
    repo.updateContext(() =>
      buildContext(session.UserBrowser.get(), newConfig.name)
    );
  });
}
