import { AuthenticatedUser } from '@asurion-hub/auth';
import { session } from '@soluto-asurion/one-service-core';
import { Claim, Address } from '@soluto-private/account-api-client';
import { PaymentSectionProps } from '../components/MultipleClaimTypeComponents/RepairAccordion';

export const formatAddress = (address: Address | undefined) => {
  const returnAddress: Array<string> = [];
  if (address) {
    if (address.address1) {
      returnAddress.push(address.address1);
    }
    if (address.address2) {
      returnAddress.push(address.address2);
    }
    if (address.address3) {
      returnAddress.push(address.address3);
    }
    let cityStateZip = '';
    if (address.city) {
      cityStateZip += address.city;
    }
    if (address.stateProvinceCode) {
      cityStateZip += (address.city ? ', ' : '') + address.stateProvinceCode;
    }
    if (address.postalCode) {
      cityStateZip += (cityStateZip.length > 0 ? ' ' : '') + address.postalCode;
    }
    if (cityStateZip.length > 0) {
      returnAddress.push(cityStateZip);
    }
    if (address.countryCode) {
      returnAddress.push(address.countryCode);
    }
  }
  return returnAddress;
};

export const shouldShowDeviceSetupModule = (
  claim: Claim,
  shouldShowDeviceSetupStepFlag: boolean
) => {
  const originalMake = claim.originalDevice?.make;
  const replacementMake = claim.fulfillmentInfo?.replacementDevice?.make;
  return !!(
    shouldShowDeviceSetupStepFlag &&
    originalMake &&
    replacementMake &&
    originalMake === replacementMake
  );
};

export const constuctFees = (claimDetails: Claim): PaymentSectionProps => ({
  insuranceDeductibleFormatted: claimDetails.fees?.deductible
    ? claimDetails.fees?.deductible
    : 0,
  total: claimDetails.fees?.userPaid ? claimDetails.fees?.userPaid : 0,
  taxesFormatted: claimDetails.fees?.tax ? claimDetails.fees?.tax : 0,
});

export const createDeviceSetupButtonReference = (
  link: string,
  oldDeviceMakeModel: string,
  newDeviceMakeModel: string
) => {
  const asurionId = AuthenticatedUser.get()?.asurionId;
  const userBrowserId = session.UserBrowser.get().UserBrowserId;

  return link
    .replaceAll('{{oldDeviceMakeModel}}', oldDeviceMakeModel)
    .replaceAll('{{newDeviceMakeModel}}', newDeviceMakeModel)
    .replaceAll('{{asurionId}}', asurionId!)
    .replaceAll('{{userBrowserId}}', userBrowserId);
};

export const getDeviceMakeModelString = (
  deviceMake?: string | null,
  deviceModel?: string | null
) => (deviceMake && deviceModel ? `${deviceMake} ${deviceModel}` : '');
