import {
  ButtonOnlyCta,
  IconCta,
  IllustrationCta,
  TextOnlyCta,
} from '../templates';

// If a CTA uses a custom component, it should be provided here
export const ctaTypeVariants = [
  {
    identifier: 'button-only-cta',
    Component: ButtonOnlyCta,
  },
  {
    identifier: 'icon-cta',
    Component: IconCta,
  },
  {
    identifier: 'illustration-cta',
    Component: IllustrationCta,
  },
  {
    identifier: 'text-only-cta',
    Component: TextOnlyCta,
  },
];
