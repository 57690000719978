import * as React from 'react';
import PopoverButton from './PopoverButton';
import PopoverPanel from './PopoverPanel';

type PopoverProps = {
  /**
   * Used for analytics. The dispatched click events will be "[popoverId]Open"
   * and "[popoverId]Close".
   */
  popoverId: string;
};

const Popover: React.FC<PopoverProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef(null);
  const actionId = open ? `${props.popoverId}Close` : `${props.popoverId}Open`;
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  return (
    <>
      <PopoverButton ref={buttonRef} onClick={onOpen} actionId={actionId} />
      <PopoverPanel
        popoverId={props.popoverId}
        targetRef={buttonRef}
        open={open}
        onClose={onClose}
      >
        {props.children}
      </PopoverPanel>
    </>
  );
};

export default Popover;
