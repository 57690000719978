import { noop } from '@asurion-hub-web/utils';
import { Paragraph } from '@npmsoluto/atomic-ui-library-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Breakpoints, {
  BREAK_POINTS_DIMENSIONS_VALUES_IN_PX,
} from '../Breakpoints';
import { useWindowSize } from '../hooks';
import { pxToEm } from '../utils';
import Button, { ButtonProps } from './ButtonWithAnalytics';
import BasePage from './Page';

export const HORIZONTAL_BREAKPOINT_PX =
  BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.mobileBig;
export const HORIZONTAL_BREAKPOINT_MEDIA_QUERY = `(min-width: ${HORIZONTAL_BREAKPOINT_PX}px)`;

const colGutter = pxToEm(16);
export const cols = (x: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) =>
  `${(x * 100) / 12}%`;

export const Page = styled(BasePage)<{
  pageName: string;
  as?: string;
  contained?: boolean;
}>`
  margin: auto;
  display: flex;
  line-height: 1.2;
  flex-flow: column nowrap;
  box-sizing: border-box;
  min-height: 100%;
  height: fit-content;
  width: 100%;
  @media ${HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    flex-wrap: wrap;
    justify-content: center;
  },
`;

export const Heading: React.FC<React.HTMLProps<HTMLHeadingElement>> = (
  props
) => {
  const { children, className, ...restProps } = props;
  return (
    <div
      className={className}
      css={`
        margin-bottom: 0;
        --paragraph-line-height: 1.2;
        --paragraph-font-size: 1.5em;
        @media ${Breakpoints.mobileSmall} {
          margin-bottom: ${pxToEm(16)};
          --paragraph-font-size: 2em;
        }
        @media ${HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
          margin-bottom: 0;
        }
      `}
    >
      <Paragraph {...restProps}>{children}</Paragraph>
    </div>
  );
};

export const ContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  padding-left: ${pxToEm(15)};
  padding-right: ${pxToEm(15)};
  @media ${HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    padding: 0;
    max-width: none;
    width: ${cols(4)};
    margin: 0;
    margin-right: ${cols(2)};
  }
`;

export const ImageContainer: React.FC = (props) => {
  const { children, ...propsWithoutChildren } = props;

  const imageContainerStyle = `
      position: relative;
      display: flex;
      flex-grow: 1;
      min-height: ${pxToEm(200)};
      width: 100%;
      margin: auto;
      @media ${HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
        order: -1;
        flex-basis: 100%;
        width: ${cols(4)};
        margin-left: ${cols(2)};
        margin-right: ${colGutter};
        max-width: none;
      }
    `;

  const imageContainerInnerStyle = `
      box-sizing: border-box;
      padding: ${pxToEm(20)} 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    `;

  return (
    <div css={imageContainerStyle} {...propsWithoutChildren}>
      <div css={imageContainerInnerStyle}>{children}</div>
    </div>
  );
};

export const ActionsContainer: React.FC = (props) => {
  const { children, ...propsWithoutChildren } = props;
  const actionsContainerStyle = `
      box-sizing: border-box;
      border-top: ${pxToEm(1)} solid #e6e6eb;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: ${pxToEm(15)};
      width: 100%;
      @media ${HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
        width: ${cols(4)};
        margin-top: 40px;
        margin-right: ${cols(2)};
        border: none;
        padding: 0;
        align-items: flex-start;
      }
    `;

  return (
    <div {...propsWithoutChildren} css={actionsContainerStyle}>
      <div
        {...propsWithoutChildren}
        css={`
          width: 100%;
          @media ${HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
            max-width: none;
          }
        `}
      >
        {children}
      </div>
    </div>
  );
};

export const ActionButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    ButtonProps & {
      disabled?: boolean;
      to?: string;
      primary?: boolean;
    }
> = (props) => {
  const { className, primary, to, disabled, onClick, ...restProps } = props;
  const { width: windowWidth } = useWindowSize();
  const buttonClasses = [
    windowWidth < HORIZONTAL_BREAKPOINT_PX && 'full-width',
    primary && 'primary',
    disabled && 'disabled',
  ].filter(Boolean);

  const renderedButton = (
    <Button
      {...restProps}
      onClick={disabled ? noop : onClick}
      class={buttonClasses.join(' ')}
    />
  );

  return (
    <div
      className={className}
      css={`
        width: 100%;
        @media ${HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
          width: auto;
        }
      `}
    >
      {to ? (
        <Link style={{ textDecoration: 'none' }} to={to}>
          {renderedButton}
        </Link>
      ) : (
        renderedButton
      )}
    </div>
  );
};
