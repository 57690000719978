import React from 'react';
import styled from 'styled-components';
import {
  getResponsiveImageUrlFromAssetAttributes,
  MobilityCoverage,
} from '@asurion-hub/enrollment';
import { pxToEm, formatPhone, DynamicLink } from '@asurion-hub-web/ui';
import { default as MobileDeviceImage } from './MobileDeviceImage';
import { DeviceDetails } from '../types';
import rightArrowIcon from '@asurion-hub-web/assets/images/icons/right-arrow.svg';

const RightArrowIcon = styled.div`
  background-image: url(${rightArrowIcon});
  width: 20px;
  height: 10px;
  margin: 2px 0 0 12px;
`;

const Device = styled.div`
  padding: ${pxToEm(24)};
`;

interface ButtonProps {
  onClick: () => void;
  ariaLabel: string;
  actionId: string;
}

const SeeInfoButton = styled(DynamicLink)<ButtonProps>`
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  justify-content: center;
  color: black;
  width: ${pxToEm(140)};
  height: ${pxToEm(50)};
  border-radius: ${pxToEm(40)};
  z-index: 1;
  align-items: center;
  margin-right: ${pxToEm(8)};
  margin-top: ${pxToEm(16)};
`;

const DeviceContainer = styled.div`
  margin-left: 1.25em;
  margin-right: 1.25em;
`;

const DeviceDisplayModuleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const DeviceInfo = styled.div`
  margin: auto 0;
  padding-left: ${pxToEm(24)};
`;

const DeviceInfoRow = styled.div`
  justify-content: center;
  margin-bottom: 8px;
`;

const DeviceFriendlyName = styled.div`
  font-size: ${pxToEm(36)};
  margin-bottom: 16px;
`;

const DeviceImageContainer = styled(MobileDeviceImage)`
  width: 100%;
  max-width: 140px;
`;

export const LegacyDeviceDisplayModule: React.FC<{
  deviceDetails: DeviceDetails;
  mobilityCoverage: MobilityCoverage;
  onInfoClick: () => void;
}> = (props) => {
  return (
    <>
      <DeviceContainer>
        <DeviceDisplayModuleContainer>
          <Device>
            {props.mobilityCoverage && (
              <DeviceImageContainer
                model={props.mobilityCoverage.model}
                responsiveImageUrl={getResponsiveImageUrlFromAssetAttributes(
                  props.mobilityCoverage.assetCatalogAttributes
                )}
              />
            )}
          </Device>
          <DeviceInfo>
            {props.deviceDetails.model && (
              <DeviceInfoRow>
                <DeviceFriendlyName data-testid="device-friendly-name">
                  {props.mobilityCoverage.friendlyName ??
                    props.deviceDetails.model}
                </DeviceFriendlyName>
              </DeviceInfoRow>
            )}
            {props.deviceDetails.phone && (
              <DeviceInfoRow>
                <b>{formatPhone(props.deviceDetails.phone)}</b>
              </DeviceInfoRow>
            )}
            <SeeInfoButton
              onClick={() => props.onInfoClick()}
              actionId="SeeDeviceDetails"
              ariaLabel="See more details about this device."
            >
              See details
              <RightArrowIcon />
            </SeeInfoButton>
          </DeviceInfo>
        </DeviceDisplayModuleContainer>
      </DeviceContainer>
    </>
  );
};
