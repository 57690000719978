import { supportedPartners, Partner } from '@asurion-hub/partner-config';
import attLogo from '@asurion-hub-web/assets/images/logos/att.svg';
import cricketLogo from '@asurion-hub-web/assets/images/logos/cricket.svg';
import sprintLogo from '@asurion-hub-web/assets/images/logos/sprint.svg';
import verizonLogo from '@asurion-hub-web/assets/images/logos/verizon.svg';

type BasePartner = {
  id: string;
  name: string;
}

type UnsupportedPartner = BasePartner & {
  url: string;
}

type PrimaryPartner = BasePartner & {
  logoUrl: string;
}

type UnsupportedPrimaryPartner = UnsupportedPartner & PrimaryPartner;

type SupportedPrimaryPartner = PrimaryPartner;

type SecondaryPartner = UnsupportedPartner;

export type SelectablePartner = UnsupportedPrimaryPartner | SupportedPrimaryPartner | SecondaryPartner;

const supportedPrimaryPartners: Array<SupportedPrimaryPartner> = [
  {
    id: Partner.Verizon,
    name: supportedPartners.verizon.name,
    logoUrl: verizonLogo,
  },
  {
    id: Partner.Cricket,
    name: supportedPartners.cricket.name,
    logoUrl: cricketLogo,
  },
];

const unsupportedPrimaryPartners: Array<UnsupportedPrimaryPartner> = [
  {
    id: 'att',
    name: 'AT&T',
    url: 'https://protectadvantage.att.com/',
    logoUrl: attLogo,
  },
  {
    id: 'sprint',
    name: 'Sprint',
    url: 'https://www.asurion.com/sprint/sprint-complete/',
    logoUrl: sprintLogo,
  },
];

export const primaryPartners: Array<SupportedPrimaryPartner | PrimaryPartner> = [
  ...supportedPrimaryPartners,
  ...unsupportedPrimaryPartners,
];

export const secondaryPartners: Array<SecondaryPartner> = [
  {
    id: 'attPrepaid',
    name: 'AT&T Prepaid',
    url: 'https://prepaidprotection.att.com/',
  },
  {
    id: 'bellMobility',
    name: 'Bell Mobility',
    url: 'https://www.phoneclaim.com/bell-mobility/',
  },
  {
    id: 'samsungCare',
    name: 'Samsung Care+/Samsung Premium Care',
    url: 'https://www.asurion.com/samsung/',
  },
  {
    id: 'simpleMobile',
    name: 'Simple Mobile',
    url: 'https://www.phoneclaim.com/mobileprotect/',
  },
  {
    id: 'straightTalk',
    name: 'Straight Talk',
    url: 'https://www.phoneclaim.com/mobileprotect/',
  },
  {
    id: 'tmobile',
    name: 'T-Mobile',
    url: 'https://www.phoneclaim.com/t-mobile/?icid=in-pcchome-tmo',
  },
  {
    id: 'telus',
    name: 'telus',
    url: 'https://www.phoneclaim.com/telus/',
  },
  {
    id: 'tracfone',
    name: 'Tracfone',
    url: 'https://www.phoneclaim.com/mobileprotect/',
  },
  {
    id: 'usCellular',
    name: 'U.S. Cellular',
    url: 'https://www.phoneclaim.com/uscellular/',
  },
  {
    id: 'virgin',
    name: 'Virgin Mobile',
    url: 'https://www.phoneclaim.com/virgin/',
  },
];
