import React from 'react';
import { useAuthState } from '@asurion-hub/auth';
import { LoadingPage } from '@asurion-hub-web/ui';
import { Redirect } from 'react-router-dom';
import { ROOT_ROUTE } from '@asurion-hub-web/config';

export const withAuthState = (
  AuthenticatedComp: React.ElementType,
  NotAuthenticatedComp: React.ElementType = () => <Redirect to={ROOT_ROUTE} />
) => (props: unknown) => {
  const authState = useAuthState();
  if (authState === 'inProgress') {
    return <LoadingPage />;
  } else if (authState === 'authenticated') {
    return <AuthenticatedComp {...props} />;
  } else {
    return <NotAuthenticatedComp {...props} />;
  }
};
