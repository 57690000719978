import { AuthenticatedUser } from './AuthenticatedUser';

export const getAccessToken = (): string => {
  const accessToken = AuthenticatedUser.accessToken;
  if (!accessToken) {
    throw new Error('user not authenticated');
  }

  return accessToken;
};
