import { getAccessToken } from '@asurion-hub/auth';
import { logger } from '@asurion-hub/logging';
import { getTweekRepository } from '@asurion-hub/tweek';
import { AccountProfile, UpdateAccountProfile } from './types';

type AccountProfileApiError = {
  error: string;
};

async function handleAccountProfileResponse<T>(
  response: Response,
  mutate?: (data: T) => Promise<T>
): Promise<T> {
  if (response.ok) {
    const data = (await response.json()) as T;
    return mutate ? await mutate(data) : data;
  } else {
    let error = await response.text();
    try {
      const json = JSON.parse(error) as AccountProfileApiError;
      error = json.error || error;
    } catch {}
    throw new Error(
      `Received error status '${response.status}' from Account Profile API: ${error}`
    );
  }
}

function getApiUrl() {
  return getTweekRepository().getValue<string>(
    'asurion_hub/asurion_profile/profile_api_url'
  );
}

export class AccountProfileClient {
  constructor(public preloadAvatar?: boolean) {}

  public async getProfile(): Promise<AccountProfile> {
    const response = await fetch(`${await getApiUrl()}/me`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    });

    return await handleAccountProfileResponse(
      response,
      this.inlineAvatar.bind(this)
    );
  }

  public async updateProfile(
    updateData: UpdateAccountProfile
  ): Promise<AccountProfile> {
    const response = await fetch(`${await getApiUrl()}/me`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    });

    return await handleAccountProfileResponse(
      response,
      this.inlineAvatar.bind(this)
    );
  }

  public async getProfileAvatarUploadUrl(contentType: string): Promise<string> {
    const response = await fetch(
      `${await getApiUrl()}/me/avatar-upload-url?contentType=${encodeURIComponent(
        contentType
      )}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${getAccessToken()}` },
      }
    );

    return (await handleAccountProfileResponse<{ url: string }>(response)).url;
  }

  public async uploadAvatar(url: string, imageFile: Blob): Promise<void> {
    const response = await fetch(`${url}`, {
      method: 'PUT',
      body: imageFile,
    });

    if (!response.ok) {
      const responseMessage = await response.text();
      throw new Error(
        `Received error status '${response.status}' from Account Profile API: ${responseMessage}`
      );
    }
  }

  public async getProfileAvatar(imageUrl: string) {
    try {
      const response = await fetch(imageUrl, {
        method: 'GET',
        headers: { 'X-Auth-Token': getAccessToken() },
      });
      if (!response.ok) {
        logger.warn('failed uploading avatar', {
          status: response.status,
          message: await response.text(),
        });
        return undefined;
      }
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (err) {
      logger.warn('failed uploading avatar', err);
      return undefined;
    }
  }

  public async deleteIdentity(identityId: string) {
    await fetch(
      `${await getApiUrl()}/me/identities/${encodeURIComponent(identityId)}`,
      {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${getAccessToken()}` },
      }
    );
  }

  private async inlineAvatar(profile: AccountProfile): Promise<AccountProfile> {
    if (this.preloadAvatar && profile.data.avatar) {
      profile.data.avatar = ((await this.getProfileAvatar(
        profile.data.avatar
      )) as unknown) as string;
    }

    return profile;
  }
}
