import { FulfillmentOption } from '@soluto-private/account-api-client';

export const getIntroMessage = (
  claimType: FulfillmentOption | undefined | string,
  isClaimComplete: boolean,
  replacementFriendlyName?: string,
  customMessage?: string
) => {
  if (claimType && customMessage) {
    return customMessage;
  }

  switch (claimType) {
    case FulfillmentOption.Repair:
      return isClaimComplete
        ? 'Your claim is complete!'
        : 'We’re ready to repair your phone!';

    case FulfillmentOption.AdvancedExchange:
      return isClaimComplete
        ? `Your ${replacementFriendlyName || 'replacement device'} has shipped!`
        : `Your replacement ${
            replacementFriendlyName || 'device'
          } can’t wait to meet you!`;

    default:
      return '';
  }
};
