import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import '@npmsoluto/atomic-ui-library/es5';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import init from './initializations';
import * as serviceWorker from './serviceWorker';

const initPromise = init();

ReactDOM.render(
  <App initPromise={initPromise} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
