import React from 'react';
import moment from 'moment';

import {
  getReplacementFriendlyName,
  getReplacementImageDevice,
  isClaimComplete,
} from '@asurion-hub/enrollment';
import { Claim } from '@soluto-private/account-api-client';
import { ClaimDetailsPageSharedProps } from '../types';
import { constuctFees, shouldShowDeviceSetupModule } from '../utils';
import { ClaimDetailsSameDayPage } from './ClaimDetailsSameDayPage';
import { useTweekValue } from 'react-tweek';

const getAccordionProps = (claimDetails: Claim, fulfillmentDevice: string) => {
  const replacementDetails = {
    repairDayAndDate: moment(
      claimDetails.fulfillmentInfo?.scheduledDate as string
    ).format('MMMM Do YYYY'),
    repairTime: moment(
      claimDetails.fulfillmentInfo?.scheduledDate as string
    ).format('[at] h:mm a'),
    addressLineOne: claimDetails.addressLines[0],
    addressLineTwo: claimDetails.addressLines[1],
    claimFileDate: moment(claimDetails.claimFiledDate).format('MMMM Do YYYY'),
    claimId: claimDetails.claimId,
    replacementDevice: fulfillmentDevice,
    phoneNumber: claimDetails.subscriberContactPhone as string,
    imeiNumber: claimDetails.fulfillmentInfo?.replacementDevice
      ?.mobileEquipmentId as string,
    alternateContactEmail: claimDetails.subscriberEmail as string,
    alternateContactPhone: claimDetails.subscriberContactPhone as string,
  };
  const paymentDetails = constuctFees(claimDetails);
  return { replacementDetails, paymentDetails };
};

export const ClaimDetailsSameDayContainer: React.FC<ClaimDetailsPageSharedProps> = (
  props
) => {
  const shouldShowDeviceSetupStepFlag = useTweekValue<boolean>(
    'asurion_hub/claims/device_setup_step_enabled',
    false
  );

  const showDeviceSetupModule: boolean = shouldShowDeviceSetupModule(
    props.claim,
    shouldShowDeviceSetupStepFlag
  );

  const deviceSetupLink = useTweekValue<string>(
    'asurion_hub/claims/device_setup_guide_link',
    ''
  );

  const fulfillmentDeviceName = getReplacementFriendlyName(props.claim);

  return (
    <ClaimDetailsSameDayPage
      {...props}
      claim={props.claim}
      isLoading={props.isLoading}
      isClaimComplete={isClaimComplete(props.claim)}
      planIncludesSupport={props.planIncludesSupport}
      showSurveyCta={props.showSurveyCta}
      surveyCTA={props.surveyCTA}
      billingSummary={{ fees: props.claim.fees }}
      notification={props.notification}
      setNotification={props.setNotification}
      fulfillmentDevice={fulfillmentDeviceName}
      replacementDeviceImageUrl={getReplacementImageDevice(
        props.currentPlan,
        props.claim
      )}
      shippingInfo={{
        name: props.claim.shipToName,
        addressLines: props.claim.addressLines,
        email: props.claim.subscriberEmail,
        phoneNumber: props.claim.subscriberContactPhone,
      }}
      whatsNextProps={{
        visitTime: moment(
          props.claim.fulfillmentInfo?.scheduledDate as string
        ).format('[at] h:mm a'),
        waitTime: 45, // askAmin
        address: props.claim.fulfillmentInfo?.address?.address1 as string,
      }}
      cta={props.cta}
      accordionProps={getAccordionProps(props.claim, fulfillmentDeviceName)}
      showDeviceSetupModule={showDeviceSetupModule}
      deviceSetupLink={deviceSetupLink}
    />
  );
};
