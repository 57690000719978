import { useQuery, queryCache } from 'react-query';
import { useAuthState } from '@asurion-hub/auth';
import { AccountGateway, Claim } from '@asurion-hub/enrollment';
import { Partner } from '@asurion-hub/partner-config';
import { CACHE_TIME_DEFAULT } from '@asurion-hub-web/react-utils-web';

export interface AllClaimsProps {
  claims?: Claim[] | null;
  claimsError?: Error | null;
  claimsAreFetching: boolean;
}

export const GET_ALL_CLAIMS_QUERY_KEY = 'getAllClaims';

export const useAllClaims = (partnerId: Partner): AllClaimsProps => {
  const authState = useAuthState();
  const getAllClaims = () => AccountGateway.getAllClaims(partnerId);

  const { error, data, isFetching } = useQuery(
    `${GET_ALL_CLAIMS_QUERY_KEY}`,
    getAllClaims,
    {
      refetchOnMount: false,
      retry: false, // disable retries for any error'ed requests
      staleTime: CACHE_TIME_DEFAULT,
      cacheTime: CACHE_TIME_DEFAULT,
      enabled: authState === 'authenticated',
      onError: async () => {
        // invalidate the query from the cache on error - refetchActive is needed to prevent infinite loop of bad queries
        await queryCache.invalidateQueries(GET_ALL_CLAIMS_QUERY_KEY, {
          refetchActive: false,
        });
      },
    }
  );

  return {
    claimsError: error,
    claimsAreFetching: isFetching,
    claims: data,
  };
};
