import { Context, FetchClientConfig } from 'tweek-client';
import { ITweekStore, TweekRepository } from 'tweek-local-cache';
import createTweekClient from './createTweekClient';
import createTweekRepository from './createTweekRepository';

let tweekRepository: TweekRepository;

export type InitTweekRepoOptions = FetchClientConfig & {
  context: Context;
  store?: ITweekStore;
};

export const initTweekRepo = ({
  context,
  store,
  ...config
}: InitTweekRepoOptions) => {
  const client = createTweekClient(config);

  tweekRepository = createTweekRepository({ client, context, store });

  return tweekRepository;
};

export const getTweekRepository = () => tweekRepository;
