import * as React from 'react';

const HorizontalRule: React.FC<{ color?: string; height?: number }> = ({
  color = '#e6e6eb',
  height = 1,
}) => (
  <hr style={{ color, backgroundColor: color, border: 0, margin: 0, height }} />
);

export default HorizontalRule;
