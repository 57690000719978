import React, { useState } from 'react';
import styled from 'styled-components';
import { useTweekValue } from 'react-tweek';
import {
  Avatar,
  Breakpoints,
  DynamicLink,
  Link,
  pxToEm,
  useComponentClickOutside,
} from '@asurion-hub-web/ui';
import { SignOutIcon } from './SignOutIcon';
import { LOGOUT_ROUTE } from '@asurion-hub-web/config';

const StyledButton = styled.button<{
  headerHeight: string;
}>`
  height: ${(props) => props.headerHeight};
  width: ${(props) => props.headerHeight};
  background: none;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  font: inherit;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    &:hover {
      border-color: #6e767d;
    }

    &:active {
      border-color: #a5aaaf;
    }
  }

  @media ${Breakpoints.desktop} {
    height: auto;
    width: auto;
  }
`;

const DropdownContent = styled.ul<{
  headerHeight: string;
}>`
  position: absolute;
  border: 1px solid #e6e6eb;
  border-top: 1px solid #e6e6eb;
  max-width: ${pxToEm(300)};
  background-color: #fff;
  top: ${(props) => props.headerHeight};
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 10;
`;

const dropdownHeaderMargin = pxToEm(16);

const DropdownHeader = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  margin: ${pxToEm(24)} ${dropdownHeaderMargin} 0 ${dropdownHeaderMargin};
  padding-bottom: ${pxToEm(16)};
  border-bottom: 1px solid #e6e6eb;
`;

const DropdownHeaderTextContainer = styled.div`
  margin: 0 ${pxToEm(10)};
  width: 80%;
`;

const DropdownHeaderText = styled.div`
  margin-bottom: ${pxToEm(5)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropdownItem = styled.li`
  display: flex;
  border-bottom: 1px solid #e6e6eb;
  cursor: pointer;
  height: ${pxToEm(48)};
  align-items: center;
  margin: ${pxToEm(12)} ${pxToEm(16)};
  outline: none;

  &:hover * {
    color: #6e767d;
  }

  &:active * {
    color: #333f48;
  }

  &:last-child {
    border: none;
  }
`;

const StyledLink = styled(Link)`
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledDynamicLink = styled(DynamicLink)`
  color: #000;
  width: 100%;
  text-decoration: underline;
`;

type DropdownProps = {
  firstName?: string;
  lastName?: string;
  avatar?: string;
  email?: string;
  headerHeight: string;
  backNamePresent: boolean;
};

export const Dropdown: React.FC<DropdownProps> = ({
  firstName,
  lastName,
  avatar,
  email,
  headerHeight,
}: DropdownProps) => {
  const [signOutIconColor, setSignOutIconColor] = useState('#333f48');

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentClickOutside(false);

  const keyPressListener = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      setIsComponentVisible(false);
    }
  };

  const standaloneAccountManagementUrl = useTweekValue(
    'asurion_hub/asurion_profile/account_management_url',
    'https://account.asurion.com'
  );

  lastName = !!lastName ? lastName : '';

  return (
    <div ref={ref}>
      <StyledButton
        headerHeight={headerHeight}
        aria-haspopup={true}
        aria-expanded={isComponentVisible}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <Avatar
          size="small"
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
          hasBorder={true}
        />
      </StyledButton>
      {isComponentVisible && (
        <nav>
          <DropdownContent role="menu" headerHeight={headerHeight}>
            {email && (
              <DropdownHeader>
                <Avatar
                  size="small"
                  avatar={avatar}
                  firstName={firstName}
                  lastName={lastName}
                  hasBorder={false}
                />
                <DropdownHeaderTextContainer>
                  <DropdownHeaderText className="fs-exclude">
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    {!!firstName ? `${firstName} ${lastName}` : email}
                  </DropdownHeaderText>
                  <DropdownHeaderText>
                    <StyledDynamicLink
                      to={standaloneAccountManagementUrl}
                      actionId="HeaderDropdown-AccountManagement"
                      onClick={() => setIsComponentVisible(false)}
                    >
                      Manage account
                    </StyledDynamicLink>
                  </DropdownHeaderText>
                </DropdownHeaderTextContainer>
              </DropdownHeader>
            )}
            <DropdownItem
              role="menuitem"
              onClick={() => setIsComponentVisible(false)}
              onKeyPress={(event) => keyPressListener(event)}
              onMouseEnter={() => setSignOutIconColor('#6e767d')}
              onMouseLeave={() => setSignOutIconColor('#333f48')}
            >
              <StyledLink to={LOGOUT_ROUTE} actionId="HeaderDropdown-Logout">
                <SignOutIcon color={signOutIconColor} />
                Sign out
              </StyledLink>
            </DropdownItem>
          </DropdownContent>
        </nav>
      )}
    </div>
  );
};
