import {
  Breakpoints,
  LoadingOverlay,
  LoadingPage,
  NotificationBanner,
  Page,
  pxToEm,
  NoDetailsFoundPage,
} from '@asurion-hub-web/ui';
import { logger } from '@soluto-asurion/one-service-core';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useHeaderNavigation } from '@asurion-hub-web/header';
import { enrichAnalytics } from 'react-shisell';
import { withTweekValues } from 'react-tweek';
import styled from 'styled-components';

import MobileDeviceImage from '../../components/MobileDeviceImage';
import { DETAILS_NOT_FOUND_ROUTE, ROOT_ROUTE } from '@asurion-hub-web/config';
import {
  Claim,
  getResponsiveImageUrlFromAssetAttributes,
  ServicePlan,
} from '@asurion-hub/enrollment';
import { useClaims, useServicePlan } from '@asurion-hub-web/enrollment';
import { PageNotification } from '@asurion-hub-web/notifications';
import {
  withLocalStorage,
  withProps,
  WithStorageProps,
} from '@asurion-hub-web/react-utils-web';
import { MoreInfoButtons, Tabs } from './components';
import { ClaimsInformation } from '../../types';
import { trigger } from '@asurion-hub-web/anywhere-expert-sdk';
import { usePlanDevice, PlanDeviceProps } from '../../hocs';

const StyledPage = styled(Page)`
  display: flex;
  max-width: 922px;
  margin: 0 auto;
`;

const DeviceTitle = styled.div`
  font-size: ${pxToEm(20)};
  margin-top: ${pxToEm(15)};
  margin-bottom: ${pxToEm(15)};
  margin-left: ${pxToEm(16)};
`;

const Device = styled.div`
  margin-top: ${pxToEm(15)};
  display: flex;
  justify-content: center;
`;

const PageContentContainer = styled.div`
  width: 100%;
  @media ${Breakpoints.tablet} {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
`;

const ContentIntroContainer = styled.div`
  @media ${Breakpoints.tablet} {
    flex-basis: 469px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${pxToEm(469)};
  max-width: 98vw;
  @media ${Breakpoints.tablet} {
    flex-basis: 453px;
    border-left: 1px solid #eee;
    flex-direction: row;
    justify-content: center;
  }
`;

interface IDeviceDetailsPageProps extends WithStorageProps, PlanDeviceProps {
  claimsUrl: string;
  openChat: () => Promise<void>;
  generalErrorText: string;
  benefitsCardsEnabled: boolean;
  servicePlan: ServicePlan | undefined;
  servicePlanError: Error | null;
  claims: Claim[];
  claimsError?: Error | null;
  claimsAreFetching: boolean;
  isLoading: boolean;
  setIsLoading: (flag: boolean) => void;
  notification: PageNotification | undefined;
  setNotification: (notification?: PageNotification) => void;
  missingClaimsLink: string;
}

const TabbedDeviceDetailsPage: React.FunctionComponent<IDeviceDetailsPageProps> = ({
  benefitsCardsEnabled,
  planDevice,
  planDeviceError,
  planDeviceIsFetching,
  openChat,
  generalErrorText,
  getItem,
  setItem,
  servicePlan,
  servicePlanError,
  claims,
  claimsError,
  claimsAreFetching: claimsIsFetching,
  isLoading,
  setIsLoading,
  notification,
  setNotification,
  missingClaimsLink,
}) => {
  const history = useHistory<{ backText?: string }>();
  // backPath assumes that the only traffic to this page (from elsewhere in the app)
  // is from the home page - will need updated when/if this changes in the future
  const backPath = history.location.state?.backText ? ROOT_ROUTE : undefined;
  // backText defaults to placeholder 'Back' - **should** never be shown to user
  useHeaderNavigation({
    headerBackPath: backPath,
    headerBackText: history.location.state?.backText || 'Home',
  });

  const deviceFriendlyName = planDevice?.friendlyName || 'Your phone';
  const OS = planDevice?.assetCatalogAttributes?.find(
    (attribute) => attribute.assetAttributeName === 'Platform / OS'
  )?.value;

  useEffect(() => {
    if (planDeviceError) {
      logger.error('Failed to fetch asurion profile', planDeviceError);
      history.push(DETAILS_NOT_FOUND_ROUTE);
    } else if (servicePlanError) {
      logger.error('Failed to fetch service plan', servicePlanError);
      history.push(DETAILS_NOT_FOUND_ROUTE);
    }
  }, [history, planDevice, planDeviceError, servicePlan, servicePlanError]);

  const onNotificationClose = () =>
    setNotification(
      notification ? { ...notification, isVisible: false } : undefined
    );

  if (planDeviceIsFetching || !servicePlan) {
    return <LoadingPage />;
  }
  if (!planDevice) {
    return <NoDetailsFoundPage />;
  }

  const claimsInformation: ClaimsInformation = {
    claimsIsFetching,
    claimsError,
    claims,
  };

  return (
    <>
      <LoadingOverlay isVisible={isLoading} />
      <NotificationBanner
        notificationBannerId="DeviceDetailsErrorBanner"
        notificationReason={notification?.reason ?? 'unknown'}
        isOpen={!!notification?.isVisible}
        status={notification?.status}
        onClose={onNotificationClose}
      >
        {notification?.message}
      </NotificationBanner>
      <StyledPage pageName="Device Details">
        <PageContentContainer>
          <ContentIntroContainer>
            <DeviceTitle data-testid="device-friendly-name">
              {planDevice.friendlyName || 'Your phone'}
            </DeviceTitle>
            <Device>
              <MobileDeviceImage
                model={planDevice.model}
                responsiveImageUrl={getResponsiveImageUrlFromAssetAttributes(
                  planDevice.assetCatalogAttributes
                )}
              />
            </Device>
            <MoreInfoButtons
              generalErrorText={generalErrorText}
              setNotification={setNotification}
              openChat={openChat}
              claimsUrl={servicePlan?.claimsUrl}
              setIsLoading={setIsLoading}
              planIncludesSupport={servicePlan.includesSupport}
            />
          </ContentIntroContainer>
          <ContentContainer>
            <Tabs
              deviceDetails={{
                name: planDevice.name,
                email: planDevice.email,
                assetId: planDevice.assetId,
                phone: planDevice.phone,
                model: planDevice.model,
                subscriberId: planDevice.subscriberId,
              }}
              planDetails={{
                ...servicePlan,
                enrollmentDate: planDevice.enrollmentDate,
              }}
              deviceFriendlyName={deviceFriendlyName}
              OS={OS}
              benefitsCardsEnabled={benefitsCardsEnabled}
              getItem={getItem}
              setItem={setItem}
              claimsInformation={claimsInformation}
              missingClaimsLink={missingClaimsLink}
            />
          </ContentContainer>
        </PageContentContainer>
      </StyledPage>
    </>
  );
};
export { TabbedDeviceDetailsPage };

const TabbedDeviceDetailsPageContainer: React.FC<IDeviceDetailsPageProps> = (
  props
) => {
  const { assetId } = useParams<{ assetId: string }>();
  const planDeviceProps = usePlanDevice(assetId);
  const { claims, claimsAreFetching, claimsError } = useClaims(
    planDeviceProps.planDevice?.subscriberId
  );
  const { servicePlan, servicePlanError } = useServicePlan(
    planDeviceProps.planDevice?.productId
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [notification, setNotification] = React.useState<PageNotification>();

  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line
    const historyState: any = history.location.state;
    // eslint-disable-next-line
    if (!(historyState && historyState.backText)) {
      history.location.state = { backText: 'Home' };
    }
  }, [history]);

  return (
    <TabbedDeviceDetailsPage
      {...props}
      {...planDeviceProps}
      claims={claims || []}
      claimsError={claimsError}
      claimsAreFetching={claimsAreFetching}
      servicePlan={servicePlan}
      servicePlanError={servicePlanError}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      notification={notification}
      setNotification={setNotification}
    />
  );
};

const Enhanced = flow(
  enrichAnalytics<IDeviceDetailsPageProps>((dispatcher, props) =>
    props.planDevice?.subscriberId
      ? dispatcher
          .withIdentity('SubscriberId', props.planDevice?.subscriberId)
          .withExtras({
            ServicePlanId: props.planDevice?.productId
              ? props.planDevice?.productId
              : 'Unknown',
            DeviceFriendlyName: props.planDevice?.friendlyName,
          })
      : dispatcher
  ),
  withTweekValues(
    {
      generalErrorText: 'asurion_hub/texts/errors/general',
      claimsUrl: 'asurion_hub/claims/urls',
      benefitsCardsEnabled: 'asurion_hub/account/benefit_cards_enabled',
      missingClaimsLink: 'asurion_hub/claims/missing_claims_link',
    },
    {
      defaultValues: {
        generalErrorText: '',
        claimsUrl: '',
        benefitsCardsEnabled: false,
        missingClaimsLink: '',
      },
    }
  ),
  withProps({ openChat: trigger }),
  withLocalStorage
)(TabbedDeviceDetailsPageContainer);

export default Enhanced;
