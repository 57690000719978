import { logger } from '@soluto-asurion/one-service-core';
import { CommonEvent } from '@soluto-private/common-event';
import { Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

type InitializeWebsocketConfig = {
  url: string;
  pingInterval?: number;
  onConnect: () => void;
  onDisconnect: () => void;
};

type WebsocketEventType = CommonEvent<unknown> | 'ping';
export let websocket$: WebSocketSubject<WebsocketEventType>;
export const websocketObservable$ = new Subject<WebsocketEventType>();

export const initializeWebsocket = ({
  url,
  pingInterval = 15000,
  onConnect,
  onDisconnect,
}: InitializeWebsocketConfig) => {
  const pingFn = setInterval(
    () => websocket$.next('ping'),
    pingInterval
  );
  websocket$ = webSocket({
    url,
    openObserver: {
      next: () => {
        onConnect();
        return pingFn;
      },
    },
    closeObserver: {
      next: () => {
        onDisconnect();
        clearInterval(pingFn);
      },
    },
  });
  websocket$.subscribe(
    msg => websocketObservable$.next(msg),
    err => logger.warn('Received error from websocket', err),
    () => logger.info('Closing websocket connection')
  );
  return websocket$;
};
