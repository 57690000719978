/* eslint-disable */
import { Checkbox as CommonCheckbox } from '@npmsoluto/atomic-ui-library-react';
import React from 'react';
import { withAnalyticOnEvent } from 'react-shisell';

type CheckboxProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement> & {
    name?: string;
    value?: string;
    checked?: boolean;
    disabled?: boolean;
    color?: string;
  },
  HTMLElement
>;
const Checkbox: React.FC<CheckboxProps> = ({ color, ...restProps }) => (
  <div
    css={`
      height: 24px;
      width: 24px;
      :hover {
        cursor: pointer;
      }
    `}
  >
    <CommonCheckbox {...restProps} />
  </div>
);

type EnhancedCheckboxProps = CheckboxProps & {
  actionId: string;
};
const EnhancedCheckbox = withAnalyticOnEvent({
  eventName: 'onChange',
  analyticName: 'Click',
})(Checkbox);

const CheckboxWithAnalytics: React.FC<EnhancedCheckboxProps> = (props: any) => (
  <EnhancedCheckbox analyticsExtras={{ ActionId: props.actionId }} {...props}>
    {props.children}
  </EnhancedCheckbox>
);

export default CheckboxWithAnalytics;
