import { pxToEm, Timeline, DynamicLink } from '@asurion-hub-web/ui';
import arrowIcon from '@asurion-hub-web/assets/images/icons/arrowIcon.svg';
import * as React from 'react';
import styled from 'styled-components';
import { createDeviceSetupButtonReference } from '../../utils';

const Button = styled(DynamicLink)`
  display: inline-block;
  line-height: 1.5;
  font-size: ${pxToEm(16)};
  border: 1px solid #000;
  border-radius: 9999px;
  padding: ${pxToEm(3)} ${pxToEm(12)};
  color: white;
  background-color: #000000;
  text-decoration: none;
  text-align: center;
`;

const Text = styled.div`
  margin-bottom: ${pxToEm(-9)};
`;

const SetupList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: ${pxToEm(16)};
  > li {
    :before {
      content: '•';
      margin-right: ${pxToEm(5)};
    }
  }
`;

const IconElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div<{ image?: string }>`
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-repeat: no-repeat;
  background-size: contain;
  width: ${pxToEm(24)};
  height: ${pxToEm(12)};
  margin: ${pxToEm(12)};
`;

export type WhatsNextSameDayProps = {
  visitTime: string;
  waitTime: number;
  address: string;
  stepIndex: number;
  showDeviceSetupModule: boolean;
  oldDeviceMakeModel?: string;
  newDeviceMakeModel: string;
  deviceSetupLink: string;
};

const numberOfStepsBeforePhoneSetup = 2;

const buildSetupYourPhoneStep = (
  link: string,
  oldDeviceMakeModel: string,
  newDeviceMakeModel: string
) => {
  return {
    heading: 'Set up your phone',
    content: (
      <>
        <SetupList>
          <li>Activate your phone</li>
          <li>Restore apps, photos, and contacts</li>
          <li>Reconnect to your favorite things</li>
        </SetupList>
        <Button
          actionId={'AdvanceExchangeDeviceSetupGuide'}
          to={createDeviceSetupButtonReference(
            link,
            oldDeviceMakeModel,
            newDeviceMakeModel
          )}
          target="_blank"
        >
          <IconElementWrapper>
            View the set up guides <Icon image={arrowIcon} />
          </IconElementWrapper>
        </Button>
      </>
    ),
  };
};

const WhatsNextSameDay: React.FC<WhatsNextSameDayProps> = (props) => {
  const { stepIndex, visitTime, waitTime, address } = props;

  const entries = [
    {
      heading: 'Appointment Scheduled',
      content: (
        <>
          Your expert will come by today between {visitTime} to {address}. They
          will help you get your phone up and running in approximately{' '}
          {waitTime} mins.
        </>
      ),
    },
    {
      heading: 'Appointment Scheduled',
      content: (
        <>
          An Asurion Expert will help you quickly activate and set up your
          device the way you like it. There are a few things you can do to help
          get your device set up as quickly as possible:
          <br />
          <br />
          1. Have your iCloud or Google username/password available.
          <br />
          2. If possible, backup your original device.
          <br />
          <br />
          <Text>
            <b>Need to update your claim or speak with us?</b>{' '}
          </Text>
          <br />
          <Button>Live chat with us</Button>
        </>
      ),
    },
    {
      heading: `Your device is on the way!`,
      content:
        'Your device is on the way! You will receive an email to tack your expert when they are on their way!',
    },
  ];

  if (props.showDeviceSetupModule) {
    entries.splice(
      numberOfStepsBeforePhoneSetup,
      0,
      buildSetupYourPhoneStep(
        props.deviceSetupLink,
        props.oldDeviceMakeModel!,
        props.newDeviceMakeModel
      )
    );
  }

  return <Timeline currentEntryIndex={stepIndex} entries={[...entries]} />;
};

export default WhatsNextSameDay;
