import React from 'react';
import { NotificationContext } from './context';
import { useNotificationReducer } from './reducer';

// eslint-disable-next-line @typescript-eslint/ban-types
export const withNotifications = <P extends {}>(
  Wrapped: React.ComponentType<P>
) => (props: P) => {
  const notifications = useNotificationReducer();
  return (
    <NotificationContext.Provider value={notifications}>
      <Wrapped {...props} />
    </NotificationContext.Provider>
  );
};
