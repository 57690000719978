import { parse } from 'query-string';
import { supportedPartners, Partner } from '@asurion-hub/partner-config';
import {
  PARTNER_STORAGE_KEY,
  setPartner,
} from '@asurion-hub-web/partner-config-react';

export default () => {
  // First store partner from query param
  const { partner: parsedPartner } = parse(window.location.search);
  const partnerFromQuery = Array.isArray(parsedPartner)
    ? ''
    : parsedPartner || '';
  if (Object.keys(supportedPartners).includes(partnerFromQuery as Partner)) {
    window.localStorage.setItem(PARTNER_STORAGE_KEY, partnerFromQuery);
  }

  // Then initialize the partner
  const partner = window.localStorage.getItem(PARTNER_STORAGE_KEY);
  if (partner) {
    setPartner(partner as Partner);
  }
};
