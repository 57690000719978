import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  DynamicLink,
  LoadingOverlay,
  NotificationBanner,
  Button,
} from '@asurion-hub-web/ui';
import { useTweekValue } from 'react-tweek';
import {
  ContentContainer,
  Title,
  ButtonContainer,
  StyledPage,
  Strong,
} from './components';

const ButtonContainerWithTopMargin = styled.div`
  margin-top: 1.5rem;
`;

const HelpWithRecentClaim = styled(DynamicLink)`
  margin: 1.5rem 0;
  text-decoration: underline;
`;

type CollectAgreementEmailProps = {
  pageErrorState: boolean;
  setPageErrorState: (errorState: boolean) => void;
  pageErrorMessage?: string;
  pageLoadingState: boolean;
  agreementEmail?: string;
  mdn: string;
  onSubmit: (e: SyntheticEvent) => void;
  setEmailInputMode: (isEmailInputMode: boolean) => void;
};

const CollectAgreementEmailPage: React.FC<CollectAgreementEmailProps> = ({
  pageErrorState,
  setPageErrorState,
  pageErrorMessage,
  pageLoadingState,
  setEmailInputMode,
  onSubmit,
}) => {
  const phoneclaimLink = useTweekValue(
    'asurion_hub/claims/phone_claim_link',
    ''
  );
  return (
    <>
      {pageErrorState && (
        <NotificationBanner
          isOpen={pageErrorState}
          onClose={() => setPageErrorState(false)}
          notificationBannerId="SendConfirmationEmailBannerError"
          notificationReason={pageErrorMessage || ''}
          status="warn"
        >
          {pageErrorMessage}
        </NotificationBanner>
      )}
      <StyledPage pageName="CollectAgreementEmail">
        <LoadingOverlay isVisible={pageLoadingState} isFullPage={true} />
        <ContentContainer>
          <Title>
            We’ll send a one-time code to{' '}
            <Strong>the email used to enroll in your protection plan</Strong>{' '}
            instead.
          </Title>
          <ButtonContainer>
            <Button
              actionId="SendOTPVerifyToEmail"
              className="full-width primary"
              data-testid="send-email-for-otp-btn"
              onClick={onSubmit}
            >
              Continue
            </Button>
            <ButtonContainerWithTopMargin>
              <Button
                actionId="ReturnToCollectMdn"
                className="full-width"
                data-testid="return-to-collect-mdn-btn"
                onClick={() => setEmailInputMode(false)}
              >
                Cancel
              </Button>
            </ButtonContainerWithTopMargin>
          </ButtonContainer>
          <HelpWithRecentClaim
            onClick={() => window.location.assign(phoneclaimLink)}
            actionId="NeedMoreHelpFromOTP"
          >
            Need help with a recent claim?
          </HelpWithRecentClaim>
        </ContentContainer>
      </StyledPage>
    </>
  );
};

export default CollectAgreementEmailPage;
