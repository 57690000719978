import { createContext, useContext } from 'react';
import { AlertBanner, Toast } from './Notification';

export type NotificationState = {
  alertBanners: AlertBanner[];
  toasts: Toast[];
  createAlertBanner: (alertBanner: Omit<AlertBanner, 'type'>) => void;
  createToast: (toast: Omit<Toast, 'type'>) => void;
};

export const initialNotificationContext: NotificationState = {
  alertBanners: [],
  toasts: [],
  createAlertBanner: () => undefined,
  createToast: () => undefined,
};

export const NotificationContext = createContext(initialNotificationContext);
NotificationContext.displayName = 'NotificationContext';

export const useNotifications = () => useContext(NotificationContext);
