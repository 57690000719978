import { getTweekRepository } from '@asurion-hub/tweek';
import { CreateClientParams } from 'contentful';
import { useMemo } from 'react';
import { useTweekValue } from 'react-tweek';

export type ContentfulConfig = {
  apiAccessToken: string;
  apiPreviewAccessToken?: string;
  environment: string;
  spaceId: string;
  previewEnabled?: boolean;
};

const toContentfulConfig = ({
  spaceId,
  apiAccessToken,
  apiPreviewAccessToken,
  environment,
  previewEnabled,
}: ContentfulConfig): CreateClientParams => {
  const previewToken = previewEnabled && apiPreviewAccessToken;
  return {
    space: spaceId,
    accessToken: previewToken || apiAccessToken,
    environment: environment,
    host: previewToken ? 'preview.contentful.com' : undefined,
  };
};

export const useContentfulConfig = (): CreateClientParams | undefined => {
  const config = useTweekValue<ContentfulConfig | undefined>(
    'asurion_hub/contentful/_',
    undefined
  );

  return useMemo<CreateClientParams | undefined>(
    () => config && toContentfulConfig(config),
    [config]
  );
};

export const getContentfulConfig = async (): Promise<CreateClientParams> => {
  const config = await getTweekRepository().getValue<ContentfulConfig>(
    'asurion_hub/contentful/_'
  );

  return toContentfulConfig(config);
};
