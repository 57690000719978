// eslint-disable
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTweekValue } from 'react-tweek';
import styled from 'styled-components';
import { LoadingOverlay, QuickLinks } from '@asurion-hub-web/ui';
import {
  ADD_DEVICES_FLOW_V1_FEATURE_FLAG,
  RouteBuilder as DevicesRouteBuilder,
} from '@asurion-hub-web/user-add-device-flow';
import { UserDevicesDisplayModule } from '@asurion-hub-web/user-devices-ui';
import { DevicesSection } from '@asurion-hub-web/user-plan-devices';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import { DiscoverSection, PlanTileSection } from './components';
import { DETAILS_NOT_FOUND_ROUTE, RouteBuilder } from '@asurion-hub-web/config';
import {
  useAccountIncludesSupport,
  usePlans,
  ServicePlansState,
} from '@asurion-hub-web/enrollment';

const pageMargin = 16;

const HomePageContainer = styled.div`
  max-width: calc(600px + ${pageMargin * 2}px);
  width: 100%;
  padding: 0 ${pageMargin}px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const Section = styled.div`
  margin-top: 70px;
`;

type AuthenticatedHomePageProps = {
  friendlyPageName?: string;
  servicePlansState: ServicePlansState;
};

const AuthenticatedHomePage: React.FC<AuthenticatedHomePageProps> = ({
  servicePlansState,
  friendlyPageName = 'Home',
}) => {
  const { plans, plansError, plansAreFetching } = usePlans();
  const pageIsLoading = plansAreFetching || !!plansError;
  const history = useHistory();
  const [partnerConfig] = usePartnerConfig();
  const accountSupport = useAccountIncludesSupport();

  useEffect(() => {
    if (plansError) {
      history.push(DETAILS_NOT_FOUND_ROUTE);
    }
  }, [plansError, history]);

  const navigateToDeviceDetails = (assetId?: string) => {
    history.push(RouteBuilder.routeForDeviceDetails(assetId));
  };

  const navigateToClaimDetails = (claimId: string) => {
    history.push(RouteBuilder.routeForClaimDetails(claimId));
  };

  const navigateToAddADevicePage = (category: string) => {
    history.push(DevicesRouteBuilder.routeForSelectDevice(category), {
      backText: friendlyPageName,
    });
  };

  const addDevicesFeatureFlag = useTweekValue(
    ADD_DEVICES_FLOW_V1_FEATURE_FLAG,
    false
  );

  return (
    <HomePageContainer>
      <LoadingOverlay isFullPage={true} isVisible={pageIsLoading} />
      <Section>
        <QuickLinks showSupportButton={
          accountSupport.accountIncludesSupport
            ? accountSupport.accountIncludesSupport
            : false
        } />
      </Section>
      <Section>
        <DevicesSection
          plans={plans}
          onInfoClick={navigateToDeviceDetails}
          onClaimClick={navigateToClaimDetails}
        />
      </Section>
      {addDevicesFeatureFlag && (
        <Section>
          <UserDevicesDisplayModule onCategoryClick={navigateToAddADevicePage} />
        </Section>
      )}
      <Section>
        <PlanTileSection servicePlansState={servicePlansState} />
      </Section>
      {!pageIsLoading && (
        <Section>
          <DiscoverSection
            parentPage={friendlyPageName}
            deviceFriendlyName=""
            partner={partnerConfig.name}
            articlesShown={999}
          />
        </Section>
      )}
    </HomePageContainer>
  );
};

export default AuthenticatedHomePage;
