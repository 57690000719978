import { Claim } from '@soluto-private/account-api-client';
import { getCarrier, getCarrierLink } from './shipping';

export const getTrackingLink = (claim: Claim): string => {
  const carrier =
    claim.trackingCarrier ?? getCarrier(claim.trackingNumber || '');

  if (!carrier || !claim.trackingNumber) {
    return '';
  }

  return getCarrierLink(carrier, claim.trackingNumber) || '';
};
