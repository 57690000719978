import { SimpleLayout } from '../components';
import { Paragraph } from '@npmsoluto/atomic-ui-library-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import sleepIcon from '../asset/sleepIcon.svg';
import { authClient } from '@asurion-hub-web/auth';
import { Page } from '@asurion-hub-web/ui';
import { AuthenticatedUser } from '@asurion-hub/auth';

const ContentContainer = styled(SimpleLayout.ContentContainer)`
  width: 100%;
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    width: ${SimpleLayout.cols(8)};
    margin-left: ${SimpleLayout.cols(2)};
  }
`;

const ButtonContainer = styled.div`
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    width: ${SimpleLayout.cols(8)};
    margin-left: 0px;
    margin-top: 30px;
  }
  margin-top: 60px;
`;

const ImageObject = styled.object`
  width: 180px;
  margin-left: auto;
  display: block;
  margin-right: auto;
`;

const SessionTimeOutPage = () => {
  useEffect(() => {
    if (AuthenticatedUser.accessToken) {
      window.location.replace(`${window.location.origin}`);
    }
  }, []);

  return (
    <Page pageName={'SessionTimeout'}>
      <SimpleLayout.Page pageName="FailedToFetchAccountDetails" contained>
        <ContentContainer>
          <ImageObject
            type="image/svg+xml"
            data={sleepIcon}
            aria-label="SleepIcon"
          />
          <SimpleLayout.Heading>
            There you are! We logged you out for your security.
          </SimpleLayout.Heading>
          <Paragraph>Would you like to sign back in?</Paragraph>
          <ButtonContainer>
            <SimpleLayout.ActionButton
              actionId="SignIn"
              primary={true}
              onClick={() => {
                authClient.redirectToLogin();
              }}
            >
              Sign in
            </SimpleLayout.ActionButton>
          </ButtonContainer>
        </ContentContainer>
      </SimpleLayout.Page>
    </Page>
  );
};
export default SessionTimeOutPage;
