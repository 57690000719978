/* eslint-disable */
import { usePartnerTheme } from '@asurion-hub-web/partner-theme-react';
import chevron from '@asurion-hub-web/assets/images/icons/chevron.png';
import * as React from 'react';
import { useAnalytics } from 'react-shisell';
import styled from 'styled-components';
import { pxToEm, Link } from '@asurion-hub-web/ui';
import { useHistory } from 'react-router-dom';
import { ROOT_ROUTE } from '@asurion-hub-web/config';

const headerHeightPx = 49;
const simpleHeaderHeight = pxToEm(headerHeightPx);

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #e6e6eb;
`;

const LogoLink = styled(Link)`
  height: 100%;
`;
const AsurionLogo = styled.img`
  width: 197px;
  margin: 0.5rem 1rem;
`;
const RowContainer = styled.div`
  height: 3.5rem;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

const MainRow = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
`;

const BackRowContainer = styled(RowContainer)`
  cursor: pointer;
`;

const ChevronImage = styled.img`
  height: 1.5em;
  width: auto;
  margin: 0 1em;
`;

interface Props {
  backText?: string;
  onBackClick?: () => void;
  hideBackButton?: boolean;
  disableLogoLink?: boolean;
  mainRowRef?: React.RefObject<HTMLDivElement>;
}

export const PureSimpleHeader: React.FC<Props> & { height: string } = ({
  backText: backName,
  onBackClick,
  children,
  disableLogoLink,
  mainRowRef,
}) => {
  const theme = usePartnerTheme();

  return (
    <Container>
      <MainRow ref={mainRowRef}>
        {disableLogoLink ? (
          <AsurionLogo
            src={theme.images.logos.header}
            alt="asurion logo"
            data-test-id="header-logo"
          />
        ) : (
          <LogoLink to={ROOT_ROUTE} actionId="HeaderLogo">
            <AsurionLogo
              src={theme.images.logos.header}
              alt="asurion logo"
              data-test-id="header-logo"
            />
          </LogoLink>
        )}
        {children}
      </MainRow>
      {backName && (
        <BackRowContainer
          onClick={() => onBackClick && onBackClick()}
          data-testid="back-btn"
        >
          <ChevronImage src={chevron} />
          <div>{backName}</div>
        </BackRowContainer>
      )}
    </Container>
  );
};

export const SimpleHeader: React.FC<Props> & { height: string } = (props) => {
  const history = useHistory<{ backText?: string }>();
  const locationState = history.location.state;
  const analytics = useAnalytics();
  const onBackClickHandler = () => {
    analytics.dispatcher.withExtra('ActionId', 'HeaderBack').dispatch('Click');
    props.onBackClick ? props.onBackClick() : history.goBack();
  };
  return (
    <PureSimpleHeader
      backText={props.backText || locationState?.backText}
      onBackClick={onBackClickHandler}
      hideBackButton={props.hideBackButton}
      disableLogoLink={props.disableLogoLink}
      mainRowRef={props.mainRowRef}
    >
      {props.children}
    </PureSimpleHeader>
  );
};

SimpleHeader.height = simpleHeaderHeight;
PureSimpleHeader.height = simpleHeaderHeight;
