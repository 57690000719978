import React, { useEffect, useState } from 'react';
import { useAuthState, AuthenticatedUser } from '@asurion-hub/auth';
import { useTweekValue } from 'react-tweek';
import { logger } from '@soluto-asurion/one-service-core';
import { useAccountIncludesSupport } from '@asurion-hub-web/enrollment';

let globalInitialized = false;
let globalSignedIn = false;

const AnywhereExpertSdkCore: React.FC<{ includesSupport: boolean }> = ({
  includesSupport,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [initializationPromise, setInitializationPromise] = useState<Promise<
    void
  > | null>(null);
  const [signInPromise, setSignInPromise] = useState<Promise<void> | null>(
    null
  );
  const [signedIn, setSignedIn] = useState(false);
  globalInitialized = initialized;
  globalSignedIn = signedIn;
  const authState = useAuthState();
  const apiKey = useTweekValue('asurion_hub/ae_sdk/api_key', '');
  useEffect(() => {
    if (authState !== 'authenticated') {
      return;
    }
    if (!window.AE_SDK) {
      logger.warn('SDK has not loaded');
      return;
    }
    if (!initialized && !initializationPromise) {
      const promise = window.AE_SDK.initialize(apiKey, {
        hiddenInitialization: true,
      })
        .then(() => {
          setInitialized(true);
          logger.info('==== AE SDK initialized');
        })
        .catch((err: Error) => {
          logger.error('failed to initialize AE SDK', err);
        });
      setInitializationPromise(promise);
      return;
    }
    const accessToken = AuthenticatedUser.accessToken;
    if (
      initialized &&
      !signedIn &&
      !signInPromise &&
      includesSupport &&
      accessToken
    ) {
      const promise = window.AE_SDK.signIn(accessToken)
        .then(() => {
          logger.info('==== AE SDK signed in');
          setSignedIn(true);
          window.AE_SDK.show();
        })
        .catch((err: Error) => {
          logger.error('failed to sign into AE SDK', err);
        });
      setSignInPromise(promise);
    }
  }, [
    authState,
    initialized,
    apiKey,
    signedIn,
    includesSupport,
    signInPromise,
    initializationPromise,
  ]);
  return null;
};

const AnywhereExpertSdk: React.FC = () => {
  const { accountIncludesSupport } = useAccountIncludesSupport();
  return <AnywhereExpertSdkCore includesSupport={!!accountIncludesSupport} />;
};

export { AnywhereExpertSdk };

export class AESDKLoadError extends Error {
  name = 'AESDKLoadError';
}
export class AESDKInitError extends Error {
  name = 'AESDKInitError';
}
export class AESDKSignInError extends Error {
  name = 'AESDKSignInError';
}
export class AESDKRuntimeError extends Error {
  name = 'AESDKRuntimeError';
}

export async function trigger() {
  if (!window.AE_SDK) {
    throw new AESDKLoadError();
  }
  if (!globalInitialized) {
    throw new AESDKInitError();
  }
  if (!globalSignedIn) {
    throw new AESDKSignInError();
  }
  try {
    await window.AE_SDK.triggerMessagingOverlay();
  } catch (err) {
    logger.error('Failed to trigger the anywhere expert SDK', err);
    throw new AESDKRuntimeError();
  }
}
