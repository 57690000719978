import * as React from 'react';
import styled from 'styled-components';
import { Partner } from '@asurion-hub/partner-config';
import { ServicePlanType } from '@asurion-hub/enrollment';
import { pxToEm, DynamicLink } from '@asurion-hub-web/ui';
import verizonLogo from '@asurion-hub-web/assets/images/logos/verizon.svg';
import verizonLogoInverted from '@asurion-hub-web/assets/images/logos/verizon-inverted.svg';
import cricketLogo from '@asurion-hub-web/assets/images/logos/cricket.svg';
import cricketLogoInverted from '@asurion-hub-web/assets/images/logos/cricket-inverted.svg';

type PlanTileProps = {
  name: string;
  partner: Partner;
  protectionType: ServicePlanType;
  isActive: boolean;
  isProtectionActive: boolean;
  backgroundImage?: string;
  logo?: string;
  tagline?: string;
  addPlanButtonConfig?: {
    label: string;
    url: string;
  };
};

const partnerLogoMap: Record<
  Partner,
  { logo: string; logoInverted: string }
> = {
  verizon: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    logo: verizonLogo,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    logoInverted: verizonLogoInverted,
  },
  cricket: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    logo: cricketLogo,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    logoInverted: cricketLogoInverted,
  },
};

const protectionTypeConfigMap: Record<
  ServicePlanType,
  { icon: React.ReactNode; label: string }
> = {
  [ServicePlanType.Mobility]: {
    label: 'Mobile Protection',
    icon: (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1966 14.4161V18.4161C13.1966 19.3366 12.4504 20.0827 11.5299 20.0827H4.02995C3.10947 20.0827 2.36328 19.3366 2.36328 18.4161V2.58274C2.36328 1.66227 3.10947 0.916077 4.02995 0.916077H5.94661"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6411 10.8324L14.102 10.6574C16.2936 9.82406 18.1953 7.72073 18.1953 5.37573V2.54573C18.1955 2.19913 17.9899 1.8855 17.672 1.7474C16.2336 1.20987 14.7124 0.927847 13.177 0.914062C11.6534 0.927075 10.1441 1.20919 8.71865 1.7474C8.40075 1.8855 8.19516 2.19913 8.19531 2.54573V5.37323C8.19531 7.71823 10.0603 9.8199 12.252 10.6549L12.7128 10.8299C13.0114 10.9449 13.3419 10.9458 13.6411 10.8324Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  [ServicePlanType.ConnectedHome]: {
    label: 'Connected Home',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7912 19.8872H13.7912C12.6882 19.8872 11.7912 18.9912 11.7912 17.8872V14.8872H9.7912V17.8872C9.7912 18.9912 8.89421 19.8872 7.7912 19.8872H5.7912C4.6882 19.8872 3.7912 18.9912 3.7912 17.8872V11.8872H1.7912C1.3872 11.8872 1.0222 11.6442 0.867205 11.2702C0.712205 10.8972 0.798205 10.4672 1.0842 10.1812L10.0842 1.18117C10.4752 0.791172 11.1072 0.791172 11.4982 1.18117L20.4982 10.1812C20.7842 10.4672 20.8702 10.8972 20.7152 11.2702C20.5602 11.6442 20.1952 11.8872 19.7912 11.8872H17.7912V17.8872C17.7912 18.9902 16.8942 19.8872 15.7912 19.8872ZM8.7912 12.8872H12.7912C13.3432 12.8872 13.7912 13.3362 13.7912 13.8872V17.8872H15.7922L15.7912 10.8872C15.7912 10.3362 16.2392 9.88717 16.7912 9.88717H17.3772L10.7912 3.30217L4.2052 9.88717H4.7912C5.3432 9.88717 5.7912 10.3362 5.7912 10.8872V17.8872H7.7912V13.8872C7.7912 13.3352 8.2382 12.8872 8.7912 12.8872Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  [ServicePlanType.CloudPhotoStorage]: {
    label: 'Cloud Photo Storage',
    icon: (
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.2081 15.065C23.9088 14.2782 24.975 12.5515 24.9165 10.6786C24.896 9.29251 24.3125 7.9744 23.3002 7.02732C22.288 6.08025 20.934 5.58568 19.5496 5.65733C18.3151 3.17316 15.7738 1.6087 12.9998 1.62517C9.21993 1.57441 6.05266 4.47264 5.76856 8.24217C4.63998 7.99404 3.45961 8.26487 2.5521 8.98018C1.64458 9.69549 1.10551 10.7799 1.08314 11.9353C0.992505 13.6242 2.13919 15.1301 3.79147 15.4918"
          stroke="white"
        />
        <path d="M13.2944 11.0898V20.492" stroke="white" />
        <path
          d="M8.94141 15.2686L13.2942 11.0898L17.6471 15.2686"
          stroke="white"
        />
      </svg>
    ),
  },
};

const Container = styled.div<{ backgroundImage?: string; isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#fff' : '#000')};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: ${({ isActive, backgroundImage }) =>
    isActive
      ? `#000 ${backgroundImage ? `url(${backgroundImage})` : ''}`
      : '#fff'};
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  border-radius: 8px;
  border: ${({ isActive }) => (isActive ? 'none' : '1px dashed #A5AAAF')};
  padding: 15px;
  width: 100%;
  height: 180px;
  box-shadow: ${({ isActive }) => isActive
    ? 'inset 0 0 0 9999px rgba(0, 0, 0, .7)'
    : 'none'};
`;

const ProtectionTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12.5px;
`;

const ProtectionTypeIcon = styled.div`
  margin-right: 8px;
`;

const ProtectionTypeLabel = styled.div`
  font-size: ${pxToEm(14)};
  font-weight: bold;
`;

const PartnerLogo = styled.div<{
  isActive: boolean;
  logo: string;
  logoInverted: string;
}>`
  height: 19px;
  background-image: url(${({ isActive, logo, logoInverted }) =>
    isActive ? logoInverted : logo});
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 3px;
`;

const LogoContainer = styled.div`
  max-height: 100px;
  width: 80%;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  max-width: max-content;
`;

const PlanName = styled.div<{ partner: Partner }>`
  ${({ partner }) =>
    partner === Partner.Verizon && `font-family: VerizonNHGTX;`}
  font-size: ${pxToEm(14)};
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 5px;
`;

const AddPlanButton = styled(DynamicLink)`
  margin-top: auto;
  border-radius: 9999px;
  padding: 7px 14px;
  align-self: start;
  border: 1px solid #000;
  display: flex;
  text-decoration: none;
  color: black;
  :after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    background: linear-gradient(#000, #000), linear-gradient(#000, #000);
    background-position: center;
    background-size: 100% 1px, 1px 100%;
    background-repeat: no-repeat;
  }
`;

const Tagline = styled.div`
  font-size: 14px;
  line-height: 1.2;
`;

const ProtectionActive = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  margin-top: auto;
  :before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjQ1NTg2IDEzLjIzMzdMMi45NzY4MSA4Ljc1NDYxTDQuNjg1MyA3LjA0NjExTDcuNDU1ODYgOS44MTY2N0wxMi42NDMgNC42Mjk1OEwxNC4zNTE0IDYuMzM4MDdMNy40NTU4NiAxMy4yMzM3WiIgZmlsbD0iIzVDQkI0RCIvPgo8L3N2Zz4K');
    margin-right: 4px;
  }
  :after {
    content: 'Active';
    font-weight: bold;
    font-size: ${pxToEm(14)};
  }
`;

const PlanTile: React.FC<PlanTileProps> = (props) => {
  const {
    name,
    partner,
    protectionType,
    isProtectionActive,
    isActive,
    backgroundImage,
    logo,
    addPlanButtonConfig,
    tagline,
  } = props;
  const protectionTypeConfig = protectionTypeConfigMap[protectionType];

  return (
    <Container isActive={isActive} backgroundImage={backgroundImage}>
      <ProtectionTypeContainer>
        <ProtectionTypeIcon>{protectionTypeConfig.icon}</ProtectionTypeIcon>
        <ProtectionTypeLabel>{protectionTypeConfig.label}</ProtectionTypeLabel>
      </ProtectionTypeContainer>
      <LogoContainer>
        {logo ? (
          <Logo alt={name} src={logo} />
        ) : (
          <>
            <PartnerLogo isActive={isActive} {...partnerLogoMap[partner]} />
            <PlanName partner={partner}>{name}</PlanName>
          </>
        )}
      </LogoContainer>
      {isActive ? (
        <ProtectionActive isActive={isProtectionActive} />
      ) : (
        <>
          {tagline && <Tagline>{tagline}</Tagline>}
          {addPlanButtonConfig && (
            <AddPlanButton to={addPlanButtonConfig.url}>
              {addPlanButtonConfig.label}
            </AddPlanButton>
          )}
        </>
      )}
    </Container>
  );
};

export default PlanTile;
