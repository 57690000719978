import { createGlobalStyle } from 'styled-components';

export default () => {
  /* eslint-disable global-require */
  require('@asurion-hub-web/assets/fonts/VerizonNHGTX/VerizonNHGTX.css');
  require('@npmsoluto/asurion-design-assets-asurion-black/asurion-black.css');
  createGlobalStyle`
    body {
      /* global styles here */
    }
  `;
};
