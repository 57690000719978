import { AuthenticatedUser, getSupportedPersonas } from '@asurion-hub/auth';
import { logger } from '@asurion-hub/logging';
import { Partner } from '@asurion-hub/partner-config';
import { getTweekRepository } from '@asurion-hub/tweek';
import {
  AccountApiClient,
  VerifyAuthOtpResult,
  VerifyOtpResult,
} from '@soluto-private/account-api-client';
import { ApiClientResponseError } from '@soluto-private/base-client';
import { Claim, Plan, PlanStatus } from './types';
import { filterClaims, retryAsync } from './utils';

async function getAccountApiClient(noAuth?: boolean) {
  const authToken = AuthenticatedUser.accessToken;
  if (!authToken && !noAuth) {
    logger.error('Missing auth token', new Error('Missing auth token'));
    throw new Error('Missing auth token');
  }

  const accountApiUrl = await getTweekRepository().getValue<string>(
    'asurion_hub/asurion_profile/api_url'
  );

  return new AccountApiClient({
    clientApplicationName: 'asurion-hub',
    authToken,
    url: accountApiUrl,
  });
}

async function clientRequest<TResponse>(
  f: (client: AccountApiClient) => Promise<TResponse>,
  requestName: keyof AccountApiClient,
  noAuth?: boolean
): Promise<TResponse> {
  try {
    const client = await getAccountApiClient(noAuth);
    return await f(client);
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const message: string = err && err.message ? String(err.message) : '';
    logger.error(
      `Error making request AccountApiClient.${requestName}: ${message}`,
      err
    );
    throw err;
  }
}

async function getPlans(partner: string): Promise<Plan[]> {
  return await clientRequest(
    (client) => client.getPlansV3(partner),
    'getPlansV3'
  );
}

async function safeGetPlans(partner: string): Promise<Plan[]> {
  return await clientRequest(async (client) => {
    try {
      return await client.getPlansV3(partner);
    } catch (error) {
      const responseError = error as ApiClientResponseError;
      if (responseError.statusCode !== 404) {
        throw error;
      }
      return [];
    }
  }, 'getPlansV3');
}

async function getClaimsBySubId(subId: string): Promise<Claim[]> {
  const shouldSupportRepairClaims = await getTweekRepository().getValue<string>(
    'asurion_hub/claims/support_multiple_claim_types'
  );
  const claims = await retryAsync(
    () =>
      clientRequest(
        (client) => client.getClaimsBySubIdV4(subId),
        'getClaimsBySubIdV4'
      ),
    {
      maxNumOfRetries: 5,
      delay: 750,
      shouldRetry: (err) => err && err.name === 'ApiClientUncaughtError',
    }
  );
  return shouldSupportRepairClaims ? claims : filterClaims(claims);
}

async function getAllClaims(partner: Partner): Promise<Claim[]> {
  const personas = getSupportedPersonas(partner);
  if (!personas.length) {
    return [];
  }
  const shouldSupportRepairClaims = await getTweekRepository().getValue<string>(
    'asurion_hub/claims/support_multiple_claim_types'
  );
  const isV5Active = await getTweekRepository().getValue<boolean>(
    'asurion_hub/claims/use_v5'
  );
  if (isV5Active) {
    const claims = await clientRequest(
      (client) => client.getClaimsV7(partner),
      'getClaimsV7'
    );
    return shouldSupportRepairClaims ? claims : filterClaims(claims);
  }

  const claimsForPersonas = await Promise.all(
    personas.map((persona) =>
      getClaimsBySubId(persona.externalId).catch((e) => {
        logger.warn(`Unable to get claims for subId ${persona.externalId}`, e);
        return [];
      })
    )
  );
  return shouldSupportRepairClaims
    ? claimsForPersonas.flat(1)
    : filterClaims(claimsForPersonas.flat(1));
}

async function sendOtp(
  mdn: string,
  partner: string,
  hash?: string
): Promise<void> {
  return await clientRequest(
    (client) => client.sendOtp({ mdn, partner, hash }),
    'sendOtp'
  );
}

async function sendOtpViaEmail(mdn: string, partner: string): Promise<void> {
  return await clientRequest(
    (client) => client.sendOtpViaEmail({ mdn, partner }),
    'sendOtpViaEmail'
  );
}

async function sendAuthOtp(email: string, clientId: string): Promise<void> {
  return await clientRequest(
    (client) => client.sendAuthOtpV2({ email, clientId }),
    'sendAuthOtpV2',
    true
  );
}

async function verifyOtp(
  otpToVerify: string,
  partner: string
): Promise<VerifyOtpResult> {
  return await clientRequest(
    (client) => client.verifyOtpV2({ otpToVerify, partner }),
    'verifyOtpV2'
  );
}

async function verifyAuthOtp(
  email: string,
  otpToVerify: string
): Promise<VerifyAuthOtpResult> {
  return await clientRequest(
    (client) => client.verifyAuthOtpV2({ email, otpToVerify }),
    'verifyAuthOtpV2',
    true
  );
}

export async function getNewPlans(): Promise<PlanStatus[]> {
  return await clientRequest((client) => client.getNewPlans(), 'getNewPlans');
}

export const AccountGateway = {
  getPlans,
  safeGetPlans,
  getClaimsBySubId,
  getAllClaims,
  sendOtp,
  sendOtpViaEmail,
  sendAuthOtp,
  verifyOtp,
  verifyAuthOtp,
  getNewPlans,
};
