import { Device } from '@asurion-hub-web/devices-base';
import { useRecoilValue, selector } from 'recoil';
import { devicesV1Gateway } from '../gateways';

// devices state
export const devicesState = selector<Device[]>({
  key: 'getDevices',
  get: async () => {
    return await devicesV1Gateway.getDevices();
  },
});

export const useDevicesState = () => useRecoilValue(devicesState);
