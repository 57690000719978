/* eslint-disable */
import {
  documentToReactComponents,
  NodeRenderer,
} from '@contentful/rich-text-react-renderer';
import { Document, INLINES } from '@contentful/rich-text-types';
import React from 'react';

import { inlineRenderers } from './renderers';
import { Renderers } from './types';

const createNodeRenderer = (renderers: Renderers): NodeRenderer => (
  node,
  children
) => {
  const contentType = node.data.target?.sys?.contentType?.sys?.id;
  const renderer = renderers[contentType];

  if (!renderer) {
    return null;
  }

  const { Component, convertProps } = renderer;
  const target = node.data.target || { fields: {}, sys: {} };
  const props = convertProps ? convertProps(target) : target.fields;

  if (children && (!Array.isArray(children) || children.length > 0)) {
    props.children = children;
  }

  return <Component {...props} />;
};

type RichTextProps = {
  document: Document;
};

const RichText: React.FC<RichTextProps> = ({ document }: RichTextProps) => {
  if (!document.content) {
    return <>{document}</>;
  }

  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: createNodeRenderer(inlineRenderers),
    },
  };

  return <>{documentToReactComponents(document, options)}</>;
};

export default RichText;
