import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  DynamicLink,
  LoadingOverlay,
  NotificationBanner,
  pxToEm,
  TextInput,
  Button,
} from '@asurion-hub-web/ui';
import {
  ContentContainer,
  Subtitle,
  Title,
  ButtonContainer,
  StyledPage,
  breakpoint,
  Label,
} from './components';
import { mdnInputMask } from '@asurion-hub-web/config';

const MdnInputContainer = styled.form`
  font-weight: bolder;
  margin-top: ${pxToEm(6)};
  --label-font-size: 0.875rem;
  @media ${breakpoint} {
    padding-bottom: 0;
  }
`;

const IDontHaveAccessLink = styled(DynamicLink)`
  margin: 1.5rem 0;
  text-decoration: underline;
`;

const MdnInputContainerWrapper: React.FC<{
  blockFromAnalytics?: boolean;
  onSubmit: (e: SyntheticEvent) => void;
}> = (props) => (
  <div>
    <MdnInputContainer className="fs-exclude" onSubmit={props.onSubmit}>
      {props.children}
    </MdnInputContainer>
  </div>
);

type CollectMdnPageProps = {
  pageErrorState: boolean;
  setPageErrorState: (errorState: boolean) => void;
  pageErrorMessage?: string;
  pageLoadingState: boolean;
  onSubmit: (e: SyntheticEvent) => void;
  mdn?: string;
  setMdn: (mdn: string) => void;
  canSubmit: boolean;
  setEmailInputMode: (isEmailInputMode: boolean) => void;
  emailConfirmationEnabled: boolean;
};

const CollectMdnPage: React.FC<CollectMdnPageProps> = ({
  pageErrorState,
  setPageErrorState,
  pageErrorMessage,
  pageLoadingState,
  onSubmit,
  mdn,
  setMdn,
  canSubmit,
  setEmailInputMode,
  emailConfirmationEnabled,
}) => {
  return (
    <>
      {pageErrorState && (
        <NotificationBanner
          isOpen={pageErrorState}
          onClose={() => setPageErrorState(false)}
          notificationBannerId="CollectMdnBannerError"
          notificationReason={pageErrorMessage || ''}
          status="warn"
        >
          {pageErrorMessage}
        </NotificationBanner>
      )}
      <StyledPage pageName="CollectMdn">
        <LoadingOverlay isVisible={pageLoadingState} isFullPage={true} />
        <ContentContainer>
          <Title>Next, what’s your mobile number on the account?</Title>
          <Subtitle>We&rsquo;ll text you a one-time code.</Subtitle>
          <MdnInputContainerWrapper onSubmit={onSubmit}>
            <Label htmlFor="mdn">
              {emailConfirmationEnabled ? `Phone Number` : `Your Phone Number`}
            </Label>
            <TextInput
              shadowed={true}
              maskConfig={{
                showMask: false,
                guide: false,
                mask: mdnInputMask,
              }}
              inputMode="tel"
              className="fs-exclude"
              value={mdn}
              onChange={(e) => setMdn(e.target.value)}
              id="mdn"
              data-testid="mdn-input"
              autoComplete="one-time-code"
            />
          </MdnInputContainerWrapper>
          <ButtonContainer>
            <Button
              actionId="CollectMdn"
              className={`full-width primary ${canSubmit ? '' : 'disabled'}`}
              data-testid="collect-mdn-btn"
              disabled={!canSubmit}
              onClick={onSubmit}
            >
              Continue
            </Button>
          </ButtonContainer>
          <IDontHaveAccessLink
            onClick={() => setEmailInputMode(true)}
            actionId="NoAccessToMDN"
          >
            {`I don't have access to this device`}
          </IDontHaveAccessLink>
        </ContentContainer>
      </StyledPage>
    </>
  );
};

export default CollectMdnPage;
