import React, { useEffect } from 'react';
import styled from 'styled-components';
import { flow } from 'fp-ts/lib/function';
import {
  Button as BaseButton,
  Breakpoints,
  pxToEm,
  LoadingPage,
  LoadingOverlay,
} from '@asurion-hub-web/ui';
import {
  DevicesSearchResultsList,
  SearchInput,
  SearchFilterButtons,
} from '@asurion-hub-web/devices-search-ui';
import {
  useMakeFilter,
  useSelectedFiltersRemoveAndAddByType,
  useOrderedSearchResultsDeviceIds,
  useOrderedSearchResultsFilters,
  Filter,
  useResetSelectedFilters,
  useSelectedFilters,
} from '@asurion-hub-web/devices-search';
import { useHistory, useParams } from 'react-router-dom';
import { useHeaderNavigation } from '@asurion-hub-web/header';
import {
  withSuspenseAndErrorBoundary,
  Device,
  GatewayDeviceCategory,
  deviceCategoryMap,
} from '@asurion-hub-web/devices-base';
import {
  useDeviceSelectedState,
  useSetDeviceIdSelectedState,
} from '@asurion-hub-web/devices';
import {
  useUpsertUserDevice,
  useResetUserDevicesState,
} from '@asurion-hub-web/user-devices';
import { SelectDevicePageProps } from '../types';
import {
  enrichAnalytics,
  useAnalytics,
  withAnalyticOnView,
} from 'react-shisell';
import { ADD_DEVICES_SELECTED_WRAPUP_ROUTE, RouteBuilder } from '../config';
import { ROOT_ROUTE } from '@asurion-hub-web/config';
import CancelAddModal from '../components/CancelAddModal';
import { parse as parseQueryString } from 'query-string';
import { useNotifications } from '@asurion-hub-web/notifications';

const PageContentContainer = styled.div<{ isItemSelected: boolean }>`
  font-family: 'Apercu-Regular-Pro';
  display: block;
  align-items: center;
  padding-left: ${pxToEm(10)};
  max-width: calc(100% - ${pxToEm(10)});

  ${(props) => props.isItemSelected && 'height: calc(100vh - 7.0625em - 5em);'}
  ${(props) => props.isItemSelected && 'overflow-y: scroll;'}

  @media ${Breakpoints.desktop} {
    max-width: 60%;
  }

  @media ${Breakpoints.mobileBig} {
    margin: 0 auto;
    ${(props) =>
      props.isItemSelected && 'min-height: calc(100vh - 7.0625em - 5em);'}
  }
`;

const TitleContainer = styled.div`
  font-size: ${pxToEm(24)};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  font-family: Apercu-Regular-Pro;
  padding: 1rem 0;
`;

const TopScrollContent = styled.div<{ isItemSelected: boolean }>`
  overflow-y: scroll;
  height: calc(
    100vh - 7.0625em - 5em -
      ${(props) => (props.isItemSelected ? pxToEm(78) : '0em')}
  );
`;

const SearchFilterButtonsContainer = styled(SearchFilterButtons)`
  margin-bottom: ${pxToEm(20)};
`;

const CategoryIcon = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: ${pxToEm(13)};
  width: 64px;
  height: 64px;
  flex-shrink: 0;
`;

const DevicesSearchInput = styled.div`
  margin-right: 16px;
  position: sticky;
  top: 5px;
`;

const Button = styled(BaseButton)`
  --button-color: #fff;
  --button-background-color: #000;
  --button-border-color: #fff;
`;

const DevicesSearchResultsListContainer = styled.div`
  margin-bottom: ${pxToEm(57)};
`;

const ButtonContainer = styled.div`
  @media ${Breakpoints.mobileBig} {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  margin: ${pxToEm(16)};
`;

const TradeMark = styled.div`
  margin-bottom: 46px;
  margin-left: 23px;
  margin-right: 23px;
  margin-top: 16px;
  color: #6e767d;
`;

export const SelectDevicePage: React.FC<SelectDevicePageProps> = ({
  category,
  showCancelModal,
}) => {
  const deviceSearchResultsIds: Device['id'][] = useOrderedSearchResultsDeviceIds();
  const filtersRemoveAndAddByType = useSelectedFiltersRemoveAndAddByType();
  const resetFilters = useResetSelectedFilters();
  const deviceSelected = useDeviceSelectedState();
  const setSelectedDeviceId = useSetDeviceIdSelectedState();
  const makeFilter = useMakeFilter();
  const history = useHistory();
  const orderedSearchResultFiltersMakes: Filter[] = useOrderedSearchResultsFilters();
  const deviceCategoryEnriched = deviceCategoryMap[category];
  const [deviceIsSet, setDeviceIsSet] = React.useState(false);
  const [upsertInProgress, setUpsertInProgress] = React.useState(false);
  const analytics = useAnalytics();
  const [state, upsertUserDevice] = useUpsertUserDevice();
  const resetUserDevicesState = useResetUserDevicesState();
  const { createAlertBanner } = useNotifications();

  // reset filters on load
  useEffect(() => {
    resetFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filtersRemoveAndAddByType(
      [],
      [{ type: 'category', value: category.toLowerCase() }]
    );
  }, [category, filtersRemoveAndAddByType]);

  const setSelectedDevice = React.useCallback(
    (id: Device['id']) => {
      setSelectedDeviceId(id);
      setDeviceIsSet(true);
    },
    [setSelectedDeviceId]
  );

  useEffect(() => {
    if (state.error) {
      createAlertBanner({
        status: 'error',
        id: 'AddDeviceAlert-Error',
        reason: "Device didn't save",
        autoDismiss: true,
        message: 'Hmm, something unexpected happened. Please try again later.',
      });
    }
  }, [createAlertBanner, state.error]);

  useEffect(() => {
    if (!state.loading && deviceSelected && deviceIsSet && !upsertInProgress) {
      setUpsertInProgress(true);
      void upsertUserDevice(deviceSelected.id, deviceSelected.make);
    }
  }, [
    deviceSelected,
    deviceIsSet,
    upsertInProgress,
    setUpsertInProgress,
    state.loading,
    upsertUserDevice,
  ]);

  useEffect(() => {
    if (state.value) {
      analytics.dispatcher
        .withExtra('DeviceId', deviceSelected?.id)
        .withExtra('DeviceMake', deviceSelected?.make)
        .withExtra('DeviceModel', deviceSelected?.model)
        .withExtra('MakeFilterValue', makeFilter?.value)
        .withExtra('ActivityType', 'AddDeviceConfirm')
        .withExtra('ClickId', 'AddDeviceConfirm')
        .dispatch('Click');

      resetUserDevicesState();

      history.push(
        RouteBuilder.getRouteAndPreserveParams(
          ADD_DEVICES_SELECTED_WRAPUP_ROUTE
        )
      );
    }
  }, [
    resetUserDevicesState,
    history,
    state.value,
    makeFilter,
    analytics,
    deviceSelected,
  ]);

  const [pendingSelectedDeviceId, setPendingSelectedDeviceId] = React.useState(
    ''
  );
  useEffect(() => {
    analytics.dispatcher
      .withExtra('DeviceSearchResultCount', deviceSearchResultsIds.length)
      .withExtra('ActionId', 'DeviceSearchResultsUpdated')
      .withExtra('ActivityType', 'DeviceSearchResultsUpdated')
      .dispatch('Activity');

    // clear out any pendingSelectedDeviceId if the results don't include it
    if (
      deviceSearchResultsIds &&
      pendingSelectedDeviceId &&
      !deviceSearchResultsIds.includes(pendingSelectedDeviceId)
    ) {
      setPendingSelectedDeviceId('');
    }
  }, [
    deviceSearchResultsIds,
    analytics,
    pendingSelectedDeviceId,
    setPendingSelectedDeviceId,
  ]);

  const setSelectedDeviceWrapper = () => {
    setSelectedDevice(pendingSelectedDeviceId);
  };

  const toggleFilter = React.useCallback(
    (filter: Filter, isSelected: boolean) =>
      filtersRemoveAndAddByType(
        [{ type: filter.type }],
        isSelected ? [] : [filter]
      ),
    [filtersRemoveAndAddByType]
  );

  return (
    <PageContentContainer
      isItemSelected={!!pendingSelectedDeviceId}
      data-testid="select-device-page"
    >
      {showCancelModal && (
        <CancelAddModal
          onStayClick={() =>
            history.push(
              `${window.location.pathname}${window.location.search}`.replace(
                /confirmBack=true&?/i,
                ''
              )
            )
          }
          onYesCancelClick={() => history.push(ROOT_ROUTE)}
        />
      )}
      <TopScrollContent isItemSelected={!!pendingSelectedDeviceId}>
        <TitleContainer>
          <CategoryIcon image={deviceCategoryEnriched.icon} />
          What kind of {deviceCategoryEnriched.text.toLowerCase()}?
        </TitleContainer>
        <DevicesSearchInput>
          <SearchInput />
        </DevicesSearchInput>
        <SearchFilterButtonsContainer
          filters={orderedSearchResultFiltersMakes}
          selectedFilter={makeFilter}
          toggleFilter={toggleFilter}
        />
        <DevicesSearchResultsListContainer>
          <DevicesSearchResultsList
            deviceIds={deviceSearchResultsIds}
            selectedDeviceId={pendingSelectedDeviceId}
            setSelectedDeviceId={setPendingSelectedDeviceId}
          />
        </DevicesSearchResultsListContainer>
      </TopScrollContent>
      <LoadingOverlay isFullPage={true} isVisible={upsertInProgress} />
      {pendingSelectedDeviceId && (
        <>
          <ButtonContainer>
            <Button
              actionId={'setSelectedDevice'}
              onClick={setSelectedDeviceWrapper}
              data-testid="set-selected-device-button"
            >
              Confirm
            </Button>
          </ButtonContainer>
          <TradeMark>
            The Asurion® trademarks and logos are the property of Asurion, LLC.
            iPhone® is the trademark of Apple Inc. All other trademarks are the
            property of their respective owners. Asurion is not affiliated with,
            sponsored by, or endorsed by Apple or any of the respective owners
            of the other trademarks appearing herein.
          </TradeMark>
        </>
      )}
    </PageContentContainer>
  );
};

export const SelectDevicePageWrapper: React.FC<SelectDeviceEnhancementProps> = ({
  deviceCategory,
}) => {
  const { confirmBack } = parseQueryString(window.location.search);
  useHeaderNavigation({
    headerBackPath: `${
      window.location.pathname
    }?confirmBack=true&${window.location.search.replace('?', '')}`,
    headerBackText: 'Home',
  });

  return (
    <SelectDevicePage
      showCancelModal={!!confirmBack}
      category={deviceCategory}
    />
  );
};

type SelectDeviceEnhancementProps = {
  deviceCategory: GatewayDeviceCategory;
  deviceSearchText: string;
};

const EnhancedSelectDevicePage = flow(
  enrichAnalytics((dispatcher, props: SelectDeviceEnhancementProps) =>
    dispatcher
      .withExtra('Page', 'SelectDevice')
      .withExtra('DevicesVersion', 'v1')
      .withExtra('DeviceCategory', props.deviceCategory)
      .withExtra('DeviceSearchText', props.deviceSearchText)
  ),
  withAnalyticOnView({
    analyticName: 'PageView',
    mapPropsToExtras: (props: SelectDeviceEnhancementProps) => ({
      Page: 'SelectDevice',
      DeviceCategory: props.deviceCategory,
    }),
  })
)(withSuspenseAndErrorBoundary(SelectDevicePageWrapper, LoadingPage));

const SelectDevicePageContainer: React.FC = () => {
  const { category } = useParams<{ category: GatewayDeviceCategory }>();
  const selectedFilters = useSelectedFilters();
  const searchText = selectedFilters
    .filter((f) => f.type === 'text')
    .map((m) => m.value)
    .join(' ');
  return (
    <EnhancedSelectDevicePage
      deviceCategory={category}
      deviceSearchText={searchText}
    />
  );
};

export default SelectDevicePageContainer;
