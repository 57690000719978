import React from 'react';
import { whatsNextRepairStepIndexMap } from '@asurion-hub/enrollment';
import RepairAccordion from '../components/MultipleClaimTypeComponents/RepairAccordion';
import WhatsNextRepair from '../components/MultipleClaimTypeComponents/WhatsNextRepair';
import { ClaimDetailsPageBase } from '../components/MultipleClaimTypeComponents/_ClaimDetailsPageBase';
import { RepairClaimDetailsProps } from '../types';

export const ClaimDetailsRepairPage: React.FC<RepairClaimDetailsProps> = (
  props
) => {
  return (
    <ClaimDetailsPageBase
      claimDetails={{
        repairDate: props.claim.claimFiledDate,
        shipmentStatus: props.claim.claimStatus,
        repairLocation: props.claim.fulfillmentInfo?.address,
      }}
      claimType={props.claim.claimType}
      isLoading={props.isLoading}
      planIncludesSupport={props.planIncludesSupport}
      showSurveyCta={props.showSurveyCta}
      isClaimComplete={!props.isClaimComplete}
      billingSummary={props.billingSummary!}
      surveyCTA={props.surveyCTA}
      notification={props.notification}
      mdn={props.currentPlan?.phone || ''}
      fulfillmentDevice={props.fulfillmentDevice}
      replacementDeviceImageUrl={props.replacementDeviceImageUrl}
      status={props.claim.claimStatus!}
      isComplete={props.isClaimComplete!}
      claimId={props.claim.claimId}
      setNotification={props.setNotification}
      accessories={props.claim.accessories}
      whatsNext={
        <WhatsNextRepair
          stepIndex={whatsNextRepairStepIndexMap(props.claim)}
          claimSubmittedTime={props.claim.claimFiledDate!}
          repairDate={props.claim.fulfillmentInfo?.scheduledDate || ''}
          repairLocation={props.claim.fulfillmentInfo?.address}
        />
      }
      detailAccordion={<RepairAccordion {...props.accordionProps} />}
      cta={props.cta}
    />
  );
};
