import React from 'react';
import styled from 'styled-components';
import { Breakpoints, ContentPanes, SectionHeading } from '@asurion-hub-web/ui';
import { CARD_MARGINS } from '../constants';

const selectors = {
  cardsContainer: '> div',
  card: '[class^="cardContainer-"]',
};

const ArticleListContainer = styled(ContentPanes)`
  ${selectors.cardsContainer} {
    padding: 0;
  }
  ${selectors.card} {
    margin: 0;
    margin-bottom: ${CARD_MARGINS[1]}px;
    max-width: none;
    width: 100%;
    flex-basis: auto;
    border-radius: 8px;
    overflow: hidden;
  }
  @media ${Breakpoints.mobileBig} {
    ${selectors.card} {
      margin: 0;
      box-sizing: border-box;
      width: calc(50% - ${CARD_MARGINS[0] / 2}px);
      margin-left: ${CARD_MARGINS[0]}px;
      margin-bottom: ${CARD_MARGINS[1]}px;
      :nth-child(odd) {
        margin-left: 0;
      }
    }
  }
`;

type DiscoverSectionProps = {
  parentPage: string;
  deviceFriendlyName: string;
  partner: string;
  articlesShown: number;
};

export const DiscoverSection: React.FC<DiscoverSectionProps> = ({
  parentPage,
  deviceFriendlyName,
  partner,
  articlesShown,
}) => {
  return (
    <div id="discover">
      <SectionHeading>Discover</SectionHeading>
      <ArticleListContainer
        articleContext={parentPage}
        partner={partner}
        limit={articlesShown}
        deviceFriendlyName={deviceFriendlyName}
      />
    </div>
  );
};
