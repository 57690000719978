import { atomFamily, selectorFamily, useRecoilValue } from 'recoil';
import { Device } from '@asurion-hub-web/devices-base';
import { devicesState } from './devicesState';

export const devicesByIdState = atomFamily<Device | undefined, Device['id']>({
  key: 'devicesAtomFamily',
  default: selectorFamily<Device | undefined, Device['id']>({
    key: 'devicesAtomFamily/Default',
    get: (id) => ({ get }) =>
      get(devicesState).find((device) => device.id === id),
  }),
});

export const useDeviceById = (id: Device['id']) =>
  useRecoilValue(devicesByIdState(id));
