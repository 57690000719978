import { AuthenticatedUser } from '@asurion-hub/auth';
import { getTweekRepository } from '@asurion-hub/tweek';
import {
  UserDevicesV1ApiClient,
  UserDeviceV1,
  UserDevicesV1,
} from '@soluto-private/user-devices-v1-api-client';

import {
  DeleteUserDeviceV1GatewayPayload,
  GetUserDeviceV1GatewayPayload,
  UpsertUserDevicesV1GatewayPayload,
} from '../models';

type ExtendedUserDevicesV1ApiClient = {
  client: UserDevicesV1ApiClient;
  asurionId: string;
};

class UserDevicesV1Gateway {
  private async getUserDevicesV1ApiClient(): Promise<
    ExtendedUserDevicesV1ApiClient
  > {
    const authToken = AuthenticatedUser.accessToken;

    if (!authToken) {
      throw new Error('Missing auth token');
    }

    const asurionId = AuthenticatedUser.get()?.asurionId;

    if (!asurionId) {
      throw new Error('Missing asurionId');
    }

    const url = await getTweekRepository().getValue<string>(
      'asurion_hub/devices/add_device_by_category/v1/user_devices_api_url'
    );

    const client = new UserDevicesV1ApiClient({
      clientApplicationName: 'asurion-hub',
      authToken,
      url,
    });

    return {
      client,
      asurionId,
    };
  }

  public async getUserDevice({
    userDeviceId,
  }: GetUserDeviceV1GatewayPayload): Promise<UserDeviceV1> {
    const apiClient = await this.getUserDevicesV1ApiClient();
    return await apiClient.client.getUserDeviceV1({
      userDeviceId,
      userId: apiClient.asurionId,
    });
  }

  public async getUserDevices(): Promise<UserDevicesV1> {
    const apiClient = await this.getUserDevicesV1ApiClient();
    return await apiClient.client.getUserDevicesV1({
      userId: apiClient.asurionId,
    });
  }

  public async upsertUserDevices(
    payload: UpsertUserDevicesV1GatewayPayload
  ): Promise<{ userId: string; userDevices: UserDeviceV1[] }> {
    const apiClient = await this.getUserDevicesV1ApiClient();
    return await apiClient.client.upsertUserDevicesV1({
      ...payload,
      userId: apiClient.asurionId,
    });
  }

  public async deleteUserDevices({
    userDeviceId,
  }: DeleteUserDeviceV1GatewayPayload): Promise<void> {
    const apiClient = await this.getUserDevicesV1ApiClient();
    return await apiClient.client.deleteUserDevicesV1({
      userDeviceId,
      userId: apiClient.asurionId,
    });
  }
}

export default new UserDevicesV1Gateway();
