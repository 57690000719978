import { AuthenticatedUser } from '@asurion-hub/auth';
import { getTweekRepository } from '@asurion-hub/tweek';
import { initializeWebsocket, websocket$ } from '@asurion-hub-web/websocket';
import { analytics } from 'react-shisell';

const dispatcher = () => analytics.dispatcher.createScoped('Websocket');
const dispatchConnected = () => dispatcher().dispatch('WebsocketConnected');
const dispatchDisconnected = () =>
  dispatcher().dispatch('WebsocketDisconnected');

export default async function () {
  const url: string = await getTweekRepository().getValue(
    'asurion_hub/websocket/url'
  );
  AuthenticatedUser.updated$.subscribe(({ state, accessToken }) => {
    if (state === 'authenticated') {
      const config = {
        url: `${url}?Authorization=${accessToken || ''}`,
        onConnect: dispatchConnected,
        onDisconnect: dispatchDisconnected,
      };
      initializeWebsocket(config);
    } else if (websocket$) {
      websocket$.complete();
    }
  });
}
