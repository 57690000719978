import {
  useRecoilValue,
  useResetRecoilState,
  selector,
  atom,
  DefaultValue,
} from 'recoil';
import { userDevicesV1Gateway } from '../gateways';
import { UserDevice } from '../types';

export const userDevicesTrigger = atom({
  key: 'userDevicesTrigger',
  default: 0,
});

export const userDevicesState = selector<UserDevice[]>({
  key: 'getUserDevices',
  get: async ({ get }) => {
    get(userDevicesTrigger);
    return (await userDevicesV1Gateway.getUserDevices()).userDevices;
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(userDevicesTrigger, (v) => v + 1);
    }
  },
});

export const useUserDevicesState = () => useRecoilValue(userDevicesState);
export const useResetUserDevicesState = () =>
  useResetRecoilState(userDevicesState);
