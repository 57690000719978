/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { ContentPanes } from '@asurion-hub-web/ui';

import { DiscoverTabProps } from '../../../../types';
import { TabPanelContainer } from './tab-content';

const Container = styled(TabPanelContainer)`
  overflow-x: hidden;
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  > div {
    box-sizing: border-box;
  }
  > div > div {
    box-sizing: border-box;
  }
`;

const DiscoverTab: React.FC<DiscoverTabProps> = (props) => {
  const { deviceFriendlyName, planDetails } = props;

  return (
    <Container>
      <ContentPanes
        articleContext={deviceFriendlyName}
        deviceFriendlyName={deviceFriendlyName}
        partner={planDetails.partner}
        limit={999}
      />
    </Container>
  );
};

export default DiscoverTab;
