export interface RetryOptions {
  maxNumOfRetries: number;
  delay: number;
  shouldRetry: (e: Error) => boolean;
}

function wait(duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

export async function retryAsync<T>(
  fn: () => Promise<T>,
  options: RetryOptions
): Promise<T> {
  try {
    return await fn();
  } catch (err) {
    if (options.maxNumOfRetries > 1 && options.shouldRetry(err)) {
      await wait(options.delay);
      return await retryAsync(fn, {
        ...options,
        maxNumOfRetries: options.maxNumOfRetries - 1,
      });
    }

    throw err;
  }
}
