import * as React from 'react';
import styled from 'styled-components';
import { Page as BasePage, Breakpoints, pxToEm } from '@asurion-hub-web/ui';
import { SelectablePartner, primaryPartners, secondaryPartners } from './partners';

const breakpoint = Breakpoints.mobileMedium;

const SubmitButton = styled.button`
  font-family: Apercu-Light-Pro;
  border-radius: 9999px;
  border: none;
  width: 100%;
  background: #000;
  color: #fff;
  padding: 12px;
  font-size: ${pxToEm(16)};
  line-height: 1.5;
  cursor: pointer;
  :disabled {
    color: #a5aaaf;
    background: #E6E6EB;
  }
`;

const Page = styled(BasePage)`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 16px;
  padding-bottom: 50px;
`;

const Intro = styled.div`
  margin-top: 47px;
  margin-bottom: 24px;
  text-align: center;
`;

const Heading = styled.div`
  font-size: ${pxToEm(32)};
  line-height: 1.2;
  margin-bottom: 24px;
`;

const Subheading = styled.div`
  font-size: ${pxToEm(16)};
  line-height: 1.5;
`;

const PrimaryPartners = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 45px;
  margin-bottom: 40px;
  border-bottom: 1px solid #D5D6DA;
  gap: 16px;
  @media ${breakpoint} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const PrimaryPartner = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: none;
  cursor: pointer;
  padding-right: 19px;
  padding-left: 34px;
  width: 100%;
  height: 84px;
  border: 1px solid #D5D6DA;
  border-radius: 4px;
  transition: border-color 0.3s;
  :hover {
    border-color: #999;
    :after {
      left: 3px;
    }
  }
  :last-child {
    margin-bottom: 0;
  }
  :after {
    content: '';
    transition: left 0.3s;
    left: 0;
    background: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M11.9107 5L8 1.41L9.41 0L15.41 6L9.41 12L8 10.59L11.9107 7H0V5H11.9107ZM13 5.99992V6.00008L13.0001 6L13 5.99992Z' fill='black'/%3E%3C/svg%3E");
    position: relative;
    width: 15.4px;
    height: 12px;
  }
  @media ${breakpoint} {
    height: 100px;
    width: calc(50% - 4px);
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const PrimaryPartnerLogo = styled.img`
  width: 100px;
`;

const SecondaryPartners = styled.div`
  padding-bottom: 24px;
  @media ${breakpoint} {
    padding: 0 29px;
  }
`;

const Form = styled.form`
`;

const PartnerSelect = styled.select`
  font-family: 'Apercu-Light-Pro';
  background: #fff;
  width: 100%;
  height: 56px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  font-size: ${pxToEm(16)};
  line-height: 1.5;
  padding: 12px;
  border-color: #a5aaaf;
  option:disabled {
    color: #6E767D;
  }
`;

type SelectPartnerPageProps = {
  onSubmit: (partner?: SelectablePartner) => void;
}

export const SelectPartnerPage: React.FC<SelectPartnerPageProps> = (props) => {
  const { onSubmit } = props
  const [secondaryPartner, setSecondaryPartner] = React.useState<SelectablePartner | ''>('');

  /* eslint-disable styled-components-a11y/no-onchange */
  return (
    <Page pageName="SelectPartner">
      <Intro>
        <Heading>We’ve got you covered.</Heading>
        <Subheading>Select your provider to sign in.</Subheading>
      </Intro>
      <PrimaryPartners>
        {primaryPartners.map((partner, i) => (
          <PrimaryPartner key={i} onClick={() => onSubmit(partner)}>
            <PrimaryPartnerLogo src={partner.logoUrl} alt={partner.name} />
          </PrimaryPartner>
        ))}
      </PrimaryPartners>
      <SecondaryPartners>
        <Subheading>Don’t see your provider?</Subheading>
        <Form onSubmit={() => secondaryPartner && onSubmit(secondaryPartner)}>
          <PartnerSelect
            value={secondaryPartner ? secondaryPartner.id : ''}
            onChange={(e) => setSecondaryPartner(secondaryPartners.find(({ id }) => e.target.value === id) || '')}
          >
            <option disabled value="">Select your provider</option>
            {secondaryPartners.map(({ id, name }, k) => (
              <option key={k} value={id}>{name}</option>
            ))}
          </PartnerSelect>
          <SubmitButton type="submit" disabled={!secondaryPartner}>Continue</SubmitButton>
        </Form>
      </SecondaryPartners>
    </Page>
  );
  /* eslint-enable styled-components-a11y/no-onchange */
};
