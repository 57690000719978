import * as React from 'react';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import { themes } from '@asurion-hub/partner-theme';

export const usePartnerTheme = () => {
  const [partnerConfig] = usePartnerConfig();
  const [theme, setTheme] = React.useState(themes[partnerConfig.id]);

  React.useEffect(() => {
    setTheme(themes[partnerConfig.id]);
  }, [partnerConfig]);

  return theme;
};
