import * as React from 'react';
import { PlanDetails } from '../../../../../../types';
import { DetailRow } from '@asurion-hub-web/ui';
import TabContent from '../TabContent';

type ProtectionHighlightsProps = {
  planDetails: PlanDetails;
};

const ProtectionHighlights: React.FC<ProtectionHighlightsProps> = ({
  planDetails: { protectionHighlights },
}) => (
  <TabContent>
    {protectionHighlights.map((protection, i) => (
      <DetailRow
        leftIcon={protection.iconSrc}
        leftIconAltText={protection.text}
        primaryText={protection.text}
        key={`protection_${i}`}
      />
    ))}
  </TabContent>
);

export default ProtectionHighlights;
