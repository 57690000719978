/* eslint-disable */
import { LocationDescriptor } from 'history';
import React from 'react';
import { Link } from 'react-router-dom';
import { withAnalyticOnEvent } from 'react-shisell';
import styled from 'styled-components';

export type DynamicLinkProps = {
  to?: LocationDescriptor;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  className?: string;
  target?: string;
  tabIndex?: number;
  actionId?: string;
  ariaLabel?: string;
};

const Span = styled.span`
  font-size: inherit;
`;

const Anchor = styled.a`
  cursor: pointer;
  font-size: inherit;
`;

const Button = styled.button`
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  border: none;
  padding: 0;
  background: none;
  color: currentColor;
`;

/**
 * A link that will become either:
 *
 *  - an anchor link, if an external URL is received
 *  - a react-router Link, if an internal URL is received
 *  - an unstyled button if no URL is received, but an onClick handler is
 *  - or a span if none of the above apply.
 *
 * All of these elements should look the same, aside from the standard
 * underlining and coloring of links, so they can be used interchangeably.
 */
const DynamicLink: React.FC<DynamicLinkProps> = (props) => {
  const { to } = props;

  let url: string | undefined = undefined;
  if (to) {
    url = typeof to === 'string' ? to : `${to.pathname}{to.search}`;
  }

  const isNotClickable = !url && !props.onClick;
  const isExternal =
    url &&
    (Boolean(url.match(/^http(?:s?):\/\//)) || Boolean(url.match(/^tel:/)));
  const baseProps = {
    onClick: props.onClick,
    className: props.className,
    children: props.children,
    'data-test-id': props.actionId,
  };

  if (isNotClickable) {
    return (
      <Span className={props.className} data-test-id={props.actionId}>
        {props.children}
      </Span>
    );
  }

  if (!to) {
    return <Button {...baseProps} aria-label={props.ariaLabel} />;
  }

  return isExternal ? (
    <Anchor
      {...baseProps}
      href={url}
      target={props.target}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </Anchor>
  ) : (
    <Anchor
      as={Link}
      {...baseProps}
      to={to}
      target={props.target}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </Anchor>
  );
};

const EnhancedDynamicLink = withAnalyticOnEvent({
  eventName: 'onClick',
  analyticName: 'Click',
})(DynamicLink);

const DynamicLinkWithAnalytics: React.FC<DynamicLinkProps> = (props: any) => (
  <EnhancedDynamicLink
    analyticsExtras={{ ActionId: props.actionId }}
    {...props}
  >
    {props.children}
  </EnhancedDynamicLink>
);

export default DynamicLinkWithAnalytics;
