import * as React from 'react';
import styled from 'styled-components';
import { ThemeStatus } from '@asurion-hub/partner-theme';
import {
  getStatusColor,
  getStatusTextColor,
  getStatusOutline,
  pxToEm,
} from '../utils';
import CloseButton from './CloseButton';
import { noop } from '@asurion-hub-web/utils';
import Breakpoints from '../Breakpoints';

export type ToastProps = {
  status?: ThemeStatus;
  onClose?: () => void;
  isOpen?: boolean;
};

const ToastContainer = styled.div<{ status: ThemeStatus }>`
  background-color: ${getStatusColor};
  color: ${getStatusTextColor};
  outline: ${getStatusOutline};
  padding: ${pxToEm(12)} ${pxToEm(16)};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;

  @media ${Breakpoints.desktop} {
    flex-basis: auto;
    justify-content: flex-start;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${pxToEm(20)};
`;

const Toast: React.FC<ToastProps> = (props) => {
  const {
    status = '' as ThemeStatus,
    children,
    onClose = noop,
    isOpen = true,
  } = props;

  return isOpen ? (
    <ToastContainer status={status}>
      <Content>{children}</Content>
      <CloseButton onClick={onClose} />
    </ToastContainer>
  ) : null;
};

export default Toast;
