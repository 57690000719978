import { AuthenticatedUser } from './AuthenticatedUser';
import {
  SupportedPersona,
  Partner,
  supportedPartners,
} from '@asurion-hub/partner-config';

import { Persona, TokenState } from './types';

export const getSupportedPersonas = (partner: Partner): Persona[] => {
  const user = AuthenticatedUser.get();
  const currentPartnerConfig = supportedPartners[partner];
  let supportedPersonas =
    user?.personas?.filter((persona) =>
      currentPartnerConfig.supportedPersonas.includes(
        persona.sourcePlatform as SupportedPersona
      )
    ) ?? [];

  // this logic filers out horizon personas with client channel IDs that we don't yet support
  supportedPersonas = supportedPersonas.filter(
    (p) =>
      p.sourcePlatform !== SupportedPersona.HORIZON.toString() ||
      p.externalId.startsWith(currentPartnerConfig.clientChannelId)
  );

  return supportedPersonas;
};

export const doesUserHaveSupportedPersonas = (partner: Partner): boolean => {
  return getSupportedPersonas(partner).length > 0;
};

export const getTokenState = (): TokenState => {
  const now = Date.now();
  const user = AuthenticatedUser.get();
  if (user) {
    const expMicrotime = user.exp * 1000;
    const isExpired = now - expMicrotime >= 0;
    return isExpired ? 'expired' : 'valid';
  }
  return 'empty';
};
