import * as React from 'react';
import { DetailsTabProps } from '../../../../types';
import {
  SectionHeader,
  TabPanelContainer,
  Benefits,
  DeviceDetails,
  ProtectionHighlights,
  PlanDetails,
} from './tab-content';

const DetailsTab: React.FC<DetailsTabProps> = ({
  deviceFriendlyName,
  planDetails,
  deviceDetails,
  onInfoNotCorrect,
  onLogout,
  benefitsCardsEnabled,
}) => {
  return (
    <TabPanelContainer data-test-id="device-details-page">
      <Benefits
        deviceFriendlyName={deviceFriendlyName}
        cardsEnabled={benefitsCardsEnabled}
        benefits={planDetails.benefitHighlights}
      />
      <ProtectionHighlights planDetails={planDetails} />
      {deviceDetails && (
        <>
          <SectionHeader>Device details</SectionHeader>
          <PlanDetails planDetails={planDetails} />
          <DeviceDetails
            deviceDetails={deviceDetails}
            onInfoNotCorrect={onInfoNotCorrect}
            onLogout={onLogout}
          />
        </>
      )}
    </TabPanelContainer>
  );
};

export default DetailsTab;
