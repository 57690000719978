import { useEffect, useState } from 'react';
import { AuthenticatedUser } from '../AuthenticatedUser';
import { pluck, distinctUntilChanged } from 'rxjs/operators';

export function useAuthState() {
  const [authState, setAuthState] = useState(AuthenticatedUser.State);

  useEffect(() => {
    const subscription = AuthenticatedUser.updated$
      .pipe(pluck('state'), distinctUntilChanged())
      .subscribe((authState) => {
        setAuthState(authState);
      });
    return () => subscription.unsubscribe();
  }, []);

  return authState;
}
