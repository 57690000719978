import { pxToEm } from '../utils';
import styled from 'styled-components';

export default styled.div`
  font-size: ${pxToEm(20)};
  margin-top: ${pxToEm(15)};
  margin-bottom: ${pxToEm(15)};
  padding: 0 0 ${pxToEm(15)} ${pxToEm(15)};
  border-bottom: 1px solid #e6e6eb;
`;
