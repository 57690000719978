import { Context, ITweekClient } from 'tweek-client';
import { ITweekStore, TweekRepository } from 'tweek-local-cache';
import exponentRefreshErrorPolicy from './exponentRefreshErrorPolicy';

export type CreateTweekRepositoryConfig = {
  client: ITweekClient;
  context: Context;
  store?: ITweekStore;
};

const createTweekRepository = ({
  client,
  context,
  store,
}: CreateTweekRepositoryConfig) => {
  const tweekRepository = new TweekRepository({
    client,
    refreshErrorPolicy: exponentRefreshErrorPolicy(300, 5),
    context,
  });

  if (store) {
    void tweekRepository.useStore(store).then(() => tweekRepository.expire());
  }

  return tweekRepository;
};

export default createTweekRepository;
