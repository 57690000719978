import moment from 'moment';
import { PartnerTheme as Theme, ThemeStatus } from '@asurion-hub/partner-theme';

export const pxToEm = (px: number | string) =>
  `${(typeof px === 'string' ? parseInt(px) : px) / 16}em`;

export const formatLongDate = (aDate: string): string =>
  moment(aDate).format('LL');

export const formatPhone = (phoneNumber: string) => {
  const phoneSegments =
    phoneNumber && /^(\d{3})(\d{3})(\d{4})$/.exec(phoneNumber);
  return phoneSegments
    ? `(${phoneSegments[1]}) ${phoneSegments[2]}-${phoneSegments[3]}`
    : phoneNumber;
};

export const getStatusColor = ({
  status,
  theme,
}: {
  status?: ThemeStatus;
  theme: Theme;
}) => (status ? theme.colors.status[status] : theme.colors.primary);

export const getStatusTextColor = ({
  status,
  theme,
}: {
  status?: ThemeStatus;
  theme: Theme;
}) =>
  status === 'warn' ||
  status === 'success' ||
  status === 'light' ||
  status === 'outline'
    ? theme.colors.primary
    : '#fff';

export const getStatusOutline = ({
  status,
}: {
  status?: ThemeStatus;
  theme: Theme;
}) => (status === 'outline' ? 'solid 1px #000' : 'none');
