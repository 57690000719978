import * as React from 'react';
import styled, { css } from 'styled-components';
import { pxToEm } from '../utils';
import checkmark from '../asset/timeline-checkmark.svg';

export type TimelineEntry = {
  heading: string;
  content: React.ReactNode;
};

export type TimelineProps = {
  entries: Array<TimelineEntry>;

  /**
   * Leaving this undefined will result in no active step and no steps checked.
   * For all steps to be checked, use a number greater than the total number of
   * entries (Infinity works fine if you want to be really defensive).
   */
  currentEntryIndex?: number;
};

const gutterPx = 36;
const highlightColor = '#F3E9FB';
const checkedBackgroundColor = '#000';

const TimelineList = styled.ol`
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
  margin-left: 36px;
  :before {
    content: '';
    position: absolute;
    top: 17px;
    left: 0;
    bottom: 3px;
    width: 1px;
    background: #000;
    z-index: 1;
  }
`;

const entryBadge = css`
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -${gutterPx}px;
  margin: auto;
  transform: translateX(-50%);
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 99999px;
`;

const TimelineEntryComponent = styled.li<{ isCurrent: boolean }>`
  position: relative;
  padding-left: ${gutterPx + 0.5}px;
  padding-top: ${pxToEm(13)};
  padding-bottom: ${pxToEm(22)};
  padding-right: 50px;
  background: ${({ isCurrent }) =>
    isCurrent ? highlightColor : 'transparent'};
  :before {
    content: '';
    position: absolute;
    background: ${({ isCurrent }) =>
      isCurrent ? highlightColor : 'transparent'};
    top: 0;
    bottom: 0;
    left: -36px;
    right: 0;
    z-index: -1;
  }
`;

const EntryHeading = styled.div<{ isCurrent: boolean; isDone: boolean }>`
  font-family: Apercu-Bold-Pro;
  font-size: ${pxToEm(16)};
  line-height: 1.5;
  position: relative;
  padding: 5px 0;
  :after {
    ${entryBadge};
    background: ${({ isCurrent }) => (isCurrent ? highlightColor : '#fff')};
    width: 24px;
    height: 24px;
    z-index: 1;
  }
  :before {
    ${entryBadge};
    border: ${({ isDone }) => (isDone ? 'none' : '1px solid #000')};
    background-color: ${({ isDone }) =>
      isDone ? checkedBackgroundColor : 'transparent'};
    background-image: ${({ isDone }) =>
      isDone ? `url(${checkmark})` : 'none'};
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
  }
`;

const EntryContent = styled.div`
  line-height: 1.5;
  font-size: ${pxToEm(16)};
`;

export const Timeline: React.FC<TimelineProps> = (props) => {
  const { entries, currentEntryIndex } = props;
  return (
    <TimelineList>
      {entries.map(({ heading, content }, i) => {
        const isCurrent = currentEntryIndex === i;
        const isDone = currentEntryIndex ? currentEntryIndex > i : false;
        return (
          <TimelineEntryComponent key={i} isCurrent={isCurrent}>
            <EntryHeading isCurrent={isCurrent} isDone={isDone}>
              {heading}
            </EntryHeading>
            <EntryContent>{content}</EntryContent>
          </TimelineEntryComponent>
        );
      })}
    </TimelineList>
  );
};
