import { selector, useRecoilValue } from 'recoil';
import { Device } from '@asurion-hub-web/devices-base';
import { devicesByIdState } from './devicesByIdState';
import { deviceIdSelectedState } from './deviceIdSelectedState';

export const deviceSelectedState = selector<Device | undefined>({
  key: 'deviceSelectedState',
  get: ({ get }) => get(devicesByIdState(get(deviceIdSelectedState))),
});

export const useDeviceSelectedState = () => useRecoilValue(deviceSelectedState);
