import React, { useState } from 'react';
import styled from 'styled-components';
import { pxToEm } from '@asurion-hub-web/ui';
import { MobileDeviceImage } from '@asurion-hub-web/user-plan-devices';

const ImageSection = styled.div`
  box-sizing: border-box;
  padding: ${pxToEm(28)};
  flex-basis: 47%;
  align-self: center;
`;

type ReplacementDeviceSectionProps = {
  deviceModel?: string;
  deviceImageUrl?: string;
  alwaysShow?: boolean;
};

const ReplacementDeviceSection: React.FC<ReplacementDeviceSectionProps> = ({
  deviceModel,
  deviceImageUrl,
  alwaysShow,
}) => {
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const onError = () => {
    setShouldDisplay(false);
  };
  const canShowDevice =
    alwaysShow || (shouldDisplay && !!(deviceModel || deviceImageUrl));

  return canShowDevice ? (
    <ImageSection>
      <MobileDeviceImage
        model={deviceModel}
        responsiveImageUrl={deviceImageUrl}
        alt="Replacement device"
        onError={onError}
      />
    </ImageSection>
  ) : null;
};

export default ReplacementDeviceSection;
