import { useEffect, useState } from 'react';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { AccountProfileService } from '../AccountProfileService';
import { AccountProfile } from '../types';

export const emptyAccountProfile: AccountProfile = {
  id: '',
  data: {},
  createdAt: '',
  updatedAt: '',
};

export const useAccountProfile = () => {
  const [accountProfile, setAccountProfile] = useState(
    AccountProfileService.get().accountProfile
  );

  useEffect(() => {
    const subscription = AccountProfileService.updated$
      .pipe(pluck('accountProfile'), distinctUntilChanged())
      .subscribe(setAccountProfile);

    return () => subscription.unsubscribe();
  }, []);

  return accountProfile || emptyAccountProfile;
};
