import magnifyingGlass from '@asurion-hub-web/assets/images/illustrations/magnifying_glass.svg';
import { SimpleLayout } from '@asurion-hub-web/ui';
import React from 'react';
import { enrichAnalytics } from 'react-shisell';
import styled from 'styled-components';

const Image = styled.img`
  margin: 0 auto auto auto;
  max-height: 100%;
  max-width: 100%;
  @media ${SimpleLayout.HORIZONTAL_BREAKPOINT_MEDIA_QUERY} {
    margin: auto;
  }
`;

const NotFoundPage = () => {
  return (
    <>
      <SimpleLayout.Page pageName="NotFound" contained>
        <SimpleLayout.ContentContainer>
          <SimpleLayout.Heading>
            404. That page doesn’t exist or it has been moved.
          </SimpleLayout.Heading>
        </SimpleLayout.ContentContainer>
        <SimpleLayout.ImageContainer>
          <Image src={magnifyingGlass} alt="a magnifying glass" />
        </SimpleLayout.ImageContainer>
      </SimpleLayout.Page>
    </>
  );
};
const Enhanced = enrichAnalytics((dispatcher) =>
  dispatcher.withExtra('Path', window.location.pathname)
)(NotFoundPage);

export default Enhanced;
