/* eslint-disable */
type Breakpoints<T = string> = {
  desktopWide: T;
  desktopLarge: T;
  desktopBig: T;
  desktop: T;
  tablet: T;
  tabletBig: T;
  mobile: T;
  mobileSmall: T;
  mobileMedium: T;
  mobileBig: T;
};

export const BREAK_POINTS_DIMENSIONS_VALUES_IN_PX: Breakpoints<number> = {
  desktopWide: 1950,
  desktopLarge: 1440,
  desktopBig: 1280,
  desktop: 1024,
  tabletBig: 900,
  tablet: 768,
  mobileBig: 566,
  mobileMedium: 479,
  mobileSmall: 375,
  mobile: 0,
};

// @ts-ignore
const Breakpoints: Breakpoints = Object.entries(
  BREAK_POINTS_DIMENSIONS_VALUES_IN_PX
).reduce((a: any, [key, value]) => {
  a[key] = `(min-width: ${value}px)`;
  return a;
}, {});

export default Breakpoints;
