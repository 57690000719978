import { Claim } from '@soluto-private/account-api-client';

export const canBuildSurveyLink = (claim?: Claim) => !!claim?.claimId;

export const buildSurveyLink = (
  surveyLink: string,
  claim: Claim,
  recordingUrl: string | undefined
) => {
  return surveyLink
    .replaceAll('<partner>', 'VZW')
    .replaceAll('<claimId>', claim.claimId)
    .replaceAll(
      '<email>',
      claim.subscriberEmail && claim.subscriberEmail.length > 0
        ? claim.subscriberEmail
        : 'NULL'
    )
    .replaceAll(
      '<phoneNumber>',
      claim.subscriberContactPhone && claim.subscriberContactPhone.length > 0
        ? claim.subscriberContactPhone
        : 'NULL'
    )
    .replaceAll('<culture>', 'En')
    .replaceAll('<recordingUrl>', recordingUrl || 'NULL');
};
