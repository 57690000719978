/* eslint-disable */
import { Page } from '@asurion-hub-web/ui';
import { ArticleView } from '@soluto-private/content-discovery';
import React from 'react';
import { useParams } from 'react-router-dom';
import { enrichAnalytics } from 'react-shisell';
import { useHeaderConfigurationBasedOnContent } from './useHeaderConfigurationBasedOnContent';
interface Props {
  articleId: string;
  backText?: string;
  backPath?: string;
}

const PureContentContainerPage: React.FC<Props> = ({ articleId }) => (
  <Page pageName={'ContentArticle'} testid={`content-article-${articleId}`}>
    <ArticleView
      articleId={articleId}
      withCSSReset={false}
      source="hub_discovery"
    />
  </Page>
);

const EnhancedContentContainerPage = enrichAnalytics(
  (dispatcher, props: Props) =>
    dispatcher.withExtra('ContentArticleId', props.articleId)
)(PureContentContainerPage);

const ContentContainerPageWrapper = () => {
  const { articleId } = useParams<{ articleId?: string }>();
  useHeaderConfigurationBasedOnContent();

  return <EnhancedContentContainerPage articleId={articleId!} />;
};

export default ContentContainerPageWrapper;
