import {
  getReplacementFriendlyName,
  getReplacementImageDevice,
  isClaimComplete,
} from '@asurion-hub/enrollment';
import { SHORT_DATE_FORMAT } from '@asurion-hub-web/config';
import { Claim } from '@soluto-private/account-api-client';
import React from 'react';
import moment from 'moment';
import { ClaimDetailsPageSharedProps } from '../types';
import { constuctFees, formatAddress } from '../utils';
import { useTweekValue } from 'react-tweek';
import { ClaimDetailsRepairPage } from './ClaimDetailsRepairPage';

const getAccordionProps = (
  claimDetails: Claim,
  phoneClaimLink: string,
  helpNumber: string
) => {
  const repairDetails = {
    scheduledDate: claimDetails.fulfillmentInfo?.scheduledDate
      ? moment(claimDetails.fulfillmentInfo.scheduledDate).format(
          SHORT_DATE_FORMAT
        )
      : '',
    repairAddress: claimDetails.fulfillmentInfo?.address
      ? formatAddress(claimDetails.fulfillmentInfo.address)
      : [],
    helpNumber: helpNumber,
    phoneclaimLink: phoneClaimLink,
  };
  const paymentDetails = constuctFees(claimDetails);
  const helpDetails = {
    helpNumber: helpNumber,
  };
  return { repairDetails, paymentDetails, helpDetails };
};

export const ClaimDetailsRepairContainer: React.FC<ClaimDetailsPageSharedProps> = (
  props
) => {
  const phoneClaimLink = useTweekValue<string>(
    'asurion_hub/claims/claim_url_vzw',
    ''
  );
  const helpNumber = useTweekValue<string>(
    'asurion_hub/claims/claim_help_number',
    ''
  );

  return (
    <ClaimDetailsRepairPage
      {...props}
      fulfillmentDevice={getReplacementFriendlyName(props.claim)}
      replacementDeviceImageUrl={getReplacementImageDevice(
        props.currentPlan,
        props.claim
      )}
      isLoading={props.isLoading}
      isClaimComplete={isClaimComplete(props.claim)}
      planIncludesSupport={props.planIncludesSupport}
      showSurveyCta={props.showSurveyCta}
      surveyCTA={props.surveyCTA}
      billingSummary={{ fees: props.claim.fees }}
      notification={props.notification}
      setNotification={props.setNotification}
      cta={props.cta}
      accordionProps={getAccordionProps(
        props.claim,
        phoneClaimLink,
        helpNumber
      )}
    />
  );
};
