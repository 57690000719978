import { useEffect } from 'react';
import { AccountProfileService } from '../AccountProfileService';

export const useInitAccountProfile = (preloadAvatar?: boolean) => {
  useEffect(() => {
    const subscription = AccountProfileService.init(preloadAvatar);

    return () => subscription.unsubscribe();
  }, [preloadAvatar]);
};
