import React from 'react';
import styled from 'styled-components';

import defaultIcon from '@asurion-hub-web/assets/images/icons/avatar.svg';
import { pxToEm } from '../utils';

type Size = 'small' | 'medium' | 'large';

type AvatarProps = {
  firstName?: string;
  lastName?: string;
  avatar?: string;
  size?: Size;
  hasBorder?: boolean;
};

const AvatarContainer = styled.div<{
  size: string;
  initials: string;
  avatar?: string;
  hasBorder: boolean;
}>`
  height: ${(props) => props.size || '64px'};
  width: ${(props) => props.size || '64px'};
  background-color: ${(props) => (props.initials ? '#F0F0F5' : '#fff')};
  border-radius: 50%;
  border: ${({ size, avatar, hasBorder }) =>
    size === '128px'
      ? avatar || !hasBorder
        ? 'none'
        : '2px solid  #000'
      : !hasBorder
        ? 'none'
        : '1px solid #000'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.avatar});
  background-size: cover;
`;

const UserInitials = styled.p<{
  fontSize: string;
}>`
  font-size: ${(props) => pxToEm(props.fontSize || '24px')};
  font-weight: ${(props) => (props.fontSize === '12px' ? '600' : 'normal')};
  text-align: center;
  padding-top: 5%;
`;

const UserIcon = styled.img`
  height: 75%;
`;

const sizes = {
  small: {
    avatarSize: '32px',
    fontSize: '12px',
  },
  medium: {
    avatarSize: '64px',
    fontSize: '24px',
  },
  large: {
    avatarSize: '128px',
    fontSize: '48px',
  },
};

const Avatar: React.FC<AvatarProps> = ({
  firstName,
  lastName,
  avatar,
  size = 'medium',
  hasBorder = true,
}: AvatarProps) => {
  let initials = '';
  if (firstName && lastName) {
    initials = firstName[0].toUpperCase() + lastName[0].toUpperCase();
  }

  return (
    <AvatarContainer
      size={sizes[size].avatarSize}
      initials={initials}
      avatar={avatar}
      className={avatar && 'fs-exclude'}
      hasBorder={hasBorder}
    >
      {!avatar && initials && <UserInitials fontSize={sizes[size].fontSize}>{initials}</UserInitials> }
      {!avatar && !initials && <UserIcon src={defaultIcon} alt="Default user icon" />}
    </AvatarContainer>
  );
};

export default Avatar;
