import { useQuery } from 'react-query';
import { useServicePlanCache } from '@asurion-hub/enrollment';

export const useServicePlan = (id?: string) => {
  const cache = useServicePlanCache();

  const { error, data, isFetching } = useQuery(
    `servicePlan-${id || ''}`,
    () => cache.get(id || ''),
    {
      refetchOnMount: false,
      enabled: !!id,
    }
  );

  return {
    servicePlanError: error,
    servicePlanIsFetching: isFetching,
    servicePlan: data,
  };
};
