import * as React from 'react';
import {
  Breakpoints,
  Page,
  LoadingOverlay,
  NotificationBanner,
} from '@asurion-hub-web/ui';
import styled from 'styled-components';
import {
  BillingSummary,
  HaveQuestions,
  IntroSection,
  ShippingInfo,
  WhatsNext,
  SurveyCTA,
  UpsellCTA,
} from './components';
import {
  BillingSummaryProps,
  ShippingInfoProps,
  ClaimDetailsProps,
  SurveyCTAProps,
} from './types';
import { PageNotification } from '@asurion-hub-web/notifications';
import { enrichAnalytics } from 'react-shisell';

type ClaimDetailsPageProps = {
  onStartChatClick: React.MouseEventHandler<HTMLButtonElement>;
  isClaimComplete: boolean;
  isLoading: boolean;
  shippingInfo: ShippingInfoProps;
  claimDetails: ClaimDetailsProps;
  billingSummary: BillingSummaryProps;
  surveyCTA: SurveyCTAProps;
  showSurveyCta: boolean | undefined;
  notification: PageNotification | undefined;
  setNotification: (notification?: PageNotification) => void;
  planIncludesSupport: boolean;
  cta: JSX.Element | null;
};

const breakpoint = Breakpoints.tablet;

const PageContainer = styled.div`
  max-width: 844px;
  display: contents;
  align-content: center;
`;

const ColumnContainer = styled.div`
  max-width: inherit;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media ${breakpoint} {
    flex-direction: row;
  }
`;

const UpsellContainer = styled.div`
  max-width: inherit;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  @media ${breakpoint} {
    margin: 16px;
    flex-basis: 50%;
  }
`;

const MobileOnly = styled.div`
  @media ${breakpoint} {
    display: none;
  }
`;

const DesktopOnly = styled.div`
  display: none;
  @media ${breakpoint} {
    display: initial;
  }
`;

const ClaimDetailsPage: React.FC<ClaimDetailsPageProps> = (props) => {
  const haveQuestionsSection = (
    <HaveQuestions onStartChatClick={props.onStartChatClick} />
  );

  const onNotificationClose = () =>
    props.setNotification(
      props.notification
        ? { ...props.notification, isVisible: false }
        : undefined
    );

  const { cta } = props;

  return (
    <PageContainer>
      <NotificationBanner
        notificationBannerId="ClaimsDetailsErrorBanner"
        notificationReason={props.notification?.reason ?? 'unknown'}
        isOpen={!!props.notification?.isVisible}
        status={props.notification?.status}
        onClose={onNotificationClose}
      >
        {props.notification?.message}
      </NotificationBanner>
      <Page
        pageName="ClaimDetails"
        css="max-width: inherit;display: inherit;align-content: inherit;"
      >
        <ColumnContainer>
          <LoadingOverlay isVisible={props.isLoading} />
          <Column>
            <IntroSection
              isClaimComplete={props.isClaimComplete}
              email={props.shippingInfo.email}
              claimId={props.claimDetails.claimId}
              deviceImageUrl={props.claimDetails.replacementDeviceImageUrl}
              deviceModel={props.claimDetails.fulfillmentDevice!}
              planIncludesSupport={props.planIncludesSupport}
            />
            {props.planIncludesSupport && (
              <DesktopOnly>{haveQuestionsSection}</DesktopOnly>
            )}
          </Column>
          <Column>
            {cta}
            <WhatsNext
              {...props.claimDetails}
              isComplete={props.isClaimComplete}
            />
            {props.planIncludesSupport && (
              <MobileOnly>{haveQuestionsSection}</MobileOnly>
            )}
            <ShippingInfo {...props.shippingInfo} />
            {props.billingSummary.fees && (
              <BillingSummary {...props.billingSummary} />
            )}
            {props.showSurveyCta && <SurveyCTA {...props.surveyCTA} />}
          </Column>
        </ColumnContainer>
        <UpsellContainer>
          <UpsellCTA />
        </UpsellContainer>
      </Page>
    </PageContainer>
  );
};

const EnhancedClaimDetailsPage = enrichAnalytics<ClaimDetailsPageProps>(
  (dispatcher, props) =>
    dispatcher.withExtra('ClaimId', props.claimDetails.claimId)
)(ClaimDetailsPage);

export default EnhancedClaimDetailsPage;
