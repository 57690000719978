import React from 'react';
import {
  NotificationBanner,
  NotificationBannerProps,
} from '@asurion-hub-web/ui';
import { useNotifications } from './context';
import { Notification } from './Notification';
import { useAutoDismiss } from './useAutoDismiss';

type AutoDismissableAlertBannerProps = NotificationBannerProps &
  Pick<Notification, 'autoDismiss'>;
const AutoDismissableAlertBanner: React.FC<AutoDismissableAlertBannerProps> = ({
  autoDismiss,
  onClose = () => undefined,
  ...rest
}) => {
  useAutoDismiss({ autoDismiss, onClose });
  return <NotificationBanner onClose={onClose} {...rest} />;
};

export const AlertBannerNotifications: React.FC = () => {
  const { alertBanners } = useNotifications();
  return (
    <>
      {alertBanners.map((notification) => (
        <AutoDismissableAlertBanner
          key={`NotificationBanner-${notification.id}`}
          isOpen={true}
          status={notification.status}
          notificationBannerId={notification.id}
          notificationReason={notification.reason}
          autoDismiss={notification.autoDismiss}
          onClose={notification.onClose}
        >
          {notification.message}
        </AutoDismissableAlertBanner>
      ))}
    </>
  );
};
