import React from 'react';
import { Accordion, pxToEm } from '@asurion-hub-web/ui';
import styled from 'styled-components';
import repairDateAndTimeIcon from '@asurion-hub-web/assets/images/icons/repairDateAndTimeIcon.svg';
import locationIcon from '@asurion-hub-web/assets/images/icons/locationIcon.svg';
import claimDetailIcon from '@asurion-hub-web/assets/images/icons/claimDetailicon.svg';
import yourPhoneIcon from '@asurion-hub-web/assets/images/icons/yourPhoneIcon.svg';
import alternateContactIcon from '@asurion-hub-web/assets/images/icons/alternateContactIcon.svg';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 30em) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }
`;

const Boxes = styled.div`
  width: ${pxToEm(415)};
`;

const RepairIcon = styled.img`
  margin-right: ${pxToEm(10)};
  width: ${pxToEm(55)};
  float: left;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pxToEm(20)} ${pxToEm(20)} ${pxToEm(10)} ${pxToEm(20)};
`;

const HeaderText = styled.div`
  font-weight: bold;
  margin-bottom: ${pxToEm(10)};
  font-size: ${pxToEm(16)};
  width: ${pxToEm(300)};
`;

const ClaimText = styled(HeaderText)`
  margin-right: 70px;
`;

const TextContainer = styled.div`
  float: right;
  max-width: ${pxToEm(160)};
  margin-right: ${pxToEm(190)};
  margin-top: 12px;
  flex: 50%;
`;

const HeaderInfo = styled.div`
  margin-top: ${pxToEm(20)};
`;

const TotalContainer = styled.div`
  background-color: #f0f0f5;
`;

const Text = styled.div`
  margin-left: ${pxToEm(20)};
  margin-top: ${pxToEm(20)};
  margin-bottom: ${pxToEm(30)};
`;

const LongText = styled.div`
  width: 200px;
`;

type ReplacementDetailsSectionProps = {
  repairDayAndDate: string;
  repairTime: string;
  addressLineOne: string;
  addressLineTwo: string;
  claimFileDate: string;
  claimId: string;
  replacementDevice: string;
  phoneNumber: string;
  imeiNumber: string;
  alternateContactPhone: string;
  alternateContactEmail: string;
};

type PaymentSectionProps = {
  insuranceDeductibleFormatted: number;
  taxesFormatted: number;
  total: number;
};

export type SameDayAccordionProps = {
  replacementDetails: ReplacementDetailsSectionProps;
  paymentDetails: PaymentSectionProps;
};

const constructReplacementDetailsSection = (
  replacementDetails: ReplacementDetailsSectionProps
) => {
  return (
    <>
      <Container>
        <Boxes>
          <HeaderInfo>
            <RepairIcon alt="Date and Time Icon" src={repairDateAndTimeIcon} />
          </HeaderInfo>
          <TextContainer>
            <ClaimText>Date and time</ClaimText>
            <LongText>{replacementDetails.repairDayAndDate}</LongText>
            <div>{replacementDetails.repairTime}</div>
          </TextContainer>
        </Boxes>
        <Boxes>
          <HeaderInfo>
            <RepairIcon alt="Location Icon" src={locationIcon} />
          </HeaderInfo>
          <TextContainer>
            <HeaderText>Location</HeaderText>
            <div>{replacementDetails.addressLineOne}</div>
            <div>{replacementDetails.addressLineTwo}</div>
          </TextContainer>
        </Boxes>
        <Boxes>
          <HeaderInfo>
            <RepairIcon alt="Claim Details Icon" src={claimDetailIcon} />
          </HeaderInfo>
          <TextContainer>
            <HeaderText>Claim details</HeaderText>
            <LongText>Filed on {replacementDetails.claimFileDate}</LongText>
            <div>ID# {replacementDetails.claimId}</div>
          </TextContainer>
        </Boxes>
        <Boxes>
          <HeaderInfo>
            <RepairIcon alt="Your Phone Icon" src={yourPhoneIcon} />
          </HeaderInfo>
          <TextContainer>
            <HeaderText>Your phone</HeaderText>
            <LongText>{replacementDetails.replacementDevice}</LongText>
            <div>{replacementDetails.phoneNumber}</div>
            <div>IMEI#: {replacementDetails.imeiNumber}</div>
          </TextContainer>
        </Boxes>
        <Boxes>
          <HeaderInfo>
            <RepairIcon
              alt="Alternate Contact Icon"
              src={alternateContactIcon}
            />
          </HeaderInfo>
          <TextContainer>
            <HeaderText>Alternate contact information</HeaderText>
            <div>{replacementDetails.alternateContactPhone}</div>
            <div>{replacementDetails.alternateContactEmail}</div>
          </TextContainer>
        </Boxes>
      </Container>
      <Text>
        Upon completion of your lost or stolen claim, Verizon will deactivate
        your old device.
        <br></br>
        <br></br>
      </Text>
    </>
  );
};

const constructPaymentSection = (
  tax: number,
  total: number,
  insuranceDeductible: number
) => {
  return (
    <div>
      <LineItem>
        <div>Insurance deductible</div>
        <div>{insuranceDeductible ? insuranceDeductible : '---'}</div>
      </LineItem>
      <LineItem>
        <div>Shipping fee</div>
        <div>FREE</div>
      </LineItem>
      <LineItem>
        <div>Taxes</div>
        <div>{tax ? tax : '---'}</div>
      </LineItem>
      <TotalContainer>
        <LineItem>
          <div>Total</div>
          <div>{total ? total : '---'}</div>
        </LineItem>
        <LineItem></LineItem>
      </TotalContainer>
      <LineItem></LineItem>
    </div>
  );
};

const constructWarrantySection = () => {
  return (
    <div>
      <Text>
        Your replacement device comes with a 12-month warranty. For warranty
        issues contact Asurion at 888-562-8662.
        <br />
        <br />
        <br />
      </Text>
    </div>
  );
};

const SameDayAccordion: React.FC<SameDayAccordionProps> = ({
  replacementDetails,
  paymentDetails,
}) => {
  return (
    <Accordion
      sections={[
        {
          heading: 'Replacement Details',
          content: constructReplacementDetailsSection(replacementDetails),
          startOpen: true,
        },
        {
          heading: 'Payment Details',
          content: constructPaymentSection(
            paymentDetails.taxesFormatted,
            paymentDetails.total,
            paymentDetails.insuranceDeductibleFormatted
          ),
          startOpen: false,
        },
        {
          heading: 'Warranty',
          content: constructWarrantySection(),
          startOpen: false,
        },
      ]}
    />
  );
};

export default SameDayAccordion;
