import boxAlertIcon from './images/icons/box-alert.svg';
import boxCheckmarkIcon from './images/icons/box-checkmark.svg';
import brokenBoneIcon from './images/icons/broken-bone.svg';
import checklistIcon from './images/icons/checklist.svg';
import cpuBrokenIcon from './images/icons/cpu-broken.svg';
import deviceDamagedIcon from './images/icons/device-damaged.svg';
import hackerIcon from './images/icons/hacker.svg';
import lostIcon from './images/icons/lost.svg';
import policeOfficerIcon from './images/icons/police-officer.svg';
import questionMarkIcon from './images/icons/question-mark-circle.svg';
import xIcon from './images/icons/close.svg';

export const claimIconsMap: Record<string, string> = {
  boxAlertIcon: boxAlertIcon,
  boxCheckmarkIcon: boxCheckmarkIcon,
  brokenBoneIcon: brokenBoneIcon,
  checklistIcon: checklistIcon,
  cpuBrokenIcon: cpuBrokenIcon,
  deviceDamagedIcon: deviceDamagedIcon,
  hackerIcon: hackerIcon,
  lostIcon: lostIcon,
  policeOfficerIcon: policeOfficerIcon,
  questionMarkIcon: questionMarkIcon,
  xIcon: xIcon,
};
