import React from 'react';
import { CtaProps } from '../types';
import { default as BaseCta } from './_Base';

export const TextOnlyCta: React.FC<CtaProps> = ({
  id,
  color,
  headline,
  content,
  ctaVariant,
  fullWidth,
}) => (
  <BaseCta
    {...{
      id,
      color,
      headline,
      content,
      ctaVariant,
      fullWidth,
    }}
  ></BaseCta>
);
