import React, { useState } from 'react';
import styled from 'styled-components';
import { CloseButton, Button, Breakpoints, pxToEm } from '@asurion-hub-web/ui';
import { CtaProps } from '../types';
import { enrichAnalytics, withAnalyticOnView } from 'react-shisell';

const breakpoint = Breakpoints.tablet;

const Container = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor || '#fff'};
  border: ${pxToEm(1)} solid
    ${(props) =>
      props.backgroundColor !== '#FFFFFF' ? props.backgroundColor : '#000'};
  padding: ${pxToEm(20)};
  margin: ${pxToEm(30)} auto;
  max-width: ${pxToEm(340)};
  display: flex;
  position: relative;
  @media ${breakpoint} {
    width: ${pxToEm(340)};
  }
`;

const FullWidthContainer = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor || '#fff'};
  border: ${pxToEm(1)} solid
    ${(props) =>
      props.backgroundColor !== '#FFFFFF' ? props.backgroundColor : '#000'};
  padding: ${pxToEm(20)};
  margin: ${pxToEm(30)} auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media ${breakpoint} {
    flex-direction: row;
  }
`;

const FullWidthImage = styled.img`
  width: ${pxToEm(75)};
  margin: ${pxToEm(10)};
`;

const FullWidthContentContainer = styled.div`
  padding-left: ${pxToEm(16)};
  padding-right: ${pxToEm(16)};
  flex-grow: 2;
`;

const FullWidthContent = styled.div`
  font-size: ${pxToEm(16)};
  line-height: ${pxToEm(24)};
  margin: ${pxToEm(6)} 0;
`;
const CloseButtonContainer = styled.div`
  position: absolute;
  right: ${pxToEm(15)};
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Headline = styled.span`
  font-size: ${pxToEm(20)};
  font-family: Apercu-Bold-Pro;
  line-height: 1.5;
`;

const Content = styled.div`
  font-size: ${pxToEm(16)};
  line-height: ${pxToEm(24)};
  margin: ${pxToEm(6)} 0;
  width: 90%;
  max-width: ${pxToEm(300)};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${pxToEm(28)};
  align-items: center;
  position: relative;

  & > * {
    margin: ${pxToEm(6)};
  }
`;

const Image = styled.img<{ imageOrientation?: string }>`
  width: ${pxToEm(75)};
  margin: ${pxToEm(10)};
  ${(props) =>
    props.imageOrientation === 'right'
      ? `margin-right: ${pxToEm(-40)}`
      : `margin-left: ${pxToEm(-40)}`};
  ${(props) => (props.imageOrientation === 'right' ? 'order: 99' : 'order: 0')};
`;

const BaseCta: React.FC<CtaProps> = ({
  id,
  color,
  headline,
  content,
  buttonText,
  buttonDestination,
  buttonActionId,
  imageAltText,
  image,
  imageOrientation,
  children,
  fullWidth,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    if (buttonDestination) {
      // eslint-disable-next-line
      window.location.replace(buttonDestination);
    }
  };

  if (isOpen) {
    return fullWidth ? (
      <FullWidthContainer backgroundColor={color} id={id}>
        {children}
        {image && <FullWidthImage src={image} alt={imageAltText} />}
        <FullWidthContentContainer>
          <Headline>{headline}</Headline>
          <FullWidthContent>{content}</FullWidthContent>
        </FullWidthContentContainer>
        {buttonText && buttonActionId && (
          <Button onClick={handleClick} actionId={buttonActionId}>
            {buttonText}
          </Button>
        )}
      </FullWidthContainer>
    ) : (
      <Container backgroundColor={color} id={id}>
        {image && (
          <Image
            src={image}
            alt={imageAltText}
            imageOrientation={imageOrientation}
          />
        )}
        <ContentContainer>
          <Headline>{headline}</Headline>
          <Content>{content}</Content>
          <ButtonContainer>
            {buttonText && buttonActionId && (
              <Button onClick={handleClick} actionId={buttonActionId}>
                {buttonText}
              </Button>
            )}
            {children}
          </ButtonContainer>
        </ContentContainer>
        <CloseButtonContainer>
          <CloseButton onClick={handleClose} ariaLabel="close notification" />
        </CloseButtonContainer>
      </Container>
    );
  }
  return <></>;
};

const EnhancedCta = withAnalyticOnView<CtaProps>({
  analyticName: 'SectionView',
})(BaseCta);

export default enrichAnalytics<CtaProps>((dispatcher, { ctaVariant }) =>
  dispatcher
    .withExtra('Section', 'CTA_Dynamic')
    .withExtra('Experiment_CTA', ctaVariant)
)(EnhancedCta);
