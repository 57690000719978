import { DynamicLink, DynamicLinkProps, pxToEm } from '@asurion-hub-web/ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

type BenefitCardProps = {
  imageUrl: string;
  heading: string;
  url: string;
  backText: string;
};

const LinkArrow: React.FC = () => (
  <svg
    css={{ marginLeft: pxToEm(16) }}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M16.4107 11L12.5 7.41L13.91 6L19.91 12L13.91 18L12.5 16.59L16.4107 13H4.5V11H16.4107ZM17.5 11.9999V12.0001L17.5001 12L17.5 11.9999Z"
    />
  </svg>
);

const Card = styled(DynamicLink)`
  text-decoration: none;
  color: #000;
  display: block;
  cursor: pointer;
  max-width: ${pxToEm(200)};
  min-height: ${pxToEm(306)};
  border: 1px solid #a5aaaf;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s, transform 0.3s;
  :hover {
    transform: translateX(-3px) translateY(-3px);
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.5);
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 100%;
  margin-bottom: ${pxToEm(8)};
`;

const Heading = styled.h3`
  font-size: ${pxToEm(16)};
  text-align: center;
  margin: 0 ${pxToEm(14)};
  margin-bottom: ${pxToEm(10)};
  line-height: 1.5;
`;

const LinkStyled = styled.span`
  font-size: ${pxToEm(16)};
  line-height: 1.5;
  color: #8223d2;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: ${pxToEm(12)};
`;

const Link: React.FC<DynamicLinkProps> = ({ children, ...props }) => (
  <LinkStyled {...props}>
    {children}
    <LinkArrow />
  </LinkStyled>
);

const BenefitCard: React.FC<BenefitCardProps> = (props) => {
  const history = useHistory();
  const onClick = () => history.push(props.url, { backText: props.backText });

  return (
    <Card
      onClick={onClick}
      actionId={`BenefitCard_${props.heading.replace(' ', '_')}`}
    >
      <Image src={props.imageUrl} alt={props.heading} />
      <Heading>{props.heading}</Heading>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link>More info</Link>
    </Card>
  );
};

export default BenefitCard;
