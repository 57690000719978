export enum Partner {
  Verizon = 'verizon',
  Cricket = 'cricket',
}

export enum SupportedPersona {
  DIGITAL = 'DIGITAL_PRODUCTS',
  LEGACY = 'LEGACY-CLAIMS',
  HORIZON = 'HORIZON',
}

export type PartnerConfig = {
  id: Partner;
  clientChannelId: string;
  name: string;
  supportedPersonas: SupportedPersona[];
};
