import { DynamicLink, DynamicLinkProps } from '@asurion-hub-web/ui';

import { ComponentType } from 'react';
import { ContentRenderer, Hyperlink } from '../types';

const hyperlink: ContentRenderer<DynamicLinkProps, Hyperlink> = {
  Component: DynamicLink as ComponentType<DynamicLinkProps>,
  convertProps: ({ fields: { href, text, ...dynamicLinkProps } }) => ({
    ...dynamicLinkProps,
    to: href,
    children: text,
  }),
};

export default {
  hyperlink,
};
