/* eslint-disable */
import * as React from 'react';
import { enrichAnalytics, withAnalyticOnView } from 'react-shisell';

type PageProps = React.HTMLAttributes<HTMLDivElement> & {
  pageName: string;
  className?: string;
  testid?: string;
  mapPropsToExtras?: (props: any) => object;
  as?: keyof React.ReactHTML;
};

const Page: React.FC<PageProps> = ({
  as = 'div',
  children,
  className,
  testid,
}) => {
  const Element = as;
  return (
    <Element className={className} id="main-content" data-testid={testid}>
      {children}
    </Element>
  );
};

const EnhancedPage = withAnalyticOnView<PageProps>({
  analyticName: 'PageView',
  mapPropsToExtras: ({ mapPropsToExtras, ...props }) =>
    mapPropsToExtras ? mapPropsToExtras(props) : {},
})(Page);

export default enrichAnalytics<PageProps>((dispatcher, { pageName }) =>
  dispatcher.withExtra('Page', pageName)
)(EnhancedPage);
