import { Breakpoints, pxToEm } from '@asurion-hub-web/ui';
import * as React from 'react';
import styled from 'styled-components';
import { BenefitHighlight } from '@asurion-hub/enrollment';
import BenefitCard from './BenefitCard';
import { RouteBuilder } from '@asurion-hub-web/config';

type BenefitsCardsProps = {
  deviceFriendlyName: string;
  benefits: BenefitHighlight[];
};

const OuterContainer = styled.div`
  position: relative;
  :before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${pxToEm(16)};
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: ${pxToEm(16)};
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }
  @media ${Breakpoints.tablet} {
    :before {
      display: none;
    }
    :after {
      display: none;
    }
  }
`;

const Container = styled.div`
  overflow-y: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  display: flex;
  padding: ${pxToEm(16)};
  box-sizing: border-box;
  :before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: ${pxToEm(16)};
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  @media ${Breakpoints.tablet} {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -${pxToEm(16)};
    :before {
      display: none;
    }
    :after {
      display: none;
    }
  }
`;

const CardContainer = styled.div`
  position: relative;
  margin-left: ${pxToEm(16)};
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  :first-child {
    margin-left: 0;
  }
  :last-child {
    padding-right: ${pxToEm(16)};
  }
  @media ${Breakpoints.tablet} {
    flex-shrink: 1;
    width: 50%;
    margin-left: 0;
    margin-bottom: ${pxToEm(16)};
    box-sizing: border-box;
    :nth-child(odd) {
      justify-content: flex-start;
      padding-right: ${pxToEm(8)};
    }
    :nth-child(even) {
      justify-content: flex-end;
      padding-left: ${pxToEm(8)};
    }
    :last-child {
      padding-right: 0;
    }
  }
`;

const BenefitsCards: React.FC<BenefitsCardsProps> = ({
  benefits,
  deviceFriendlyName,
}) => {
  return (
    <OuterContainer>
      <Container>
        {benefits.map(({ text, articleId, image }, i) => (
          <CardContainer key={i}>
            <BenefitCard
              backText={deviceFriendlyName}
              imageUrl={image?.fields.file.url || ''}
              heading={text}
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              url={RouteBuilder.routeForArticle(articleId!)}
            />
          </CardContainer>
        ))}
      </Container>
    </OuterContainer>
  );
};

export default BenefitsCards;
