import { LocationDescriptor } from 'history';
import * as React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import styled from 'styled-components';
import { PartnerTheme as Theme, ThemeStatus } from '@asurion-hub/partner-theme';
import { getStatusColor, getStatusTextColor, pxToEm } from '../utils';
import { DynamicLink } from '.';

type ThemedStatusProps = {
  theme: Theme;
  status?: ThemeStatus;
};

type TextInputProps = {
  status?: ThemeStatus;

  /**
   * Informational text to show, often to assist with an error.
   */
  infoText?: string;

  /**
   * An informational link. Will only display if infoLinkUrl or infoLinkOnClick is defined.
   */
  infoLinkText?: string;
  infoLinkOnClick?: React.MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement
  >;
  infoLinkUrl?: LocationDescriptor;

  /**
   * Styles to be passed to the inner component.
   */
  innerStyle?: React.CSSProperties;

  /**
   * See react-text-mask documentation.
   */
  maskConfig?: MaskedInputProps;

  /**
   * Whether to use the shadowed style.
   */
  shadowed?: boolean;

  className?: string;
};

const Input = styled(MaskedInput)<{
  status?: ThemeStatus;
  $shadowed?: boolean;
}>`
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: ${pxToEm(16)};
  line-height: 1.4;
  padding-top: ${pxToEm(14)};
  padding-left: ${pxToEm(12)};
  padding-right: ${pxToEm(12)};
  padding-bottom: ${pxToEm(8)};
  font-family: Apercu-Regular-Pro;
  transition: border-color 0.3s, background-color 0.3s;
  border-width: 1px;
  border-style: solid;
  border-color: ${getStatusColor};
  box-shadow: ${({ $shadowed: shadowed }) =>
    shadowed ? '3px 3px 0 rgba(0,0,0,.25)' : 'none'};
`;

const InputWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
`;

const InputFooter = styled.span<
  ThemedStatusProps & { isVisible?: boolean; heightPx: number }
>`
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-size: ${pxToEm(14)};
  color: #fff;
  background-color: ${getStatusColor};
  transition: height 0.3s, margin-bottom 0.3s, padding 0.3s,
    background-color 0.3s;
  padding: ${({ isVisible }) => (isVisible ? pxToEm(4) : 0)} ${pxToEm(4)};
  height: ${({ isVisible, heightPx }) => (isVisible ? `${heightPx}px` : 0)};
  margin-bottom: ${({ isVisible }) => (isVisible ? 0 : pxToEm(21))};
`;

const InputFooterInner = styled.span`
  display: block;
  padding: 0;
`;

const StatusText = styled.span<{ status?: ThemeStatus; isVisible?: boolean }>`
  color: ${getStatusTextColor};
  margin-right: auto;
  width: 100%;
  transition: opacity 0.3s, color 0.3s;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const InfoLink = styled(DynamicLink)<{
  status?: ThemeStatus;
  isVisible?: boolean;
}>`
  color: ${getStatusTextColor};
  text-decoration: underline;
  transition: opacity 0.3s, color 0.3s;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const TextField = React.forwardRef<
  MaskedInput,
  TextInputProps & React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const {
    status,
    infoText,
    infoLinkUrl,
    infoLinkText,
    infoLinkOnClick,
    maskConfig,
    shadowed,
    ...restProps
  } = props;

  const hasInfoLink = Boolean((infoLinkUrl || infoLinkOnClick) && infoLinkText);
  const isFooterVisible = Boolean(infoText || hasInfoLink);
  const footerRef = React.useRef<HTMLSpanElement>(null);
  const [footerHeightPx, setFooterHeightPx] = React.useState(0);

  React.useEffect(() => {
    if (infoText && footerRef && footerRef.current) {
      setFooterHeightPx(footerRef.current.clientHeight);
    }
  }, [infoText, footerRef]);

  return (
    <InputWrapper className={props.className}>
      <Input
        {...maskConfig}
        {...restProps}
        $shadowed={shadowed}
        ref={ref}
        status={status}
      />
      <InputFooter
        heightPx={footerHeightPx}
        status={status}
        isVisible={isFooterVisible}
      >
        <InputFooterInner ref={footerRef}>
          <StatusText status={status} isVisible={isFooterVisible}>
            {infoText}
          </StatusText>
          {hasInfoLink && (
            <InfoLink
              status={status}
              isVisible={isFooterVisible}
              to={infoLinkUrl}
              onClick={infoLinkOnClick}
              ariaLabel="view more info"
            >
              {infoLinkText}
            </InfoLink>
          )}
        </InputFooterInner>
      </InputFooter>
    </InputWrapper>
  );
});

export default TextField;
