import { Claim, Plan } from '../../types';
import { getResponsiveImageUrlFromAssetAttributes } from './getResponsiveImageUrlFromAssetAttributes';

export const getReplacementImageDevice = (
  currentPlan: Plan | undefined,
  claim: Claim
) => {
  const originalMake = claim.originalDevice?.make,
    originalModel = claim.originalDevice?.model;

  const replacementMake = claim.fulfillmentInfo?.replacementDevice?.make,
    replacementModel = claim.fulfillmentInfo?.replacementDevice?.model;

  const allVariablesAccountedFor = [
    originalMake,
    originalModel,
    replacementMake,
    replacementModel,
  ].some(Boolean);

  const replacementIsSameDevice =
    allVariablesAccountedFor &&
    originalMake === replacementMake &&
    originalModel === replacementModel;

  return replacementIsSameDevice
    ? getResponsiveImageUrlFromAssetAttributes(
        currentPlan?.assets[0].assetCatalogAttributes
      )
    : undefined;
};
