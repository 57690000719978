import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import LoadingSpinner, { LoadingSpinnerProps } from './LoadingSpinner';
import { Fade } from './transitions';

const LoadingOverlayStyled = styled(Fade)<{ isFullPage: boolean }>`
  position: ${(props) => (props.isFullPage ? 'fixed' : 'absolute')};
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LoadingOverlayProps = {
  isVisible?: boolean;
  isFullPage?: boolean;
  spinnerProps?: LoadingSpinnerProps;
};

export const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
  const { spinnerProps = {}, isFullPage } = props;
  const rendered = (
    <LoadingOverlayStyled
      in={Boolean(props.isVisible)}
      timeout={200}
      isFullPage={props.isFullPage || false}
    >
      <LoadingSpinner {...spinnerProps} />
    </LoadingOverlayStyled>
  );

  return isFullPage ? ReactDOM.createPortal(rendered, document.body) : rendered;
};

export default LoadingOverlay;
