import { withNotifications } from '@asurion-hub-web/notifications';
import { useInitAccountProfile } from '@asurion-hub/account-profile';
import { ContentfulProvider } from '@asurion-hub/contentful';
import { getTweekRepository } from '@asurion-hub/tweek';
import { ThemeProvider } from '@asurion-hub-web/partner-theme-react';
import {
  LoadingPage,
  ResponsiveIndicator,
  CatchAllErrorPage,
} from '@asurion-hub-web/ui';
import { flow } from 'fp-ts/lib/function';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { enrichAnalytics, withAnalyticOnView } from 'react-shisell';
import { TweekProvider, useTweekValue } from 'react-tweek';
import { RecoilRoot } from 'recoil';
import Routes from './Routes';
import { AnywhereExpertSdk } from '@asurion-hub-web/anywhere-expert-sdk';
import { useSessionTimeout } from './hoc/sessionTimeoutHook';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  initPromise: Promise<void>;
}
const withProviders = (AppComp: React.FC<Props>) => (props: Props) => (
  <RecoilRoot>
    <TweekProvider value={getTweekRepository()}>
      <ContentfulProvider Loader={LoadingPage}>
        <ThemeProvider>
          <AppComp {...props} />
        </ThemeProvider>
      </ContentfulProvider>
    </TweekProvider>
  </RecoilRoot>
);

const App: React.FC<Props> = ({ initPromise }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const catchAllErrorEnabled: boolean = useTweekValue(
    'asurion_hub/account/catch_all_error_enabled',
    false
  );
  useInitAccountProfile(true);
  useSessionTimeout();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initPromise.then(() => setIsLoaded(true));
  }, [initPromise]);
  if (!isLoaded) {
    return <LoadingPage />;
  }

  const renderedApp = (
    <div className="App">
      <Routes />
      <AnywhereExpertSdk />
      {process.env.NODE_ENV === 'development' && <ResponsiveIndicator />}
    </div>
  );

  if (catchAllErrorEnabled) {
    return (
      <ErrorBoundary FallbackComponent={CatchAllErrorPage}>
        {renderedApp}
      </ErrorBoundary>
    );
  }

  return renderedApp;
};

export default flow(
  withProviders,
  withNotifications,
  withAnalyticOnView<Props>({ analyticName: 'Loaded' }),
  enrichAnalytics((dispatcher) => dispatcher.createScoped('AsurionHub'))
)(hot(App));
