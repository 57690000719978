import * as React from 'react';
import styled from 'styled-components';
import { useWindowSize } from '../hooks';

export type CollapseProps = {
  isOpen: boolean;
  /* In milliseconds */
  duration?: number;
};

const CollapseContainer = styled.div<Required<CollapseProps>>`
  overflow-y: hidden;
  transition: ${({ duration }) =>
    `height ${duration}ms, opacity ${duration}ms`};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;

export const Collapse: React.FC<CollapseProps & { className?: string }> = (
  props
) => {
  const { isOpen, children, duration = 300, className } = props;
  const windowSize = useWindowSize();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (containerRef.current && contentRef.current) {
      containerRef.current.style.height = isOpen
        ? `${contentRef.current.clientHeight}px`
        : '0px';
    }
  }, [isOpen, windowSize]);

  return (
    <CollapseContainer
      ref={containerRef}
      isOpen={isOpen}
      duration={duration}
      className={className}
    >
      <div ref={contentRef}>{children}</div>
    </CollapseContainer>
  );
};
