import React, { useState } from 'react';
import { PlanDevice } from '../hocs';
import styled from 'styled-components';
import {
  DynamicLink,
  formatPhone,
  pxToEm,
  Breakpoints
} from '@asurion-hub-web/ui';
import MobileDeviceImage from './MobileDeviceImage';
import { Claim } from '@soluto-private/account-api-client';
import { getResponsiveImageUrlFromAssetAttributes } from '@asurion-hub/enrollment';

const breakpoint = Breakpoints.mobileSmall;

type DevicesProps = {
  planDevices: PlanDevice[];
  onInfoClick: (assetId: string) => void;
  onClaimClick: (claimId: string) => void;
  claims: Claim[];
};

const DeviceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: ${pxToEm(25)} 0;
  flex-wrap: wrap;
  @media ${breakpoint} {
    justify-content: center;
    border: 1px solid #D5D6DA;
    border-radius: 4px;
    padding: 22px;
  }
`;

const CustomLine = styled.div`
  border: 0;
  height: ${pxToEm(1)};
  background: #000;
  opacity: 0.1;
  margin-left: -16px;
  margin-right: -16px;
  @media ${breakpoint} {
    display: none;
  }
`;

const DeviceImageContainer = styled(MobileDeviceImage)`
  width: ${pxToEm(61)};
  max-width: ${pxToEm(140)};
  height: ${pxToEm(91)};
  max-height: initial;
  @media ${breakpoint} {
    height: ${pxToEm(87)};
  }
`;

const TextContainer = styled.div`
  margin-left: 10px;
  display: flex;
  max-width: 150px;
  flex-direction: column;
  justify-content: center;
`;

const StatusBanner = styled.div<{ contentVisible: boolean }>`
  ${(props) => (props.contentVisible ? 'background-color: #37E7A7;' : '')}
  width: fit-content;
  padding: ${pxToEm(2)} ${pxToEm(3)};
  font-family: 'Apercu-Regular-Pro';
  border-radius: ${pxToEm(2)};
  font-size: ${pxToEm(14)};
  line-height: ${pxToEm(14)};
  min-height: ${pxToEm(14)};
  margin-bottom: ${pxToEm(10)};
`;

const Button = styled(DynamicLink)`
  display: block;
  border: ${pxToEm(1)} solid black;
  font-size: ${pxToEm(14)};
  width: 100%;
  height: ${pxToEm(40)};
  background: black;
  border-radius: ${pxToEm(40)};
  padding: ${pxToEm(8)};
  color: white;
  z-index: 1;
  text-decoration: none;
  @media ${breakpoint} {
    width: ${pxToEm(200)};
  }
`;

const TrackClaimButton = styled(Button)`
  background: black;
  color: white;
`;

const DeviceDetailButton = styled(Button)`
  background: white;
  color: black;
  margin-top: 16px;
  @media ${breakpoint} {
    margin-top: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-top: 16px;
  width: 100%;
  @media ${breakpoint} {
    width: auto;
    margin-top: 0;
  }
`;

const ModelText = styled.div`
  font-size: ${pxToEm(16)};
  color: #6e767d;
`;

const PhoneNumberText = styled.div`
  font-size: ${pxToEm(20)};
  display: flex;
`;

const ShowButton = styled.button`
  width: 100%;
  margin: auto;
  text-align: center;
  background-color: white;
  margin-left: ${pxToEm(20)};
  margin-bottom: ${pxToEm(20)};
  border: none;
`;

const getClaimId = (planDevice: PlanDevice, claims: Claim[]): string => {
  const claim = claims.filter(
    (claim) => claim.subId === planDevice.subscriberId
  );
  if (claim.length > 0) {
    return claim[0].claimId;
  }
  return '';
};

const sortPlanDevices = (planDevices: PlanDevice[]): PlanDevice[] => {
  return [...planDevices].sort((a, b) =>
    a.hasClaimInProgress && !b.hasClaimInProgress ? -1 : 0
  );
};

type SingleDeviceProps = {
  planDevice: PlanDevice;
  claims: Claim[];
  onInfoClick: (assetId: string) => void;
  onClaimClick: (claimId: string) => void;
};

const SingleDevice: React.FC<SingleDeviceProps> = ({
  planDevice,
  claims,
  onInfoClick,
  onClaimClick,
}) => {
  return (
    <>
    <DeviceContainer>
      <DeviceImageContainer
        model={planDevice.model}
        responsiveImageUrl={getResponsiveImageUrlFromAssetAttributes(
          planDevice.assetCatalogAttributes
        )}
      />

      <TextContainer>
        {planDevice.hasClaimInProgress && (
          <StatusBanner
            contentVisible={planDevice.hasClaimInProgress}
          >
            {planDevice.hasClaimInProgress && 'Claim in progress'}
          </StatusBanner>
        )}
        <PhoneNumberText>
          <b>{formatPhone(planDevice.mobileDeviceNumber!)}</b>
        </PhoneNumberText>
        <ModelText data-testid="device-friendly-name">
          {planDevice.model}
        </ModelText>
      </TextContainer>
      <ButtonContainer>
        {planDevice.hasClaimInProgress && (
          <TrackClaimButton
            actionId="trackMyClaim_authHomePage"
            onClick={() => {
              onClaimClick(getClaimId(planDevice, claims));
            }}
          >
            Track my claim
          </TrackClaimButton>
        )}
        <DeviceDetailButton
          actionId="deviceAndPlanDetail_authHomePage"
          onClick={() => {
            onInfoClick(planDevice.assetId!);
          }}
        >
          Device and plan details
        </DeviceDetailButton>
      </ButtonContainer>
    </DeviceContainer>
    <CustomLine />
  </>
  )
}

export const Devices: React.FC<DevicesProps> = (props) => {
  const { planDevices } = props;
  const [showMoreActive, setShowMoreActive] = useState(false);

  return (
    <>
      {sortPlanDevices(planDevices).map((planDevice, index) => {
        if (index < 4) {
          return (
            <SingleDevice
              planDevice={planDevice}
              key={index}
              claims={props.claims}
              onInfoClick={props.onInfoClick}
              onClaimClick={props.onClaimClick}
            />
          );
        }
        return <></>;
      })}
      {showMoreActive && planDevices.length > 4 && (
        <>
          {sortPlanDevices(planDevices).map((planDevice, index) => {
            if (index >= 4) {
              return (
                <SingleDevice
                  planDevice={planDevice}
                  key={index}
                  claims={props.claims}
                  onInfoClick={props.onInfoClick}
                  onClaimClick={props.onClaimClick}
                />
              );
            }
            return <></>;
          })}
        </>
      )}
      {!showMoreActive && planDevices.length > 4 && (
        <ShowButton onClick={() => setShowMoreActive(true)}>
          Show all ({planDevices.length}) ↓
        </ShowButton>
      )}
      {showMoreActive && planDevices.length > 4 && (
        <ShowButton onClick={() => setShowMoreActive(false)}>
          Show Less ↑
        </ShowButton>
      )}
    </>
  );
};
