import { parse as parseQueryString } from 'query-string';

export const useQueryString = (key: string): string | undefined => {
  const value = parseQueryString(window.location.search)[key];
  if (!value) {
    return undefined;
  }
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};
