const ONBOARDING_PREFIX = '/o-tmp';
const ERROR_PREFIX = '/error';
declare const ROUTE_PREFIX: string;
export const INFO_NOT_CORRECT_ROUTE_BY_PLAN = `${ROUTE_PREFIX}${ERROR_PREFIX}/personal-information/plans/:subId?`;
export const DETAILS_NOT_FOUND_ROUTE = `${ROUTE_PREFIX}${ERROR_PREFIX}/details-not-found`;
export const ACCOUNT_CREATED_ROUTE = `${ROUTE_PREFIX}${ONBOARDING_PREFIX}/account-created`;
export const COLLECT_MDN_ROUTE = `${ROUTE_PREFIX}/collect-mdn`;
export const VERIFY_MDN_ROUTE = `${ROUTE_PREFIX}/verify-mdn`;
export const SESSION_TIMEOUT = `${ROUTE_PREFIX}${ERROR_PREFIX}/session-timeout`;
export const CATCH_ALL_ERROR = `${ROUTE_PREFIX}${ERROR_PREFIX}/general`;
export const DEVICE_DETAILS_ROUTE = `${ROUTE_PREFIX}/devices/:assetId?`;
export const CLAIM_DETAILS_ROUTE = `${ROUTE_PREFIX}/claim-details/:claimId?`;
export const CLAIM_DETAILS_BY_PLAN_ROUTE = `${ROUTE_PREFIX}/claim-details/:subscriberId?/:claimId?`;
export const SELECT_PARTNER_ROUTE = `${ROUTE_PREFIX}/select-partner`;
export const ARTICLE_ROUTE = `${ROUTE_PREFIX}/article`;
export const VERSION_ROUTE = `${ROUTE_PREFIX}/version`;
export const ROOT_ROUTE = `${ROUTE_PREFIX}/`;
export const LOGOUT_ROUTE = `${ROUTE_PREFIX}/logout`;
export const MOBILE_ARTICLE_ROUTE = `${ROUTE_PREFIX}/mobile-article`;
export class RouteBuilder {
  static routeForArticle(articleId: string) {
    return `${ARTICLE_ROUTE}/${articleId}`;
  }
  static routeForInfoNotCorrectByPlan(subId: string) {
    return `${INFO_NOT_CORRECT_ROUTE_BY_PLAN.replace(':subId', subId)}`;
  }

  static routeForClaimDetails(claimId: string) {
    return `${CLAIM_DETAILS_ROUTE.replace(':claimId', claimId)}`;
  }

  static routeForDeviceDetails(assetId?: string) {
    return DEVICE_DETAILS_ROUTE.replace(':assetId', assetId || '');
  }
}
