import { whatsNextReplaceStepIndexMap } from '@asurion-hub/enrollment';
import React from 'react';
import moment from 'moment';
import { enrichAnalytics } from 'react-shisell';
import { ReplacementClaimDetailsProps } from '../types';
import { getDeviceMakeModelString } from '../utils';
import { ClaimDetailsPageBase } from '../components/MultipleClaimTypeComponents/_ClaimDetailsPageBase';
import { ReplacementAccordion } from '../components/MultipleClaimTypeComponents';
import { ReplacementAccordionProps } from '../components/MultipleClaimTypeComponents/ReplacementAccordion';
import WhatsNextReplace from '../components/MultipleClaimTypeComponents/WhatsNextReplace';

const ReplacementAccordionElement: React.FC<ReplacementAccordionProps> = (
  props: ReplacementAccordionProps
) => {
  return <ReplacementAccordion {...props} />;
};

const ClaimDetailsReplacePage: React.FC<ReplacementClaimDetailsProps> = (
  props
) => {
  return (
    <ClaimDetailsPageBase
      claimType={props.claim.claimType}
      isLoading={props.isLoading}
      planIncludesSupport={props.planIncludesSupport}
      showSurveyCta={props.showSurveyCta}
      isClaimComplete={props.isClaimComplete as boolean}
      billingSummary={props.billingSummary!}
      accessories={props.claim.accessories}
      claimId={props.claim.claimId}
      isComplete={props.isLoading}
      surveyCTA={props.surveyCTA}
      mdn={props.claim.subscriberContactPhone!}
      status={props.claim.claimStatus!}
      notification={props.notification}
      setNotification={props.setNotification}
      fulfillmentDevice={props.fulfillmentDevice}
      replacementDeviceImageUrl={props.replacementDeviceImageUrl}
      detailAccordion={
        <ReplacementAccordionElement {...props.accordionProps} />
      }
      cta={props.cta}
      whatsNext={
        <WhatsNextReplace
          stepIndex={whatsNextReplaceStepIndexMap(props.claim)}
          confirmationEmail={props.shippingInfo?.email}
          claimSubmittedTime={props.claim.claimFiledDate!}
          trackingNumber={
            props.accordionProps.replacementDetails.trackingNumber
          }
          trackingUrl={props.accordionProps.replacementDetails.trackingUrl}
          deliveryDate={
            props.claim.fulfillmentInfo?.scheduledDate
              ? moment(props.claim.fulfillmentInfo.scheduledDate).format(
                  'MMMM Do YYYY, [at] h:mm a'
                )
              : 'Not available'
          }
          shipmentStatus={props.claim.claimStatus!}
          showDeviceSetupModule={props.showDeviceSetupModule}
          oldDeviceMakeModel={getDeviceMakeModelString(
            props.claim.originalDevice?.make,
            props.claim.originalDevice?.model
          )}
          newDeviceMakeModel={getDeviceMakeModelString(
            props.claim.fulfillmentInfo?.replacementDevice?.make,
            props.claim.fulfillmentInfo?.replacementDevice?.model
          )}
          deviceSetupLink={props.deviceSetupLink}
        />
      }
      claimDetails={{
        repairDate: props.claim.claimFiledDate,
        shipmentStatus: props.claim.claimStatus,
        repairLocation: props.claim.fulfillmentInfo?.address,
      }}
    />
  );
};

export default enrichAnalytics<ReplacementClaimDetailsProps>(
  (dispatcher, { showDeviceSetupModule }) =>
    dispatcher.withExtra('DeviceSetupModuleShown', showDeviceSetupModule)
)(ClaimDetailsReplacePage);
