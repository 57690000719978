import * as React from 'react';
import styled from 'styled-components';
import { DetailRow, SectionHeader } from '@asurion-hub-web/ui';
import { ShippingInfoProps } from '../types';

import shippedToIcon from '@asurion-hub-web/assets/images/icons/person-silhouette.svg';
import addressIcon from '@asurion-hub-web/assets/images/icons/address-book.svg';
import emailIcon from '@asurion-hub-web/assets/images/icons/envelope.svg';
import phoneIcon from '@asurion-hub-web/assets/images/icons/phone-ring.svg';

const Address = styled.address`
  font-style: normal;
`;

const ShippingInfo: React.FC<ShippingInfoProps> = (props) => {
  const addressLines = props.addressLines || [];
  const hasAddress = addressLines.length;
  return (
    <>
      <SectionHeader>Shipping info</SectionHeader>
      {props.name ? (
        <DetailRow
          blockFromAnalytics={true}
          leftIcon={shippedToIcon}
          rowTitle="Shipped to"
          primaryText={props.name}
        />
      ) : (
        <></>
      )}
      {hasAddress ? (
        <DetailRow
          blockFromAnalytics={true}
          leftIcon={addressIcon}
          rowTitle="Address"
          primaryText={
            <Address>
              {addressLines.map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Address>
          }
        />
      ) : (
        <></>
      )}
      <DetailRow
        blockFromAnalytics={true}
        leftIcon={emailIcon}
        rowTitle="Contact email"
        primaryText={props.email}
      />
      <DetailRow
        blockFromAnalytics={true}
        leftIcon={phoneIcon}
        rowTitle="Contact number"
        primaryText={props.phoneNumber}
      />
    </>
  );
};

export default ShippingInfo;
