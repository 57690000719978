/* eslint-disable */
import { CLAIM_DETAILS_ROUTE } from '@asurion-hub-web/config';
import { partnerConfig$ } from '@asurion-hub-web/partner-config-react';
import {
  AuthenticatedUser,
  AuthState,
  getSupportedPersonas,
  getTokenState,
} from '@asurion-hub/auth';
import { PartnerConfig } from '@asurion-hub/partner-config';
import { getTweekRepository } from '@asurion-hub/tweek';
import { analytics, session } from '@soluto-asurion/one-service-core';
import { analytics as shisell } from 'react-shisell';
import { AnalyticsEventModel } from 'shisell';
import {
  withExtra,
  withExtras,
  withFilter,
  withIdentity,
} from 'shisell/extenders';

const addAsurionId = (model: AnalyticsEventModel) => {
  if (AuthenticatedUser.State === AuthState.authenticated) {
    const user = AuthenticatedUser.get();
    model.Identities['AsurionId'] = user?.asurionId;
    const personas = getSupportedPersonas(partnerConfig$.value.id);
    personas.forEach(
      (p) => (model.ExtraData[`Persona_${p.sourcePlatform}`] = p.externalId)
    );
    model.ExtraData['Personas'] = personas.map(
      (p) => `${p.sourcePlatform}_${p.externalId}`
    );
  }
};

const addTokenState = (model: AnalyticsEventModel) => {
  model.ExtraData.TokenState = getTokenState();
};

const addExtenderForQueryParam = (
  paramName: string,
  propertyName: string,
  extender: any,
  extenders: any[]
) => {
  const qp = session.UserBrowser.get().sessionQueryParameters;
  if (!qp) {
    return;
  }

  const value = qp.get(paramName);
  if (!value) {
    return;
  }

  extenders.push(extender(propertyName, value));
};

const isClaimRedirect = (): boolean => {
  const isClaimsPage =
    window.location.pathname.split('/')[1] ===
    CLAIM_DETAILS_ROUTE.split('/')[1];
  const qp = session.UserBrowser.get().sessionQueryParameters;
  return isClaimsPage && qp
    ? Boolean(qp.get('session_state') && qp.get('code'))
    : false;
};

export default function initAnalytics() {
  const dispatcher = analytics.getDispatcher();
  shisell.transformDispatcher(() => {
    const extenders = [withFilter(addAsurionId), withFilter(addTokenState)];

    // [queryParamName, analyticsPropertyName, typeOfExtender]
    const queryParamsBasedProperties = [
      ['HomeUserId', 'HomeUserId', withIdentity],
      ['tempId', 'CampaignTemplateId', withExtra],
      ['journeyId', 'CampaignJourneyId', withExtra],
      ['posId', 'CampaignPositionId', withExtra],
      ['partner', 'CampaignPartner', withExtra],
      ['campaign', 'Campaign', withExtra],
      ['subscriberID', 'SubscriberId', withIdentity],
    ];

    /**
     * TODO: This is a temporary hack to set the correct CampaignJourneyId when
     * a user is redirected from the claims site to the hub. Eventually the
     * claims team will pass the journeyId param so we can use the standard
     * query param handling.
     */
    if (isClaimRedirect()) {
      extenders.push(withExtra('CampaignJourneyId', 'claim_redirect'));
      extenders.push(withExtra('CampaignPartner', 'VZW'));
    }

    queryParamsBasedProperties.forEach((qp) =>
      addExtenderForQueryParam(
        qp[0] as string,
        qp[1] as string,
        qp[2],
        extenders
      )
    );
    return dispatcher!.extend(...extenders);
  });
}

export const addPartnerToAnalytics = async () => {
  partnerConfig$.subscribe((partner: PartnerConfig) =>
    shisell.transformDispatcher((dispatcher) =>
      dispatcher!.extend(withExtras({ Partner: partner.id }))
    )
  );
};

export const addFeatureFlagsToAnalytics = async () => {
  const plansFromCustomerApiEnabled = await getTweekRepository().getValue<boolean>(
    'asurion_hub/account/fetch_plans_from_customer_api_enabled'
  );
  const claimsV5Enabled = await getTweekRepository().getValue<boolean>(
    'asurion_hub/claims/use_v5'
  );
  shisell.transformDispatcher((dispatcher) => {
    return dispatcher!.extend(
      withExtras({
        ClaimsV5Enabled: claimsV5Enabled,
        PlansFromCustomerApiEnabled: plansFromCustomerApiEnabled,
      })
    );
  });
};
