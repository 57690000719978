import { useEffect } from 'react';
import { Notification } from './Notification';

const DEFAULT_AUTODISMISS_DELAY_MS = 5000;

export const useAutoDismiss = ({
  autoDismiss = false,
  onClose = () => undefined,
}: Pick<Notification, 'autoDismiss' | 'onClose'>) =>
  useEffect(() => {
    if (autoDismiss) {
      const intervalId = setInterval(onClose, DEFAULT_AUTODISMISS_DELAY_MS);
      return () => clearInterval(intervalId);
    }
    return;
  }, [autoDismiss, onClose]);
