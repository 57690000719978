import * as React from 'react';
import styled from 'styled-components';
import { Breakpoints, Button, pxToEm } from '@asurion-hub-web/ui';
import * as assets from '../assets';
import { useServicePlan } from '@asurion-hub-web/enrollment';

const Container = styled.div`
  position: relative;
  width: fill-available;
  margin: ${pxToEm(16)};
  font-family: Apercu-Regular-Pro;
  background: #f0f0f5;
  background-image: url(${assets.upsellDevicesMobileImage});
  background-position: calc(100% + 2px) 100%;
  background-repeat: no-repeat;
  @media ${Breakpoints.tablet} {
    background-image: url(${assets.upsellDevicesDesktopImage});
    background-size: contain;
  }
`;

const ContentContainer = styled.div`
  margin: 45px 0 0 54px;
`;

const CornerBanner = styled.div`
  position: absolute;
  width: 75px;
  height: 64px;
  margin-top: -4px;
  margin-left: -3px;
  background-image: url(${assets.upsellCornerBanner});
`;

const PartnerLogo = styled.img``;

const Paragraph = styled.p`
  line-height: 1.5;
  margin: 0;
`;

const Heading = styled(Paragraph)`
  font-family: VerizonNHGTX;
  font-size: 28.75px;
  font-weight: bold;
  margin: 8px 0;
`;

const Subheading = styled(Paragraph)`
  font-size: ${pxToEm(20)};
  margin-bottom: ${pxToEm(8)};
`;

const PreButtonHeading = styled.div`
  font-size: ${pxToEm(18)};
  margin: ${pxToEm(8)} 0;
`;

const BulletList = styled.ul`
  font-size: ${pxToEm(14)};
  margin: 0;
  padding: 0;
  list-style: none;
`;

const BulletListItem = styled.li`
  line-height: 1.5;
  :before {
    content: '\\2022'; /* &bull; */
    margin-right: ${pxToEm(8)};
  }
  max-width: 58%;
  @media ${Breakpoints.tablet} {
    max-width: unset;
  }
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  margin: ${pxToEm(20)} ${pxToEm(8)};
`;

const BoldText = styled(Paragraph)`
  font-weight: bold;
  display: contents;
`;

const onUpsellClicked = (url?: string) => () => {
  if (url) {
    window.open(url, '_blank');
  }
};

interface Props {
  upsellUrl?: string;
}

const UpsellCTA: React.FC<Props> = ({ upsellUrl }) => {
  return (
    <Container>
      <CornerBanner />
      {/* <UpsellDevicesImage /> */}
      <ContentContainer>
        <PartnerLogo src={assets.vzwLogo} alt="Verizon Logo" />
        <Heading>Protect Home</Heading>
        <Subheading>The easy way to protect your tech</Subheading>
        <BulletList>
          <BulletListItem>
            Cracks? Breaks? Spills? We’ll repair or replace it
          </BulletListItem>
          <BulletListItem>
            Need help? US-based experts are here for you 24/7/365
          </BulletListItem>
          <BulletListItem>
            No matter when or where you bought your devices - we cover it
          </BulletListItem>
        </BulletList>
        <PreButtonHeading>
          All for just
          <BoldText> $25/month</BoldText>
        </PreButtonHeading>
        <ButtonWrapper>
          <Button
            disabled={false}
            class="primary"
            actionId="UpsellSeeDetails"
            onClick={onUpsellClicked(upsellUrl)}
          >
            See details &gt;
          </Button>
        </ButtonWrapper>
      </ContentContainer>
    </Container>
  );
};

const UpsellCTAContainer: React.FC = () => {
  const homePlan = useServicePlan('VPH');
  if (homePlan.servicePlanIsFetching || homePlan.servicePlanError) {
    return null;
  }
  return <UpsellCTA upsellUrl={homePlan?.servicePlan?.infoUrl} />;
};

export default UpsellCTAContainer;
