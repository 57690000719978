import React from 'react';
import styled from 'styled-components';
import * as SimpleLayout from './SimpleLayout';

type HeadingWithIconProps = {
  iconSrc: string;
};

const Icon = styled.div<Pick<HeadingWithIconProps, 'iconSrc'>>`
  position: absolute;
  background-image: url(${(props) => props.iconSrc});
  width: 24px;
  height: 24px;
  margin: 0 0 0 1.75em;
`;

const Heading = styled(SimpleLayout.Heading)`
  font-weight: bold;
  font-family: Apercu-Regular-Pro;
  line-height: 150%;
  font-size: 20px;
  --paragraph-font-size: 1em;
  --paragraph-line-height: 1em;
  position: relative;
  margin: 0 auto 0.75em 3.5em;
  top: 5px;
  width: calc(100% - 3.5em);
`;

const HeadingWithIconContainer = styled.div`
  position: relative;
`;

const HeadingWithIcon: React.FC<HeadingWithIconProps> = (props) => {
  return (
    <HeadingWithIconContainer>
      <Icon iconSrc={props.iconSrc} />
      <Heading>{props.children}</Heading>
    </HeadingWithIconContainer>
  );
};

export default HeadingWithIcon;
