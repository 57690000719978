import Button, { ButtonProps } from './ButtonWithAnalytics';
import CloseButton from './CloseButton';
import { noop } from '@asurion-hub-web/utils';
import * as React from 'react';
import styled from 'styled-components';
import { pxToEm } from '../utils';

type ButtonConfig = ButtonProps & {
  content: React.ReactNode;
};

type CalloutProps = {
  color?: string;
  headline: string;
  imageUrl?: string;
  imageAltText?: string;
  hasCloseButton?: boolean;
  onClose?: () => void;
  buttons?: Array<ButtonConfig>;
  isOpen?: boolean;
};

const Headline = styled.span`
  font-size: ${pxToEm(20)};
  font-family: Apercu-Bold-Pro;
  line-height: 1.5;
`;

const Container = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor || '#fff'};
  border: ${(props) => (props.backgroundColor ? 'none' : '1px solid #000')};
  padding: ${pxToEm(20)};
  margin: ${pxToEm(30)} ${pxToEm(15)};
`;

const Content = styled.div`
  font-size: ${pxToEm(16)};
  line-height: 1.5;
  margin: ${pxToEm(6)} 0;
`;

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Image = styled.img`
  overflow: hidden;
  object-fit: contain;
  max-height: ${pxToEm(120)};
  margin-left: ${pxToEm(20)};
`;

const ButtonsImageWrapper = styled.div`
  display: flex;
`;

const CloseButtonContainer = styled.div`
  float: right;
  margin: -0.5em;
`;

const ButtonWrapper = styled.div`
  margin: ${pxToEm(6)} 0;
  --button-background-color--hover: none;
`;

const Callout: React.FC<CalloutProps> = (props) => {
  const {
    color,
    headline,
    imageUrl,
    imageAltText,
    hasCloseButton,
    onClose = noop,
    isOpen = true,
    buttons,
    children,
  } = props;

  return isOpen ? (
    <Container backgroundColor={color}>
      {hasCloseButton && (
        <CloseButtonContainer>
          <CloseButton onClick={onClose} ariaLabel="close notification" />
        </CloseButtonContainer>
      )}
      <Headline>{headline}</Headline>
      <Content>{children}</Content>
      <ButtonsImageWrapper>
        <ButtonsContainer>
          {buttons?.map(({ content, ...buttonProps }, i) => {
            return (
              <ButtonWrapper key={i}>
                <Button {...buttonProps}>{content}</Button>
              </ButtonWrapper>
            );
          })}
        </ButtonsContainer>
        <Image src={imageUrl} alt={imageAltText} />
      </ButtonsImageWrapper>
    </Container>
  ) : null;
};

export default Callout;
