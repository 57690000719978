import * as React from 'react';
import { useSearchParam } from 'react-use';
import { Redirect } from 'react-router-dom';
import { useTweekValue } from 'react-tweek';
import { SelectPartnerPage } from './SelectPartnerPage';
import { supportedPartners, Partner } from '@asurion-hub/partner-config';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import { PARTNER_PICKER_ENABLED } from '@asurion-hub-web/config';

export const SelectPartnerPageContainer: React.FC = () => {
  const [,setPartner] = usePartnerConfig();
  const [toUrl, setToUrl] = React.useState<string>();
  const redirectUrl = decodeURIComponent(useSearchParam('redirect_url') || '');
  const fallbackUrl = '/';
  const isEnabled = useTweekValue(PARTNER_PICKER_ENABLED, false);

  if (!isEnabled) {
    return <Redirect to="/" />;
  }

  return toUrl ? (
    <Redirect to={toUrl || fallbackUrl} />
  ) : (
    <SelectPartnerPage onSubmit={(partner) => {
      if (partner) {
        if (partner.id in supportedPartners) {
          setPartner(partner.id as Partner);
          setToUrl(redirectUrl || fallbackUrl);
        } else if ('url' in partner) {
          setToUrl(partner.url);
        } else {
          setToUrl(fallbackUrl);
        }
      }
    }} />
  );
};
