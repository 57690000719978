import defaultDevice from '@asurion-hub-web/assets/images/default_device.jpg';
import { pxToEm } from '@asurion-hub-web/ui';
import React, { SyntheticEvent, useState } from 'react';
import styled from 'styled-components';

interface Props {
  model?: string;
  responsiveImageUrl?: string;
  className?: string;
  alt?: string;
  onError?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
}

const PhoneImage = styled.img<{ isVisible?: boolean; isActual: boolean }>`
  object-fit: contain;
  max-height: ${pxToEm(200)};
  ${(props) => (props.isActual ? '' : 'opacity: .4;')}

  ${(props) => (props.isVisible === false ? 'display: none;' : '')}
`;

const showDefaultImage = (event: SyntheticEvent<HTMLImageElement, Event>) => {
  /* eslint-disable no-param-reassign */
  event.currentTarget.src = defaultDevice;
};

const buildUrl = (model: string) => {
  return `https://s3.amazonaws.com/assetcatalog-images/Responsive/${model}_responsive.png`;
};

const buildRespImgUrl = (responsiveImageUrl: string) => {
  return `https://s3.amazonaws.com/assetcatalog-images/Responsive/${responsiveImageUrl}`;
};

const MobileDeviceImage: React.FC<Props> = ({
  model,
  responsiveImageUrl,
  className,
  alt = 'Your device, protected by Asurion',
  onError = showDefaultImage,
}) => {
  const [showActualImage, setShowActualImage] = useState(false);
  return (
    <>
      <PhoneImage
        className={className}
        onError={onError}
        onLoad={() => setShowActualImage(true)}
        isVisible={showActualImage}
        isActual={true}
        src={
          responsiveImageUrl
            ? buildRespImgUrl(responsiveImageUrl)
            : model
            ? buildUrl(model)
            : defaultDevice
        }
        alt={alt}
      />
      {!showActualImage && (
        <PhoneImage
          className={className}
          onError={onError}
          isActual={false}
          src={defaultDevice}
          alt={alt}
        />
      )}
    </>
  );
};
export default MobileDeviceImage;
