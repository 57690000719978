import React from 'react';
import styled from 'styled-components';
import { pxToEm, TextInput } from '@asurion-hub-web/ui';
import magnifyingGlass from '@asurion-hub-web/assets/images/icons/magnifying-glass.svg';
import { useTextFilters } from '@asurion-hub-web/devices-search';

const SearchTextInput = styled(TextInput)<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-position: 98% center;
  background-repeat: no-repeat;
  border-color: #eee;
  border-radius: ${pxToEm(5)};
`;

const SearchInput: React.FC = () => {
  const [searchText, setSearchText] = useTextFilters();
  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSearchText(event.target.value);
  };

  return (
    <SearchTextInput
      className="fs-exclude"
      onChange={onChangeSearch}
      value={searchText}
      maskConfig={{
        mask: false,
        showMask: false,
        guide: false,
      }}
      id="searchText"
      data-testid="add-device-searchTextInput"
      image={magnifyingGlass}
    />
  );
};

export default SearchInput;
