import { useCallback } from 'react';
import {
  atom,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { Filter } from './filter';

export const selectedFilters = atom<Filter[]>({
  key: 'selectedFilters',
  default: [],
});

export const useSelectedFilters = () => useRecoilValue(selectedFilters);
export const useSetSelectedFilters = () => useSetRecoilState(selectedFilters);
export const useSelectedFiltersRemoveAndAddByType = () => {
  const setSelectedFilters = useSetSelectedFilters();

  return useCallback(
    (filtersToRemove: Pick<Filter, 'type'>[], filtersToAdd: Filter[]) => {
      setSelectedFilters((prev) => {
        return [
          ...prev.filter(
            (f) => !filtersToRemove.some((e) => e.type === f.type)
          ),
          ...filtersToAdd,
        ];
      });
    },
    [setSelectedFilters]
  );
};

export const useResetSelectedFilters = () =>
  useResetRecoilState(selectedFilters);
