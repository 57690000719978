import React from 'react';
import { CtaProps } from '../types';
import { default as BaseCta } from './_Base';

export const IconCta: React.FC<CtaProps> = ({
  id,
  color,
  headline,
  content,
  buttonText,
  buttonDestination,
  buttonActionId,
  hyperlinkDestination,
  hyperlinkText,
  image,
  imageAltText,
  imageOrientation,
  ctaVariant,
  fullWidth,
}) => (
  <BaseCta
    {...{
      id,
      color,
      headline,
      content,
      buttonText,
      buttonDestination,
      buttonActionId,
      image,
      imageAltText,
      imageOrientation,
      ctaVariant,
      fullWidth,
    }}
  >
    {hyperlinkDestination && hyperlinkText && (
      <a href={hyperlinkDestination}>{hyperlinkText}</a>
    )}
  </BaseCta>
);
