import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import Breakpoints from '../../Breakpoints';
import { useScrollPosition, useWindowSize } from '../../hooks';
import { pxToEm } from '../../utils';
import DynamicLink from '../DynamicLink';
import { TabNavProps } from './models';

const tabNavHeight = 48;
const breakpoint = Breakpoints.desktop;

const TabNavContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TabsPlaceholder = styled.div<{ isSticky: boolean }>`
  height: ${tabNavHeight}px;
  position: sticky;
`;

const Tabs = styled.div<{
  isSticky: boolean;
  indicatorRefElement?: HTMLSpanElement;
}>`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  height: ${tabNavHeight}px;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  transform: translateY(-100%);

  ${(props) =>
    props.isSticky &&
    `
    position: fixed;
    transform: none;
    top: 0;
    z-index: 5;
  `}

  @media ${breakpoint} {
    position: absolute;
    justify-content: left;
    top: auto;
    transform: translateY(-100%);
    margin: 0;
  }

  /* Active tab indicator */
  :before {
    content: '';
    position: absolute;
    background: #000;
    z-index: 1;
    bottom: 0;
    height: 4px;
    ${(props) =>
      props.indicatorRefElement &&
      `
      transition: left 0.5s, width 0.5s;
      width: ${props.indicatorRefElement.clientWidth}px;
      left: ${props.indicatorRefElement.offsetLeft}px;
    `}
  }

  /* Background and bottom border */
  :after {
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    top: 0;
    left: -100px;
    right: -100px;
    bottom: 0;
    margin: auto;
    z-index: -1;
    border-bottom: 2px solid #f3f3f3;
    @media ${breakpoint} {
      left: 0;
      right: 0;
    }
  }
`;

const Tab = styled(DynamicLink)<{ tabCount: number }>`
  cursor: pointer;
  font-family: Apercu-Regular-Pro;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  font-size: ${pxToEm(16)};
  width: ${(props) => 100 / props.tabCount}%;
`;

const TabInner = styled.span<{ isActive: boolean }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TabPanels = styled.div`
  position: relative;
  display: flex;
  max-height: calc(100% - ${tabNavHeight}px);
`;

const TabPanel = styled.div<{ isSticky: boolean; isActive: boolean }>`
  width: 100%;
  transition: opacity
    ${(props) => {
      if (!props.isSticky) {
        return '0.5s';
      }
      if (props.isActive) {
        return '0.5s 1ms';
      }
      return '0s';
    }};
  position: ${(props) => (props.isActive ? 'static' : 'absolute')};
  top: 0;
  bottom: 0;
  z-index: ${(props) => (props.isActive ? 0 : -1)};
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  overflow-y: ${(props) => (props.isActive ? 'auto' : 'hidden')};
`;

type TabInnerRefElement = HTMLSpanElement;
type TabInnerRef = React.RefObject<TabInnerRefElement>;

function TabNav<TTabKey extends string, TTabProps = unknown>(
  props: TabNavProps<TTabKey, TTabProps>
): ReturnType<React.FC> {
  const {
    tabs,
    activeTabKey,
    setActiveTabKey,
    onSetActiveTab,
    isSticky = false,
    tabProps = {},
  } = props;
  const [tabsPosition, setTabsPosition] = useState<number>();
  const [isCurrentlySticky, setIsCurrentlySticky] = useState(false);
  const [tabInnerRefs, setTabInnerRefs] = useState<Array<TabInnerRef>>([]);
  const [activeTabRef, setActiveTabRef] = useState<TabInnerRef>();
  const [topOffset] = useState(0);
  const windowSize = useWindowSize();
  const pageScrollPosition = useScrollPosition();
  const tabsRef = React.createRef<HTMLDivElement>();

  useLayoutEffect(() => {
    setTabInnerRefs(tabs.map(() => React.createRef()));
  }, [tabs]);

  useLayoutEffect(() => {
    if (tabsRef && tabsRef.current) {
      setTabsPosition(
        tabsRef.current.getBoundingClientRect().top +
          pageScrollPosition.scrollY -
          topOffset
      );
    }
  }, [windowSize, pageScrollPosition, topOffset, tabsRef]);

  useLayoutEffect(() => {
    if (tabsPosition !== undefined) {
      setIsCurrentlySticky(
        isSticky && pageScrollPosition.scrollY > tabsPosition
      );
    }
  }, [tabsPosition, pageScrollPosition, isSticky]);

  useLayoutEffect(() => {
    const activeTabIndex = tabs.findIndex((tab) => tab.key === activeTabKey);
    setActiveTabRef(tabInnerRefs[activeTabIndex]);
  }, [activeTabKey, tabInnerRefs, tabs]);

  const setActiveTab = (tabKey: TTabKey) => {
    setActiveTabKey(tabKey);
    if (tabsPosition && pageScrollPosition.scrollY > tabsPosition) {
      window.scrollTo(0, tabsPosition);
    }
    if (onSetActiveTab) {
      onSetActiveTab(tabKey);
    }
  };

  return (
    <TabNavContainer>
      <TabsPlaceholder ref={tabsRef} isSticky={isCurrentlySticky} />
      <Tabs
        isSticky={isCurrentlySticky}
        indicatorRefElement={
          activeTabRef && activeTabRef.current
            ? activeTabRef.current
            : undefined
        }
      >
        {tabs.map((tab, i) => (
          <Tab
            key={tab.key}
            tabCount={tabs.length}
            onClick={setActiveTab.bind(null, tab.key)}
            actionId={`TabNav-${props.tabNavId}-${tab.key}`}
          >
            <TabInner ref={tabInnerRefs[i]} isActive={tab.key === activeTabKey}>
              {tab.label}
            </TabInner>
          </Tab>
        ))}
      </Tabs>
      <TabPanels>
        {tabs.map(({ key, Component }, i) => (
          <TabPanel
            key={i}
            isSticky={isCurrentlySticky}
            isActive={key === activeTabKey}
          >
            <Component
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              {...(tabProps as any)}
              activeTabKey={activeTabKey}
              setActiveTabKey={setActiveTabKey}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </TabNavContainer>
  );
}

export default TabNav;
