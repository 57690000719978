import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { PartnerConfig, Partner } from '@asurion-hub/partner-config';
import { setPartner as baseSetPartner, partnerConfig$ } from './subject';
import { PARTNER_STORAGE_KEY } from './constants';

export const useStoredPartner = (): [Partner | undefined, (partner: Partner) => void] => {
  const [partner, setPartner] = useState<Partner>();
  const [,setStoredPartner] = useLocalStorage<Partner | undefined>(PARTNER_STORAGE_KEY, undefined, { raw: true });
  const updateHasPartner = () => {
    setPartner(localStorage.getItem(PARTNER_STORAGE_KEY) as Partner);
  }

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener('storage', updateHasPartner);
    // eslint-disable-next-line no-restricted-globals
    return () => removeEventListener('storage', updateHasPartner);
  }, []);

  return [partner, setStoredPartner];
}

export const usePartnerConfig = (): [
  PartnerConfig,
  (partner: Partner) => void
] => {
  const [storedPartner, setStoredPartner] = useStoredPartner();
  const [partnerConfig, setPartnerConfig] = useState(partnerConfig$.value);

  useEffect(() => {
    const subscription = partnerConfig$.subscribe((config) => {
      setPartnerConfig(config);
    });
    if (storedPartner && storedPartner !== partnerConfig.id) {
      baseSetPartner(storedPartner);
    }
    return () => subscription.unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setPartner = (partnerId: Partner) => {
    baseSetPartner(partnerId);
    setStoredPartner(partnerId);
    dispatchEvent(new Event('storage'));
  };

  return [partnerConfig, setPartner];
};
